import React from "react";
import { Container, Typography, Box } from "@mui/material";

const LaunchInstance = () => {
  return (
    <Box
      sx={{
        padding: "150px 25px 25px 25px",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "1.5rem",
            md: "1.5rem",
            lg: "2.5rem",
          },
          textAlign: "center",
        }}
      >
        Launch an On-Demand GPU Instance
      </Typography>
      <Typography
        variant="p"
        component="p"
        sx={{
          fontSize: {
            xs: "0.875rem",
            sm: "1rem",
            md: "1.125rem",
            lg: "1.25rem",
          },
          maxWidth: "600px",
          margin: "0 auto",
          marginBottom: "20px",
        }}
      >
        Get started with launching your on-demand GPU instance in just a few
        steps. Choose from a variety of GPU models, configure your instance, and
        deploy it within minutes. NVIDIA H100 instances are available starting
        at $2.49/hr.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {/* Add more detailed content, forms, or steps here */}
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontSize: {
              xs: "0.75rem",
              sm: "0.875rem",
              md: "1rem",
              lg: "1.125rem",
            },
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          1. Select your GPU model
          <br />
          2. Configure instance settings
          <br />
          3. Review and launch your instance
        </Typography>
      </Box>
    </Box>
  );
};

export default LaunchInstance;
