import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import chintu from "./../../../../Assets/Images/chintu.gif";

const Chintu = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "180px 20px",
          sm: "150px 40px",
          md: "150px 60px",
          lg: "110px 80px 80px 80px",
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} textAlign="left">
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "2.5rem",
                lg: "3.5rem",
              },
            }}
          >
            Meet Chintu: Your AI Assistance for Compute.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={chintu}
            alt="Chintu"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, textAlign: "left" }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.5rem",
              md: "1.75rem",
              lg: "2rem",
            },
          }}
        >
          Unlocking Efficiency and Productivity
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: {
              xs: "0.875rem",
              sm: "1rem",
              md: "1.125rem",
              lg: "1.25rem",
            },
            mb: 4,
          }}
        >
          In India, the endearing nickname “Chintu” is often used to refer to a
          younger person. It’s a term of familiarity, suggesting that Chintu can
          help you get things done. But what if we could all have a “Chintu” in
          our office—an AI-powered assistant ready to tackle our needs?
        </Typography>
        <Typography
          variant="h4"
          component="h3"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.5rem",
              sm: "1.75rem",
              md: "2rem",
              lg: "2.5rem",
            },
            mb: 4,
          }}
        >
          Introducing Chintu by TechOptima: India’s First AI Bot
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: {
              xs: "0.875rem",
              sm: "1rem",
              md: "1.125rem",
              lg: "1.25rem",
            },
            mb: 4,
          }}
        >
          Chintu is more than just a nickname; it’s a smart solution. As India’s
          pioneering AI bot, Chintu is here to assist you across various
          domains, especially in the field of artificial intelligence. Just like
          chatGPT, Chintu is your go-to resource for AI engineering, cloud
          computing, and any other services you require.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: {
              xs: "0.875rem",
              sm: "1rem",
              md: "1.125rem",
              lg: "1.25rem",
            },
          }}
        >
          Whether you’re optimizing algorithms, deploying machine learning
          models, or exploring cloud-based solutions, Chintu has your back. Say
          goodbye to manual tasks and hello to seamless assistance with Chintu
          by your side.
        </Typography>
      </Box>
    </Box>
  );
};

export default Chintu;
