import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../Assets/Images/OIP.jpeg";

const LearnMoreForAI = () => {
  return (
    <Box
      sx={{
        padding: "150px 20px  40px 20px",
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
            xl: "3.5rem",
          },
          // marginBottom: {
          //   xs: "20px",
          //   sm: "25px",
          //   md: "30px",
          //   lg: "35px",
          //   xl: "40px",
          // },
        }}
      >
        Your Data. Your AI. Your Compute. Your Future.
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src="https://img.freepik.com/free-photo/business-people-meeting-looking-presentation_9975-23062.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890"
            alt="About TechOptima"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.3rem",
                xl: "1.4rem",
              },
              // marginBottom: {
              //   xs: "15px",
              //   sm: "20px",
              //   md: "25px",
              //   lg: "30px",
              //   xl: "35px",
              // },
            }}
          >
            <h3>Discover the Future with TechOptima</h3>
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.1rem",
                lg: "1.2rem",
                xl: "1rem",
              },
            }}
          >
            "At TechOptima, we believe in empowering you with the tools and
            technologies that define the future. Our focus spans across data
            management, artificial intelligence, and high-performance computing.
            <br />
            <br />
            We are dedicated to providing solutions that are tailored to meet
            the unique needs of your business. Our commitment to innovation
            ensures that we stay ahead of the curve, bringing you the best in
            technological advancements.
            <br />
            <br />
            Join us on a journey to explore the endless possibilities that
            technology offers. Stay informed about our latest projects and
            developments as we shape the future together."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LearnMoreForAI;
