import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const TermsofService = () => {
  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: "var(--buttonHover)", padding: "210px 20px 0 20px" }}
      >
        <Grid item xs={12}>
          <Container
            maxWidth="lg"
            sx={{ backgroundColor: "white", padding: "16px" }}
          >
            <Typography variant="h4" component="h1" sx={{ color: "navy" }}>
              Techoptima Terms of Service
            </Typography>
          </Container>
        </Grid>
      </Grid>

      <Container maxWidth="lg" sx={{ padding: "16px" }}>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h5" component="h3">
            Welcome to Techoptima Terms of Service!
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            We invite you to explore and utilize the content and features
            available on our website.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Website Content
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Techoptima owns the website and all translations. You may access and
            use the website for internal information purposes only, without
            altering, copying, or redistributing the content. Any expanded use
            requires written approval from Techoptima.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Changes or Updates
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Techoptima may change, update, or discontinue any aspect of the
            website at any time without notice. Continued use signifies your
            agreement to any modifications.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Techoptima provides the website "as is" without any warranties,
            including merchantability and fitness for a particular purpose.
            While we take steps to prevent infections, we can't guarantee the
            website will be free of viruses or available at all times.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Accuracy of Information
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Although sourced from reliable places, Techoptima doesn't guarantee
            the accuracy, completeness, or adequacy of the information on the
            website. Users are responsible for how they use this information.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Third-Party Links
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Our website may contain links to third-party websites for user
            convenience. Techoptima does not endorse or take responsibility for
            these sites.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Techoptima is not liable for any damages arising from the use or
            inability to use the website or any inaccuracies in the information
            provided.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Termination
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Users must comply with all laws when using the website. Techoptima
            can terminate access at any time for any reason. Disclaimer of
            Warranty, Accuracy of Information, Indemnification, and Third-Party
            Rights clauses remain effective post-termination.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Indemnification
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Users agree to indemnify and defend Techoptima and its affiliates
            against all losses, expenses, damages, and costs from misuse of the
            website.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Third-Party Rights
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Disclaimer of Warranty, Limitation of Liability, and Indemnification
            clauses benefit Techoptima and its affiliates, who can enforce these
            terms.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            General Provisions
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Inconsistent provisions in any communications are void. If any term
            is invalidated by a court, the remaining terms stay effective.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Governing Law
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            These terms are governed by Connecticut law. Users consent to the
            jurisdiction of Connecticut courts.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Privacy Statement
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Techoptima values your privacy. When you register, we collect
            personal information to provide a personalized online experience. We
            do not share this information with third parties.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Restriction on Use
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Users must not violate website security, overload the
            infrastructure, use automated tools to access the site without
            consent, or use the site's data to develop AI or other technologies
            that infringe on intellectual property rights.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Claims of Copyright Infringement
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            We respect intellectual property rights and address infringement
            claims under the DMCA. Submit claims to our designated agent with
            required information.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Digital Services Act (DSA) Notice (for EU users)
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            We comply with the DSA to ensure a safe online environment. Report
            illegal content or activities to legalcompliance@techoptima.com with
            a detailed description and your contact details.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsofService;
