import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import "../Services.css";

const AILedQuality = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // marginTop: "200px",
      }}
    >
      {/* <Box sx={{ padding: 2, maxWidth: "800px", width: "100%" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          AI Led Quality
        </Typography>
        <Paper sx={{ padding: 2, boxShadow: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, marginRight: 2 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Model Validation
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Bench Marketing(IP)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Rebustness & Performance" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Explainability & Interpretability" />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Model Reproducibility
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Model Catalog" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Model Metadata Management" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Defect Prediction, Test Metrics & Reporting" />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Paper>
      </Box> */}
    </Box>
  );
};

export default AILedQuality;
