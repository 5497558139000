import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import optima from "../../../Assets/Images/optimalab.jpg";
import optima2 from "../../../Assets/Images/optimalab2.jpg";
import optima3 from "../../../Assets/Images/optimalab3.jpg"
import optima4 from "../../../Assets/Images/optimalab4.jpg"
import optima5 from "../../../Assets/Images/optimalab5.jpg"




const Title = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(3),
  textAlign: "center",
}));

const Section = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}));

const ListTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const OptimaLabs = () => {
  return (
    <Container maxWidth="lg" style={{ marginTop: "150px", padding: "0 16px" }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        Pioneering the Future with Optima Labs
      </Typography>

      <Section>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <img
              src={optima} // replace with your image source
              alt="TechOptima Vision"
              style={{ width: "100%", height: "350px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" paragraph>
              At TechOptima, we believe in pushing the boundaries of what’s possible
              with technology. Our mission is to empower businesses to thrive in an
              ever-evolving digital landscape through innovative AI solutions,
              cutting-edge cloud infrastructure, and a relentless focus on
              optimization. Welcome to the next generation of tech
              innovation—welcome to Optima Labs.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="h4" gutterBottom>
              What is Optima Labs?
            </Typography>
            <Typography variant="body1" paragraph>
              Optima Labs is the beating heart of TechOptima, where visionary ideas
              meet advanced technology to create transformative solutions. It’s
              where our brightest minds collaborate, innovate, and experiment,
              driving the development of cutting-edge AI applications and optimized
              infrastructure that propel businesses into the future.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <img
              src={optima2} // replace with your image source
              alt="Optima Labs"
              style={{ width: "100%", height: "350px" }}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <img
              src={optima3} // replace with your image source
              alt="Our Vision"
              style={{ width: "100%", height: "350px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Our Vision:
            </Typography>
            <Typography variant="body1" paragraph>
              At Optima Labs, we envision a world where technology seamlessly
              integrates with every facet of business, enhancing efficiency, driving
              growth, and fostering innovation. We are committed to developing
              solutions that not only meet the needs of today but anticipate the
              challenges of tomorrow.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography variant="h4" gutterBottom>
          Core Offerings at Optima Labs:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="OPTIMA-X Framework"
              secondary="Our signature OPTIMA-X framework encapsulates the essence of our approach—Optimized Operations, Predictive Power, Transformative Technology, Intelligent Integration, Modular Scalability, Adaptive Solutions, and eXponential Innovation. Through this framework, we deliver AI solutions that are scalable, intelligent, and future-ready."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI 360 Services"
              secondary="From Applied AI and Data Science to AI Quality and Assurance, Optima Labs offers a comprehensive suite of AI services. Our holistic approach ensures that every aspect of AI development—from model creation to deployment—is optimized for maximum impact and efficiency."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="AI Developer Cloud Infrastructure"
              secondary="Optima Labs provides a robust cloud infrastructure tailored for AI development. This includes everything from data storage and model training to deployment and continuous validation. Our infrastructure is designed to support the full lifecycle of AI projects, offering flexibility, security, and scalability."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Cutting-Edge Research and Development"
              secondary="Innovation is at the core of Optima Labs. Our R&D team is constantly exploring new frontiers in AI, from the latest in machine learning algorithms to breakthroughs in cloud computing. This continuous innovation ensures that our clients have access to the most advanced tools and technologies."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Custom AI Solutions"
              secondary="No two businesses are the same, and neither are our solutions. Optima Labs specializes in creating tailored AI applications that align with your specific business objectives, industry demands, and operational challenges."
            />
          </ListItem>
        </List>
      </Section>

      <Section>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="h4" gutterBottom>
              Why Choose Optima Labs?
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Expertise and Innovation"
                  secondary="Our team comprises leading experts in AI, data science, and cloud technologies, dedicated to pushing the limits of what’s possible."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Seamless Integration"
                  secondary="We understand the complexities of modern business environments. Our solutions are designed to integrate seamlessly with your existing systems, ensuring smooth transitions and minimal disruptions."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Future-Proof Solutions"
                  secondary="The world of technology is constantly changing. At Optima Labs, we stay ahead of the curve, ensuring that your business is equipped with future-proof tools and strategies."
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <img
              src={optima4} // replace with your image source
              alt="Why Choose Us"
              style={{ width: "100%", height: "350px" }}
            />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <img
              src={optima5} // replace with your image source
              alt="Shaping the Future"
              style={{ width: "100%", height: "350px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Join Us in Shaping the Future:
            </Typography>
            <Typography variant="body1" paragraph>
              At Optima Labs, we’re not just creating technology—we’re crafting the
              future. Whether you’re looking to harness the power of AI, optimize
              your operations, or stay ahead in a competitive market, TechOptima is
              your partner in innovation.
            </Typography>
            <Typography variant="body1" paragraph>
              Explore the possibilities with Optima Labs and take the first step
              toward transforming your business for the future.
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </Container>
  );
};

export default OptimaLabs;
