// // // import React from "react";
// // // import {
// // //   Container,
// // //   Typography,
// // //   Box,
// // //   Paper,
// // //   List,
// // //   ListItem,
// // //   ListItemText,
// // //   Divider,
// // //   Button,
// // //   Grid,
// // // } from "@mui/material";
// // // import { useTheme } from "@mui/material/styles";
// // // import useMediaQuery from "@mui/material/useMediaQuery";
// // // import { useNavigate } from "react-router-dom";

// // // const TechnicalSupport = () => {
// // //   const theme = useTheme();
// // //   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
// // //   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
// // //   const navigate = useNavigate();

// // //   const styles = {
// // //     header: {
// // //       margin: isMobile ? "16px" : "45px 0px 25px 0px ",
// // //     },
// // //     content: {
// // //       marginBottom: isMobile ? "16px" : "32px",
// // //       fontSize: isMobile ? "1rem" : "1.25rem",
// // //     },
// // //     section: {
// // //       marginTop: isMobile ? "16px" : "32px",
// // //       textAlign: isMobile ? "center" : "left",
// // //     },
// // //     paper: {
// // //       padding: isMobile ? "8px" : "16px",
// // //       marginBottom: isMobile ? "8px" : "16px",
// // //       display: "flex",
// // //       flexDirection: "column",
// // //       justifyContent: "center",
// // //       height: "100%",
// // //     },
// // //     button: {
// // //       margin: isMobile ? "8px" : "55px ",
// // //       padding: "10px 20px",
// // //     },
// // //   };

// // //   const supportChannels = [
// // //     {
// // //       title: "Email Support",
// // //       description:
// // //         "Reach out to our support team via email at support@techoptima.com. We aim to respond within 24 hours.",
// // //     },
// // //     {
// // //       title: "Live Chat",
// // //       description:
// // //         "Chat with our support agents in real-time for quick assistance. Available 24/7.",
// // //     },
// // //     {
// // //       title: "Phone Support",
// // //       description:
// // //         "Call us at 1-800-123-4567 for direct support from our technical experts. Available Monday to Friday, 9 AM - 5 PM.",
// // //     },
// // //     {
// // //       title: "Community Forum",
// // //       description:
// // //         "Join our community forum to ask questions and find solutions from other users and experts.",
// // //     },
// // //   ];

// // //   const commonIssues = [
// // //     {
// // //       issue: "Unable to login",
// // //       solution:
// // //         'Ensure your username and password are correct. If you forgot your password, use the "Forgot Password" link to reset it.',
// // //     },
// // //     {
// // //       issue: "Error 404",
// // //       solution:
// // //         "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Check the URL or return to the homepage.",
// // //     },
// // //     {
// // //       issue: "Software not updating",
// // //       solution:
// // //         "Ensure your internet connection is stable. Restart the software and try updating again. If the issue persists, contact support.",
// // //     },
// // //     {
// // //       issue: "Payment issues",
// // //       solution:
// // //         "Verify your payment details and ensure you have sufficient funds. Contact your bank if the problem continues or contact our support for assistance.",
// // //     },
// // //   ];

// // //   const recentUpdates = [
// // //     {
// // //       title: "System Maintenance Notice",
// // //       author: "TechOptima Support",
// // //       date: "2024-07-29",
// // //     },
// // //     {
// // //       title: "New Features in Support Portal",
// // //       author: "TechOptima Team",
// // //       date: "2024-07-28",
// // //     },
// // //     {
// // //       title: "Improved Live Chat Response Times",
// // //       author: "TechOptima Support",
// // //       date: "2024-07-27",
// // //     },
// // //   ];

// // //   const testimonials = [
// // //     {
// // //       quote:
// // //         "The support team was incredibly helpful and resolved my issue quickly.",
// // //       author: "John Doe",
// // //     },
// // //     {
// // //       quote: "I appreciate the 24/7 live chat. It saved me a lot of time.",
// // //       author: "Jane Smith",
// // //     },
// // //     {
// // //       quote: "Excellent customer service and knowledgeable staff.",
// // //       author: "Robert Brown",
// // //     },
// // //   ];

// // //   return (
// // //     <Container
// // //       sx={{
// // //         paddingTop: isMobile ? "100px" : "150px",
// // //         textAlign: "center",
// // //       }}
// // //     >
// // //       <Typography
// // //         variant={isMobile ? "h4" : "h3"}
// // //         component="h1"
// // //         style={styles.header}
// // //       >
// // //         Technical Support
// // //       </Typography>
// // //       <Typography variant="body1" style={styles.content}>
// // //         Welcome to the Technical Support page. Our dedicated support team is
// // //         here to assist you with any technical issues you may encounter. Explore
// // //         the various support channels we offer, find solutions to common issues,
// // //         and stay updated with our latest support updates.
// // //       </Typography>

// // //       <Box style={styles.section}>
// // //         <Typography
// // //           variant={isMobile ? "h6" : "h5"}
// // //           component="h2"
// // //           style={styles.header}
// // //         >
// // //           Support Channels
// // //         </Typography>
// // //         <Grid container spacing={isMobile ? 2 : 3}>
// // //           {supportChannels.map((channel, index) => (
// // //             <Grid item xs={12} md={6} lg={3} key={index}>
// // //               <Paper style={styles.paper}>
// // //                 <Typography variant="h6">{channel.title}</Typography>
// // //                 <Typography variant="body2">{channel.description}</Typography>
// // //               </Paper>
// // //             </Grid>
// // //           ))}
// // //         </Grid>
// // //       </Box>

// // //       <Box style={styles.section}>
// // //         <Typography
// // //           variant={isMobile ? "h6" : "h5"}
// // //           component="h2"
// // //           style={styles.header}
// // //         >
// // //           Common Issues and Solutions
// // //         </Typography>
// // //         <Paper style={styles.paper}>
// // //           <List>
// // //             {commonIssues.map((item, index) => (
// // //               <React.Fragment key={index}>
// // //                 <ListItem>
// // //                   <ListItemText
// // //                     primary={item.issue}
// // //                     secondary={item.solution}
// // //                   />
// // //                 </ListItem>
// // //                 {index < commonIssues.length - 1 && <Divider />}
// // //               </React.Fragment>
// // //             ))}
// // //           </List>
// // //         </Paper>
// // //       </Box>

// // //       <Box style={styles.section}>
// // //         <Typography
// // //           variant={isMobile ? "h6" : "h5"}
// // //           component="h2"
// // //           style={styles.header}
// // //         >
// // //           Recent Support Updates
// // //         </Typography>
// // //         <Paper style={styles.paper}>
// // //           <List>
// // //             {recentUpdates.map((update, index) => (
// // //               <React.Fragment key={index}>
// // //                 <ListItem>
// // //                   <ListItemText
// // //                     primary={update.title}
// // //                     secondary={`by ${update.author} on ${update.date}`}
// // //                   />
// // //                 </ListItem>
// // //                 {index < recentUpdates.length - 1 && <Divider />}
// // //               </React.Fragment>
// // //             ))}
// // //           </List>
// // //         </Paper>
// // //       </Box>

// // //       <Box style={styles.section}>
// // //         <Typography
// // //           variant={isMobile ? "h6" : "h5"}
// // //           component="h2"
// // //           style={styles.header}
// // //         >
// // //           Customer Testimonials
// // //         </Typography>
// // //         <Grid container spacing={isMobile ? 2 : 3}>
// // //           {testimonials.map((testimonial, index) => (
// // //             <Grid item xs={12} md={4} key={index}>
// // //               <Paper style={styles.paper}>
// // //                 <Typography variant="body2">"{testimonial.quote}"</Typography>
// // //                 <Typography variant="body2" align="right">
// // //                   - {testimonial.author}
// // //                 </Typography>
// // //               </Paper>
// // //             </Grid>
// // //           ))}
// // //         </Grid>
// // //       </Box>

// // //       <Box>
// // //         <Button
// // //           variant="contained"
// // //           color="primary"
// // //           style={styles.button}
// // //           onClick={() => navigate("/Contact")}
// // //         >
// // //           Contact Support
// // //         </Button>
// // //       </Box>
// // //     </Container>
// // //   );
// // // };

// // // export default TechnicalSupport;

// // import React from "react";
// // import {
// //   Container,
// //   Typography,
// //   Box,
// //   Paper,
// //   List,
// //   ListItem,
// //   ListItemText,
// //   Divider,
// //   Button,
// //   Grid,
// // } from "@mui/material";
// // import { useTheme } from "@mui/material/styles";
// // import useMediaQuery from "@mui/material/useMediaQuery";
// // import { useNavigate } from "react-router-dom";

// // const TechnicalSupport = () => {
// //   const theme = useTheme();
// //   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
// //   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
// //   const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
// //   const navigate = useNavigate();

// //   const styles = {
// //     header: {
// //       margin: isMobile ? "16px" : "45px 0px 25px 0px",
// //     },
// //     content: {
// //       marginBottom: isMobile ? "16px" : "32px",
// //       fontSize: isMobile ? "1rem" : "1.25rem",
// //     },
// //     section: {
// //       margin: isMobile ? "16px" : "32px",
// //       textAlign: isMobile ? "center" : "left",
// //     },
// //     paper: {
// //       padding: isMobile ? "8px" : "16px",
// //       marginBottom: isMobile ? "8px" : "16px",
// //       display: "flex",
// //       flexDirection: "column",
// //       justifyContent: "center",
// //       height: "100%",
// //     },
// //     button: {
// //       margin: isMobile ? "8px" : "55px",
// //       padding: "10px 20px",
// //     },
// //   };

// //   const supportChannels = [
// //     {
// //       title: "Email Support",
// //       description:
// //         "Reach out to our support team via email at support@techoptima.com. We aim to respond within 24 hours.",
// //     },
// //     {
// //       title: "Live Chat",
// //       description:
// //         "Chat with our support agents in real-time for quick assistance. Available 24/7.",
// //     },
// //     {
// //       title: "Phone Support",
// //       description:
// //         "Call us at 1-800-123-4567 for direct support from our technical experts. Available Monday to Friday, 9 AM - 5 PM.",
// //     },
// //     {
// //       title: "Community Forum",
// //       description:
// //         "Join our community forum to ask questions and find solutions from other users and experts.",
// //     },
// //   ];

// //   const commonIssues = [
// //     {
// //       issue: "Unable to login",
// //       solution:
// //         'Ensure your username and password are correct. If you forgot your password, use the "Forgot Password" link to reset it.',
// //     },
// //     {
// //       issue: "Error 404",
// //       solution:
// //         "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Check the URL or return to the homepage.",
// //     },
// //     {
// //       issue: "Software not updating",
// //       solution:
// //         "Ensure your internet connection is stable. Restart the software and try updating again. If the issue persists, contact support.",
// //     },
// //     {
// //       issue: "Payment issues",
// //       solution:
// //         "Verify your payment details and ensure you have sufficient funds. Contact your bank if the problem continues or contact our support for assistance.",
// //     },
// //   ];

// //   const recentUpdates = [
// //     {
// //       title: "System Maintenance Notice",
// //       author: "TechOptima Support",
// //       date: "2024-07-29",
// //     },
// //     {
// //       title: "New Features in Support Portal",
// //       author: "TechOptima Team",
// //       date: "2024-07-28",
// //     },
// //     {
// //       title: "Improved Live Chat Response Times",
// //       author: "TechOptima Support",
// //       date: "2024-07-27",
// //     },
// //   ];

// //   const testimonials = [
// //     {
// //       quote:
// //         "The support team was incredibly helpful and resolved my issue quickly.",
// //       author: "John Doe",
// //     },
// //     {
// //       quote: "I appreciate the 24/7 live chat. It saved me a lot of time.",
// //       author: "Jane Smith",
// //     },
// //     {
// //       quote: "Excellent customer service and knowledgeable staff.",
// //       author: "Robert Brown",
// //     },
// //   ];

// //   return (
// //     <Container
// //       sx={{
// //         paddingTop: isMobile ? "100px" : "150px",
// //         textAlign: "center",
// //       }}
// //     >
// //       <Typography
// //         variant={isMobile ? "h4" : "h3"}
// //         component="h1"
// //         style={styles.header}
// //       >
// //         Technical Support
// //       </Typography>
// //       <Typography variant="body1" style={styles.content}>
// //         Welcome to the Technical Support page. Our dedicated support team is
// //         here to assist you with any technical issues you may encounter. Explore
// //         the various support channels we offer, find solutions to common issues,
// //         and stay updated with our latest support updates.
// //       </Typography>

// //       <Box style={styles.section}>
// //         <Typography
// //           variant={isMobile ? "h6" : "h5"}
// //           component="h2"
// //           style={styles.header}
// //         >
// //           Support Channels
// //         </Typography>
// //         <Grid container spacing={isMobile ? 2 : 3}>
// //           {supportChannels.map((channel, index) => (
// //             <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
// //               <Paper style={styles.paper}>
// //                 <Typography variant="h6">{channel.title}</Typography>
// //                 <Typography variant="body2">{channel.description}</Typography>
// //               </Paper>
// //             </Grid>
// //           ))}
// //         </Grid>
// //       </Box>

// //       <Box style={styles.section}>
// //         <Typography
// //           variant={isMobile ? "h6" : "h5"}
// //           component="h2"
// //           style={styles.header}
// //         >
// //           Common Issues and Solutions
// //         </Typography>
// //         <Paper style={styles.paper}>
// //           <List>
// //             {commonIssues.map((item, index) => (
// //               <React.Fragment key={index}>
// //                 <ListItem>
// //                   <ListItemText
// //                     primary={item.issue}
// //                     secondary={item.solution}
// //                   />
// //                 </ListItem>
// //                 {index < commonIssues.length - 1 && <Divider />}
// //               </React.Fragment>
// //             ))}
// //           </List>
// //         </Paper>
// //       </Box>

// //       <Box style={styles.section}>
// //         <Typography
// //           variant={isMobile ? "h6" : "h5"}
// //           component="h2"
// //           style={styles.header}
// //         >
// //           Recent Support Updates
// //         </Typography>
// //         <Paper style={styles.paper}>
// //           <List>
// //             {recentUpdates.map((update, index) => (
// //               <React.Fragment key={index}>
// //                 <ListItem>
// //                   <ListItemText
// //                     primary={update.title}
// //                     secondary={`by ${update.author} on ${update.date}`}
// //                   />
// //                 </ListItem>
// //                 {index < recentUpdates.length - 1 && <Divider />}
// //               </React.Fragment>
// //             ))}
// //           </List>
// //         </Paper>
// //       </Box>

// //       {/* <Box style={styles.section}>
// //         <Typography
// //           variant={isMobile ? "h6" : "h5"}
// //           component="h2"
// //           style={styles.header}
// //         >
// //           Customer Testimonials
// //         </Typography>
// //         <Grid container spacing={isMobile ? 2 : 3}>
// //           {testimonials.map((testimonial, index) => (
// //             <Grid item xs={12} sm={6} md={4} key={index}>
// //               <Paper style={styles.paper}>
// //                 <Typography variant="body2">"{testimonial.quote}"</Typography>
// //                 <Typography variant="body2" align="right">
// //                   - {testimonial.author}
// //                 </Typography>
// //               </Paper>
// //             </Grid>
// //           ))}
// //         </Grid>
// //       </Box> */}

// //       <Box sx={{ padding: { xs: "45px", sm: "45px", md: "45px", lg: "45px" } }}>
// //         <Button
// //           variant="contained"
// //           color="primary"
// //           style={styles.button}
// //           onClick={() => navigate("/Contact")}
// //         >
// //           Contact Support
// //         </Button>
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default TechnicalSupport;

// import React from "react";
// import {
//   Container,
//   Typography,
//   Box,
//   Paper,
//   List,
//   ListItem,
//   ListItemText,
//   Divider,
//   Button,
//   Grid,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useNavigate } from "react-router-dom";

// const TechnicalSupport = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
//   const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
//   const navigate = useNavigate();

//   const styles = {
//     header: {
//       margin: isMobile ? "16px" : "45px 0px 25px 0px",
//     },
//     content: {
//       marginBottom: isMobile ? "16px" : "32px",
//       fontSize: isMobile ? "1rem" : "1.25rem",
//     },
//     section: {
//       marginTop: isMobile ? "16px" : "32px",
//       textAlign: isMobile ? "center" : "left",
//     },
//     paper: {
//       padding: isMobile ? "8px" : "16px",
//       marginBottom: isMobile ? "8px" : "16px",
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       height: "100%",
//     },
//     button: {
//       margin: isMobile ? "8px" : "55px",
//       padding: "10px 20px",
//     },
//   };

//   const supportChannels = [
//     {
//       title: "Email Support",
//       description:
//         "Reach out to our support team via email at support@techoptima.com. We aim to respond within 24 hours.",
//     },
//     {
//       title: "Live Chat",
//       description:
//         "Chat with our support agents in real-time for quick assistance. Available 24/7.",
//     },
//     {
//       title: "Phone Support",
//       description:
//         "Call us at 1-800-123-4567 for direct support from our technical experts. Available Monday to Friday, 9 AM - 5 PM.",
//     },
//   ];

//   const commonIssues = [
//     {
//       issue: "Unable to login",
//       solution:
//         'Ensure your username and password are correct. If you forgot your password, use the "Forgot Password" link to reset it.',
//     },
//     {
//       issue: "Error 404",
//       solution:
//         "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Check the URL or return to the homepage.",
//     },
//     {
//       issue: "Software not updating",
//       solution:
//         "Ensure your internet connection is stable. Restart the software and try updating again. If the issue persists, contact support.",
//     },
//     {
//       issue: "Payment issues",
//       solution:
//         "Verify your payment details and ensure you have sufficient funds. Contact your bank if the problem continues or contact our support for assistance.",
//     },
//   ];

//   const recentUpdates = [
//     {
//       title: "System Maintenance Notice",
//       author: "TechOptima Support",
//       date: "2024-07-29",
//     },
//     {
//       title: "New Features in Support Portal",
//       author: "TechOptima Team",
//       date: "2024-07-28",
//     },
//     {
//       title: "Improved Live Chat Response Times",
//       author: "TechOptima Support",
//       date: "2024-07-27",
//     },
//   ];

//   const testimonials = [
//     {
//       quote:
//         "The support team was incredibly helpful and resolved my issue quickly.",
//       author: "John Doe",
//     },
//     {
//       quote: "I appreciate the 24/7 live chat. It saved me a lot of time.",
//       author: "Jane Smith",
//     },
//     {
//       quote: "Excellent customer service and knowledgeable staff.",
//       author: "Robert Brown",
//     },
//   ];

//   return (
//     <Container
//       sx={{
//         paddingTop: isMobile ? "100px" : "150px",
//         textAlign: "center",
//       }}
//     >
//       <Typography
//         variant={isMobile ? "h4" : "h3"}
//         component="h1"
//         style={styles.header}
//       >
//         Technical Support
//       </Typography>
//       <Typography variant="body1" style={styles.content}>
//         Welcome to the Technical Support page. Our dedicated support team is
//         here to assist you with any technical issues you may encounter. Explore
//         the various support channels we offer, find solutions to common issues,
//         and stay updated with our latest support updates.
//       </Typography>

//       <Box style={styles.section}>
//         <Typography
//           variant={isMobile ? "h6" : "h5"}
//           component="h2"
//           style={styles.header}
//         >
//           Support Channels
//         </Typography>
//         <Grid container spacing={isMobile ? 2 : 3}>
//           {supportChannels.map((channel, index) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//               <Paper
//                 sx={{
//                   padding: isMobile ? "8px" : "16px",
//                   marginBottom: isMobile ? "8px" : "16px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   height: "100%",
//                 }}
//               >
//                 <Typography variant="h6">{channel.title}</Typography>
//                 <Typography variant="body2">{channel.description}</Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>

//       <Box style={styles.section}>
//         <Typography
//           variant={isMobile ? "h6" : "h5"}
//           component="h2"
//           style={styles.header}
//         >
//           Common Issues and Solutions
//         </Typography>
//         <Paper style={styles.paper}>
//           <List>
//             {commonIssues.map((item, index) => (
//               <React.Fragment key={index}>
//                 <ListItem>
//                   <ListItemText
//                     primary={item.issue}
//                     secondary={item.solution}
//                   />
//                 </ListItem>
//                 {index < commonIssues.length - 1 && <Divider />}
//               </React.Fragment>
//             ))}
//           </List>
//         </Paper>
//       </Box>

//       <Box style={styles.section}>
//         <Typography
//           variant={isMobile ? "h6" : "h5"}
//           component="h2"
//           style={styles.header}
//         >
//           Recent Support Updates
//         </Typography>
//         <Paper style={styles.paper}>
//           <List>
//             {recentUpdates.map((update, index) => (
//               <React.Fragment key={index}>
//                 <ListItem>
//                   <ListItemText
//                     primary={update.title}
//                     secondary={`by ${update.author} on ${update.date}`}
//                   />
//                 </ListItem>
//                 {index < recentUpdates.length - 1 && <Divider />}
//               </React.Fragment>
//             ))}
//           </List>
//         </Paper>
//       </Box>

//       <Box>
//         <Button
//           variant="contained"
//           color="primary"
//           style={styles.button}
//           onClick={() => navigate("/Contact")}
//         >
//           Contact Support
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default TechnicalSupport;

import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { color } from "d3";

const TechnicalSupport = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const styles = {
    header: {
      margin: isMobile ? "16px" : "45px 0px 25px 0px",
    },
    content: {
      marginBottom: isMobile ? "16px" : "32px",
      fontSize: isMobile ? "1rem" : "1.25rem",
    },
    section: {
      marginTop: isMobile ? "16px" : "32px",
      textAlign: isMobile ? "center" : "left",
    },
    paper: {
      padding: isMobile ? "16px" : "24px",
      marginBottom: isMobile ? "16px" : "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
    button: {
      margin: isMobile ? "8px" : "25px",
      padding: "10px 20px",
      backgroundColor: "var(--button-color)",
    },
  };

  const supportChannels = [
    {
      title: "Email Support",
      description:
        "Reach out to our support team via email at support@techoptima.com. We aim to respond within 24 hours.",
    },
    {
      title: "Live Chat",
      description:
        "Chat with our support agents in real-time for quick assistance. Available 24/7.",
    },
    {
      title: "Phone Support",
      description:
        "Call us at 1-800-123-4567 for direct support from our technical experts. Available Monday to Friday, 9 AM - 5 PM.",
    },
    {
      title: "Community Forum",
      description:
        "Join our community forum to ask questions and find solutions from other users and experts.",
    },
  ];

  const commonIssues = [
    {
      issue: "Unable to login",
      solution:
        'Ensure your username and password are correct. If you forgot your password, use the "Forgot Password" link to reset it.',
    },
    {
      issue: "Error 404",
      solution:
        "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Check the URL or return to the homepage.",
    },
    {
      issue: "Software not updating",
      solution:
        "Ensure your internet connection is stable. Restart the software and try updating again. If the issue persists, contact support.",
    },
    {
      issue: "Payment issues",
      solution:
        "Verify your payment details and ensure you have sufficient funds. Contact your bank if the problem continues or contact our support for assistance.",
    },
  ];

  const recentUpdates = [
    {
      title: "System Maintenance Notice",
      author: "TechOptima Support",
      date: "2024-07-29",
    },
    {
      title: "New Features in Support Portal",
      author: "TechOptima Team",
      date: "2024-07-28",
    },
    {
      title: "Improved Live Chat Response Times",
      author: "TechOptima Support",
      date: "2024-07-27",
    },
  ];

  const testimonials = [
    {
      quote:
        "The support team was incredibly helpful and resolved my issue quickly.",
      author: "John Doe",
    },
    {
      quote: "I appreciate the 24/7 live chat. It saved me a lot of time.",
      author: "Jane Smith",
    },
    {
      quote: "Excellent customer service and knowledgeable staff.",
      author: "Robert Brown",
    },
  ];

  return (
    <Container
      sx={{
        paddingTop: isMobile ? "100px" : "150px",
        textAlign: "center",
      }}
    >
      <Typography
        variant={isMobile ? "h4" : "h3"}
        component="h1"
        style={styles.header}
      >
        Technical Support
      </Typography>
      <Typography variant="body1" style={styles.content}>
        Welcome to the Technical Support page. Our dedicated support team is
        here to assist you with any technical issues you may encounter. Explore
        the various support channels we offer, find solutions to common issues,
        and stay updated with our latest support updates.
      </Typography>

      <Box style={styles.section}>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          component="h2"
          style={styles.header}
        >
          Common Issues and Solutions
        </Typography>
        <Paper style={styles.paper}>
          <List>
            {commonIssues.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={item.issue}
                    secondary={item.solution}
                  />
                </ListItem>
                {index < commonIssues.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Box>

      <Box style={styles.section}>
        <Typography
          variant={isMobile ? "h6" : "h5"}
          component="h2"
          style={styles.header}
        >
          Recent Support Updates
        </Typography>
        <Paper style={styles.paper}>
          <List>
            {recentUpdates.map((update, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={update.title}
                    secondary={`by ${update.author} on ${update.date}`}
                  />
                </ListItem>
                {index < recentUpdates.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Box>

      <Box sx={{ padding: { xs: "45", sm: "45px" } }}>
        <Button
          variant="contained"
          color="primary"
          style={styles.button}
          onClick={() => navigate("/Contact", { state: { from: "home" } })}
        >
          Contact Support
        </Button>
      </Box>
    </Container>
  );
};

export default TechnicalSupport;
