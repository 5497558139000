// import React from "react";
// import {
//   Grid,
//   Typography,
//   Box,
//   Card,
//   CardContent,
//   CardMedia,
//   Fade,
// } from "@mui/material";
// import { useInView } from "react-intersection-observer";

// const ConferencePage = () => {
//   const { ref: cardRef1, inView: cardInView1 } = useInView({
//     triggerOnce: true,
//   });
//   const { ref: cardRef2, inView: cardInView2 } = useInView({
//     triggerOnce: true,
//   });
//   const { ref: cardRef3, inView: cardInView3 } = useInView({
//     triggerOnce: true,
//   });

//   return (
//     <Box
//       sx={{
//         padding: {
//           xs: "40px 20px",
//           sm: "60px 30px",
//           md: "80px 50px",
//           lg: "100px 70px",
//         },
//         backgroundColor: "#f5f5f5",
//       }}
//     >
//       <Typography
//         variant="h2"
//         component="h1"
//         gutterBottom
//         sx={{
//           fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
//           textAlign: "center",
//           marginBottom: "20px",
//         }}
//       >
//         Upcoming Conferences
//       </Typography>

//       <Grid container spacing={4}>
//         <Grid item xs={12} md={4}>
//           <Fade in={cardInView1} timeout={1000}>
//             <Card ref={cardRef1} sx={{ height: "100%" }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image="https://via.placeholder.com/300"
//                 alt="Conference 1"
//               />
//               <CardContent>
//                 <Typography
//                   variant="h5"
//                   component="div"
//                   sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
//                 >
//                   Conference 1
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Date: 25th August 2024
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Location: New York, USA
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Description: Join us for a day of insightful talks and
//                   networking opportunities.
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Fade>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Fade in={cardInView2} timeout={1000}>
//             <Card ref={cardRef2} sx={{ height: "100%" }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image="https://via.placeholder.com/300"
//                 alt="Conference 2"
//               />
//               <CardContent>
//                 <Typography
//                   variant="h5"
//                   component="div"
//                   sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
//                 >
//                   Conference 2
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Date: 12th September 2024
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Location: London, UK
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Description: A must-attend event for professionals looking to
//                   expand their knowledge.
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Fade>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Fade in={cardInView3} timeout={1000}>
//             <Card ref={cardRef3} sx={{ height: "100%" }}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image="https://via.placeholder.com/300"
//                 alt="Conference 3"
//               />
//               <CardContent>
//                 <Typography
//                   variant="h5"
//                   component="div"
//                   sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
//                 >
//                   Conference 3
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Date: 5th October 2024
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Location: Tokyo, Japan
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="text.secondary"
//                   sx={{
//                     fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
//                   }}
//                 >
//                   Description: Engage with leading experts and discover the
//                   latest trends in the industry.
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Fade>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default ConferencePage;

// src/ConferencePage.js
import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";

const ConferencePage = () => {
  const cards = [
    {
      title: "Keynote Speech",
      description: "An inspiring keynote speech by a leading industry expert.",
      image: "https://via.placeholder.com/300",
    },
    {
      title: "Workshop",
      description: "Hands-on workshop sessions to enhance your skills.",
      image: "https://via.placeholder.com/300",
    },
    {
      title: "Networking",
      description: "Opportunities to network with professionals.",
      image: "https://via.placeholder.com/300",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: {
          xs: "150px ",
          sm: "60px ",
          md: "150px ",
          lg: "130px ",
        },
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom textAlign="center">
        Conference 2024
      </Typography>
      <Typography variant="h5" component="p" gutterBottom textAlign="center">
        Join us for an unforgettable experience
      </Typography>

      <Grid container spacing={4}>
        {cards.map((card, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia component="img" image={card.image} alt={card.title} />
              <CardContent>
                <Typography variant="h6" component="h2">
                  {card.title}
                </Typography>
                <Typography>{card.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ConferencePage;
