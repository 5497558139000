

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Paper,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import Config from "../../../Config/Config"
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "600px",
  margin: "auto",
  marginTop: theme.spacing(8),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ScheduleCall = () => {
  const location = useLocation(); // Retrieve the location object
  const navigate = useNavigate(); // Use the navigate hook for redirection
  const [name, setName] = useState("");
  const [service, setService] = useState("Software Outsourcing");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [pageName, setPageName] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Set the page name based on the location path
    const path = location.state?.from || "/default-path";
    console.log(path);
    if (path === "/ai-engineering") {
      setPageName("aiengineering");
    } else if (path === "/data-science") {
      setPageName("datascience");
    } else if (path === "/ai-quality") {
      setPageName("aiquality");
    } else if (path === "/ai-assurance") {
      setPageName("aiassurance");
    } else if (path === "/cloud-transformation") {
      setPageName("cloudtransformation");
    } else if (path === "/cloud-managed-services") {
      setPageName("cloudmanagedservices");
    } else if (path === "/cloud-migrations") {
      setPageName("cloudmigrations");
    } else if (path === "/cloud-operations") {
      setPageName("cloudoperations");
    } else if (path === "/cloud-native-apps") {
      setPageName("cloudnativeapps");
    } else if (path === "/cloud-devops") {
      setPageName("clouddevops");
    } else if (path === "/enterprise-development") {
      setPageName("enterprisedevelopment");
    } else if (path === "/enterprise-platform") {
      setPageName("enterpriseplatform");
    } else if (path === "/agile-devops") {
      setPageName("agiledevops");
    } else if (path === "/support-maintenance") {
      setPageName("supportmaintenance");
    } else if (path === "/intelligent-automation") {
      setPageName("intelligentautomation");
    } else if (path === "/low-code") {
      setPageName("lowcode");
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: email,
      pageName: pageName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate("/"); // Fallback to the home page if no state is found
    }
  };

  return (
    <Box style={{ padding: "90px 20px 20px 20px" }}>
      <Container>
        <Typography variant="h5" gutterBottom>
          Thank you for your interest in TechOptima.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We'd like to ask a few questions to better understand your software
          development needs.
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormField
            fullWidth
            label="Your name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormField
            fullWidth
            label="Your work email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl component="fieldset" style={{ marginBottom: "16px" }}>
            <Typography variant="body1" gutterBottom>
              Select your service
            </Typography>
            <RadioGroup
              row
              value={service}
              onChange={(e) => setService(e.target.value)}
            >
              <FormControlLabel
                value="AI 360"
                control={<Radio />}
                label="AI 360"
              />
              <FormControlLabel
                value="Cloud"
                control={<Radio />}
                label="Cloud"
              />
              <FormControlLabel
                value="Digital Services"
                control={<Radio />}
                label="Digital Services"
              />
            </RadioGroup>
          </FormControl>
          <FormField
            fullWidth
            label="How can we help?"
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          {/* <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: "16px",
              backgroundColor: "var(--button-color)",
            }}
          >
            Get started <span>&rarr;</span>
          </Button> */}
          <Button
            type="submit"
            variant="contained"
            sx={{
              marginTop: "16px",
              backgroundColor: "var(--button-color)",
              position: "relative",
              overflow: "hidden",
              "&:hover": {
                backgroundColor: "var(--buttonHover)",
              },
            }}
          >
            Get started
          </Button>
        </form>
      </Container>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid vat(--black) !importent",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Form Submitted Successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {pageName === "aiengineering"
                ? "Thank you for showing interest in our AI engineering services. Our team will reach out to you shortly."
                : pageName === "datascience"
                ? "Thank you for showing interest in our Data Science services. Our team will reach out to you shortly."
                : pageName === "aiquality"
                ? "Thank you for showing interest in our AI Quality services. Our team will reach out to you shortly."
                : pageName === "aiassurance"
                ? "Thank you for showing interest in our AI Assurance services. Our team will reach out to you shortly."
                : pageName === "cloudtransformation"
                ? "Thank you for showing interest in our Cloud Transformation services. Our team will reach out to you shortly."
                : pageName === "cloudmanagedservices"
                ? "Thank you for showing interest in our Cloud Managed Services. Our team will reach out to you shortly."
                : pageName === "cloudmigrations"
                ? "Thank you for showing interest in our Cloud Migrations services. Our team will reach out to you shortly."
                : pageName === "cloudoperations"
                ? "Thank you for showing interest in our Cloud Operations services. Our team will reach out to you shortly."
                : pageName === "cloudnativeapps"
                ? "Thank you for showing interest in our Cloud Native Application Development services. Our team will reach out to you shortly."
                : pageName === "clouddevops"
                ? "Thank you for showing interest in our Cloud DevOps services. Our team will reach out to you shortly."
                : pageName === "enterprisedevelopment"
                ? "Thank you for showing interest in our Enterprise Application Development services. Our team will reach out to you shortly."
                : pageName === "enterpriseplatform"
                ? "Thank you for showing interest in our Enterprise Platform Development services. Our team will reach out to you shortly."
                : pageName === "agiledevops"
                ? "Thank you for showing interest in our Agile DevOps services. Our team will reach out to you shortly."
                : pageName === "supportmaintenance"
                ? "Thank you for showing interest in our Support and Maintenance services. Our team will reach out to you shortly."
                : pageName === "intelligentautomation"
                ? "Thank you for showing interest in our Intelligent Automation RPA services. Our team will reach out to you shortly."
                : pageName === "lowcode"
                ? "Thank you for showing interest in our Low Code Application services. Our team will reach out to you shortly."
                : "Thank you for your submission. Our team will reach out to you shortly."}
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ScheduleCall;
