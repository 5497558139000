

import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Partner from "../../../../../Assets/Images/Partner.jpg"

const PartnerPortal = () => {
  return (
    <Box
      sx={{
        padding: "150px 20px 20px 20px",
        backgroundImage: `url(https://th.bing.com/th/id/OIP.EiB9kcI53auql21l-wNxzgHaEK?w=308&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <Box
            component="img"
            src={Partner}
            alt="Partner Portal Image"
            sx={{ width: "100%", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" component="p" gutterBottom>
            <h3>Welcome to the TechOptima Partner Portal</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ fontStyle: "italic" }}
          >
            "The TechOptima Partner Portal is designed to provide our partners
            with easy access to resources, tools, and information that will help
            you succeed. This portal will include:
            <br />
            <br />
            - Comprehensive documentation and guides
            <br />
            - Latest updates and news
            <br />
            - Support and contact information
            <br />
            <br />
            We are continuously working to enhance this portal to better serve
            our partners. Stay tuned for more updates and features as we develop
            and improve this platform.
            <br />
            <br />
            Thank you for partnering with TechOptima. Together, we can achieve
            great success."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerPortal;
