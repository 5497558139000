

import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../../../Assets/Images/OIP.jpeg";

const GPUBenchmarks = () => {
  return (
    <Box
      sx={{
        padding:"150px 20px 20px 20px" ,
        
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Benchmarks at TechOptima
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <Box
            component="img"
            src="https://img.freepik.com/premium-photo/businessman-drawing-increases-graph-virtual-screen_9083-2285.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=sph"
            alt="Benchmark Image"
            sx={{
              width: "100%",
              borderRadius: "8px",
              height: {
                xs: "auto",
                md: "100%",
              },
              maxHeight: {
                xs: "300px",
                md: "500px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Benchmarking and Performance Evaluation</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "At TechOptima, benchmarking is an integral part of our approach to
            delivering high-quality solutions. Our benchmarking processes
            involve evaluating the performance of our products and services
            against industry standards and best practices.
            <br />
            <br />
            By conducting rigorous benchmark tests, we ensure that our solutions
            meet and exceed the expectations of our clients. Our benchmarks
            cover various aspects, including speed, efficiency, and reliability,
            to provide a comprehensive assessment of our performance.
            <br />
            <br />
            We utilize state-of-the-art tools and methodologies to conduct our
            benchmarking activities, ensuring accurate and reliable results.
            Through continuous benchmarking, we identify areas for improvement
            and innovate to stay ahead in the competitive landscape."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GPUBenchmarks;
