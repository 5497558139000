import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  Backdrop,
  Container,
  Fade,
} from "@mui/material";
import CyberSecurityImage from "../../../Assets/Images/AppliedAI/AI32.jpg"; // Replace with your actual image path

import Contact from "../../Footer/FooterOptions/Contact/Contact";
import Config from "../../../Config/Config.js";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../Styles/Styles.js";
import background1 from "../../../Assets/Images/background1.jpg";

const CyberSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [pageName, setPageName] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log({ ...formData, pageName });

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.location.reload();
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        className="header-top"
        sx={{
          // backgroundImage: backgroundImage,
          backgroundImage: `url(${background1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "absulate",
          color: "var(--white)",
          textAlign: { xs: "center", md: "left" },
          px: { xs: 2, sm: 4, md: 4 },
          py: { xs: 4, sm: 6, md: 8 },
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              color: "var(--white)",
              padding: "20px 0px",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, // Responsive font size
            }}
            gutterBottom
          >
            Cyber Security at TechOptima
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "var(--white)",
              width: { xs: "100%", md: "80%" }, // Responsive width
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
            }}
          >
            Protecting Your Digital Assets
          </Typography>
          <ButtonPrimary
            variant="contained"
            className="header-button"
            onClick={() =>
              navigate("/scheduleCall", {
                state: { from: location.pathname },
              })
            }
            sx={{
              mt: { xs: 2, md: 4 }, // Responsive margin
              width: { xs: "100%", sm: "auto" }, // Full width on small screens
              fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" }, // Responsive font size
              padding: { xs: "10px 20px", sm: "12px 24px", md: "14px 28px" }, // Responsive padding
            }}
          >
            Schedule a Free Consultation
          </ButtonPrimary>
        </Container>
      </Box>
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          sx={{ padding: "20px 10px 0px 10px" }}
        >
          {/* Image on the left */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={CyberSecurityImage}
              alt="Cyber Security Image"
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: {
                  xs: "auto",
                  md: "100%",
                },
                maxHeight: {
                  xs: "500px",
                  md: "500px",
                },
              }}
            />
          </Grid>

          {/* Content on the right */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.5rem",
                  lg: "3rem",
                },
              }}
            >
              {/* Cyber Security at TechOptima */}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1.125rem",
                  lg: "1.25rem",
                },
              }}
            >
              <h3>Protecting Your Digital Assets</h3>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontStyle: "italic",
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1.125rem",
                  lg: "1.25rem",
                },
              }}
            >
              "At TechOptima, cybersecurity is at the core of our operations. We
              employ the latest technologies and methodologies to safeguard your
              digital assets from threats. Our comprehensive approach to
              cybersecurity ensures that your data remains secure, your systems
              are protected, and your business operates without disruption.
              <br />
              <br />
              Our team of experts is dedicated to identifying vulnerabilities,
              implementing robust security measures, and staying ahead of
              emerging threats. We offer a range of services, including threat
              detection, risk assessment, and incident response, tailored to
              meet the unique needs of your organization.
              <br />
              <br />
              With TechOptima's cybersecurity solutions, you can trust that your
              business is in safe hands, allowing you to focus on what matters
              most—your growth and success."
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid var(--white)",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {/* Form Submitted Successfully */}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Thank you for your submission. Our team will reach out to you
              shortly.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default CyberSecurity;
