import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const Partners = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "150px 20px",
          sm: "150px 40px",
          md: "150px 60px",
          lg: "150px 80px",
        },
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Partners in TechOptima
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Collaboration and Innovation</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "At TechOptima, we believe in the power of collaboration and
            innovation. Our partners play a crucial role in our success, helping
            us to deliver cutting-edge solutions and drive technological
            advancements. We collaborate with industry leaders, academic
            institutions, and technology experts to foster an environment of
            innovation and excellence.
            <br />
            <br />
            Our partnerships are built on trust, mutual respect, and a shared
            commitment to achieving common goals. We work together to tackle
            complex challenges, explore new opportunities, and create value for
            our clients and the industry as a whole.
            <br />
            <br />
            By joining forces with our partners, we are able to leverage diverse
            expertise, resources, and perspectives to stay at the forefront of
            technology. Together, we strive to make a positive impact on the
            world and drive progress in various fields, including AI, machine
            learning, software engineering, and more.
            <br />
            <br />
            We are proud to partner with some of the most respected names in the
            industry and look forward to continuing our journey of growth and
            innovation with them."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Partners;
