import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../Assets/Images/OIP.jpeg";

const OptimaGRC = () => {
  return (
    <Box
      sx={{
        padding: "150px 25px 25px 25px",
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Optima GRC (Governance, Risk, and Compliance )
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>What is the Essence of GRC?</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Optima GRC Program is to plan and perform Governance activities,
            manage and monitor the Risks and Opportunities - with the ultimate
            goal of achieving and continuously improving the Compliance posture
            of the Organization so as to meet it's Business Objectives."
          </Typography>

          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>How can Stellar GRC protect my business from cybercrime?</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Stellar GRC offers a range of services such as network security,
            data encryption, vulnerability assessments, and security consulting
            to ensure that your digital assets are safe and secure. Our team of
            experts works closely with you to assess your security needs and
            develop a customized plan that fits your unique requirements.
          </Typography>

          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>What is two-factor authentication?</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Two-factor authentication is a security measure that requires users
            to provide two forms of identification to access their accounts.
            This provides an extra layer of security and helps prevent
            unauthorized access to your accounts. Stellar GRC offers two-factor
            authentication as part of its services to help protect your business
            from cyber threats.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>How can Stellar GRC help your Organization</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "At Stellar GRC, our expert consultants help you to evaluate the
            Compliance requirements for your Organization and also to understand
            and document the Risks and Opportunities. And our uniquely designed
            GRC Platform enables you to create a fit-for-purpose Governance
            program, manage it efficiently, and report on the Compliance posture
            to all stakeholders involved.
          </Typography>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>How to design an effective GRC program</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            " When a GRC program is being designed, the approach has to start by
            keeping the end goal in focus. The Organization has to first assess
            the business environment, regulatory requirements and the management
            expectations; to arrive at a baseline of its Compliance
            requirements. The next step is to evaluate the market, partners and
            competitors to understand its Risks and Opportunities. The final
            step is to use expert knowledge to detail all the Governance
            activities that need to be performed by various stakeholders, so
            that the Risks are managed"
          </Typography>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>What is cybercrime?</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            Cybercrime refers to criminal activity that is carried out using the
            internet or other digital communication technologies. It includes a
            range of illegal activities such as hacking, phishing, identity
            theft, and fraud.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OptimaGRC;
