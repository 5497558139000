import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { Container, styled } from "@mui/system";
import ContactForm from "./ContactForm";
import { useNavigate, useLocation } from "react-router-dom";
import "./Contact.css";
import Config from "../../../../Config/Config";

const ContactFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "50px",
  background: "linear-gradient(180deg, #d4d3d167 50%, #FFFFFF 50%)",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "30px",
  },
}));

const RightSideOptions = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },
}));

const OfficeLocationWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(8),
  padding: theme.spacing(4),
  backgroundColor: "#F5F5F5",
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const OfficeCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

// const OfficeImage = styled("img")(({ theme }) => ({
//   width: "100px",
//   height: "100px",
//   borderRadius: theme.shape.borderRadius,
//   marginRight: theme.spacing(2),
//   [theme.breakpoints.down("sm")]: {
//     marginRight: 0,
//     marginBottom: theme.spacing(2),
//   },
// }));

const Contact = ({
  formData: initialFormData,
  handleInputChange,
  handleFormSubmit,
  setFormData,
}) => {
  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState("");
  const [formData, setFormDataInternal] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "India",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const officeLocationRef = useRef(null);
  const [submitHandler, setSubmitHandler] = useState(() => handleFormSubmit);

  useEffect(() => {
    if (location.state && location.state.from === "home") {
      setFormDataInternal(initialFormData);
    }
  }, [location.state, initialFormData]);

  useEffect(() => {
    if (location.state?.from === "home") {
      console.log("location.state?.from", location.state?.from);
      setPageName("Contact Sales");
      setSubmitHandler(() => handleFormSubmitWithModal);
    } else {
      setSubmitHandler(() => handleFormSubmit);
    }
  }, [location, handleFormSubmit, location.state?.from]);

  const handleFormSubmitWithModal = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log(sessionStorage.getItem("email"));

    const apiEndpoint = `${Config.apiBaseUrl}`;

    const data = {
      toEmail: sessionStorage.getItem("email"),
      pageName: "Contact Sales",
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);

      // Clear session storage
      if (location.state.from === "home") {
        sessionStorage.removeItem("email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleScrollToLocations = () => {
    if (officeLocationRef.current) {
      officeLocationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClose = () => {
    setOpen(false);

    setFormDataInternal({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
    if (location.state?.from === "home") {
      navigate("/");
    }
  };

  const handleInputChangeInternal = (event) => {
    const { name, value } = event.target;

    // Update form data state
    setFormDataInternal((prevData) => ({ ...prevData, [name]: value }));

    // Store email in session storage if the name is "email"
    if (name === "email") {
      sessionStorage.setItem("email", value);
      // console.log(sessionStorage.getItem("email"))
    }
  };

  return (
    <Box sx={{ paddingTop: { xs: "50px", sm: "90px" } }}>
      <ContactFormWrapper sx={{ padding: { xs: "10px", md: "10px" } }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ padding: { xs: "10px", sm: "50px" } }}
          >
            <ContactForm
              formData={formData}
              handleInputChange={
                location.state?.from === "home"
                  ? handleInputChangeInternal
                  : handleInputChange
              }
              handleFormSubmit={submitHandler}
              setFormData={
                location.state?.from === "home"
                  ? setFormDataInternal
                  : setFormData
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <RightSideOptions
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "flex-start", md: "flex-start" },
                textAlign: { xs: "left", md: "left" },
                paddingTop: {
                  xs: "10px",
                  md: "530px",
                  lg: "500px", // adjust for 1200px screen size
                },
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                sx={{
                  fontWeight: { xs: "500", sm: "600" },
                }}
              >
                Need help with something specific?
              </Typography>
              {/* <Box mb={2}>
                <Typography variant="body2">
                  <a
                    style={{
                      // cursor: "pointer",
                      textDecoration: "none",
                      color: "var(--button-color)",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Contact our support team →
                  </a>
                  <br />
                  <a style={{ fontSize: "12px" }}>Customer@techoptima.in</a>
                </Typography>
              </Box> */}
              <Box mb={2}>
                <Typography variant="body2">
                  <a
                    style={{
                      textDecoration: "none",
                      color: "var(--button-color)",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    href="mailto:support@techoptima.in"
                  >
                    Contact our support team →
                  </a>
                  <br />
                  <a
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                    href="mailto:customer@techoptima.in"
                  >
                    Customer@techoptima.in
                  </a>
                </Typography>
              </Box>

              <Box mb={2}>
                <Typography variant="body2">
                  <a
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "var(--button-color)",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    href="/white-papers"
                  >
                    White Paper →
                  </a>
                  <br />
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ fontSize: "12px" }}
                  >
                    Master the Optima Platform with instructor-led and
                    self-paced training or become a certified developer
                  </Typography>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2" component="p">
                  <a
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "var(--button-color)",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    href="/blogs"
                  >
                    Blogs →
                  </a>
                  <br />
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ fontSize: "12px" }}
                  >
                    Read technical documentation for Optima on AWS, Azure or
                    Google Cloud
                  </Typography>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">
                  <a
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "var(--button-color)",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    href="#office-locations"
                    onClick={handleScrollToLocations}
                  >
                    Office Locations →
                  </a>
                  <br />
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ fontSize: "12px" }}
                  >
                    See all our office locations worldwide
                  </Typography>
                </Typography>
              </Box>
            </RightSideOptions>
          </Grid>
        </Grid>
      </ContactFormWrapper>

      <OfficeLocationWrapper ref={officeLocationRef}>
        <Typography
          variant="h4"
          component="h4"
          gutterBottom
          sx={{
            whiteSpace: "nowrap",
            fontSize: { xs: "28px", md: "32px" },
            textAlign: "center",
          }}
        >
          Our Office Locations
        </Typography>
        <Box
          className="office-container"
          // sx={{
          //   display: "flex",
          //   flexWrap: "nowrap",
          //   overflowX: "auto",
          //   gap: 2,
          // }}
        >
          <div className="office-card">
            <img
              src="https://media.istockphoto.com/id/172906779/photo/charminar.jpg?s=612x612&w=0&k=20&c=hXM4-QUT22QMHIX53KFCKGR0f8GghHjGwip7_TzKVIo="
              alt="Hyd"
              className="office-image"
            />
            <Box>
              <Typography variant="h6" component="h6">
                Hyderabad
              </Typography>
              <Typography variant="body1" component="p">
                Plot No E4, First Floor, CMC Enclave Rd Number 1,
                <br />
                near Pearl Village Road, Kondapur,
                <br /> Hyderabad, Telangana, India - 500084.
              </Typography>
              <Typography>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  href="https://www.google.com/maps/place/TechOptima+Pvt+Ltd/@17.461542,78.34599,16z/data=!4m6!3m5!1s0x3bcb937620c56109:0x5c51a937bfa118d!8m2!3d17.4615421!4d78.3459904!16s%2Fg%2F11p59fwrzy?hl=en&entry=ttu"
                >
                  Find on map →
                </a>
              </Typography>
            </Box>
          </div>
          <div className="office-card">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrin196GA9w2a-e4UN--_IehlKY8EtIqr9Vw&usqp=CAU"
              alt="USA"
              className="office-image"
            />
            <Box>
              <Typography variant="h6" component="h6">
                Chantilly, USA
              </Typography>
              <Typography variant="body1" component="p">
                4915 Trail Vista Lane, Chantilly, VA, 20151.
              </Typography>
              <Typography>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  href="https://www.google.com/maps/place/4915+Trail+Vista+Ln,+Chantilly,+VA+20151,+USA/@38.8756879,-77.4493007,19z/data=!3m1!4b1!4m6!3m5!1s0x89b644161347ae31:0x636c121719112ba4!8m2!3d38.8756879!4d-77.4486556!16s%2Fg%2F11gv16rhcl?entry=ttu"
                >
                  Find on map →
                </a>
              </Typography>
            </Box>
          </div>

          <div className="office-card">
            <img
              src="https://img.freepik.com/premium-photo/flag-australia-realistic-national-flag-realistic-waving-wind_824086-2186.jpg?uid=P156693472&ga=GA1.1.425741652.1718973890" // Alternate Australian flag image URL
              alt="Australia"
              className="office-image"
            />
            <Box>
              <Typography variant="h6" component="h6">
                Googong, Australia
              </Typography>
              <Typography variant="body1" component="p">
                84 Erskine loop, Googong NSW 2620, Australia
              </Typography>
              <Typography>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  href="https://www.google.com/maps/place/84+Erskine+Loop,+Googong+NSW+2620,+Australia"
                >
                  Find on map →
                </a>
              </Typography>
            </Box>
          </div>
        </Box>
      </OfficeLocationWrapper>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Form Submitted Successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {location.state?.from === "/"
                ? "Thank you for contacting sales. Our team will reach out to you shortly."
                : "Thank you for your submission. Our team will reach out to you shortly."}
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Contact;
