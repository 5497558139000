import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import "../Services.css";

const AILedAssurance = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // marginTop: "200px",
      }}
    >
      {/* <Box sx={{ padding: 2, maxWidth: "800px", width: "100%" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          AI-Led-Assurance
        </Typography>
        <Paper sx={{ padding: 2, boxShadow: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, marginRight: 2 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Model Security
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="AI Driven SOC & SIEM (IP)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="AI Security" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Security for AI" />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Model Governance
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Consisting (ATS)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Responsible & Trustworthy AI" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="AI Observability" />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Paper>
      </Box> */}
    </Box>
  );
};

export default AILedAssurance;
