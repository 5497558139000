import React from "react";
import { Container, Typography } from "@mui/material";

const Support = () => {
  return (
    <div style={{ marginTop: "200px" }}>
      <Container>
        <Typography variant="h4">Support</Typography>
        <Typography variant="body1">
          This is the support page content.
        </Typography>
      </Container>
    </div>
  );
};

export default Support;
