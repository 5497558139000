import React from "react";
import { Container, Typography, Box } from "@mui/material";

const ReserveInstance = () => {
  return (
    <Container
      sx={{
        textAlign: "center",
        padding: "150px 25px 25px 25px",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Reserve Your Cloud Instance
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          fontSize: {
            xs: "0.875rem",
            sm: "1rem",
            md: "1.125rem",
            lg: "1.25rem",
          },
          maxWidth: "600px",
          margin: "0 auto",
          marginBottom: "20px",
        }}
      >
        Reserve thousands of NVIDIA H100s, H200s, and GH200s with Quantum-2
        InfiniBand Networking. Enjoy dedicated resources and superior
        performance for your high-demand applications.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {/* Add more detailed content, forms, or steps here */}
        <Typography
          variant="body2"
          component="p"
          sx={{
            fontSize: {
              xs: "0.75rem",
              sm: "0.875rem",
              md: "1rem",
              lg: "1.125rem",
            },
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          1. Choose your GPU models
          <br />
          2. Select the quantity
          <br />
          3. Configure networking options
          <br />
          4. Review and reserve your instances
        </Typography>
      </Box>
    </Container>
  );
};

export default ReserveInstance;
