import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: "var(--buttonHover)", padding: "210px 20px 0 20px" }}
      >
        <Grid item xs={12}>
          <Container
            maxWidth="lg"
            sx={{ backgroundColor: "var(--white)", padding: "16px" }}
          >
            <Typography variant="h4" component="h1">
              TechOptima Privacy Policy
            </Typography>
            <Typography variant="h6" component="h2" sx={{ marginTop: "16px" }}>
              Effective Date: May 2024
            </Typography>
          </Container>
        </Grid>
      </Grid>

      <Container maxWidth="lg" sx={{ padding: "16px" }}>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h5" component="h3">
            Introduction
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            TechOptima Pvt Ltd (“TechOptima,” “we,” “us,” or “our”) is committed
            to protecting your privacy. This Privacy Policy outlines how we
            collect, use, and safeguard your personal information when you
            interact with our website and services. By using our website or
            services, you agree to the terms described in this policy.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Information We Collect
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            <strong>Personal Information:</strong> We collect personal
            information such as your name, email address, and contact details
            when you engage with us through our website or other communication
            channels.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            <strong>Usage Data:</strong> We automatically collect information
            about your interactions with our website, including IP addresses,
            browser type, and pages visited. This helps us improve our services
            and enhance your experience.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            <strong>Data Strategy Services:</strong> TechOptima specializes in
            crafting comprehensive data strategies tailored to your business
            objectives. We use your information to assess your current data
            capabilities, define a roadmap, and align data initiatives with your
            strategic goals.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            <strong>Data Governance and Compliance:</strong> We establish robust
            data governance frameworks to ensure data integrity, security, and
            compliance with relevant regulations. Your data assets are managed
            responsibly and ethically.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            <strong>Enabling Data-Driven Decision Making:</strong> Our goal is
            to transform your organization into a data-driven enterprise. We
            provide tools and processes for data collection, analysis, and
            visualization, empowering informed decision-making.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Security Measures
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            We implement multi-layered security strategies, including network
            security, endpoint protection, and cybersecurity awareness training.
            Your data is safeguarded against cyber threats.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Your Rights
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            You have the right to access, correct, or delete your personal
            information. For any inquiries or requests, please contact us at
            privacy@techoptima.com.
          </Typography>

          <Typography variant="h5" component="h3" sx={{ marginTop: "32px" }}>
            Changes to this Policy
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "16px" }}>
            We may update this Privacy Policy periodically. Please review it
            regularly to stay informed about how we handle your data.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
