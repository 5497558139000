import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Paper,
  Grid,
  Chip,
  Avatar,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import AI1 from "../../../../Assets/Images/AIImages/AIsecurity.webp";
import AI2 from "../../../../Assets/Images/AIImages/Aiassurance.jpg";

import AI3 from "../../../../Assets/Images/AIImages/strategy.jpg";
import { trendingArticles } from "../blogData";

const blogs = [
  { count: 27, label: "Looking for Development" },
  { count: 4, label: "News From Our World" },
  { count: 78, label: "Startup Advice" },
  { count: 10, label: "Startup Expert Interviews" },
  { count: 18, label: "Tech Knowledge" },
  { count: 45, label: "AI Innovations" },
  { count: 23, label: "Market Trends" },
  { count: 34, label: "Business Strategies" },
  { count: 56, label: "Entrepreneurship" },
  { count: 12, label: "Investment Insights" },
];

const trendingBlog = [
  {
    image: AI1,
    title: "How to Find a CTO for Your Startup: The 2024 Entrepreneur’s Guide",
    description:
      "When building a startup, you have many options for software development. Whether you hire a team of developers, outsource to an agency, or find a CTO. While all of these options are viable, if you find a CTO with the right tech expertise, you’ll have gained a key player to help you succeed. But remember, if you pick [...]",
    author: {
      name: "Daniel de Castro Ruivo",
      avatar: AI1,
    },
    category: "Looking for Development",
  },
  {
    image: AI2,
    title: "Top 10 Startup Mistakes and How to Avoid Them",
    description:
      "Every startup faces challenges, but some mistakes can be avoided with the right knowledge and strategy. In this article, we discuss the top 10 mistakes startups make and how you can avoid them to ensure your business thrives. From failing to research the market to not managing finances properly, these insights will help you steer clear of common pitfalls.",
    author: {
      name: "Jane Doe",
      avatar: AI2,
    },
    category: "Startup Advice",
  },
  {
    image: AI3,
    title: "The Future of AI in Business",
    description:
      "Artificial Intelligence (AI) is transforming the business landscape. From automating routine tasks to providing deep insights through data analysis, AI is becoming an integral part of modern businesses. This article explores the current trends in AI and how businesses can leverage these technologies to stay ahead of the competition.",
    author: {
      name: "John Smith",
      avatar: AI3,
    },
    category: "AI Innovations",
  },
];

const Blogs = () => {
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const itemWidth = 160; // Width of each item (150px) + margin (10px)
  const [currentTrendingIndex, setCurrentTrendingIndex] = useState(0);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -itemWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: itemWidth,
        behavior: "smooth",
      });
    }
  };

  const handleArticleClick = (blog) => {
    navigate("/article", { state: { article: blog } });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTrendingIndex(
        (prevIndex) => (prevIndex + 1) % trendingBlog.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container style={{ paddingTop: "150px" }}>
      <Box textAlign="center" mt={4} mb={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Blogs
        </Typography>
        <Typography variant="subtitle1">
          From early-stage startup advice to how to develop a high-quality
          product, and everything in between. We have compiled years of
          knowledge to help you make the right choices on your startup journey.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={4}>
        <Box position="relative" width={{ xs: "100%", sm: "80%", md: "50%" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <SearchIcon position="start" style={{ marginRight: "8px" }} />
              ),
            }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={4}
        mt={4}
        style={{ gap: "20px" }}
      >
        <IconButton onClick={scrollLeft} style={{ zIndex: 1 }}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box
          display="flex"
          flexWrap="nowrap"
          overflow="hidden"
          ref={scrollRef}
          style={{
            scrollBehavior: "smooth",
            width: `${itemWidth * 5}px`,
            overflowX: "scroll",
            justifyContent: "flex-start",
            padding: "20px 0",
          }}
          className="hide-scrollbar"
        >
          {blogs.map((blog, index) => (
            <Paper
              key={index}
              elevation={3}
              style={{
                padding: "8px",
                textAlign: "center",
                width: "180px",
                height: "50px",
                borderRadius: "8px",
                marginRight: "10px",
                flexShrink: 0,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                color="primary"
                style={{ fontSize: "1.0rem", fontWeight: "bold" }}
                gutterBottom
              >
                {blog.count}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                {blog.label}
              </Typography>
            </Paper>
          ))}
        </Box>
        <IconButton onClick={scrollRight} style={{ zIndex: 1 }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Box mb={4}>
        <Typography variant="h4" gutterBottom style={{ paddingBottom: "10px" }}>
          Trending Now
        </Typography>
        <Paper
          elevation={3}
          sx={{
            padding: "32px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            minHeight: "300px",
          }}
        >
          <Box
            component="img"
            src={trendingBlog[currentTrendingIndex].image}
            alt={trendingBlog[currentTrendingIndex].title}
            sx={{
              width: { xs: "100%", md: "200px" },
              height: "auto",
              marginRight: { md: "32px" },
              marginBottom: { xs: "16px", md: "0" },
            }}
          />
          <Box flexGrow={1}>
            <Typography variant="h5" gutterBottom>
              {trendingBlog[currentTrendingIndex].title}
            </Typography>
            <Typography variant="body1" paragraph>
              {trendingBlog[currentTrendingIndex].description}
            </Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <Avatar
                src={trendingBlog[currentTrendingIndex].author.avatar}
                alt={trendingBlog[currentTrendingIndex].author.name}
                style={{ marginRight: "8px" }}
              />
              <Typography variant="body2">
                {trendingBlog[currentTrendingIndex].author.name}
              </Typography>
              <Chip
                label={trendingBlog[currentTrendingIndex].category}
                style={{ marginLeft: "auto" }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>

      <hr style={{ margin: "20px 0", borderTop: "2px solid #ccc" }} />

      <Box mt={4} mb={4}>
        <Grid
          container
          spacing={3}
          className="articleGrid"
          style={{ marginTop: "20px" }}
        >
          {trendingArticles.map((article, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                className="articleCard"
                style={{
                  padding: "16px",
                  minHeight: "350px",
                  marginBottom: "16px",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                onClick={() => handleArticleClick(article)}
              >
                <img
                  src={article.image}
                  alt={article.title}
                  className="articleImage"
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <Typography variant="h6" style={{ marginTop: "10px" }}>
                  {article.title}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <style>
        {`
          .hide-scrollbar::-webkit-scrollbar {
            display: none;
          }
          .hide-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}
      </style>
    </Container>
  );
};

export default Blogs;
