import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../Assets/Images/OIP.jpeg";

const AIAndMLPartner = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "150px 20px",
          sm: "150px 30px",
          md: "150px 50px",
          lg: "150px 70px",
          xl: "190px 80px 80px 80px",
        },
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
            xl: "3.5rem",
          },
          marginBottom: {
            xs: "20px",
            sm: "25px",
            md: "30px",
            lg: "35px",
            xl: "40px",
          },
        }}
      >
        Your AI and Machine Learning Partner
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src="https://img.freepik.com/free-photo/business-people-meeting-looking-presentation_9975-23062.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890"
            alt="AI and ML Partner"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.3rem",
                xl: "1.4rem",
              },
              marginBottom: {
                xs: "15px",
                sm: "20px",
                md: "25px",
                lg: "30px",
                xl: "35px",
              },
            }}
          >
            <h3>Don’t just compete. Dominate with AI.</h3>
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.1rem",
                lg: "1.2rem",
                xl: "1rem",
              },
            }}
          >
            "At TechOptima, we provide you with the tools and expertise needed
            to not only keep up with the competition but to lead the way in AI
            and machine learning.
            <br />
            <br />
            Our cutting-edge solutions and innovative approach ensure that you
            are always at the forefront of technology, ready to tackle any
            challenge and seize every opportunity.
            <br />
            <br />
            Partner with us to transform your business with AI and machine
            learning, and achieve unparalleled success in your industry."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AIAndMLPartner;
