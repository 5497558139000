import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Container,
  styled,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { padding } from "@mui/system";
import AIbussiness from "./../../../../../Assets/Images/AIImages/AIbussiness.jpg";
import AIImage from "./../../../../../Assets/Images/AIImages/AIbussiness.webp"; // Example image path
import Config from "./../../../../../Config/Config.js";
import Contact from "../../../FooterOptions/Contact/Contact.js";
import { ButtonPrimary } from "../../../../../Styles/Styles.js";

import { useLocation, useNavigate } from "react-router-dom";

const SectionBackground = styled(Box)(({ imageUrl }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100%",
  width: "100%",
}));

const sections = [
  {
    title: "Key aspects of Expert Guidance",
    description: [
      "Data analytics is the process of examining, transforming, and modeling raw data to uncover meaningful patterns, insights, and trends.",
    ],
    imageUrl: AIImage,
    analyticsTypes: [
      {
        type: "AI 360",
        description:
          "Predictive analytics is a powerful branch of advanced analytics that uses historical data, statistical modeling, data mining techniques, and machine learning to make predictions about future outcomes.",
      },
      {
        type: "Cloud",
        description:
          "Descriptive analytics is a fundamental aspect of data analytics that focuses on summarizing and interpreting historical data to identify patterns, trends, and relationships.",
      },
      {
        type: "Digital Services",
        description:
          "Diagnostic analytics is a type of data analytics that examines data or content to answer the question, 'Why did it happen?' It is characterized by techniques such as drill-down, data discovery, data mining, and correlations.",
      },
    ],
  },
];

const ExpertGuidancePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [pageName, setPageName] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log({ ...formData, pageName });

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.location.reload();
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };
  return (
    <Box sx={{ py: 6 }}>
      <Box
        className="header-top"
        sx={{
          backgroundImage: `url(${AIbussiness})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "absulate",
          // backgroundColor: "var(--fontcolor)",
          color: "var(--white)",
          textAlign: { xs: "center", md: "left" },
          px: { xs: 2, sm: 4, md: 10 },
          py: { xs: 4, sm: 6, md: 8 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" }, // Adjusted to align left on larger screens
            maxWidth: "lg", // Ensure it does not stretch too wide on large screens
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "var(--white)",
              padding: "5px 10px",
              display: "inline-block",
              marginBottom: "10px",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
            // className="header-title"
            gutterBottom
          >
            Expert Guidance
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "var(--white)",
              width: { xs: "100%", md: "80%" },
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            Get expert guidance for implementing AI and cybersecurity solutions.
          </Typography>
          <ButtonPrimary
            variant="contained"
            className="header-button"
            onClick={() =>
              navigate("/scheduleCall", { state: { from: location.pathname } })
            }
            sx={{
              mt: { xs: 2, md: 4 },
              width: { xs: "100%", sm: "auto" },
              fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
              padding: { xs: "10px 20px", sm: "12px 24px", md: "14px 28px" },
            }}
          >
            Schedule a Free Consultation
          </ButtonPrimary>
        </Container>
      </Box>

      {/* Content Section */}
      {sections.map((section, index) => (
        <Grid
          container
          spacing={4}
          alignItems="center"
          key={index}
          style={{ padding: "0 16px" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              className="image-container"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <img
                src={section.imageUrl}
                alt="Content Image"
                className="section-image"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "500px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {section.title}
            </Typography>
            {section.description.map((desc, idx) => (
              <Typography variant="body1" gutterBottom key={idx}>
                {desc}
              </Typography>
            ))}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {section.analyticsTypes.map((analytics, idx) => (
                <Grid item xs={12} sm={6} key={idx}>
                  <Box>
                    <Typography
                      variant="h5"
                      component="h6"
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        textDecoration: "none",
                        color: "var(--button-color)",
                      }}
                    >
                      <CheckCircleIcon
                        sx={{
                          color: "var(--button-color)",
                          mr: 1,
                          fontSize: "14px",
                        }}
                      />
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          textDecoration: "none",
                          color: "var(--button-color)",
                          "&:hover": {
                            color: "var(--button-color) !important",
                          },
                        }}
                      >
                        {analytics.type}
                      </a>
                    </Typography>
                    <Typography style={{ fontSize: "14px" }}>
                      {analytics.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid var(--white)",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {/* Form Submitted Successfully */}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Thank you for your submission. Our team will reach out to you
              shortly.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ExpertGuidancePage;
