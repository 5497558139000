import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import support2 from "../../../Assets/Images/support2.jpg";
import Lowcode from "../../../Assets/Images/Lowcode.jpg";
import Culture from "../../../Assets/Images/Culture.jpg";
const Careers = () => {
  return (
    <Container
      sx={{
        paddingTop: "150px",
      }}
    >
      <Box py={4}>
        <Typography variant="h3" align="center" gutterBottom>
          Careers at Techoptima
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Why Techoptima?
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          At Techoptima, we foster innovation, creativity, and growth. Join us
          to be a part of a dynamic team working on cutting-edge technologies.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                alt="Team Collaboration"
                height="300"
                image={support2}
                title="Team Collaboration"
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography gutterBottom variant="h5" component="div">
                Team Collaboration
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Work with a talented team in a collaborative environment that
                encourages innovative ideas and solutions.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography gutterBottom variant="h5" component="div">
                Innovation
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Be a part of an innovative culture that constantly pushes the
                boundaries of technology and creativity.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                alt="Innovation"
                height="340"
                image={Lowcode}
                title="Innovation"
              />
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                alt="Our Culture"
                height="300"
                image={Culture}
                title="Our Culture"
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography gutterBottom variant="h5" component="div">
                Our Culture
              </Typography>
              <Typography variant="body2" color="text.secondary">
                At Techoptima, we value diversity, creativity, and teamwork. Our
                culture is built on mutual respect and a shared passion for
                excellence.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Careers;
