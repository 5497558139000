import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";

const WhitePaper = () => {
  return (
    <Container
      sx={{
        padding: {
          xs: "150px 20px",
          sm: "150px 40px",
          md: "150px 60px",
          lg: "150px 80px",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Master the Optima Platform
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Instructor-led and Self-paced Training</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
              },
            }}
          >
            "At Optima, we offer comprehensive training programs designed to
            help you master our platform. Whether you prefer instructor-led
            sessions or self-paced learning, we have options to suit your needs.
            <br />
            <br />
            Our training programs cover a wide range of topics, including
            platform basics, advanced features, and best practices for
            maximizing your productivity. Each course is developed by industry
            experts and includes hands-on exercises to reinforce learning.
            <br />
            <br />
            Join our training programs to gain the skills and knowledge needed
            to become proficient in using the Optima platform. Stay ahead of the
            curve with our continuously updated training materials."
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Become a Certified Developer</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
              },
            }}
          >
            "Take your expertise to the next level by becoming a certified
            developer on the Optima platform. Our certification program is
            designed to validate your skills and knowledge, giving you a
            competitive edge in the job market.
            <br />
            <br />
            The certification process includes a comprehensive exam that tests
            your understanding of the platform and your ability to apply what
            you've learned in real-world scenarios. Upon passing the exam,
            you'll receive a certificate that recognizes your achievement and
            proficiency.
            <br />
            <br />
            Achieving certification demonstrates your commitment to professional
            development and positions you as a trusted expert in the field.
            Start your journey to becoming a certified Optima developer today."
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhitePaper;
