import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import "../Services.css";

const AppliedDataScience = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // marginTop: "200px",
      }}
    >
      <Box sx={{ padding: 2, maxWidth: "800px", width: "100%" }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Applied Data Science
        </Typography>
        <Paper sx={{ padding: 2, boxShadow: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, marginRight: 2 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Insights
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Data Engineering" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Data Operations" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Data Visualization" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Reporting & DashBoarding" />
                </ListItem>
              </List>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" color="secondary" gutterBottom>
                Analytics
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Predictive Analytics" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Descriptive Analytics" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Diagnostic Analytics" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Prescriptive Analytics" />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AppliedDataScience;
