import React, { useState, useEffect } from "react";
import Radious from "./../../../Assets/Images/Radious.jpg";

import {
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Tooltip,
} from "recharts";

const VendorSelectionCriteria = () => {
  const initialScores = {
    features: Array(5).fill(""),
    customization: Array(5).fill(""),
    maintenance: Array(5).fill(""),
    scalability: Array(5).fill(""),
    deployment: Array(4).fill(""),
    security: Array(4).fill(""),
    affordability: Array(4).fill(""),
    viability: Array(4).fill(""),
  };

  const initialNotes = {
    features: Array(5).fill(""),
    customization: Array(5).fill(""),
    maintenance: Array(5).fill(""),
    scalability: Array(5).fill(""),
    deployment: Array(4).fill(""),
    security: Array(4).fill(""),
    affordability: Array(4).fill(""),
    viability: Array(4).fill(""),
  };

  const [scores, setScores] = useState(initialScores);
  const [notes, setNotes] = useState(initialNotes);
  const [open, setOpen] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [showDashboard, setShowDashboard] = useState(false);

  const questions = {
    features: [
      "Does the vendor align with your top-priority use cases and functional and nonfunctional requirements?",
      "Is the solution's interface intuitive, accessible, and easy to use for the target end users?",
      "Can the solution generate valuable outputs in the desired formats or mediums (e.g., images, music, text)? Does the generated output conflict with copyrights?",
      "Does the vendor provide out-of-the-box compliance to industry and regulatory standards? Does the solution align with your ethics policies and principles?",
      "Are templates, shells, and pretrained models and bots readily available? What data was used to train the prebuilt models?",
    ],
    customization: [
      "Does the vendor provide options to fine-tune or adapt the generative AI model to align with your creative vision or domain-specific constraints?",
      "Are popular programming languages, frameworks, and tactics used in the modification of Gen AI models, algorithms, or training methods?",
      "Are all changes and modifications to the Gen AI model catalogued and versioned?",
      "Is the Gen AI model compatible with a variety of data types and structures?",
      "Is the Gen AI model continuously retrained with fresh data?",
    ],
    maintenance: [
      "Is there a service-level agreement (SLA) that outlines the vendor's commitments regarding response times and issue resolution?",
      "Are there designated support channels, such as email, phone, or a dedicated support portal?",
      "Does the vendor provide training and onboarding?",
      "Can multiple users or teams collaborate within the solution? Does the vendor provide forums to enable discussion and sharing among peers?",
      "Does the solution provide a dashboard to monitor the modifications, uses, value, and performances of the Gen AI bots?",
    ],
    scalability: [
      "Can additional resources be provisioned to ensure optimal processing speeds during high-volume events and handle increasing data volumes and user demands?",
      "Are there any licensing, load or traffic constraints of the solution?",
      "How easy it is to transfer models, training data, and other solution artifacts to and from this solution?",
      "Can the solution's execution be timed, planned, and orchestrated according to the user's preferred schedule or workflow triggers?",
      "Are your disaster recovery and business continuity requirements satisfied by the solution?",
    ],
    deployment: [
      "Is the solution interoperable with existing applications, data infrastructure, and technologies in your stack?",
      "Does the solution meet your deployment and data residency preferences?",
      "Does this vendor align with your direction from an enterprise architecture perspective?",
      "Does the vendor provide out-of-the-box plugins and customizable APIs to support integration with other solutions?",
    ],
    security: [
      "Does this vendor comply with your organization’s security policies?",
      "Are training and operational data managed and governed separately to abide privacy and confidentiality requirements?",
      "Does the solution provide role-based access and monitor user activities?",
      "Does the vendor provide sufficient protection of their Gen AI bots to prevent IP and data leakage?",
    ],
    affordability: [
      "Does the vendor provide transparent pricing information and options for different budget requirements?",
      "Do you need to bring in or train specialized labor to implement and maintain the solution (e.g. FTE developers or BI analysts)?",
      "Are free or demo versions available for teams to test and experiment with the solution's features and capabilities?",
      "What is the cost to leverage vendor infrastructure to operate and support the solution?",
    ],
    viability: [
      "Are they an established player with a proven track record or a new and untested entrant to the market? What is the financial health of the vendor?",
      "Does the vendor have a cogent and realistic product roadmap? Are they making sensible investments that align with your organization’s internal direction?",
      "Does the vendor engage and collaborate with peers in the industry and marketplace?",
      "What is the scope of Gen AI solutions in the vendor's portfolio? How committed are they to the particular solution category?",
    ],
  };

  const maxScores = {
    features: 5 * 5,
    customization: 5 * 5,
    maintenance: 5 * 5,
    scalability: 5 * 5,
    deployment: 4 * 5,
    security: 4 * 5,
    affordability: 4 * 5,
    viability: 4 * 5,
  };

  const weights = {
    features: 0.2,
    customization: 0.15,
    maintenance: 0.15,
    scalability: 0.1,
    deployment: 0.1,
    security: 0.1,
    affordability: 0.1,
    viability: 0.1,
  };

  const calculateTotal = (category) =>
    scores[category].reduce((acc, score) => acc + Number(score), 0);

  const calculateWeightedScore = (category) => {
    const average = calculateTotal(category) / (scores[category].length * 5);
    return average * weights[category] * 100;
  };

  useEffect(() => {
    const total = Object.keys(weights)
      .reduce((acc, category) => acc + calculateWeightedScore(category), 0)
      .toFixed(2);
    setTotalScore(total);
  }, [scores]);

  const handleScoreChange = (category, index) => (event) => {
    const newScores = { ...scores };
    newScores[category][index] = event.target.value;
    setScores(newScores);
  };

  const handleNoteChange = (category, index) => (event) => {
    const newNotes = { ...notes };
    newNotes[category][index] = event.target.value;
    setNotes(newNotes);
  };

  const handleSubmit = () => {
    const selectedData = {};

    Object.keys(weights).forEach((section) => {
      const selects = scores[section];
      let total = 0;
      selects.forEach((select) => {
        total += parseInt(select) || 0;
      });
      const average = selects.length > 0 ? total / selects.length : 0;

      let weightedAverage;
      if (section === "features") {
        weightedAverage = average * 4; // 20%
      } else if (section === "customization" || section === "maintenance") {
        weightedAverage = average * 3; // 15%
      } else {
        weightedAverage = average * 2; // 10%
      }

      selectedData[section] = {
        score: weightedAverage.toFixed(2),
        notes: notes[section],
      };
    });

    localStorage.setItem("vendorSelectionData", JSON.stringify(selectedData));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowDashboard(true);
  };

  const handleGoBack = () => {
    setShowDashboard(false);
  };

  const radarData = [
    { subject: "Features", A: calculateWeightedScore("features") },
    { subject: "Customization", A: calculateWeightedScore("customization") },
    { subject: "Maintenance", A: calculateWeightedScore("maintenance") },
    { subject: "Scalability", A: calculateWeightedScore("scalability") },
    { subject: "Deployment", A: calculateWeightedScore("deployment") },
    { subject: "Security", A: calculateWeightedScore("security") },
    { subject: "Affordability", A: calculateWeightedScore("affordability") },
    { subject: "Viability", A: calculateWeightedScore("viability") },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box
        sx={{
          padding: {
            lg: "150px 80px 80px 80px",
            md: "150px 60px 60px 60px",
            sm: "150px 40px 40px 40px",
            xs: "150px 20px 20px 20px",
          },
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={Radious} // Replace with the actual image URL
              alt="Radius illustration"
              sx={{
                width: "100%",
                maxHeight: "400px",
                borderRadius: "45px 0px 45px 0px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontSize: isMobile ? "1.5rem" : "4rem",
              }}
            >
              What is Radius?
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontStyle: "italic",
                fontSize: isMobile ? "0.875rem" : "inherit",
              }}
            >
              Radius is a cybersecurity solution that integrates Security
              Information and Event Management (SIEM) and Extended Detection and
              Response (XDR) capabilities. SIEM focuses on collecting and
              analyzing log data from various sources to detect security
              incidents, while XDR extends this by integrating data from
              multiple security layers, such as endpoints, networks, and cloud
              environments, to provide a more comprehensive threat detection and
              response system.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{
                fontSize: isMobile ? "1.25rem" : "2.5rem",
              }}
            >
              Why is Radius Important?
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: isMobile ? "0.875rem" : "inherit",
              }}
            >
              Radius is crucial because it enhances an organization’s ability to
              detect, investigate, and respond to security threats more
              effectively. Traditional SIEM solutions can be limited by their
              focus on log data, whereas XDR provides a broader view by
              incorporating additional security telemetry. This unified approach
              helps in identifying sophisticated threats that might be missed
              when analyzing data in isolation.
              <br />
              <br />
              Key benefits include:
              <br />- <strong>Enhanced Visibility:</strong> By collecting data
              from diverse sources, Radius offers a comprehensive understanding
              of the security landscape.
              <br />- <strong>Advanced Analytics:</strong> Utilizes machine
              learning and behavioral analytics to detect anomalies and
              potential threats.
              <br />- <strong>Improved Response:</strong> Facilitates quicker
              and more effective incident response by correlating data across
              different security layers.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{
                fontSize: isMobile ? "1.25rem" : "2.5rem",
              }}
            >
              How is Radius Implemented?
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: isMobile ? "0.875rem" : "inherit",
              }}
            >
              Radius is implemented by integrating various security tools and
              data sources into a single platform. Here’s a simplified process:
              <br />
              <br />- <strong>Data Collection:</strong> Gathers security data
              from endpoints, network traffic, cloud environments, and other
              sources.
              <br />- <strong>Data Correlation:</strong> Correlates and analyzes
              this data to identify patterns and anomalies that indicate
              potential threats.
              <br />- <strong>Threat Detection:</strong> Uses advanced analytics
              and threat intelligence to detect and prioritize security
              incidents.
              <br />- <strong>Incident Response:</strong> Provides automated and
              manual response options to mitigate identified threats, including
              isolating affected systems, blocking malicious activities, and
              initiating remediation processes.
              <br />
              <br />
              Would you like to dive deeper into any specific feature or aspect
              of Radius?
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* {!showDashboard && (
        <div id="vendorSelectionCriteriaSection" className="content-section">
          <Typography variant="h3" className="heading-gradient">
            Radius
          </Typography>
          <Typography variant="h5">Evaluation Criteria</Typography>
          <TableContainer component={Paper}>
            <Table className="table table-bordered">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Leading Questions
                  </TableCell>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(questions).map((category) => (
                  <React.Fragment key={category}>
                    <TableRow>
                      <TableCell
                        component="th"
                        colSpan={3}
                        className="table-category-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </TableCell>
                    </TableRow>
                    {questions[category].map((question, index) => (
                      <TableRow key={index}>
                        <TableCell className="table-cell">{question}</TableCell>
                        <TableCell className="table-cell">
                          <Select
                            value={scores[category][index]}
                            onChange={handleScoreChange(category, index)}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {[1, 2, 3, 4, 5].map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell className="table-cell">
                          <TextField
                            placeholder="Add note"
                            fullWidth
                            value={notes[category][index]}
                            onChange={handleNoteChange(category, index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                <TableRow>
                  <TableCell component="th" className="table-cell">
                    Total Score
                  </TableCell>
                  <TableCell className="table-cell">{totalScore}%</TableCell>
                  <TableCell className="table-cell"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ textAlign: "center", margin: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              className="sub-btn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {showDashboard && (
        <div id="chartSection" className="content-section">
          <Typography variant="h3" className="heading-gradient text-center">
            Vendor Performance Dashboard
          </Typography>
          <div className="row">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RadarChart
                cx="50%"
                cy="50%"
                outerRadius="80%"
                width={600}
                height={500}
                data={radarData}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={30} domain={[0, 25]} tickCount={6} />
                <Radar
                  name="Total"
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
                <Tooltip />
                <Legend />
              </RadarChart>
            </div>
            <div className="col-12 col-md-12" id="summaryTable">
              <TableContainer component={Paper}>
                <Table className="table table-bordered">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        Total Percentage
                      </TableCell>
                      <TableCell
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        Notes
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(questions).map((category) => (
                      <TableRow key={category}>
                        <TableCell className="table-cell">
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {calculateWeightedScore(category).toFixed(2)}%
                        </TableCell>
                        <TableCell className="table-cell">
                          <div>
                            {notes[category]
                              .map((note, index) => ({ note, index }))
                              .filter((item) => item.note)
                              .map((item, filteredIndex) => (
                                <div key={item.index}>{`${filteredIndex + 1}. ${
                                  item.note
                                }`}</div>
                              ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        // className="table-cell"
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        <strong>{totalScore}%</strong>
                      </TableCell>
                      <TableCell
                        className="summary-table-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div
            className="text-center mt-4"
            style={{ textAlign: "center", margin: "20px" }}
          >
            <Button variant="contained" color="primary" onClick={handleGoBack}>
              Go Back
            </Button>
          </div>
        </div>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your total score is {totalScore}%.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Next
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default VendorSelectionCriteria;
