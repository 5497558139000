import React from "react";
import { Container, Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const ArticlePage = () => {
  const location = useLocation();
  const { article } = location.state;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      style={{
        padding: isSmallScreen
          ? "150px 20px 20px 20px" // More compact padding for small screens
          : isMediumScreen
          ? "150px 50px 20px 50px" // Medium padding for medium screens
          : "150px 150px 20px 150px", // Original padding for large screens
        position: "relative",
      }}
    >
      <Box>
        <Box
          sx={{
            top: "20px",
            right: "20px",
            textAlign: "right",
          }}
        >
          {/* <Typography variant="body1" sx={{ fontStyle: "bold" }}>
            Writtern by:&nbsp;
            <Typography
              variant="body1 "
              sx={{ fontStyle: "italic", fontWeight: "800" }}
            >
              {article.writer.name}
            </Typography>
          </Typography> */}
          {/* <Typography
            variant="body2"
            sx={{ fontStyle: "italic", fontWeight: "700" }}
          >
            {article.writer.designation}
          </Typography> */}
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            ...published on: {article.writer.publishedDate}
          </Typography>
        </Box>

        {/* Article title */}
        <Typography variant="h4" gutterBottom>
          {article.title}
        </Typography>

        <Box
          component="img"
          src={article.image}
          alt={article.title}
          style={{
            width: isSmallScreen ? "100%" : "100%",
            margin: "40px auto",
            display: "block",
            height: "450px",
          }}
        />

        {/* Article sections */}
        {article.content.sections.map((section, index) => (
          <React.Fragment key={index}>
            <Box
              mb={4}
              display="flex"
              flexDirection={
                isSmallScreen
                  ? "column"
                  : index % 2 === 0
                  ? "row"
                  : "row-reverse"
              }
              alignItems={isSmallScreen ? "flex-start" : "center"}
              style={{ gap: "20px" }}
            >
              {/* <Box flex={1}>
                <Typography variant="h6" gutterBottom>
                  {section.heading}
                </Typography>
                {Array.isArray(section.content) ? (
                  section.content.map((item, idx) => (
                    <div key={idx} style={{ display: "inline" }}>
                      {item.subheading ? (
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: item.fontWeight || "normal" }}
                          gutterBottom
                        >
                          {item.text}
                        </Typography>
                      ) : (
                        <Typography variant="body1" gutterBottom>
                          {item}
                        </Typography>
                      )}
                    </div>
                  ))
                ) : (
                  <Typography variant="body1">{section.content}</Typography>
                )}
              </Box> */}

              <Box flex={1}>
                <Typography variant="h6" gutterBottom>
                  {section.heading}
                </Typography>
                {Array.isArray(section.content) ? (
                  section.content.map((item, idx) => (
                    <div key={idx} style={{ marginBottom: "16px" }}>
                      {/* Check if the item is a subheading and the next item is text */}
                      {item.subheading &&
                      typeof section.content[idx + 1] === "string" ? (
                        <Typography
                          variant="body1"
                          style={{ display: "inline" }}
                        >
                          <span
                            style={{ fontWeight: "bold", marginRight: "8px" }}
                          >
                            {item.text}
                          </span>
                          <span>{section.content[idx + 1]}</span>{" "}
                        </Typography>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <Typography variant="body1">{section.content}</Typography>
                )}
              </Box>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Container>
  );
};

export default ArticlePage;
