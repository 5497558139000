// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Modal,
//   Backdrop,
//   Fade,
//   Button,
//   Container,
// } from "@mui/material"; // Import Container here
// import Contact from "../../../Footer/FooterOptions/Contact/Contact"; // Import the Contact component
// import Config from "../../../../Config/Config";

// const ScalarServer = () => {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     company: "",
//     email: "",
//     country: "India",
//     phone: "",
//     contactReason: "Select",
//     message: "",
//   });
//   const [open, setOpen] = useState(false);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     console.log("Form submitted");
//     console.log(formData);

//     const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
//     const data = {
//       toEmail: formData.email,
//       pageName: "ScalarServer contact", // Specify the page name
//     };

//     try {
//       const response = await fetch(apiEndpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const result = await response.json();
//       console.log("Success:", result);

//       // Show success popup
//       setOpen(true);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//     window.scrollTo({ top: 0, behavior: "smooth" });
//     setFormData({
//       firstName: "",
//       lastName: "",
//       company: "",
//       email: "",
//       country: "India",
//       phone: "",
//       contactReason: "Select",
//       message: "",
//     });
//   };

//   return (
//     <Box
//       sx={{
//         paddingTop: {
//           xs: "150px ",
//           sm: "150px ",
//           md: "150px ",
//           lg: "150px ",
//         },
//       }}
//     >
//       <Box
//         sx={{
//           backgroundImage: `url('https://www.techoptima.in/assets/images/banner/Data-Strategy.png')`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//           backgroundPosition: "center",
//           color: "white",
//           textAlign: { xs: "center", md: "left" }, // Center text on small screens
//           px: { xs: 2, sm: 4, md: 10 }, // Responsive padding
//           py: { xs: 4, sm: 6, md: 20 }, // Responsive padding
//         }}
//       >
//         <Container>
//           <Typography
//             variant="h2"
//             component="h1"
//             gutterBottom
//             sx={{ color: "white" }}
//           >
//             OptimaX
//           </Typography>
//         </Container>
//       </Box>

//       <Box sx={{ mt: 4, px: { xs: 2, sm: 4, md: 10 } }}>
//         {/* <Grid container spacing={4} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <img
//               src="https://img.freepik.com/free-photo/business-people-looking-chart-office_23-2148017088.jpg?t=st=1722437174~exp=1722440774~hmac=bf54a651a4a49f0bf8512f71af9538036ab2bc4a8ea2197672f5aa326985cc01&w=740"
//               alt="Unlocking Efficiency and Scalability"
//               style={{ width: "100%", height: "50%", borderRadius: "8px" }}
//             />
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="h4" component="h2" gutterBottom>
//               Unlocking Efficiency and Scalability
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               OPTIMA-X conveys a forward-thinking, optimized approach that
//               aligns perfectly with the values of TechOptima. It suggests a
//               blend of cutting-edge technology, scalability, and innovation,
//               making it an attractive and memorable concept for clients.
//             </Typography>
//           </Grid>
//         </Grid>

//         <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
//           <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
//             <Typography variant="h4" component="h2" gutterBottom>
//               Auto Scaling Capabilities
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Cloud platforms provide auto scaling features that dynamically
//               adjust resources based on demand. When workloads spike, the system
//               automatically provisions additional compute resources. Conversely,
//               during low-demand periods, it scales down to save costs. This
//               elasticity ensures optimal resource utilization without manual
//               intervention.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
//             <img
//               src="https://img.freepik.com/free-photo/top-view-office-desk-with-growth-chart-hands_23-2148780613.jpg?t=st=1722435938~exp=1722439538~hmac=fc617dc64d8ea65723b1f3677721f612a305a59ca093a1809cff90bee08a831d&w=996"
//               alt="Auto Scaling Capabilities"
//               style={{ width: "100%", height: "auto", borderRadius: "8px" }}
//             />
//           </Grid>
//         </Grid> */}
//         <Grid
//           container
//           spacing={4}
//           alignItems="center "
//           //   sx={{ fontStyle: "italic" }}
//         >
//           <Typography
//             variant="body1"
//             gutterBottom
//             sx={{
//               fontSize: {
//                 xs: "1rem",
//                 sm: "1.25rem",
//                 md: "1.5rem",
//                 lg: "1.25rem",
//               },
//               padding: {
//                 xs: "2rem",
//                 sm: "1.25rem",
//                 md: "1.5rem",
//                 lg: "2rem",
//               },
//             }}
//           >
//             In a world that is so competent, being on your toes is extremely
//             important. When it comes to AI Model training, speed, ability to
//             multitask, and a good memory bandwidth are the components that are
//             essential to an AI engineer. A Graphic Processing Unit is such a
//             system which comes handy for an AI engineer in building their models
//             smoothly.
//           </Typography>
//           <Typography
//             variant="body1"
//             gutterBottom
//             sx={{
//               fontSize: {
//                 xs: "1rem",
//                 sm: "1.25rem",
//                 md: "1.5rem",
//                 lg: "1.25rem",
//               },
//               padding: {
//                 xs: "2rem",
//                 sm: "1.25rem",
//                 md: "1.5rem",
//                 lg: "2rem",
//               },
//             }}
//           >
//             TechOptima’s NVIDIA DGX System is one such platform which provides
//             ai developers with the scope of building models without having to
//             worry about the space, cost or even the rate of training. TechOptima
//             aims to help budding developers overcome the following challenges:
//           </Typography>
//         </Grid>
//       </Box>
//       <Contact
//         formData={formData}
//         handleInputChange={handleInputChange}
//         handleFormSubmit={handleFormSubmit}
//         setFormData={setFormData}
//       />
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: 400,
//               bgcolor: "background.paper",
//               border: "2px solid #000",
//               boxShadow: 24,
//               p: 4,
//             }}
//           >
//             <Typography id="transition-modal-title" variant="h6" component="h2">
//               Form Submitted Successfully
//             </Typography>
//             <Typography id="transition-modal-description" sx={{ mt: 2 }}>
//               Thank you for contacting us. Our team will reach out to you
//               shortly.
//             </Typography>
//             <Button onClick={handleClose} sx={{ mt: 2 }}>
//               OK
//             </Button>
//           </Box>
//         </Fade>
//       </Modal>
//     </Box>
//   );
// };

// export default ScalarServer;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Button,
  Container,
} from "@mui/material"; // Import Container here
import Contact from "../../../Footer/FooterOptions/Contact/Contact"; // Import the Contact component
import Config from "../../../../Config/Config";

const ScalarServer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "India",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [open, setOpen] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log(formData);

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName: "ScalarServer contact", // Specify the page name
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "India",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  return (
    <Box
      sx={{
        paddingTop: {
          xs: "150px",
          sm: "150px",
          md: "150px",
          lg: "150px",
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('https://www.techoptima.in/assets/images/banner/Data-Strategy.png')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          color: "white",
          textAlign: { xs: "center", md: "left" }, // Center text on small screens
          px: { xs: 2, sm: 4, md: 10 }, // Responsive padding
          py: { xs: 4, sm: 6, md: 20 }, // Responsive padding
        }}
      >
        <Container>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              color: "white",
              fontSize: {
                xs: "2rem",
                sm: "2.5rem",
                md: "3rem",
                lg: "4rem",
              },
            }}
          >
            OptimaX
          </Typography>
        </Container>
      </Box>

      <Box sx={{ mt: 4, px: { xs: 2, sm: 4, md: 10 } }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1.125rem",
                  lg: "1.25rem",
                },
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "var(--button-color)" }}
              >
                OptimaX
              </span>{" "}
              conveys a forward-thinking, optimized approach that aligns
              perfectly with the values of TechOptima. It suggests a blend of
              cutting-edge technology, scalability, and innovation, making it an
              attractive and memorable concept for clients.
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.75rem",
                  md: "2rem",
                  lg: "2.25rem",
                },
              }}
            >
              O.P.T.I.M.A-X stands for:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>O: Optimized Operations</strong>
              <br />
              Streamline processes and maximize efficiency with AI-driven
              solutions tailored to your business needs.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>P: Predictive Power</strong>
              <br />
              Leverage advanced predictive analytics to anticipate trends,
              mitigate risks, and drive proactive decision-making.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>T: Transformative Technology</strong>
              <br />
              Utilize cutting-edge AI and cloud technologies that transform
              traditional operations into future-ready processes.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>I: Intelligent Integration</strong>
              <br />
              Seamlessly integrate AI systems into your existing infrastructure,
              ensuring smooth transitions and minimal disruptions.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>M: Modular Scalability</strong>
              <br />
              Build a scalable architecture that grows with your business,
              allowing for easy expansion and adaptation.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>A: Adaptive Solutions</strong>
              <br />
              Deliver adaptive AI models that evolve with changing data and
              market conditions, maintaining relevance and accuracy.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                },
              }}
            >
              <strong>X: eXponential Innovation</strong>
              <br />
              Drive exponential growth and innovation with continuous
              advancements in AI, ensuring your business stays at the forefront
              of technology.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Form Submitted Successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Thank you for contacting us. Our team will reach out to you
              shortly.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ScalarServer;
