import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { ButtonPrimary } from "../../../../Styles/Styles";

const Form = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "700px",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(1),
  },
}));
const ContactForm = ({
  firstName,
  lastName,
  company,
  email,
  country,
  phone,
  contactReason,
  message,
  handleInputChange,
  handleFormSubmit,
  setFormData,
}) => {
  const theme = useTheme();
  // Function to reset form fields
  const resetForm = () => {
    if (setFormData) {
      setFormData({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        country: "",
        phone: "",
        contactReason: "Select",
        message: "",
      });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormSubmit(event);
    resetForm(); // Clear form fields after submission
  };
  return (
    <Box
      sx={{
        padding: "25px",
        [theme.breakpoints.down("sm")]: { padding: "16px" },
        [theme.breakpoints.down("xs")]: { padding: "8px" },
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography
            variant="h3"
            component="h3"
            gutterBottom
            sx={{
              fontSize: { xs: "1.75rem", sm: "2rem", md: "2.5rem" },
              paddingTop: { xs: "80px", sm: "80px" },
            }}
          >
            Let's Shape the Future with AI Innovation!
          </Typography>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
          >
            Fill out the form and a Optima team member will reach out
          </Typography>
          <Form component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="firstName"
                  value={firstName}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="lastName"
                  value={lastName}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company"
                  variant="outlined"
                  fullWidth
                  required
                  name="company"
                  value={company}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  variant="outlined"
                  fullWidth
                  required
                  select
                  name="country"
                  value={country}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                >
                  <MenuItem value="India">India</MenuItem>
                  <MenuItem value="USA">USA</MenuItem>
                  <MenuItem value="Canada">Canada</MenuItem>
                  <MenuItem value="UK">UK</MenuItem>
                  <MenuItem value="Australia">Australia</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Contact Reason"
                  variant="outlined"
                  fullWidth
                  required
                  select
                  name="contactReason"
                  value={contactReason}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      height: "50px",
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                >
                  <MenuItem value="Select">Select...</MenuItem>
                  <MenuItem value="Managed Services">Managed Services</MenuItem>
                  <MenuItem value="IT Consulting & Advisory">
                    IT Consulting & Advisory
                  </MenuItem>
                  <MenuItem value="Cyber Security">Cyber Security</MenuItem>
                  <MenuItem value="Web Development">Web Development</MenuItem>
                  <MenuItem value="Mobile Development">
                    Mobile Development
                  </MenuItem>
                  <MenuItem value="Cloud Services">Cloud Services</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  name="message"
                  value={message}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  InputLabelProps={{
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="p" paragraph>
                  By submitting, I agree to the processing of my personal data
                  by TechOptima in accordance with our{" "}
                  <a
                    href="/privacypolicy"
                    sx={{
                      color: "var(--button-color)",
                      textDecoration: "none",
                      "&:hover": {
                        color: "var(--button-color)",
                      },
                    }}
                  >
                    Privacy Policy.
                  </a>{" "}
                  I understand I can update my preferences at any time.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonPrimary variant="contained" fullWidth type="submit">
                  Submit
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactForm;
