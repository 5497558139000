import React from "react";
import { useParams } from "react-router-dom";
import Header from "./Header/Header";
import AppliedAIEngineering from "./../Applied-AI- Engineering/AppliedAIEngineering";
import CloudTransformation from "./../Cloud/CloudTransformation/CloudTransformation";
import CloudManagedServices from "./../Cloud/Cloud Managed Services/CloudManagedServices";
import CloudMigrations from "./../Cloud/Cloud Migrations/CloudMigrations";
import CloudOperations from "./../Cloud/Cloud Operations/CloudOperations";
import CloudNativeApplicationDevelopment from "./../Cloud/Cloud Native Application Development/CloudNativeApplicationDevelopment";
import CloudDevOps from "./../Cloud/Cloud DevOps/CloudDevOps";
import EnterpriseApplicationDevelopment from "./../DigitalServices/Enterprise application Development/EnterpriseapplicationDevelopment";
import EnterprisePlatformDevelopment from "./../DigitalServices/Enterprise platform development/Enterpriseplatformdevelopment";
import AgileDevOps from "./../DigitalServices/Agile DevOps/AgileDevOps";
import IntelligentAutomationRPA from "./../DigitalServices/Intelligent Automation RPA/IntelligentAutomationRPA";
import LowCodeApplication from "./../DigitalServices/Low Code Application/LowCodeApplication";
import AIbussiness from "./../../../Assets/Images/AIImages/AIbussiness.jpg";
import Development from "../../Home/AppliedAIEngineeringOptions/Development/Development";
import AIOptimization from "../../Home/AppliedAIEngineeringOptions/AIOptimization/AIOptimization";
import DataOperations from "../../Home/AppliedDataScience/DataOperations/DataOperations";
import Datavisualization from "../../Home/AppliedDataScience/Datavisualization/Datavisualization";
import BenchmarkingIP from "../../Home/AIQualityOptions/BenchmarkingIP/BenchmarkingIP";
import Robustness from "../../Home/AIQualityOptions/Robustness/Robustness";
import Explainability from "../../Home/AIQualityOptions/Explainability/Explainability";
import AIDeiven from "../../Home/AIAssurance/AIDeiven/AIDeiven";
import AISecurity from "../../Home/AIAssurance/AISecurity/AISecurity";
import Consulting from "../../Home/AIAssurance/Consulting/Consulting";
import ResponsibleAndTrustworthy from "../../Home/AIAssurance/ResponsibleAndTrustworthy/ResponsibleAndTrustworthy";
import Observability from "../../Home/AIAssurance/Observability/Observability";

import ReportingAndDashboarding from "../../Home/AppliedDataScience/ReportingAndDashboarding/ReportingAndDashboarding";

import Tuning from "../../Home/AppliedAIEngineeringOptions/Development/DevelopOptions/Tuning";

import AppliedAI from "../../../Assets/Images/AppliedAI/AppliedAI.jpg";
import AppliedAI2 from "../../../Assets/Images/AppliedAI/AppliedAI2.jpg";
import AppliedAI3 from "../../../Assets/Images/AppliedAI/AppliedAI3.jpg";
import AppliedAI5 from "../../../Assets/Images/AppliedAI/AppliedAI5.jpg";
import AppliedAI6 from "../../../Assets/Images/AppliedAI/AppliedAI6.jpg";
import AITrust from "./../../../Assets/Images/AppliedAI/AITrust.jpg";
import AppliedAI8 from "../../../Assets/Images/AppliedAI/AppliedAI8.jpg";
import AppliedAI9 from "../../../Assets/Images/AppliedAI/AppliedAI9.jpg";
import AppliedAI10 from "../../../Assets/Images/AppliedAI/AppliedAI10.jpg";
import AppliedAI11 from "../../../Assets/Images/AppliedAI/AppliedAI11.jpg";
import AppliedAI12 from "../../../Assets/Images/AppliedAI/AppliedAI12.jpg";


import Cloud1 from "../../../Assets/Images/Cloud/Cloud1.jpg";
import Cloud2 from "../../../Assets/Images/Cloud/Cloud2.jpg";
import Cloud3 from "../../../Assets/Images/Cloud/Cloud3.jpg";
import Cloud4 from "../../../Assets/Images/Cloud/Cloud4.jpg";
import Cloud5 from "../../../Assets/Images/Cloud/Cloud5.jpg";
import Cloud6 from "../../../Assets/Images/Cloud/Cloud6.jpg";
import Cloud7 from "../../../Assets/Images/Cloud/Cloud7.jpg";
import Cloud8 from "../../../Assets/Images/Cloud/Cloud8.jpg";
import Cloud9 from "../../../Assets/Images/Cloud/Cloud9.jpg";
import Cloud10 from "../../../Assets/Images/Cloud/Cloud10.jpg";
import Cloud11 from "../../../Assets/Images/Cloud/Cloud11.jpg";
import Cloud12 from "../../../Assets/Images/Cloud/Cloud12.jpg";
import Cloud14 from "../../../Assets/Images/Cloud/Cloud14.jpg";
import Cloud15 from "../../../Assets/Images/Cloud/Cloud15.jpg";
import Cloud16 from "../../../Assets/Images/Cloud/Cloud16.jpg";
import Cloud18 from "../../../Assets/Images/Cloud/Cloud18.jpg";
import Cloud19 from "../../../Assets/Images/Cloud/Cloud19.jpg";

import Clouddashboard1 from "../../../Assets/Images/dashboardImage/Clouddashboard1.jpg";
import Clouddashboard2 from "../../../Assets/Images/dashboardImage/Clouddashboard2.jpg";
import Clouddashboard3 from "../../../Assets/Images/dashboardImage/Clouddashboard3.jpg";
import Clouddashboard4 from "../../../Assets/Images/dashboardImage/Clouddashboard4.jpg";
import Clouddashboard5 from "../../../Assets/Images/dashboardImage/Clouddashboard5.jpg";
import Clouddashboard6 from "../../../Assets/Images/dashboardImage/Clouddashboard6.jpg";

import DashDashboard2 from "../../../Assets/Images/dashboardImage/Dashboard2.jpg";
import DashDashboard3 from "../../../Assets/Images/dashboardImage/Dashboard3.jpg";
import DashDashboard4 from "../../../Assets/Images/dashboardImage/Dashboard4.jpg";
import DashDashboard5 from "../../../Assets/Images/dashboardImage/Dashboard5.jpg";
import DashDashboard6 from "../../../Assets/Images/dashboardImage/Dashboard6.jpg";
import DashDashboard7 from "../../../Assets/Images/dashboardImage/Dashboard7.jpg";
import DashDashboard8 from "../../../Assets/Images/dashboardImage/Dashboard8.jpg";
import DashDashboard9 from "../../../Assets/Images/dashboardImage/Dashboard9.jpg";

import DashDashboard10 from "../../../Assets/Images/dashboardImage/Dashboard10.jpg";
import DashDashboard11 from "../../../Assets/Images/dashboardImage/Dashboard11.jpg";
import DashDashboard12 from "../../../Assets/Images/dashboardImage/Dashboard12.jpg";
import DashDashboard13 from "../../../Assets/Images/dashboardImage/Dashboard13.jpg";
import DashDashboard14 from "../../../Assets/Images/dashboardImage/Dashboard14.jpg";
import DashDashboard15 from "../../../Assets/Images/dashboardImage/Dashboard15.jpg";
import DashDashboard16 from "../../../Assets/Images/dashboardImage/Dashboard16.jpg";
import DashDashboard17 from "../../../Assets/Images/dashboardImage/Dashboard17.jpg";
import DashDashboard18 from "../../../Assets/Images/dashboardImage/Dashboard18.jpg";
import DashDashboard19 from "../../../Assets/Images/dashboardImage/Dashboard19.jpg";
import DashDashboard20 from "../../../Assets/Images/dashboardImage/Dashboard20.jpg";
import DashDashboard21 from "../../../Assets/Images/dashboardImage/Dashboard21.jpg";
import DashDashboard22 from "../../../Assets/Images/dashboardImage/Dashboard22.jpg";
import DashDashboard23 from "../../../Assets/Images/dashboardImage/Dashboard23.jpg";
import DashDashboard24 from "../../../Assets/Images/dashboardImage/Dashboard24.jpg";
import DashDashboard25 from "../../../Assets/Images/dashboardImage/Dashboard25.jpg";
import DashDashboard26 from "../../../Assets/Images/dashboardImage/Dashboard26.jpg";
import DashDashboard27 from "../../../Assets/Images/dashboardImage/Dashboard27.jpg";
import DashDashboard28 from "../../../Assets/Images/dashboardImage/Dashboard28.jpg";
import DashDashboard29 from "../../../Assets/Images/dashboardImage/Dashboard29.jpg";
import DashDashboard30 from "../../../Assets/Images/dashboardImage/Dashboard30.jpg";
import DashDashboard31 from "../../../Assets/Images/dashboardImage/Dashboard31.jpg";
import DashDashboard32 from "../../../Assets/Images/dashboardImage/Dashboard32.jpg";
import DashDashboard33 from "../../../Assets/Images/dashboardImage/Dashboard33.jpg";
import DashDashboard34 from "../../../Assets/Images/dashboardImage/Dashboard34.jpg";
import DashDashboard35 from "../../../Assets/Images/dashboardImage/Dashboard35.jpg";
import DashDashboard36 from "../../../Assets/Images/dashboardImage/Dashboard36.jpg";
import DashDashboard37 from "../../../Assets/Images/dashboardImage/Dashboard37.jpg";

import Agail from "../../../Assets/Images/DigitalSevices/Agail.jpg";
import Agail2 from "../../../Assets/Images/DigitalSevices/Agail2.jpg";
import Agail3 from "../../../Assets/Images/DigitalSevices/Agail3.jpg";

import Entp2 from "../../../Assets/Images/DigitalSevices/Entp2.jpg";
import Entp3 from "../../../Assets/Images/DigitalSevices/Entp3.jpg";
import Entp4 from "../../../Assets/Images/DigitalSevices/Entp4.jpg";
import Entp5 from "../../../Assets/Images/DigitalSevices/Entp5.jpg";
import Entp6 from "../../../Assets/Images/DigitalSevices/Entp6.jpg";
import Entp7 from "../../../Assets/Images/DigitalSevices/Entp7.jpg";
import Entp8 from "../../../Assets/Images/DigitalSevices/Entp8.jpg";
import Entp9 from "../../../Assets/Images/DigitalSevices/Entp9.jpg";
import Entp10 from "../../../Assets/Images/DigitalSevices/Entp10.jpg";
import Entp11 from "../../../Assets/Images/DigitalSevices/Entp11.jpg";
import Entp12 from "../../../Assets/Images/DigitalSevices/Entp12.jpg";

import enterprice from "../../../Assets/Images/DigitalSevices/enterprice.jpg";

import AI1 from "../../../Assets/Images/AppliedAI/AI1.jpg";
import AI2 from "../../../Assets/Images/AppliedAI/AI2.jpg";
import AI3 from "../../../Assets/Images/AppliedAI/AI3.jpg";
import AI4 from "../../../Assets/Images/AppliedAI/AI4.jpg";
import AI5 from "../../../Assets/Images/AppliedAI/AI5.jpg";
import AI6 from "../../../Assets/Images/AppliedAI/AI6.jpg";
import AI7 from "../../../Assets/Images/AppliedAI/AI7.jpg";
import AI8 from "../../../Assets/Images/AppliedAI/AI8.jpg";
import AI9 from "../../../Assets/Images/AppliedAI/AI9.jpg";
import AI10 from "../../../Assets/Images/AppliedAI/AI10.jpg";
import AI11 from "../../../Assets/Images/AppliedAI/AI11.jpg";
import AI12 from "../../../Assets/Images/AppliedAI/AI12.jpg";
import AI13 from "../../../Assets/Images/AppliedAI/AI13.jpg";
import AI14 from "../../../Assets/Images/AppliedAI/AI14.jpg";
import AI15 from "../../../Assets/Images/AppliedAI/AI15.jpg";
import AI16 from "../../../Assets/Images/AppliedAI/AI16.jpg";

import AI17 from "../../../Assets/Images/AppliedAI/AI17.jpg";
import AI18 from "../../../Assets/Images/AppliedAI/AI18.jpg";

import AI21 from "../../../Assets/Images/AppliedAI/AI21.jpg";
import AI22 from "../../../Assets/Images/AppliedAI/AI22.jpg";
import AI23 from "../../../Assets/Images/AppliedAI/AI23.jpg";

import AI24 from "../../../Assets/Images/AppliedAI/AI24.jpg";
import AI25 from "../../../Assets/Images/AppliedAI/AI26.jpg";
import AI27 from "../../../Assets/Images/AppliedAI/AI27.jpg";
import AI28 from "../../../Assets/Images/AppliedAI/AI28.jpg";
import AI29 from "../../../Assets/Images/AppliedAI/AI29.jpg";
import AI30 from "../../../Assets/Images/AppliedAI/AI30.jpg";
import AI31 from "../../../Assets/Images/AppliedAI/AI31.jpg";
import AI32 from "../../../Assets/Images/AppliedAI/AI32.jpg";
import AI33 from "../../../Assets/Images/AppliedAI/AI33.jpg";
import AI34 from "../../../Assets/Images/AppliedAI/AI34.jpg";

import dev from "../../../Assets/Images/AppliedAI/dev.jpg";
import dev1 from "../../../Assets/Images/AppliedAI/dev1.jpg";
import dev2 from "../../../Assets/Images/AppliedAI/dev2.jpg";
import dev3 from "../../../Assets/Images/AppliedAI/dev3.jpg";
import dev4 from "../../../Assets/Images/AppliedAI/dev4.jpg";
import dev5 from "../../../Assets/Images/AppliedAI/dev5.jpg";
import dev6 from "../../../Assets/Images/AppliedAI/dev6.jpg";
import dev7 from "../../../Assets/Images/AppliedAI/dev7.jpg";
import dev8 from "../../../Assets/Images/AppliedAI/dev8.jpg";



import Anl1 from "../../../Assets/Images/AppliedAI/Anl1.jpg";
import Anl2 from "../../../Assets/Images/AppliedAI/Anl2.jpg";
import Anl4 from "../../../Assets/Images/AppliedAI/Anl4.jpg";
import Anl5 from "../../../Assets/Images/AppliedAI/Anl5.jpg";
import Anl10 from "../../../Assets/Images/AppliedAI/Anl10.jpg";
import Anl11 from "../../../Assets/Images/AppliedAI/Anl11.jpg";
import Anl12 from "../../../Assets/Images/AppliedAI/Anl12.jpg";
import Anl13 from "../../../Assets/Images/AppliedAI/Anl13.jpg";
import Anl14 from "../../../Assets/Images/AppliedAI/Anl14.jpg";
import Anl15 from "../../../Assets/Images/AppliedAI/Anl15.jpg";
import Anl16 from "../../../Assets/Images/AppliedAI/Anl16.jpg";
import Anl17 from "../../../Assets/Images/AppliedAI/Anl17.jpg";

import explainability from "../../../Assets/Images/AppliedAI/explainability.jpg";
import explainability1 from "../../../Assets/Images/AppliedAI/explainability1.jpg";
import explainability2 from "../../../Assets/Images/AppliedAI/explainability2.jpg";

import background1 from "../../../Assets/Images/background1.jpg";

const contentMap = {
  "ai-engineering": {
    component: AppliedAIEngineering,

    backgroundImage: `url(${background1})`,
    title: "Applied AI Engineering",
    subtitle:
      "Applied AI Engineering is the practice of leveraging advanced artificial intelligence (AI) technologies to solve real-world problems",
    extraContent: {
      content1:
        "As an expert in Applied AI Engineering, we offer guidance through each of these steps, ensuring that AI solutions are not only technically sound but also strategically aligned with your business goals. Our approach is collaborative and tailored to your specific needs, drawing on deep expertise to deliver impactful and sustainable AI-driven transformations.",
      heading: "Harnessing AI to Revolutionize Business Solutions",
      image: AIbussiness,

      details: [
        "Addressing complex challenges with AI techniques.",
        "Seamlessly incorporating AI models into existing systems.",
        "Automating processes and optimizing workflows.",
        "Driving business growth through strategic AI applications.",
      ],
      sections: [
        {
          text: "Leveraging Applied AI Engineering for Business Success",
          description:
            "Applied AI Engineering has a wide range of applications across various industries, transforming operations and delivering significant benefits.",
          details: [
            "Healthcare: AI enhances diagnostics, personalized medicine, and patient management, leading to better health outcomes and efficient healthcare delivery.",
            "Finance: AI improves fraud detection, risk management, and customer service by analyzing transaction patterns and providing real-time insights and personalized financial advice.",
            "Retail: AI optimizes inventory management, customer engagement, and personalized marketing through predictive analytics and recommendation systems.",
            "Manufacturing: AI supports predictive maintenance, quality control, and automation, increasing productivity and reducing operational costs.",
            "Telecommunications: AI boosts network optimization, customer service, and predictive analytics, ensuring reliable and efficient communication services.",
            "Transportation and Logistics: AI enhances route optimization, supply chain management, and autonomous vehicles, improving efficiency and safety in transportation systems.",
          ],
          image: AppliedAI5,
        },
      ],
      additional: [
        {
          text: "AI-Driven Decision Making for Businesses",
          description:
            "By applying AI engineering principles, businesses can unlock AI's full potential, transforming data into actionable insights and innovative solutions to gain a competitive edge in today's rapidly evolving technological landscape:",
          details: [
            "Optimize Operations: AI automates repetitive tasks, streamlines processes, and boosts efficiency, allowing businesses to focus on strategic activities and reduce costs.",
            "Enhance Decision-Making: AI provides data-driven insights and predictive analytics, enabling better strategic decisions and swift responses to market changes.",
            "Innovate: AI fosters innovation by developing new products and services that meet evolving customer needs and market demands, keeping businesses relevant.",
            "Stay Competitive: AI ensures businesses stay competitive by keeping pace with technological advancements, supporting long-term sustainability and growth.",
          ],

          image: AppliedAI6,
        },
      ],
      newSectionTitle: "Roadmap to Successful AI Implementation",
      newSectionDescription:
        "A successful AI implementation roadmap includes identifying business challenges, gathering and preparing data, developing and training models, integrating them into existing systems, and maintaining them for continuous improvement.",
      newSection: [
        {
          title: "Problem Identification",
          description:
            "Understand the specific challenges and objectives of the organization.",
        },
        {
          title: "Data Collection and Preparation",
          description:
            "Gather and preprocess data to ensure it is clean, relevant, and ready for analysis.",
        },
        {
          title: "Model Development",
          description:
            "Develop and train AI models using appropriate techniques and algorithms.",
        },
        {
          title: "Integration and Deployment",
          description:
            "Integrate the AI models into existing systems and deploy them in real-world environments.",
        },
        {
          title: "Evaluation and Optimization",
          description:
            "Continuously monitor and evaluate the performance of the AI models, making necessary adjustments to optimize results.",
        },
        {
          title: "Maintenance and Support",
          description:
            "Provide ongoing support to ensure the AI solutions remain effective and up-to-date with evolving business needs.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Development",
        description:
          "Our development team leads in AI innovation, creating advanced solutions in AI, ML, DL, and Neural Networks.",
        link: "/development",
      },
      {
        title: "Research",
        description:
          "Our AIOps services ensure seamless integration, management, and scaling of AI systems, maintaining their efficiency, security, and effectiveness.",
        link: "/appliedAIresearch",
      },
    ],
  },
  "data-science": {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard2})`,
    title: "Applied DataScience",
    subtitle:
      "By leveraging Applied Data Science, organizations can transform raw data into strategic insights, driving decision-making, efficiency, and innovation.",
    extraContent: {
      content1:
        "Applied Data Science applies data science techniques to solve real-world problems and derive actionable insights. It encompasses data collection, cleaning, analysis, and interpretation to inform decisions and drive strategy. Combining statistical analysis, machine learning, and data engineering, it transforms raw data into valuable information.",
      heading: "Transforming Data into Actionable Insights",
      image: AppliedAI3,
      details: [
        "Gathering raw data from various sources and preparing it by removing inaccuracies and inconsistencies.",
        "Analyzing data to identify patterns using advanced statistical methods and machine learning algorithms.",
        "Designing and maintaining efficient data processing architecture.",
        "Providing insights from analysis results to inform decision-making and support strategic initiatives.",
      ],
      sections: [
        {
          text: "Harnessing Data Science Across Industries",
          description:
            "Applied Data Science can be implemented across various domains and industries, offering valuable insights and solutions. Its applications are vast and varied, providing significant benefits across numerous sectors",
          details: [
            "Healthcare: Data science improves patient outcomes through predictive analytics and personalized treatment plans, enabling early disease detection and tailored healthcare strategies.",
            "Finance: Data analysis enhances fraud detection, risk management, and investment strategies, helping financial institutions identify fraudulent activities, manage risks, and make informed investment decisions.",
            "Retail: Data science optimizes supply chain management, inventory control, and personalized marketing efforts, ensuring efficient operations and targeted marketing strategies to enhance customer engagement.",
            "Manufacturing: Data science improves production efficiency, quality control, and predictive maintenance, leading to streamlined manufacturing processes and reduced downtime.",
            "Telecommunications: Data science enhances network optimization, customer service, and churn prediction, ensuring reliable services, improved customer support, and reduced customer attrition.",
            "Government: Data science informs policy decisions, improves public services, and optimizes resource allocation, helping governments make data-driven decisions to serve the public effectively.",
          ],
          image: AppliedAI,
        },
      ],
      additional: [
        {
          text: "Transforming Data into Strategic Assets",
          description:
            "Applied Data Science empowers organizations to leverage their data assets effectively, transforming data into a strategic resource that drives growth and innovation. Here are some key aspects of its importance:",
          details: [
            "Inform Decision-Making: Applied Data Science provides data-driven insights that support strategic and operational decisions, enabling organizations to make informed choices based on solid evidence.",
            "Enhance Efficiency:	By identifying inefficiencies and opportunities for improvement, Applied Data Science optimizes processes, leading to enhanced operational efficiency and cost savings.",
            "Drive Innovation: Data insights enable the development of new products, services, and business models, fostering innovation and helping organizations stay competitive in the market.",
            "Predict Trends:	Predictive analytics forecast future trends and behaviors, allowing organizations to plan proactively and stay ahead of industry changes and customer demands.",
            "Improve Customer Experience: Analyzing customer data helps organizations understand preferences and behaviors, leading to improved customer satisfaction and loyalty through personalized experiences and targeted offerings.",
          ],

          image: AppliedAI2,
        },
      ],
      newSectionTitle: "Structured Process of Applied Data Science",
      newSectionDescription:
        "Transforming raw data into insights involves a structured process with several critical steps in Applied Data Science.",
      newSection: [
        {
          title: "Data Collection",
          description:
            "Gathering data from various sources, including databases, sensors, social media, and more.",
        },
        {
          title: "Data Cleaning and Preparation",
          description:
            "Ensuring the data is accurate, complete, and formatted correctly for analysis. This step may involve removing duplicates, handling missing values, and standardizing data formats.",
        },
        {
          title: "Exploratory Data Analysis",
          description:
            "Using statistical techniques to explore and visualize data, identifying patterns, trends, and anomalies.",
        },
        {
          title: "Model Building",
          description:
            "Develop predictive or descriptive models using machine learning algorithms and statistical methods, including selecting algorithms, tuning hyperparameters, and validating performance.",
        },
        {
          title: "Data Visualization",
          description:
            "Creating visual representations of data and model results to communicate insights effectively to stakeholders.",
        },
        {
          title: "Deployment and Monitoringt",
          description:
            "Implementing data science models in production, continuously monitoring performance, retraining with new data, and adjusting to maintain accuracy and relevance.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Data Analytics",
        description:
          "Data engineering involves designing, building, and maintaining data systems, pipelines, and warehouses, ensuring data quality, reliability, and accessibility.",
        link: "/dataanalytics",
      },
      {
        title: "Data Insights",
        description:
          "Data Operations is a methodology that enhances communication, integration, and data flow automation between data administrators and consumers.",
        link: "/dataInsights",
      },
    ],
  },
  "ai-quality": {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard3})`,
    title: "AI Quality",
    subtitle:
      "By focusing on AI Quality, organizations can navigate the complexities of AI implementation with confidence, ensuring their systems deliver valuable and trustworthy results.",
    extraContent: {
      content1:
        "AI Quality ensures AI systems are reliable, accurate, and ethical. It involves rigorous standards for evaluating and improving models, data, and processes. This includes comprehensive testing, validation, and monitoring to ensure correct functioning, accurate predictions, and adherence to ethical guidelines, ultimately building trust through continuous improvement.",
      heading: "The Pillars of AI Quality: Accuracy, Reliability, and Ethics",
      details: [
        "Ensuring AI models deliver consistent and precise outcomes across various scenarios.",
        "Adhering to ethical guidelines to prevent biases, ensure fairness, and protect user privacy.",
        "Implementing rigorous testing and validation to identify and fix AI system issues.",
        "Regularly monitor AI performance and adjust as needed to improve efficiency and reliability.",
      ],
      image: AITrust,
      sections: [
        {
          text: "Optimizing AI Systems for Maximum Impact",
          description:
            "The quality of AI systems is crucial for outcomes and trust, especially in industries like healthcare, finance, and retail. Implementing AI quality practices ensures reliability and effectiveness throughout the AI lifecycle, building trust at every stage of development and deployment.",
          details: [
            "Data Collection and Preparation: Ensuring high-quality data through thorough cleaning, validation, and preprocessing to eliminate biases and inaccuracies.",
            "Model Development: Applying rigorous testing and validation to create robust and accurate AI models.",
            "Deployment: Continuously monitoring and evaluating AI systems in real-world environments to maintain optimal performance and reliability.",
            "Post-Deployment Monitoring: Regularly updating and refining AI models to adapt to new data and evolving conditions.",
          ],
          image: AppliedAI8,
        },
      ],
      additional: [
        {
          text: "Achieving Excellence in AI Quality",
          description:
            "Ensuring AI Quality is essential for building reliable, accurate, and ethical AI systems. High-quality AI fosters user trust, ensures compliance with regulations, and drives long-term success by delivering dependable outcomes:",
          details: [
            "Reliability: Dependable and consistent AI systems minimize errors and failures.",
            "Accuracy: Trustworthy predictions and insights are crucial for informed decision-making.",
            "Ethical Compliance: Adherence to ethical standards prevents biases and ensures fairness.",
            "User Trust: High-quality AI fosters confidence among users and stakeholders, promoting broader adoption.",
            "Regulatory Compliance: Meeting industry standards and regulations avoids legal and financial issues.",
          ],

          image: AppliedAI9,
        },
      ],
      newSectionTitle:
        "Ensuring AI Excellence with Comprehensive Quality Management",
      newSectionDescription:
        "Achieving top-tier AI performance requires meticulous attention to various critical aspects. By focusing on data integrity, model validation, fairness, performance tracking, compliance, and continuous refinement, you can ensure your AI systems are robust, reliable, and ethically sound. Let us help you navigate the complexities of AI quality management:",
      newSection: [
        {
          title: "Data Quality Management",
          description:
            "Implementing processes to ensure the quality, integrity, and fairness of data used in AI models.",
        },
        {
          title: "Model Validation and Testing",
          description:
            "Conducting extensive testing using various datasets to validate model performance and identify potential issues.",
        },
        {
          title: "Bias and Fairness Analysis",
          description:
            "Analyzing AI models for biases and implementing strategies to mitigate any unfairness.",
        },
        {
          title: "Performance Monitoring",
          description:
            "Continuously monitoring AI systems to track performance metrics and identify areas for improvement",
        },
        {
          title: "Ethical and Regulatory Compliance",
          description:
            "Ensuring AI systems adhere to ethical guidelines and comply with relevant industry regulations.",
        },
        {
          title: "Continuous Improvement",
          description:
            "Regularly updating AI models and processes based on feedback and new data to maintain high standards of quality and performance.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Model Validation",
        description:
          "Benchmarking models at TechOptima ensures their performance, efficiency, and scalability meet industry standards, keeping them competitive and effective.",
        link: "/modelValidation",
      },
      {
        title: "Model Reproducibility",
        description:
          "To ensure robust and high-performing AI models at TechOptima, we adopt a comprehensive approach involving model development, evaluation, and continuous improvement.",
        link: "/modelReproducibility",
      },
    ],
  },
  "ai-assurance": {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard4})`,
    title: "AI Assurance",
    subtitle:
      "AI Assurance is crucial for building trust in AI systems by ensuring reliability, ethical behavior, and regulatory compliance. ",
    extraContent: {
      content1:
        "AI Assurance is the process of verifying that an AI system meets specific standards of safety, reliability, and ethics. It involves assessing the system's development, deployment, and ongoing operation to identify and mitigate potential risks.",
      heading: "Core Components of AI Assurance",
      details: [
        "Safety: Ensuring the AI system operates without causing harm.",

        "Reliability: Verifying consistent and dependable performance.",

        "Transparency: Providing clear and understandable explanations of AI decisions",

        "Accountability: Establishing responsibility for AI actions and outcomes.",

        "Security: Protecting the AI system from malicious attacks and vulnerabilities",
      ],
      image: AppliedAI10,

      sections: [
        {
          text: "Applications of AI Assurance Across Industries",
          description:
            "AI Assurance is crucial across various industries to ensure the safe, reliable, and ethical use of AI systems.",
          details: [
            "Healthcare: Ensuring AI-driven diagnostics and treatment recommendations are accurate, safe, and ethical.",

            "Finance: Verifying that AI algorithms for fraud detection and credit scoring are reliable and unbiased.",

            "Automotive: Assessing the safety and reliability of AI in autonomous vehicles.",

            "Retail: Verifying that AI-driven customer insights and personalized recommendations are ethical and secure.",

            "Manufacturing: Ensuring AI systems in automation and predictive maintenance operate safely and efficiently.",
          ],
          image: AppliedAI11,
        },
      ],
      additional: [
        {
          text: "Building Business Resilience Through AI Assurance.",
          description:
            "AI Assurance protects businesses by ensuring AI systems are safe, reliable, and ethical. It builds trust, prevents disruptions, and helps companies navigate challenges for long-term success.",
          details: [
            "Mitigates AI Risks: Identifies and addresses potential threats and vulnerabilities.",

            "Aids in Regulatory Compliance: Adheres to industry standards and legal requirements.",

            "Protects Brand Reputation: Builds trust with customers and stakeholders. ",

            "Fosters Innovation: Creates a safe environment for AI experimentation.",

            "Strengthens Competitive Advantage: Differentiates the business through robust AI practices.",
          ],

          image: AppliedAI12,
        },
      ],
      newSectionTitle: "Fortifying AI with Assurance",
      newSectionDescription:
        "AI Assurance safeguards AI systems by ensuring they are safe, reliable, ethical, and secure. It involves a comprehensive approach from development to deployment to build trust and mitigate risks.",
      newSection: [
        {
          title: "Risk assessment and mitigation",

          description:
            "Identifying potential threats and implementing safeguards. ",
        },

        {
          title: "Ethical guidelines",

          description: "Adhering to ethical principles and avoiding biases.",
        },

        {
          title: "Robust testing and validation",

          description: "Ensuring AI systems perform as expected.",
        },

        {
          title: "Continuous monitoring and evaluation",

          description:
            "Tracking AI performance and identifying areas for improvement.",
        },

        {
          title: "Transparency and explainability",

          description: "Making AI decisions understandable to human.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Modal Security",
        description:
          "Security Operations Centers (SOCs) and Security Information and Event Management (SIEM) systems are vital for organizational cybersecurity.",
        link: "/modalsecurity",
      },
      {
        title: "Model Governance",
        description:
          "AI security involves protecting AI systems from threats, ensuring secure development and operation, safeguarding models and data, and complying with security standards.",
        link: "/modelgovernance",
      },
    ],
  },
  "cloud-transformation": {
    component: CloudTransformation,
    backgroundImage: `url(${Clouddashboard6})`,
    title: "Cloud Transformation",
    subtitle:
      "Cloud transformation is the process of migrating an organization’s IT infrastructure, applications, and data from on-premises environments to the cloud, leveraging cloud services to improve agility, scalability, and cost-efficiency.",
    extraContent: {
      content1:
        "TechOptima's experts empower businesses in India and beyond to achieve scalability, agility, and cost-efficiency through seamless migration, modernization, and management of cloud infrastructure.",
      heading: "Cloud Transformation and its Components",
      details: [
        "Scalability: Cloud platforms offer the ability to scale resources up or down based on demand, ensuring optimal performance without over-provisioning.",
        "Reduced Capital Expenditure (CapEx): Cloud transformation reduces the need for significant upfront investments in physical infrastructure, converting CapEx to operational expenditure (OpEx).",
        "Faster Deployment: Cloud services enable rapid deployment of applications and services, reducing time-to-market for new products and features.",
        "Continuous Delivery: Integration of DevOps practices and CI/CD pipelines in the cloud allows for continuous development, testing, and deployment.",
        "Access to Advanced Technologies: Cloud platforms offer access to cutting-edge technologies such as AI, machine learning, big data analytics, and IoT.",
      ],
      image: Cloud1,
      sections: [
        {
          text: "Leveraging Cloud Transformation for Business Success",
          description:
            "Cloud transformation enables businesses to modernize processes, enhance innovation, and drive digital transformation.",
          details: [
            "Healthcare: Improve patient care with cloud-based data management and analytics.",
            "Finance: Enhance security and scalability with cloud-based financial applications.",
            "Retail: Optimize inventory and customer engagement with cloud solutions.",
            "Manufacturing: Increase productivity with cloud-based automation and IoT.",
            "Education: Enable remote learning and collaboration with cloud services.",
          ],
          image: Cloud2,
        },
      ],
      additional: [
        {
          text: "Transforming Your Business with Cloud",
          description:
            "Cloud transformation provides businesses with the tools and flexibility needed to stay competitive in today's fast-paced market.",
          details: [
            "Optimize Operations: Streamline processes and reduce costs with cloud-based solutions.",
            "Enhance Decision-Making: Leverage data analytics for informed business decisions.",
            "Drive Innovation: Develop new products and services with cloud-native technologies.",
            "Improve Customer Experience: Offer personalized services and support with cloud applications.",
            "Ensure Security: Protect data and applications with advanced cloud security features.",
          ],
          image: Cloud3,
        },
      ],
      newSectionTitle: "Roadmap to Successful Cloud Transformation",
      newSectionDescription:
        "A successful cloud transformation roadmap includes planning, migration, integration, and continuous improvement.",
      newSection: [
        {
          title: "Assessment and Planning",
          description:
            "Conduct a thorough assessment of the existing IT infrastructure and applications.",
        },
        {
          title: "Pilot and Proof of Concept (PoC)",
          description:
            "Implement a pilot project or PoC to test the feasibility and performance of the cloud transformation plan.",
        },
        {
          title: "Migration and Deployment",
          description:
            "Execute the migration plan, starting with non-critical applications and data. Gradually move critical applications and data to the cloud. ",
        },
        {
          title: "Optimization and Management",
          description:
            "Optimize cloud resources for performance, cost, and scalability.  ",
        },
        {
          title: "Security and Compliance",
          description:
            "Ensure all migrated data and applications comply with industry standards and regulations. ",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Scalability",
        description:
          "Cloud platforms offer the ability to scale resources up or down based on demand, ensuring optimal performance without over-provisioning.",
      },
      {
        title: "Reduced CapEx",
        description:
          "Cloud transformation reduces the need for significant upfront investments in physical infrastructure, converting CapEx to operational expenditure (OpEx).",
      },
      {
        title: "Faster Deployment",
        description:
          "Cloud services enable rapid deployment of applications and services, reducing time-to-market for new products and features.",
      },
      {
        title: "Continuous Delivery",
        description:
          "Integration of DevOps practices and CI/CD pipelines in the cloud allows for continuous development, testing, and deployment.",
      },
      {
        title: "Access to Advanced Technologies",
        description:
          "Cloud platforms offer access to cutting-edge technologies such as AI, machine learning, big data analytics, and IoT.",
      },
    ],
  },
  "cloud-managed-services": {
    component: CloudManagedServices,
    backgroundImage: `url(${Clouddashboard2})`,

    title: "Cloud Managed Services",
    subtitle:
      "Free yourself from the complexities of cloud management with TechOptima's comprehensive Cloud Managed Services.",
    extraContent: {
      content1:
        "Cloud management involves overseeing and controlling cloud computing resources and services to ensure their optimal operation, performance, and cost-efficiency.",
      heading: "Key Components of Cloud Management",
      details: [
        "Resource Provisioning and Orchestration: Allocating and configuring cloud resources like virtual machines, storage, and networking components.",
        "Cost Management and Optimization: Monitoring and analyzing cloud expenditures to ensure alignment with budgets.",
        "Performance Monitoring: Using tools to track the performance and health of cloud resources, including uptime, response times, and resource utilization.",
        "Security and Compliance: Implementing and managing security policies, including access controls, encryption, and threat detection.",
        "Backup and Disaster Recovery: Automating and managing data backups to protect against data loss. Implementing and testing disaster recovery plans to ensure business continuity in case of outages or failures.",
      ],
      image: Cloud4,
      sections: [
        {
          text: "Comprehensive Cloud Management",
          description:
            "Our cloud management services ensure your cloud infrastructure is managed efficiently and effectively, providing continuous performance and reliability.",
          details: [
            "Proactive Monitoring: We continuously monitor your cloud environment to detect and resolve issues before they impact your business operations.",
            "Incident Management: Our team responds swiftly to any incidents, ensuring minimal downtime and disruption to your services.",
            "Performance Optimization: We analyze and optimize the performance of your cloud resources to ensure they meet your business needs.",
            "Security Management: We implement robust security measures to protect your data and applications from threats.",
            "Compliance Assurance: We ensure your cloud operations comply with industry standards and regulations.",
          ],
          image: Cloud5,
        },
      ],
      additional: [
        {
          text: "Efficient Cloud Management for Your Business",
          description:
            "TechOptima's cloud managed services allow you to focus on your core business while we handle the complexities of cloud management.",
          details: [
            "Optimize Operations: Streamline cloud operations for maximum efficiency.",
            "Enhance Security: Implement robust security protocols to protect your data.",
            "Reduce Costs: Optimize cloud expenditures to align with your budget.",
            "Improve Performance: Ensure your cloud resources perform at their best.",
            "Ensure Compliance: Adhere to industry standards and regulations.",
          ],
          image: Cloud6,
        },
      ],
      newSectionTitle: "Effective Cloud Management Strategies",
      newSectionDescription:
        "Our cloud management strategies are designed to ensure your cloud infrastructure operates efficiently and effectively.",
      newSection: [
        {
          title: "Resource Provisioning",
          description:
            "Allocate and configure cloud resources like virtual machines, storage, and networking components.",
        },
        {
          title: "Cost Management",
          description:
            "Monitor and analyze cloud expenditures to ensure alignment with budgets. Identify opportunities to reduce costs through strategies like rightsizing and reserved instances.",
        },
        {
          title: "Backup and Disaster Recovery",
          description:
            "Automate and manage data backups to protect against data loss. Implement and test disaster recovery plans to ensure business continuity in case of outages or failures.",
        },
        {
          title: "Identity and Access Management (IAM)",
          description:
            "Manage user identities and permissions to ensure secure access to cloud resources. Implement mechanisms for user authentication and authorization.",
        },
        {
          title: "Configuration Management",
          description:
            "Manage and track configurations of cloud resources to ensure consistency and compliance with policies. Use tools to automate configuration changes and updates.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Proactive Monitoring",
        description:
          "Continuous monitoring of your cloud environment to detect and resolve issues before they impact your business.",
      },
      {
        title: "Incident Management",
        description:
          "Swift response to incidents to minimize downtime and disruption to your services.",
      },
      {
        title: "Performance Optimization",
        description:
          "Regular analysis and optimization of your cloud resources to ensure they meet your business needs.",
      },
      {
        title: "Security Management",
        description:
          "Implementation of robust security measures to protect your data and applications from threats.",
      },
      {
        title: "Compliance Assurance",
        description:
          "Ensuring your cloud operations comply with industry standards and regulations.",
      },
    ],
  },
  "cloud-migrations": {
    component: CloudMigrations,
    backgroundImage: `url(${Clouddashboard3})`,

    title: "Cloud Migrations",
    subtitle:
      "Guarantee the reliability and performance of your AI solutions with our assurance services.",
    extraContent: {
      content1:
        "TechOptima ensures a smooth transition to the cloud, enhancing scalability, agility, and efficiency.",
      heading: "Benefits of Cloud Migration Services provided by TechOptima",
      details: [
        "Elastic Resources: Easily scale resources up or down based on demand without the need for significant upfront investments in hardware.",
        "Global Reach: Cloud services provide global accessibility, enabling businesses to expand their reach and serve customers worldwide with minimal latency.",
        "Reduced CapEx: Shifts expenses from capital expenditure to operational expenditure, allowing businesses to pay only for what they use.",
        "Operational Cost Savings: Cloud providers handle maintenance, updates, and security, reducing the need for in-house IT resources.",
        "Faster Deployment: Rapidly deploy applications and services, reducing time-to-market for new products and features.",
      ],
      image: Cloud7,
      sections: [
        {
          text: "Harnessing Cloud Migration for Business Growth",
          description:
            "Cloud migration enables businesses to modernize processes, enhance innovation, and drive digital transformation.",
          details: [
            "Infrastructure Modernization: Cloud infrastructure can be scaled up or down based on demand, providing flexibility and cost efficiency.",
            "Data Storage and Management: Storing large volumes of structured and unstructured data for analytics and business intelligence.",
            "Application Development and Deployment: Enables streamlined development, testing, and deployment processes with tools and platforms that support automation and collaboration.",
            "Big Data and Analytics: Cloud-based analytics platforms provide tools for data processing, analysis, and visualization.",
            "Collaboration and Productivity: Cloud-based communication platforms like Slack, Microsoft Teams, and Zoom facilitate remote collaboration and communication.",
          ],
          image: Cloud8,
        },
      ],
      additional: [
        {
          text: "Optimizing Business Operations with Cloud Migration",
          description:
            "Cloud migration provides businesses with the tools and flexibility needed to stay competitive in today's fast-paced market.",
          details: [
            "Optimize Operations: Streamline processes and reduce costs with cloud-based solutions.",
            "Enhance Decision-Making: Leverage data analytics for informed business decisions.",
            "Drive Innovation: Develop new products and services with cloud-native technologies.",
            "Improve Customer Experience: Offer personalized services and support with cloud applications.",
            "Ensure Security: Protect data and applications with advanced cloud security features.",
          ],
          image: Cloud9,
        },
      ],
      newSectionTitle: "Implementation Phases of Cloud Migration",
      newSectionDescription:
        "Our structured approach ensures a smooth transition to the cloud:",
      newSection: [
        {
          title: "Assessment and Planning",
          description:
            "Conduct a thorough assessment of the existing IT infrastructure and applications. Define the goals and objectives of the cloud transformation. Develop a detailed migration plan and roadmap.",
        },
        {
          title: "Pilot and Proof of Concept (PoC)",
          description:
            "Implement a pilot project or PoC to test the feasibility and performance of the cloud transformation plan. Identify potential challenges and address them before full-scale implementation.",
        },
        {
          title: "Migration and Deployment",
          description:
            "Execute the migration plan, starting with non-critical applications and data. Gradually move critical applications and data to the cloud. Ensure continuous testing and validation throughout the migration process.",
        },
        {
          title: "Optimization and Management",
          description:
            "Optimize cloud resources for performance, cost, and scalability. Implement management and monitoring tools to ensure the smooth operation of cloud resources. Continuously improve and adapt the cloud environment based on changing business needs and technological advancements.",
        },
        {
          title: "Security and Compliance",
          description:
            "Ensure all migrated data and applications comply with industry standards and regulations. Implement robust security measures to protect sensitive information.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Elastic Resources",
        description:
          "Easily scale resources up or down based on demand without the need for significant upfront investments in hardware.",
      },
      {
        title: "Global Reach",
        description:
          "Cloud services provide global accessibility, enabling businesses to expand their reach and serve customers worldwide with minimal latency.",
      },
      {
        title: "Reduced CapEx",
        description:
          "Shifts expenses from capital expenditure to operational expenditure, allowing businesses to pay only for what they use.",
      },
      {
        title: "Operational Cost Savings",
        description:
          "Cloud providers handle maintenance, updates, and security, reducing the need for in-house IT resources.",
      },
      {
        title: "Faster Deployment",
        description:
          "Rapidly deploy applications and services, reducing time-to-market for new products and features.",
      },
    ],
  },
  "cloud-operations": {
    component: CloudOperations,
    backgroundImage: `url(${Clouddashboard4})`,

    title: "Cloud Operations",
    subtitle:
      "Ensure seamless cloud operations with our comprehensive management services.",
    extraContent: {
      content1:
        "Our cloud operations services ensure your cloud infrastructure is managed efficiently, providing continuous performance and reliability.",
      heading: "Key Components of Cloud Operations",
      details: [
        "Proactive Monitoring: Continuously monitor your cloud environment to detect and resolve issues before they impact your business.",
        "Incident Management: Respond swiftly to any incidents, ensuring minimal downtime and disruption to your services.",
        "Performance Optimization: Analyze and optimize the performance of your cloud resources to ensure they meet your business needs.",
        "Security Management: Implement robust security measures to protect your data and applications from threats.",
        "Compliance Assurance: Ensure your cloud operations comply with industry standards and regulations.",
      ],
      image: Cloud10,
      sections: [
        {
          text: "Comprehensive Cloud Operations",
          description:
            "Our cloud operations services cover all aspects of managing and optimizing your cloud environment.",
          details: [
            "Resource Provisioning: Allocate and configure cloud resources like virtual machines, storage, and networking components.",
            "Cost Management: Monitor and analyze cloud expenditures to ensure alignment with budgets. Identify opportunities to reduce costs through strategies like rightsizing and reserved instances.",
            "Backup and Disaster Recovery: Automate and manage data backups to protect against data loss. Implement and test disaster recovery plans to ensure business continuity in case of outages or failures.",
            "Identity and Access Management (IAM): Manage user identities and permissions to ensure secure access to cloud resources. Implement mechanisms for user authentication and authorization.",
            "Configuration Management: Manage and track configurations of cloud resources to ensure consistency and compliance with policies. Use tools to automate configuration changes and updates.",
          ],
          image: Cloud11,
        },
      ],
      additional: [
        {
          text: "Efficient Cloud Operations for Your Business",
          description:
            "TechOptima's cloud operations services ensure your cloud infrastructure operates smoothly and efficiently, allowing you to focus on your core business.",
          details: [
            "Optimize Operations: Streamline cloud operations for maximum efficiency.",
            "Enhance Security: Implement robust security protocols to protect your data.",
            "Reduce Costs: Optimize cloud expenditures to align with your budget.",
            "Improve Performance: Ensure your cloud resources perform at their best.",
            "Ensure Compliance: Adhere to industry standards and regulations.",
          ],
          image: Cloud12,
        },
      ],
      newSectionTitle: "Effective Cloud Operations Strategies",
      newSectionDescription:
        "Our cloud operations strategies are designed to ensure your cloud infrastructure operates efficiently and effectively.",
      newSection: [
        {
          title: "Resource Provisioning",
          description:
            "Allocate and configure cloud resources like virtual machines, storage, and networking components.",
        },
        {
          title: "Cost Management",
          description:
            "Monitor and analyze cloud expenditures to ensure alignment with budgets. Identify opportunities to reduce costs through strategies like rightsizing and reserved instances.",
        },
        {
          title: "Backup and Disaster Recovery",
          description:
            "Automate and manage data backups to protect against data loss. Implement and test disaster recovery plans to ensure business continuity in case of outages or failures.",
        },
        {
          title: "Identity and Access Management (IAM)",
          description:
            "Manage user identities and permissions to ensure secure access to cloud resources. Implement mechanisms for user authentication and authorization.",
        },
        {
          title: "Configuration Management",
          description:
            "Manage and track configurations of cloud resources to ensure consistency and compliance with policies. Use tools to automate configuration changes and updates.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Proactive Monitoring",
        description:
          "Continuously monitor your cloud environment to detect and resolve issues before they impact your business.",
      },
      {
        title: "Incident Management",
        description:
          "Respond swiftly to any incidents, ensuring minimal downtime and disruption to your services.",
      },
      {
        title: "Performance Optimization",
        description:
          "Analyze and optimize the performance of your cloud resources to ensure they meet your business needs.",
      },
      {
        title: "Security Management",
        description:
          "Implement robust security measures to protect your data and applications from threats.",
      },
      {
        title: "Compliance Assurance",
        description:
          "Ensure your cloud operations comply with industry standards and regulations.",
      },
    ],
  },
  "cloud-native-apps": {
    component: CloudNativeApplicationDevelopment,
    backgroundImage: `url(${Clouddashboard5})`,

    title: "CloudNative Application Development",
    subtitle:
      "Develop and deploy applications with speed and agility using cloud-native technologies.",
    extraContent: {
      content1:
        "Embrace modern application development practices with cloud-native technologies to enhance agility, scalability, and resilience.",
      heading: "Advantages of Cloud-Native Application Development",
      details: [
        "Microservices Architecture: Break down applications into smaller, manageable services that can be developed, deployed, and scaled independently.",
        "Containerization: Package applications and their dependencies into containers for consistent and portable deployment across environments.",
        "Serverless Computing: Build and run applications without managing infrastructure, allowing you to focus on writing code.",
        "DevOps and CI/CD: Integrate DevOps practices and CI/CD pipelines to automate the development, testing, and deployment processes.",
        "Agility and Flexibility: Quickly adapt to changing business requirements and deliver new features faster.",
      ],
      image: Cloud14,
      sections: [
        {
          text: "Harnessing Cloud-Native Technologies for Business Growth",
          description:
            "Utilize cutting-edge tools and practices for cloud-native application development.",
          details: [
            "Kubernetes: Orchestrate and manage containerized applications with Kubernetes for scalability and reliability.",
            "Docker: Use Docker to containerize applications and their dependencies for consistent and portable deployment.",
            "AWS Lambda: Leverage serverless computing with AWS Lambda to run code without provisioning or managing servers.",
            "CI/CD Pipelines: Implement CI/CD pipelines with tools like Jenkins and GitLab to automate the build, test, and deployment processes.",
            "Service Mesh: Use service mesh technologies like Istio to manage microservices communication, security, and monitoring.",
          ],
          image: Cloud15,
        },
      ],
      additional: [
        {
          text: "Driving Innovation with Cloud-Native Applications",
          description:
            "Cloud-native application development provides businesses with the tools and flexibility needed to stay competitive in today's fast-paced market.",
          details: [
            "Optimize Operations: Streamline processes and reduce costs with cloud-native solutions.",
            "Enhance Decision-Making: Leverage data analytics for informed business decisions.",
            "Drive Innovation: Develop new products and services with cloud-native technologies.",
            "Improve Customer Experience: Offer personalized services and support with cloud-native applications.",
            "Ensure Security: Protect data and applications with advanced cloud security features.",
          ],
          image: Cloud16,
        },
      ],
      newSectionTitle: "Effective Cloud-Native Development Strategies",
      newSectionDescription:
        "Our cloud-native development strategies are designed to ensure your applications are developed efficiently and effectively.",
      newSection: [
        {
          title: "Microservices Architecture",
          description:
            "Develop applications using microservices architecture for improved scalability and maintainability.",
        },
        {
          title: "Containerization",
          description:
            "Package applications and dependencies into containers for consistent and portable deployment.",
        },
        {
          title: "Serverless Computing",
          description:
            "Build and run applications without managing infrastructure using serverless computing.",
        },
        {
          title: "DevOps and CI/CD",
          description:
            "Automate development, testing, and deployment with DevOps practices and CI/CD pipelines.",
        },
        {
          title: "Agility and Flexibility",
          description:
            "Quickly adapt to changing business requirements and deliver new features faster.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Microservices Architecture",
        description:
          "Develop applications using microservices architecture for improved scalability and maintainability.",
      },
      {
        title: "Containerization",
        description:
          "Package applications and dependencies into containers for consistent and portable deployment.",
      },
      {
        title: "Serverless Computing",
        description:
          "Build and run applications without managing infrastructure using serverless computing.",
      },
      {
        title: "DevOps and CI/CD",
        description:
          "Automate development, testing, and deployment with DevOps practices and CI/CD pipelines.",
      },
      {
        title: "Agility and Flexibility",
        description:
          "Quickly adapt to changing business requirements and deliver new features faster.",
      },
    ],
  },
  "cloud-devops": {
    component: CloudDevOps,
    backgroundImage: `url(${Clouddashboard6})`,

    title: "Cloud DevOps",
    subtitle:
      "Accelerate your development and operations with our Cloud DevOps services.",
    extraContent: {
      content1:
        "Streamline your development and operations processes with Cloud DevOps practices, improving collaboration, efficiency, and time-to-market.",
      heading: "Benefits of Cloud DevOps",
      details: [
        "Continuous Integration: Automate the integration of code changes into a shared repository to detect and address issues early.",
        "Continuous Deployment: Automatically deploy applications to production environments, ensuring faster and more reliable releases.",
        "Infrastructure as Code (IaC): Manage and provision infrastructure using code, enabling version control, automation, and repeatability.",
        "Monitoring and Logging: Implement comprehensive monitoring and logging to gain visibility into application performance and troubleshoot issues.",
        "Collaboration: Foster collaboration between development and operations teams to improve efficiency and quality.",
      ],
      image: Cloud11,
      sections: [
        {
          text: "Harnessing Cloud DevOps for Operational Efficiency",
          description:
            "Utilize industry-leading tools and practices for effective Cloud DevOps.",
          details: [
            "Jenkins: Automate CI/CD pipelines with Jenkins for seamless integration and deployment.",
            "Terraform: Use Terraform for Infrastructure as Code (IaC) to manage and provision infrastructure.",
            "Docker: Containerize applications with Docker for consistent and portable deployment.",
            "Prometheus: Implement monitoring with Prometheus to gain visibility into application performance and health.",
            "Ansible: Automate configuration management and application deployment with Ansible.",
          ],
          image: Cloud18,
        },
      ],
      additional: [
        {
          text: "Optimizing Development and Operations with Cloud DevOps",
          description:
            "Cloud DevOps provides businesses with the tools and flexibility needed to stay competitive in today's fast-paced market.",
          details: [
            "Optimize Operations: Streamline development and operations for maximum efficiency.",
            "Enhance Security: Implement robust security protocols to protect your data.",
            "Reduce Costs: Optimize cloud expenditures to align with your budget.",
            "Improve Performance: Ensure your applications perform at their best.",
            "Ensure Compliance: Adhere to industry standards and regulations.",
          ],
          image: Cloud19,
        },
      ],
      newSectionTitle: "Effective Cloud DevOps Strategies",
      newSectionDescription:
        "Our cloud DevOps strategies are designed to ensure your development and operations processes are efficient and effective.",
      newSection: [
        {
          title: "Continuous Integration",
          description:
            "Automate the integration of code changes into a shared repository to detect and address issues early.",
        },
        {
          title: "Continuous Deployment",
          description:
            "Automatically deploy applications to production environments, ensuring faster and more reliable releases.",
        },
        {
          title: "Infrastructure as Code (IaC)",
          description:
            "Manage and provision infrastructure using code, enabling version control, automation, and repeatability.",
        },
        {
          title: "Monitoring and Logging",
          description:
            "Implement comprehensive monitoring and logging to gain visibility into application performance and troubleshoot issues.",
        },
        {
          title: "Collaboration",
          description:
            "Foster collaboration between development and operations teams to improve efficiency and quality.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Continuous Integration",
        description:
          "Automate the integration of code changes into a shared repository to detect and address issues early.",
      },
      {
        title: "Continuous Deployment",
        description:
          "Automatically deploy applications to production environments, ensuring faster and more reliable releases.",
      },
      {
        title: "Infrastructure as Code (IaC)",
        description:
          "Manage and provision infrastructure using code, enabling version control, automation, and repeatability.",
      },
      {
        title: "Monitoring and Logging",
        description:
          "Implement comprehensive monitoring and logging to gain visibility into application performance and troubleshoot issues.",
      },
      {
        title: "Collaboration",
        description:
          "Foster collaboration between development and operations teams to improve efficiency and quality.",
      },
    ],
  },
  "enterprise-development": {
    component: EnterpriseApplicationDevelopment,
    backgroundImage: `url(${enterprice})`,
    // 'url("https://www.techoptima.in/assets/images/case-study-1.png")',
    title: "Enterprise Application Development",
    subtitle:
      "Drive your organisation ahead with our complete enterprise application development services, which guarantee efficiency, scalability, and innovation",
    extraContent: {
      content1:
        "Enterprise Application Development (EAD) at TechOptima focuses on building robust, scalable software that integrates business functions, enhancing efficiency, decision-making, and overall success.",
      heading: "Key Benefits of Enterprise Application Development",
      details: [
        "Business Process Automation: Automates manual tasks, reduces redundancies, and optimizes workflows, allowing employees to focus on strategic initiatives",

        "Improved Decision-Making: Offers real-time access to accurate data, enabling quick responses to market changes and informed decisions",

        "Enhanced Collaboration: Provides a single point for information sharing and collaboration, fostering teamwork.",

        "Competitive Advantages: Helps businesses stay ahead by leveraging technology to enhance performance and gain a competitive edge.",

        "Scalability and Flexibility: Easily adapts to business growth and evolving needs, allowing for the addition for new features and integration with other systems",
      ],
      image: Entp3,
      sections: [
        {
          text: "Implementing Enterprise Application Development for Organisation Growth",
          description:
            "Enterprise Application Development (EAD) creates custom software solutions tailored to an organization's specific needs, aiming to improve efficiency, productivity, and decision-making.",
          details: [
            "Strategic Alignment: EAD initiatives are strategically aligned to optimize project prioritization and maximize value delivery.",

            "Stakeholder Engagement: EAD ensures and involves key stakeholders from different departments to gather requirements and ensure the application meets diverse business needs.",

            "Comprehensive Testing: Implementing rigorous testing protocols to ensure the application is reliable, performs well, and is free of critical bugs.",

            "Continuous Improvement: Regularly update and improve the application based on user feedback and changing business requirements.",

            "Robust Architecture: Scalable and Secure architecture that can handle the growing needs of the organization and integrate seamlessly with existing systems.",
          ],
          image: Entp2,
        },
      ],
      additional: [
        {
          text: "Applications of EAD in Different Industries",
          description:
            "Enterprise Application Development (EAD) is highly versatile and can be applied across various industries.",
          details: [
            "Healthcare: EAD can be used to develop Electronic Health Records (EHR) systems, patient management software and telemedicine platforms, improving patient care.",

            "Finance: Financial institutions use EAD for developing robust banking applications, fraud detection systems.",

            "Manufacturing: EAD supports manufacturing through Enterprise Resource Planning (ERP) systems, supply chain management, software, and production scheduling tools.",

            "Education: Educational institutions use EAD to develop Learning Management System (LMS), student information systems, and virtual classrooms. ",

            "Government: Government agencies implement EAD for developing public services portals, document management systems, and citizen engagement platforms.",
          ],
          image: Entp4,
        },
      ],
      newSectionTitle:
        "Effective Enterprise Application Development Strategies",

      newSectionDescription:
        "Our enterprise application development strategies ensure robust, scalable, and efficient applications that support your business goals.",
      newSection: [
        {
          title: "Clear Vision and Goals",

          description:
            "Define a clear vision and set specific, measurable goals for the EAD project to ensure alignment with business objectives.",
        },

        {
          title: "User-Centric Design",

          description:
            "Focus on creating an intuitive and user-friendly interface to enhance user adoption and satisfaction.",
        },

        {
          title: "Training and Support",

          description:
            "Provide extensive training for users and establish a support system to address any issues that arise post-deployment.",
        },

        {
          title: "Change Management",

          description:
            "Regularly update and improve the application based on user feedback and evolving business needs.",
        },

        {
          title: " Performance Monitoring ",

          description:
            "Continuously monitor the application’s performance and make necessary adjustments to ensure it meets business goals.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Enhanced Customer Experience",

        description:
          "Streamlines internal processes, leading to faster response times, personalized services, and improved customer satisfaction.",
      },

      {
        title: "Cost-Effectiveness",

        description:
          "Saves time and reduces costs by improving productivity and efficiency.",
      },

      {
        title: "Comprehensive Testing",

        description:
          "Implements rigorous testing protocols to ensure the application is reliable, performs well, and is free of critical bugs",
      },

      {
        title: "Robust Architecture",

        description:
          "Scalable and Secure Architecture that can handle the growing needs of the organization and Integrate seamlessly with existing systems.",
      },

      {
        title: "Agile Methodology",

        description:
          "Agile Methodologies allow for iterative development, continuous feedback, and flexibility to adapt to changing requirements.",
      },
    ],
  },
  "enterprise-platform": {
    component: EnterprisePlatformDevelopment,
    backgroundImage: `url(${DashDashboard6})`,
    title: "Enterprise Development",
    subtitle:
      "Drive your business forward with our comprehensive enterprise development services, ensuring efficiency, scalability, and innovation.",
    extraContent: {
      content1:
        "Enterprise Platform Development at TechOptima involves creating and deploying large-scale software platforms that serve as the backbone for various business processes and operations within an organization. These platforms integrate multiple systems and applications, enabling seamless data flow, improved efficiency, and better decision-making.",
      heading: "Key Components of Enterprise Platform Development",
      details: [
        "System Integration: Connecting various existing systems such as ERP, CRM, HRM, and SCM to ensure seamless data flow and process integration.",
        "Customization and Configuration: Customizing the platform to meet specific business needs, workflows, and processes.",
        "Scalability: Designing the platform to handle increasing loads and expanding operations without compromising performance.",
        "Security: Implementing robust security measures to protect sensitive business data, including encryption, access controls, and secure authentication.",
        "User Experience: Designing user-friendly interfaces to enhance usability and user adoption.",
      ],
      image: Entp5,
      sections: [
        {
          text: "Harnessing Enterprise Platform Development for Business Success",
          description:
            "Enterprise Platform Development finds its application across diverse industries, providing tailored solutions that enhance operations and drive growth.",
          details: [
            "ERP (Enterprise Resource Planning): Integrates various business processes such as finance, HR, supply chain, and manufacturing into a unified system.",
            "CRM (Customer Relationship Management): Manages customer interactions, sales processes, marketing campaigns, and customer service.",
            "HRM (Human Resource Management): Automates HR processes, including recruiting, onboarding, payroll, performance evaluations, and employee data management.",
            "SCM (Supply Chain Management): Manages the flow of goods, data, and finances from procurement to delivery, optimizing supply chain operations.",
            "BPM (Business Process Management): Supports the design, execution, monitoring, and optimization of business processes.",
          ],
          image: Entp6,
        },
      ],
      additional: [
        {
          text: "Optimizing Business Operations with Enterprise Development",
          description:
            "Enterprise Platform Development is essential for businesses seeking to streamline operations, enhance decision-making, and maintain a competitive edge.",
          details: [
            "Automating Business Processes: Implements workflow automation and business process management tools to enhance efficiency.",
            "Integration of Systems: Uses ERP systems and API integration to ensure seamless data flow across business functions.",
            "Enhancing Customer Relationships: Develops CRM systems and customer portals to manage interactions and improve service.",
            "Data Management and Analytics: Utilizes data warehousing and BI tools for better analysis and reporting.",
            "Improving Collaboration and Communication: Implements intranet portals and collaboration tools to facilitate teamwork.",
          ],
          image: Entp7,
        },
      ],
      newSectionTitle: "Effective Enterprise Development Strategies",
      newSectionDescription:
        "Our enterprise development strategies ensure robust, scalable, and efficient applications that support your business goals.",
      newSection: [
        {
          title: "Automating Business Processes",
          description:
            "Implements workflow automation and business process management tools to enhance efficiency.",
        },
        {
          title: "Integration of Systems",
          description:
            "Uses ERP systems and API integration to ensure seamless data flow across business functions.",
        },
        {
          title: "Enhancing Customer Relationships",
          description:
            "Develops CRM systems and customer portals to manage interactions and improve service.",
        },
        {
          title: "Data Management and Analytics",
          description:
            "Utilizes data warehousing and BI tools for better analysis and reporting.",
        },
        {
          title: "Improving Collaboration and Communication",
          description:
            "Implements intranet portals and collaboration tools to facilitate teamwork.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "System Integration",
        description:
          "Connecting various existing systems such as ERP, CRM, HRM, and SCM to ensure seamless data flow and process integration.",
      },
      {
        title: "Customization and Configuration",
        description:
          "Customizing the platform to meet specific business needs, workflows, and processes.",
      },
      {
        title: "Scalability",
        description:
          "Designing the platform to handle increasing loads and expanding operations without compromising performance.",
      },
      {
        title: "Security",
        description:
          "Implementing robust security measures to protect sensitive business data, including encryption, access controls, and secure authentication.",
      },
      {
        title: "User Experience",
        description:
          "Designing user-friendly interfaces to enhance usability and user adoption.",
      },
    ],
  },
  "agile-devops": {
    component: AgileDevOps,
    backgroundImage: `url(${DashDashboard7})`,
    title: "Agile DevOps",
    subtitle:
      "Enhance the efficiency and reliability of your software delivery with our Agile DevOps services.",
    extraContent: {
      content1:
        "Agile DevOps combines Agile methodologies with DevOps practices to streamline the software development lifecycle, ensuring faster, more reliable, and higher-quality software delivery.",
      heading: "Key Benefits of Agile DevOps",
      details: [
        "Faster Development: Deliver software updates quickly and reliably through iterative cycles.",
        "Improved Quality: Continuous testing and integration reduce defects and enhance software quality.",
        "Increased Efficiency: Automation reduces manual effort and streamlines workflows.",
        "Enhanced Collaboration: Cross-functional teams work together towards common goals.",
        "Greater Flexibility: Quickly adapt to changing requirements and market conditions.",
      ],
      image: Agail,
      sections: [
        {
          text: "Applications of Agile DevOps Across Industries",
          description:
            "Agile DevOps can be applied in various sectors to improve efficiency, quality, and speed of software delivery.",
          details: [
            "Software Development: Streamline the creation and delivery of applications with CI/CD pipelines.",
            "E-Commerce: Enhance online shopping platforms with rapid feature development and seamless deployments.",
            "Financial Services: Develop and update banking applications quickly and reliably.",
            "Healthcare: Maintain patient management systems and telemedicine platforms efficiently.",
          ],
          image: Agail2,
        },
      ],
      additional: [
        {
          text: "Harnessing Agile DevOps for Business Success",
          description:
            "Agile DevOps is crucial for businesses seeking to enhance operational efficiency, reduce costs, and improve customer satisfaction.",
          details: [
            "Faster Time to Market: Accelerated delivery through iterative cycles and continuous integration.",
            "Improved Quality and Reliability: Continuous testing and automated deployments reduce errors.",
            "Increased Efficiency: Streamlined processes and automation enhance productivity.",
            "Enhanced Collaboration: Unified teams work together towards common goals.",
            "Greater Flexibility: Quickly adapt to changing requirements and market conditions.",
          ],
          image: Agail3,
        },
      ],
      newSectionTitle: "Implementing Agile DevOps",
      newSectionDescription:
        "Our approach to implementing Agile DevOps ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced DevOps tools and Agile methodologies to develop and deploy software solutions.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your DevOps systems run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your DevOps systems.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Agile DevOps.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of DevOps solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Faster Development",
        description:
          "Deliver software updates quickly and reliably through iterative cycles.",
      },
      {
        title: "Improved Quality",
        description:
          "Continuous testing and integration reduce defects and enhance software quality.",
      },
      {
        title: "Increased Efficiency",
        description:
          "Automation reduces manual effort and streamlines workflows.",
      },
      {
        title: "Enhanced Collaboration",
        description:
          "Cross-functional teams work together towards common goals.",
      },
      {
        title: "Greater Flexibility",
        description:
          "Quickly adapt to changing requirements and market conditions.",
      },
    ],
  },
  "intelligent-automation": {
    component: IntelligentAutomationRPA,
    backgroundImage: `url(${DashDashboard8})`,
    title: "Intelligent Automation RPA",
    subtitle:
      "Revolutionize your operations with our advanced Intelligent Automation RPA solutions.",
    extraContent: {
      content1:
        "Intelligent Automation RPA (Robotic Process Automation) combines traditional RPA with advanced AI technologies to automate a wide range of tasks, enhancing efficiency, accuracy, and decision-making processes.",
      heading: "Key Benefits of Intelligent Automation RPA",
      details: [
        "Increased Efficiency: Automates repetitive tasks, freeing up human employees to focus on more strategic activities.",
        "Enhanced Accuracy: Minimizes errors associated with manual data entry and repetitive processes.",
        "Cost Savings: Reduces labor costs by automating tasks that would otherwise require human intervention.",
        "Scalability: Easily scales to handle increased volumes of work without additional resources.",
        "Improved Decision-Making: AI and ML provide insights that support better business decisions.",
      ],
      image: Entp7,
      sections: [
        {
          text: "Applications of Intelligent Automation RPA Across Industries",
          description:
            "Intelligent Automation RPA can be applied in various sectors to streamline operations and enhance productivity.",
          details: [
            "Customer Service: Chatbots use NLP to interact with customers, handle inquiries, and resolve issues in real time.",
            "Finance and Accounting: Bots extract data from invoices, validate information, and process payments, reducing manual effort and errors.",
            "Human Resources: IA assists in screening resumes, scheduling interviews, and managing candidate communications.",
            "Healthcare: Automates the entry and management of patient data, including appointment scheduling and insurance verification.",
            "Supply Chain and Logistics: Manages order entry, tracking, and fulfillment processes, improving efficiency and accuracy.",
          ],
          image: Entp8,
        },
      ],
      additional: [
        {
          text: "Harnessing Intelligent Automation RPA for Business Success",
          description:
            "Intelligent Automation RPA is crucial for businesses seeking to enhance operational efficiency, reduce costs, and improve customer satisfaction.",
          details: [
            "Automation of Repetitive Tasks: Enables employees to focus on more strategic, creative activities.",
            "Data Management and Analytics: Uses AI to analyze data and generate actionable insights.",
            "Enhanced Customer Experience: Provides faster response times and personalized interactions.",
            "Improved Compliance: Ensures consistent adherence to regulatory requirements and policy guidelines.",
            "Scalable Solutions: Can easily adapt to increased workloads or new areas without significant additional investments.",
          ],
          image: Entp9,
        },
      ],
      newSectionTitle: "Implementing Intelligent Automation RPA",
      newSectionDescription:
        "Our approach to implementing Intelligent Automation RPA ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced RPA platforms and AI technologies to develop and deploy automation solutions.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your automation systems run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your RPA systems.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Intelligent Automation RPA.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of RPA solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Automation of Repetitive Tasks",
        description:
          "Enables employees to focus on more strategic, creative activities.",
      },
      {
        title: "Data Management and Analytics",
        description:
          "Uses AI to analyze data and generate actionable insights.",
      },
      {
        title: "Enhanced Customer Experience",
        description:
          "Provides faster response times and personalized interactions.",
      },
      {
        title: "Improved Compliance",
        description:
          "Ensures consistent adherence to regulatory requirements and policy guidelines.",
      },
      {
        title: "Scalable Solutions",
        description:
          "Can easily adapt to increased workloads or new areas without significant additional investments.",
      },
    ],
  },
  "low-code": {
    component: LowCodeApplication,
    backgroundImage: `url(${DashDashboard9})`,
    title: "Low Code Application",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Low Code Application Development enables rapid application creation with minimal hand-coding, utilizing visual development tools and pre-built components to streamline the process.",
      heading: "Key Benefits of Low Code Application Development",
      image:
        "https://img.freepik.com/free-photo/business-hand-robot-handshake-artificial-intelligence-digital-transformation_53876-138972.jpg?t=st=1722422668~exp=1722426268~hmac=72e030778ff115843cddb619987b5a4c6518631cf1b9736debcfadeeff93dd6e&w=996",

      details: [
        "Faster Development: Quickly build and deploy applications, reducing time-to-market.",
        "Reduced Costs: Lower development and maintenance costs by minimizing the need for extensive coding.",
        "Enhanced Flexibility: Easily adapt to changing business needs with customizable solutions.",
        "Empowered Business Users: Enable non-technical users to create and modify applications.",
        "Improved Collaboration: Foster better alignment between business and IT teams.",
      ],
      image: Entp10,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Internal Process Automation: Automate workflows and approval processes to reduce manual effort.",
            "Customer Engagement: Develop custom applications for gathering and analyzing customer feedback.",
            "Custom CRM Systems: Create tailored CRM solutions to manage customer interactions and sales pipelines.",
            "Data Management Solutions: Build dashboards and data visualization tools to generate insights.",
            "Integration with Modern Technologies: Integrate AI, ML, and automation tools to create intelligent applications.",
          ],
          image: Entp11,
        },
      ],
      additional: [
        {
          text: "Harnessing Low Code Development for Business Success",
          description:
            "Low Code Application Development is crucial for businesses seeking to accelerate innovation, reduce costs, and improve agility.",
          details: [
            "Rapid Prototyping: Quickly develop prototypes to validate ideas and gather feedback.",
            "Lower Technical Barriers: Enable non-technical users to participate in application development.",
            "Simplified Maintenance: Easily update and maintain applications due to the visual development process.",
            "Scalable Solutions: Handle growing user numbers and data volumes with scalable low-code platforms.",
            "Enhanced User Experience: Create intuitive, user-friendly interfaces with responsive design.",
          ],
          image: Entp12,
        },
      ],
      newSectionTitle: "Implementing Low Code Application Development",
      newSectionDescription:
        "Our approach to implementing Low Code Application Development ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your low-code applications run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your low-code applications.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Low Code Application Development.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of low-code solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Faster Development",
        description:
          "Quickly build and deploy applications, reducing time-to-market.",
      },
      {
        title: "Reduced Costs",
        description:
          "Lower development and maintenance costs by minimizing the need for extensive coding.",
      },
      {
        title: "Enhanced Flexibility",
        description:
          "Easily adapt to changing business needs with customizable solutions.",
      },
      {
        title: "Empowered Business Users",
        description:
          "Enable non-technical users to create and modify applications.",
      },
      {
        title: "Improved Collaboration",
        description: "Foster better alignment between business and IT teams.",
      },
    ],
  },
  // Applied AI Engineering Options-----------------------------------------------------------------------------------------

  development: {
    component: Development,
    backgroundImage: `url(${DashDashboard9})`,
    title: "Development",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Low Code Application Development enables rapid application creation with minimal hand-coding, utilizing visual development tools and pre-built components to streamline the process.",
      heading: "Key Benefits of Low Code Application Development",
      details: [
        "Faster Development: Quickly build and deploy applications, reducing time-to-market.",
        "Reduced Costs: Lower development and maintenance costs by minimizing the need for extensive coding.",
        "Enhanced Flexibility: Easily adapt to changing business needs with customizable solutions.",
        "Empowered Business Users: Enable non-technical users to create and modify applications.",
        "Improved Collaboration: Foster better alignment between business and IT teams.",
      ],
      image: AppliedAI10,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Internal Process Automation: Automate workflows and approval processes to reduce manual effort.",
            "Customer Engagement: Develop custom applications for gathering and analyzing customer feedback.",
            "Custom CRM Systems: Create tailored CRM solutions to manage customer interactions and sales pipelines.",
            "Data Management Solutions: Build dashboards and data visualization tools to generate insights.",
            "Integration with Modern Technologies: Integrate AI, ML, and automation tools to create intelligent applications.",
          ],
          image: AppliedAI11,
        },
      ],
      additional: [
        {
          text: "Harnessing Low Code Development for Business Success",
          description:
            "Low Code Application Development is crucial for businesses seeking to accelerate innovation, reduce costs, and improve agility.",
          details: [
            "Rapid Prototyping: Quickly develop prototypes to validate ideas and gather feedback.",
            "Lower Technical Barriers: Enable non-technical users to participate in application development.",
            "Simplified Maintenance: Easily update and maintain applications due to the visual development process.",
            "Scalable Solutions: Handle growing user numbers and data volumes with scalable low-code platforms.",
            "Enhanced User Experience: Create intuitive, user-friendly interfaces with responsive design.",
          ],
          image: AppliedAI12,
        },
      ],
      newSectionTitle: "Implementing Low Code Application Development",
      newSectionDescription:
        "Our approach to implementing Low Code Application Development ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your low-code applications run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your low-code applications.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Low Code Application Development.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of low-code solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Development of AI (ML,DL,NN))",
        description:
          "Quickly build and deploy applications, reducing time-to-market.",
        link: "/developmentofAI(ML,DL,NN)",
      },
      {
        title: "AI Operations",
        description:
          "Lower development and maintenance costs by minimizing the need for extensive coding.",
        link: "/aiOperations",
      },
      {
        title: "Hyper and Fine Tuning",
        description:
          "Lower development and maintenance costs by minimizing the need for extensive coding.",
        link: "/tuning",
      },
    ],
  },

  // Development Optiions-----------------------------------------------------------------------------------------------
  "developmentofAI(ML,DL,NN)": {
    component: Development,
    backgroundImage: `url(${DashDashboard10})`,
    title: "Development of AI (ML,DL,NN)",
    subtitle:
      "Leading AI innovation through sophisticated solutions in AI, Machine Learning, Deep Learning, and Neural Networks.",
    extraContent: {
      content1:
        "Our development team specializes in creating intelligent systems that learn from data, adapt to changing environments, and deliver accurate predictions and insights.",
      heading: "Key Services in AI Development",
      details: [
        "Machine Learning (ML): Design and implement algorithms that enable systems to learn and improve from experience, including supervised, unsupervised, and reinforcement learning.",
        "Deep Learning (DL): Construct and train deep neural networks to model complex patterns in data for applications like image recognition, NLP, and autonomous systems.",
        "Neural Networks (NN): Design and optimize neural network architectures, such as CNNs for image tasks and RNNs for sequence data.",
        "LLMs: Work with large language models (LLMs) to deliver advanced natural language understanding.",
        "Gen AI: Develop and deploy generative AI models.",
        "XLM: Create and manage extremely large models (XLMs) for high-performance tasks.",
        "LXM: Develop large business models (LXMs) tailored for specific business applications.",
      ],
      image: dev,
      sections: [
        {
          text: "Development of AI Models",
          description:
            "Our comprehensive approach to AI model development involves several critical steps.",
          details: [
            "Data Collection and Preparation: Gather and preprocess data from various sources, clean it, and split it into training, validation, and test sets.",
            "Feature Engineering: Extract, select, and create relevant features to enhance model performance.",
            "Model Selection and Design: Choose and design appropriate model architectures, including ML algorithms, DL networks, and NN architectures.",
            "Model Training and Tuning: Train models with techniques like hyperparameter tuning, regularization, and optimization algorithms.",
            "Model Evaluation and Validation: Assess model performance using metrics and validation techniques, and perform error analysis.",
            "Model Deployment: Deploy models using strategies like REST APIs, batch processing, and real-time streaming, ensuring scalability and monitoring.",
            "Model Maintenance and Updating: Continuously monitor, retrain, and update models to maintain accuracy and relevance.",
            "Documentation and Knowledge Sharing: Document the development process and share knowledge within the organization.",
          ],
          image: dev1,
        },
      ],
      additional: [
        {
          text: "Documentation and Knowledge Sharing",
          description:
            "We emphasize the importance of thorough documentation and knowledge sharing in our AI development process.",
          details: [
            "Comprehensive Documentation: Record data sources, preprocessing steps, model architecture, hyperparameters, and evaluation metrics.",
            "Knowledge Sharing: Facilitate internal seminars, workshops, and collaboration to disseminate best practices and insights.",
          ],
          image: dev2,
        },
      ],
      newSectionTitle: "Implementation Process",
      newSectionDescription:
        "Our structured process ensures efficient and effective AI model development.",
      newSection: [
        {
          title: "Data Collection and Preparation",
          description:
            "We gather and preprocess data from various sources to ensure it is suitable for model development.",
        },
        {
          title: "Feature Engineering",
          description:
            "We focus on extracting and selecting the most impactful features to improve model accuracy.",
        },
        {
          title: "Model Selection and Design",
          description:
            "We carefully choose and design model architectures based on the problem and data characteristics.",
        },
        {
          title: "Model Training and Tuning",
          description:
            "Our team trains and fine-tunes models using advanced techniques to achieve optimal performance.",
        },
        {
          title: "Model Evaluation and Validation",
          description:
            "We use robust evaluation metrics and validation techniques to assess and improve model performance.",
        },
        {
          title: "Model Deployment",
          description:
            "We ensure smooth deployment of models into production environments with scalability and monitoring in place.",
        },
        {
          title: "Model Maintenance and Updating",
          description:
            "Our team continuously monitors and updates models to keep them relevant and accurate over time.",
        },
      ],
    },
    highlightedPoints: [],
  },

  aiOperations: {
    component: Development,
    backgroundImage: `url(${DashDashboard11})`,
    title: "AI Operations",
    subtitle:
      "AIOps leverages AI to automate IT operations, improve efficiency, and enhance reliability by combining data analytics, machine learning, and automation.",
    extraContent: {
      content1:
        "AI Operations (AIOps) is a framework that leverages artificial intelligence (AI) to automate IT operations, improve efficiency, and enhance reliability. By combining data analytics, machine learning, and automation, AIOps enables organizations to gain valuable insights, optimize resource allocation, and proactively address issues.",
      heading: "Key Components of AI Operations",
      details: [
        "Data Collection and Integration: Data collection and integration in AIOps involve gathering data from various sources and consolidating it into a unified view of the IT environment.",
        "Machine Learning and Analytics: AIOps leverages machine learning models and advanced analytics to analyze data, identify patterns, and support decision-making.",
        "Event Correlation and Analysis: AIOps uses event correlation and root cause analysis to identify significant incidents, reduce noise, and provide actionable insights for remediation.",
        "Automation and Orchestration: AIOps involves automating routine tasks and orchestrating automated processes across different systems to improve efficiency and streamline operations.",
        "Real-Time Monitoring and Predictive Capabilities: AIOps involves real-time monitoring of IT systems and uses predictive analytics to forecast future issues and provide proactive solutions.",
      ],
      image: dev3,
      sections: [
        {
          text: "Key Benefits of AI Operations",
          description:
            "AI Operations (AIOps) is a powerful approach to managing IT operations, leveraging artificial intelligence to automate tasks, improve efficiency, and enhance reliability. Here are some key benefits.",
          details: [
            "Improved Efficiency and Reliability: AIOps improves efficiency, reliability, and proactively resolves issues by automating tasks and identifying problems early.",
            "Enhanced Decision Making: AIOps enhances decision-making by providing data-driven insights, optimizing resource allocation, and proactively managing risks.",
            "Cost Reduction: AIOps reduces costs by minimizing downtime, optimizing resource utilization, and automating tasks.",
            "Improved Customer Experience: AIOps improves customer experience by providing faster response times, consistent service quality, and proactive support.",
            "Competitive Advantages: AIOps provides a competitive advantage by enhancing efficiency, improving customer experience, and mitigating risks.",
          ],
          image: dev4,
        },
      ],
      additional: [
        {
          text: "Applications of AI Operations in Various Industries",
          description:
            "AI Operations (AIOps) is revolutionizing the way organizations manage their IT operations by leveraging artificial intelligence to automate tasks, improve efficiency, and enhance reliability.",
          details: [
            "IT Operations: AIOps in IT operations automates incident management, optimizes performance, and predicts future resource needs.",
            "Telecommunications: AIOps in telecommunications automates network monitoring, customer service, and optimizes network configurations for improved performance and efficiency.",
            "Financial Services: AIOps in financial services automates fraud detection, manages risks proactively, and ensures regulatory compliance through automated monitoring.",
            "Healthcare: AIOps in healthcare automates medical image analysis, monitors patient vital signs, and predicts equipment failures to improve patient care and prevent disruptions.",
            "Manufacturing: AIOps in manufacturing automates predictive maintenance, quality control, and optimizes supply chain operations for improved efficiency and cost-effectiveness.",
          ],
          image: dev5,
        },
      ],
      newSectionTitle: "Implementing AI Operations",
      newSectionDescription:
        "Implementing AI operations (AIOps) involves integrating artificial intelligence and machine learning into IT operations to enhance efficiency, automate processes, and improve decision-making. Here are some key steps and considerations:",
      newSection: [
        {
          title: "Define Objectives and Use Cases",
          description:
            "AIOps implementation begins with identifying goals and selecting specific use cases where AIOps can have the most impact.",
        },
        {
          title: "Data Collection and Integration",
          description:
            "Data collection and integration involve gathering data from various sources and ensuring seamless integration to create a unified data repository.",
        },

        {
          title: "Implement Machine Learning Models",
          description:
            "Model selection and training involve choosing appropriate machine learning models based on use cases, training them on historical data, and validating their performance with test data.",
        },
        {
          title: "Automation and Orchestration",
          description:
            "AIOps implementation involves automating routine tasks and orchestrating automated processes across different systems to improve efficiency and streamline operations.",
        },
        {
          title: "Monitoring and Continuous Improvement",
          description:
            "AIOps implementation requires real-time monitoring of AI models and IT operations, along with a feedback loop to refine models and processes based on real-time data and outcomes.",
        },
        {
          title: "Security and Compliance",
          description:
            "AIOps implementation requires strong security measures and compliance with relevant regulations to protect data and AI models.",
        },

        {
          title: "Collaboration and Change Management",
          description:
            "AIOps implementation requires cross-functional collaboration and effective change management to ensure successful adoption.",
        },

        {
          title: "Evaluate and Scale",
          description:
            "AIOps implementation involves evaluating its impact on operations and scaling up initiatives based on success and learnings.",
        },
      ],
    },
    highlightedPoints: [],
  },

  tuning: {
    component: Tuning,
    backgroundImage: `url(${DashDashboard12})`,
    title: "Hyper and Fine Tuning",
    subtitle:
      "Tailoring AI models to meet specific business requirements through advanced tuning techniques.",
    extraContent: {
      content1:
        "Hyperparameter and fine-tuning of AI models are critical steps in developing robust and high-performing models at TechOptima.",
      heading: "Benefits of Hyper and Fine Tuning",
      details: [
        "Learning Rate: Determines the step size at each iteration while moving towards a minimum of a loss function. ",

        "Batch Size: Number of training examples utilized in one iteration. ",

        "Number of Epochs: The number of complete passes through the training dataset. ",

        "Regularization Parameters: Parameters like L1 and L2 regularization that help prevent overfitting. ",

        "Model-Specific Parameters: Parameters specific to certain models, such as the number of layers and neurons in a neural network.",
      ],
      image: dev6,
      sections: [
        {
          text: "Approaches to Hyperparameter Tuning: ",
          description:
            "Hyperparameter and fine-tuning are meticulously executed to ensure the development of high-performing AI models.",
          details: [
            "Enhanced Accuracy: Optimize model performance by fine-tuning hyperparameters, enhancing accuracy, and reducing computation costs.",
            " Reduced Computation Costs: Efficiently allocate resources by optimizing hyperparameters.",
            "Improved Model Performance: Achieve robust and high-performing models through systematic optimization.",
            " Customizable Solutions: Tailor AI models to meet specific business requirements.",
            "Iterative Testing and Validation: Ensure the best possible outcomes through continuous testing and validation.",
          ],
          image: dev7,
        },
      ],
      additional: [
        {
          text: " Implement Fine-Tuning for AI Models",
          description:
            "Fine-tuning involves using pre-trained models and adapting them to new datasets through a series of optimization steps.",
          details: [
            " Pre-Trained Models: Use models pre-trained on large datasets (e.g., ImageNet for image tasks, BERT for NLP tasks).",
            "Freezing Layers: Initially, freeze the layers of the pre-trained model to retain learned features.",
            "Selective Unfreezing: Gradually unfreeze layers and fine-tune the network by retraining with the new dataset.",
            "Learning Rate Adjustment: Often requires a smaller learning rate to avoid overwriting the pre-trained weights significantly.",
          ],
          image: dev8,
        },
      ],
      newSectionTitle:
        "Implementing Hyperparameter and Fine-Tuning at TechOptima",
      newSectionDescription:
        "Use models pre-trained on large datasets (e.g., ImageNet for image tasks, BERT for NLP tasks).",
      newSection: [
        {
          title: "Pre-Trained Models",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "Freezing Layers",
          description:
            "Initially, freeze the layers of the pre-trained model to retain learned features.",
        },
        {
          title: "Selective Unfreezing ",
          description:
            "Gradually unfreeze layers and fine-tune the network by retraining with the new dataset.",
        },
        {
          title: "Learning Rate Adjustment",
          description:
            "Often requires a smaller learning rate to avoid overwriting the pre-trained weights significantly.",
        },
        {
          title: "Regularization",
          description:
            "Apply techniques such as dropout, L2 regularization to prevent overfitting.",
        },
        {
          title: "Optimization",
          description:
            "Use advanced optimizers like Adam, RMSprop to fine-tune the model effectively.",
        },
      ],
    },
    highlightedPoints: [
      // no Poits
    ],
  },

  // ------------------------------------------------------------------------------------------------------------------------------
  appliedAIresearch: {
    component: CloudTransformation,
    backgroundImage: `url(${DashDashboard13})`,
    title: "Applied AI Research",
    subtitle:
      "Applied AI Research transformation is the process of migrating an organization’s IT infrastructure, applications, and data from on-premises environments to the cloud, leveraging cloud services to improve agility, scalability, and cost-efficiency.",
    extraContent: {
      content1:
        "Our Applied AI Research division focuses on harnessing the power of AI to drive innovation and transformation in organizations, moving IT infrastructure, applications, and data from on-premises to the cloud for enhanced agility, scalability, and cost-efficiency.",
      heading: "Key Areas of Applied AI Research",
      details: [
        "Cloud Transformation: Migrate and optimize IT infrastructure, applications, and data to the cloud, leveraging advanced AI techniques to enhance performance.",
        "Agility Enhancement: Utilize AI to improve organizational agility, enabling faster adaptation to changing market conditions and customer demands.",
        "Scalability Solutions: Develop AI-driven solutions that ensure scalability of IT resources in the cloud, handling increased workloads and data growth efficiently.",
        "Cost Efficiency: Implement AI research to reduce operational costs by optimizing resource usage and automating processes in cloud environments.",
      ],
      image: AI1,
      sections: [
        {
          text: "Process of Applied AI Research",
          description:
            "Our structured approach to Applied AI Research involves several critical steps to ensure successful transformation.",
          details: [
            "Assessment and Strategy Development: Evaluate the current IT infrastructure and develop a strategy for AI-driven cloud transformation.",
            "Cloud Migration Planning: Plan and execute the migration of applications, data, and infrastructure to the cloud, ensuring minimal disruption.",
            "AI-Enabled Optimization: Apply AI techniques to optimize cloud resources, improving efficiency and performance.",
            "Scalability Engineering: Design solutions that leverage cloud scalability to handle growing data and workload demands.",
            "Cost Management: Implement AI-driven cost management strategies to ensure the transformation is cost-effective and sustainable.",
          ],
          image: AI2,
        },
      ],
      additional: [
        {
          text: "Benefits of Applied AI Research",
          description:
            "Our Applied AI Research offers significant benefits to organizations undergoing cloud transformation.",
          details: [
            "Enhanced Agility: Enable rapid adaptation to changing business environments through AI-driven solutions.",
            "Improved Scalability: Leverage cloud capabilities to scale IT resources dynamically, meeting increased demand.",
            "Cost Savings: Achieve significant cost reductions by optimizing resource usage and automating processes.",
            "Future-Ready Infrastructure: Build a future-ready IT infrastructure that is agile, scalable, and efficient.",
          ],
          image: AI3,
        },
      ],
      newSectionTitle: "Implementation Process",
      newSectionDescription:
        "Our process ensures a smooth transition to a cloud-enabled, AI-driven infrastructure.",
      newSection: [
        {
          title: "Assessment and Strategy Development",
          description:
            "We begin with a thorough assessment of the existing IT infrastructure and develop a tailored cloud transformation strategy.",
        },
        {
          title: "Pilot and Proof of Concept (PoC)",
          description:
            "Implement a pilot project or PoC to test the feasibility and performance of the cloud transformation plan. Identify potential challenges and address them before full-scale implementation.",
        },
        {
          title: "Cloud Migration Planning",
          description:
            "Our team meticulously plans and executes the migration of applications, data, and infrastructure to the cloud.",
        },
        {
          title: "AI-Enabled Optimization",
          description:
            "We apply AI techniques to optimize cloud resources, ensuring maximum efficiency and performance.",
        },
        {
          title: "Scalability Engineering",
          description:
            "Our solutions are designed to leverage cloud scalability, allowing for seamless handling of increased workloads.",
        },
        {
          title: "Cost Management",
          description:
            "We implement AI-driven strategies to manage and reduce costs during and after the cloud transformation.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "ModelHubs",
        description:
          "ModelHubs provide centralized repositories for AI models, allowing easy access, sharing, and deployment across cloud environments, facilitating collaboration and scalability.",
        link: "/modelhubs",
      },
      {
        title: "FrameWorks",
        description:
          "Frameworks enable the development and deployment of AI models within cloud environments, offering a structured approach to building scalable and efficient AI solutions.",
        link: "/frameworks",
      },
      {
        title: "Accelerators (Medtek AI)",
        description:
          "Accelerators like Medtek AI leverage cloud infrastructure to enhance the speed and efficiency of AI model training and inference, driving innovation in healthcare and beyond.",
        link: "/accelerators",
      },
    ],
  },

  // ResearchOptions---------------------------------------------------------------------------------------------------------------
  modelhubs: {
    component: CloudTransformation,
    backgroundImage: `url(${DashDashboard14})`,
    title: "Model Hubs ",
    subtitle:
      "Centralized platform for AI model management, collaboration, and deployment.",
    extraContent: {
      content1:
        "Our Model Hub is a centralized repository that streamlines the management, sharing, and deployment of AI models, enabling collaboration and scalability across cloud environments.",
      heading: "Key Features of Model Hub",
      details: [
        "Centralized Repository: Store and manage AI models in a single, organized location accessible to teams across the organization.",
        "Version Control: Track changes and manage different versions of models to ensure consistency and reliability.",
        "Collaboration Tools: Enable teams to collaborate on model development, sharing insights, and improvements easily.",
        "Deployment Integration: Seamlessly integrate with cloud platforms to deploy models at scale, ensuring high availability and performance.",
        "Security and Compliance: Ensure that all models are stored and accessed securely, adhering to organizational and regulatory standards.",
      ],
      image: AI4,
      sections: [
        {
          text: "Benefits of Using Model Hub",
          description:
            "Model Hub offers several advantages that enhance the efficiency and effectiveness of AI model management.",
          details: [
            "Improved Collaboration: Facilitates collaboration among data scientists, developers, and business stakeholders, leading to more refined and effective AI models.",
            "Scalability: Supports the deployment of models across multiple environments, scaling resources as needed to handle growing demands.",
            "Efficiency: Reduces time-to-market for AI solutions by streamlining the model management and deployment processes.",
            "Enhanced Security: Provides robust security features to protect sensitive models and data, ensuring compliance with industry standards.",
          ],
          image: AI5,
        },
      ],
      additional: [
        {
          text: "Integrating Model Hub with Your Workflow",
          description:
            "We offer seamless integration of Model Hub into your existing workflow to maximize its benefits.",
          details: [
            "Easy Integration: Model Hub can be integrated with existing development tools and cloud platforms, fitting smoothly into your current processes.",
            "Customizable Access Controls: Define and manage access rights for different team members, ensuring that models are accessed and modified by authorized personnel only.",
            "Automated Workflows: Set up automated workflows for model training, evaluation, and deployment, enhancing efficiency and reducing manual effort.",
            "Continuous Monitoring: Implement continuous monitoring of deployed models to track performance and make necessary adjustments.",
          ],
          image: AI6,
        },
      ],
      newSectionTitle: "Implementation Process",
      newSectionDescription:
        "Our approach to implementing Model Hub ensures a smooth and efficient transition.",
      newSection: [
        {
          title: "Assessment and Planning",
          description:
            "We begin by assessing your current model management practices and develop a tailored implementation plan.",
        },
        {
          title: "Customization and Integration",
          description:
            "Our team customizes Model Hub to meet your specific needs and integrates it into your existing systems.",
        },
        {
          title: "Training and Onboarding",
          description:
            "We provide comprehensive training and onboarding to ensure your team can effectively use Model Hub from day one.",
        },
        {
          title: "Deployment and Monitoring",
          description:
            "We support the deployment of Model Hub and set up monitoring to ensure it operates smoothly and efficiently.",
        },
        {
          title: "Ongoing Support",
          description:
            "Our team offers ongoing support to address any issues and help you maximize the benefits of Model Hub.",
        },
      ],
    },
    highlightedPoints: [],
  },
  frameworks: {
    component: CloudTransformation,
    backgroundImage: `url(${DashDashboard15})`,
    title: "Frame Works",
    subtitle:
      "Structured solutions for building, deploying, and scaling AI models.",
    extraContent: {
      content1:
        "Our Frameworks provide a structured approach to developing and deploying AI models, offering tools and libraries that simplify the creation, training, and deployment of AI solutions across cloud environments.",
      heading: "Key Features of Frameworks",
      details: [
        "Comprehensive Libraries: Access a wide range of pre-built functions and tools that accelerate AI development and deployment.",
        "Modular Architecture: Utilize modular components that can be easily integrated, customized, and scaled according to project needs.",
        "Cross-Platform Compatibility: Ensure seamless integration and operation across various cloud platforms and on-premises environments.",
        "Optimized Performance: Leverage optimized algorithms and processing techniques to enhance model efficiency and reduce computation time.",
        "Support for Multiple Languages: Develop AI solutions in various programming languages, offering flexibility and adaptability to different teams and projects.",
      ],
      image: AI7,
      sections: [
        {
          text: "Benefits of Using Frameworks",
          description:
            "Frameworks offer several advantages that streamline the AI model development and deployment process.",
          details: [
            "Accelerated Development: Speed up the AI development process with pre-built libraries and tools that reduce coding effort.",
            "Scalability: Easily scale AI solutions across multiple environments, ensuring that they can handle increasing data and user demands.",
            "Consistency: Ensure consistent performance and behavior across different stages of the development and deployment pipeline.",
            "Cost Efficiency: Reduce development costs by minimizing the need for custom code and leveraging reusable components.",
          ],
          image: AI8,
        },
      ],
      additional: [
        {
          text: "Integrating Frameworks into Your Workflow",
          description:
            "We provide seamless integration of our Frameworks into your existing AI development and deployment workflows.",
          details: [
            "Seamless Integration: Our Frameworks can be integrated with your current development tools and cloud platforms to fit smoothly into your workflow.",
            "Customizable Modules: Tailor the framework’s components to meet your specific needs, ensuring optimal performance and flexibility.",
            "Automation Support: Set up automated processes for model training, evaluation, and deployment, improving efficiency and reducing manual tasks.",
            "Continuous Improvement: Incorporate feedback and updates into the framework to continuously enhance your AI solutions.",
          ],
          image: AI9,
        },
      ],
      newSectionTitle: "Implementation Process",
      newSectionDescription:
        "Our process ensures a smooth and effective implementation of AI Frameworks in your organization.",
      newSection: [
        {
          title: "Assessment and Planning",
          description:
            "We begin by assessing your current AI development practices and define a tailored implementation plan for the frameworks.",
        },
        {
          title: "Customization and Integration",
          description:
            "Our team customizes the framework components to align with your project needs and integrates them into your existing systems.",
        },
        {
          title: "Migration and Deployment",
          description:
            "Execute the migration plan, starting with non-critical applications and data. Gradually move critical applications and data to the cloud. Ensure continuous testing and validation throughout the migration process.",
        },
        {
          title: "Training and Onboarding",
          description:
            "We provide comprehensive training and onboarding sessions to ensure your team can effectively use the frameworks from day one.",
        },
        {
          title: "Deployment and Monitoring",
          description:
            "We support the deployment of frameworks and set up monitoring systems to ensure they function efficiently and effectively.",
        },
        {
          title: "Ongoing Support",
          description:
            "Our team offers ongoing support to address any issues and help you maximize the benefits of our AI Frameworks.",
        },
      ],
    },
    highlightedPoints: [],
  },
  accelerators: {
    component: CloudTransformation,
    backgroundImage: `url(${DashDashboard16})`,
    title: "Accelerators (Medtek AI)",
    subtitle:
      "Speeding up AI innovation in healthcare with specialized accelerators.",
    extraContent: {
      content1:
        "Medtek AI Accelerators are designed to fast-track the development, deployment, and scaling of AI-driven healthcare solutions, ensuring rapid innovation and improved patient outcomes.",
      heading: "Key Features of Medtek AI Accelerators",
      details: [
        "Healthcare-Specific Models: Utilize pre-built AI models tailored to healthcare applications, from diagnostics to patient monitoring.",
        "Rapid Prototyping: Quickly develop and test new AI solutions with accelerators that streamline the prototyping phase.",
        "Integration with Medical Systems: Ensure seamless integration with existing electronic health record (EHR) systems, medical devices, and other healthcare technologies.",
        "Regulatory Compliance: Accelerators are designed to meet industry standards and regulations, ensuring that AI solutions are compliant with healthcare laws.",
        "Scalable Infrastructure: Leverage cloud-based infrastructure that allows for the scalable deployment of AI models across multiple healthcare facilities.",
      ],
      image: AI10,
      sections: [
        {
          text: "Benefits of Using Medtek AI Accelerators",
          description:
            "Medtek AI Accelerators provide significant advantages in the development and deployment of healthcare AI solutions.",
          details: [
            "Faster Time-to-Market: Accelerators reduce the time required to develop and deploy AI solutions, enabling quicker innovation in healthcare.",
            "Enhanced Accuracy: Leverage pre-built models that are optimized for accuracy in healthcare applications, ensuring reliable outcomes.",
            "Cost Efficiency: Reduce development costs by using specialized accelerators that minimize the need for extensive custom development.",
            "Improved Patient Outcomes: Implement AI solutions that are designed to enhance diagnostic accuracy, treatment planning, and patient monitoring.",
          ],
          image: AI11,
        },
      ],
      additional: [
        {
          text: "Integrating Medtek AI Accelerators into Healthcare Workflows",
          description:
            "Our accelerators are designed to fit seamlessly into existing healthcare workflows, maximizing their impact.",
          details: [
            "Seamless Integration: Medtek AI Accelerators integrate easily with existing healthcare IT systems, ensuring a smooth transition.",
            "Customizable Solutions: Tailor the accelerators to meet the specific needs of different healthcare providers and specialties.",
            "Automation Capabilities: Enable automated processes for diagnostics, data analysis, and patient monitoring, reducing manual workloads.",
            "Continuous Monitoring and Improvement: Implement continuous monitoring to track performance and improve AI solutions over time.",
          ],
          image: AI12,
        },
      ],
      newSectionTitle: "Implementation Process",
      newSectionDescription:
        "Our structured process ensures the successful implementation of Medtek AI Accelerators in healthcare environments.",
      newSection: [
        {
          title: "Assessment and Planning",
          description:
            "We start by assessing the current healthcare IT infrastructure and define a plan to implement Medtek AI Accelerators effectively.",
        },
        {
          title: "Customization and Integration",
          description:
            "Our team customizes the accelerators to align with the specific requirements of your healthcare facility and integrates them seamlessly.",
        },
        {
          title: "Training and Onboarding",
          description:
            "We provide comprehensive training to ensure healthcare professionals can effectively use the AI solutions powered by the accelerators.",
        },
        {
          title: "Deployment and Monitoring",
          description:
            "We support the deployment of accelerators and establish monitoring systems to ensure optimal performance and compliance.",
        },
        {
          title: "Ongoing Support",
          description:
            "Our team offers continuous support to address any issues and ensure the long-term success of Medtek AI Accelerators in your organization.",
        },
      ],
    },
    highlightedPoints: [],
  },

  // APPLIED DATA SCIENCE --------------------------------------------------------------------------------------------------

  dataanalytics: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard17})`,
    title: "Data Analytics",
    subtitle:
      "Data analytics is the process of examining data to extract meaningful insights and make informed decisions.",
    extraContent: {
      content1:
        "Data analytics is the process of examining, transforming, and modeling raw data to uncover meaningful patterns, insights, and trends. It involves applying various statistical and computational techniques to extract valuable information that can guide decision-making and improve outcomes.",
      heading: "Key aspects of Data Analytics",
      details: [
        "Data Collection: Gathering relevant and high-quality data from various sources.",
        "Data Cleaning: Removing errors and inconsistencies to ensure data accuracy.",
        "Data Analysis: Using statistical methods and machine learning to uncover insights.",
        "Data Visualization: Presenting data in visual formats like charts and graphs.",
        "Data Integration: Embedding insights into business processes for informed decision-making.",
      ],
      image: AI1,
      sections: [
        {
          text: "Key benefits of Data Analytics",
          description:
            "Data analytics empowers organizations to make informed decisions, improve efficiency, and gain a competitive advantage through data-driven insights.",
          details: [
            "Improved Decision-Making: By providing data-driven insights, organizations can make more informed and accurate decisions, reducing reliance on intuition.",
            "Increased Efficiency and Productivity: Automating and streamlining processes through data analytics helps identify bottlenecks and optimize resource allocation, leading to higher efficiency.",
            "Enhanced Customer Experience: Analyzing customer data allows businesses to personalize services and products, improving customer satisfaction and loyalty.",
            "Risk Management: Data analytics helps in identifying potential risks and fraud, enabling proactive measures to mitigate them",
            "Competitive Advantage: Leveraging data analytics can provide a competitive edge by uncovering market trends and opportunities that might otherwise go unnoticed.",
          ],
          image: AI2,
        },
      ],
      additional: [
        {
          text: "Applications of Data Analytics in Various Industries",
          description:
            "Data analytics is transforming industries across the globe, providing invaluable insights and driving innovation.",
          details: [
            "Retail: Helps in understanding customer behavior, optimize inventory, and personalize marketing campaigns, enhancing customer satisfaction and boosting sales ",
            "Healthcare: Improves patient outcomes by predicting disease outbreaks, personalizing treatments, and enhancing diagnosis and preventive care.",
            "Transportation: In transportation, data analytics improves route planning, reduces fuel consumption, and enhances safety through predictive maintenance.",
            "Manufacturing: Enhances efficiency by optimizing production, predicting maintenance, and improving supply chain management, resulting in lower costs and higher productivity.",
            "Telecommunications: Telecom companies use data analytics to enhance network performance, predict customer churn, and develop targeted marketing strategies.",
          ],
          image: AI3,
        },
      ],
      newSectionTitle: "Implementing Data Analytics",
      newSectionDescription:
        "Implementing data analytics involves defining clear objectives, collecting and managing high-quality data, analysing it using statistical methods and machine learning, and visualizing the insights for decision-making.",
      newSection: [
        {
          title: "Define Objectives and Goals",
          description:
            "Clearly outline what you hope to achieve with data analytics. This includes identifying key performance indicators (KPIs) and business objectives.",
        },
        {
          title: "Data Collection and Integration",
          description:
            "Gather data from various sources such as databases, APIs, and external datasets. Ensure that your data is integrated into a centralized system for easy access and analysis.",
        },
        {
          title: "Data Cleaning and Preparation",
          description:
            "Clean and preprocess the data to remove any inconsistencies, duplicates, or errors. This step is crucial for ensuring the accuracy and reliability of your analysis.",
        },
        {
          title: "Choose the Right Tools and Technologies",
          description:
            "Select appropriate tools and technologies for data analysis, such as Python, R, SQL, and data visualization tools like Tableau or Power BI.",
        },

        {
          title: "Data Analysis and Modeling",
          description:
            "Apply statistical methods, machine learning algorithms, and other analytical techniques to extract insights from the data. This may involve predictive modeling, clustering, or regression analysis.",
        },

        {
          title: "Visualization and Reporting",
          description:
            "Create dashboards and reports to visualize the data and present the insights in an easily understandable format. This helps stakeholders make informed decisions quickly.",
        },

        {
          title: "Implementation and Monitoring",
          description:
            "Deploy the analytics solutions and continuously monitor their performance. Make adjustments as needed to improve accuracy and relevance.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Predictive Analytics",
        description:
          "Predictive analytics is a powerful branch of advanced analytics that uses historical data, statistical modeling, data mining techniques, and machine learning to make predictions about future outcomes.",
        link: "/predictiveanalytics",
      },
      {
        title: "Descriptive Analytics",
        description:
          "Descriptive analytics is a fundamental aspect of data analytics that focuses on summarizing and interpreting historical data to identify patterns, trends, and relationships.",
        link: "/descriptiveanalytics",
      },
      {
        title: "Diagnostic Analytics",
        description:
          "Diagnostic analytics is a type of data analytics focused on understanding why something happened. It goes beyond descriptive analytics, which tells you what happened, by identifying the root causes of trends and correlations between variables.",
        link: "/diagnosticanalytics",
      },
      {
        title: "Prescriptive analytics ",
        description:
          "Prescriptive analytics uses data, algorithms, and machine learning to suggest the best course of action for a given situation. It answers the question, “What should we do next?” by considering various factors such as past performance, current conditions, and potential future scenarios.",
        link: "/prescriptiveanalytics",
      },
    ],
  },

  // ---------------------------------------------------------------------------------------------------------

  predictiveanalytics: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard18})`,
    title: "Predictive Analytics ",
    subtitle: "Forecasting Future Outcomes with Advanced Techniques",
    extraContent: {
      content1:
        "Predictive analytics is a powerful tool used to forecast future trends and outcomes based on historical data. It employs statistical models, machine learning algorithms, and data mining techniques to analyze past patterns and make predictions about the future.",
      heading: "Key Components of Predictive Analytics",
      details: [
        "Data Collection and Preparation: Involves gathering relevant data from various sources, cleaning and correcting errors, and transforming the data into a suitable format for analysis.",
        "Feature Engineering: Involves selecting the most relevant features and creating new features to improve the predictive power of a model.",
        "Model Building: Involves selecting appropriate algorithms, training the model on historical data, and evaluating its performance using various metrics.",
        "Model Deployment: Involves integrating the trained model into existing systems and continuously monitoring its performance to ensure accuracy.",
        "Tools and Techniques: Predictive analytics relies on various techniques, including statistical modeling, machine learning, and data mining, to discover patterns and relationships in data and make accurate predictions.",
      ],
      image: AI4,
      sections: [
        {
          text: "Key Benefits of Predictive Analytics",
          description:
            "Predictive analytics, a powerful tool for forecasting future trends and outcomes, offers numerous advantages. Here are some key benefits:",
          details: [
            "Competitive Advantage: Organizations that leverage predictive analytics can stay ahead of the competition by quickly adapting to market changes and identifying new opportunities.",
            "Risk Management: It enables the identification and mitigation of potential risks by predicting events such as fraud, equipment failures, and market fluctuations",
            "Cost Reduction: Predictive maintenance and optimized resource allocation help in reducing operational costs and minimizing downtime.",
            "Enhanced Customer Experience: By anticipating customer needs and behaviors, businesses can personalize services and improve customer satisfaction and retention.",
            "Increased Efficiency: Predictive analytics streamlines operations by optimizing supply chains, inventory management, and workforce planning.",
          ],
          image: AI5,
        },
      ],
      additional: [
        {
          text: "Applications of Predictive Analytics in Various Industries",
          description:
            "Predictive analytics is widely used across various industries to forecast future trends and improve decision-making",
          details: [
            "Healthcare: Predictive analytics helps in predicting disease outbreaks, patient readmissions, and treatment outcomes. It enables personalized treatment plans and efficient resource allocation",
            "Finance: Financial institutions use predictive analytics to assess credit risk, detect fraud, and forecast market trends. It helps in making informed investment decisions and managing risks.",
            "Retail: Retailers leverage predictive analytics to forecast demand, optimize inventory, and personalize marketing strategies. It helps in understanding customer behavior and improving sales",
            "Telecommunications: Telecom companies use predictive analytics to predict customer churn, optimize network performance, and enhance customer service.",
            "Transportation: Predictive analytics is used to optimize routes, manage fleet operations, and predict maintenance needs. It helps in reducing operational costs and improving service reliability.",
          ],
          image: AI6,
        },
      ],
      newSectionTitle: "Implementing Predictive Analytics",
      newSectionDescription:
        "Implementing these steps effectively can help organizations anticipate future trends, optimize operations, and make data-driven decisions.",
      newSection: [
        {
          title: "Define Business Objectives",
          description:
            "Clearly outline the questions you want predictive analytics to answer. This helps in focusing the analysis and setting measurable goals.",
        },
        {
          title: "Data Collection and Preparation",
          description:
            "Gather relevant data from various sources such as databases, CRM systems, and external datasets. Ensure the data is clean, accurate, and formatted correctly for analysis.",
        },

        {
          title: "Select the Right Tools and Techniques",
          description:
            "Choose appropriate predictive analytics tools and techniques, such as machine learning algorithms (e.g., regression, classification) and statistical methods.",
        },
        {
          title: "Build Predictive Models",
          description:
            "Develop and train predictive models using historical data. This involves selecting the right features, tuning model parameters, and validating the models to ensure accuracy.",
        },
        {
          title: "Deploy and Monitor Models",
          description:
            "Implement the predictive models in your business processes. Continuously monitor their performance and make adjustments as needed to maintain accuracy and relevance.",
        },
        {
          title: "Interpret and Communicate Results",
          description:
            "Analyze the model outputs to draw meaningful insights. Communicate these insights to stakeholders in a clear and actionable manner.",
        },
        {
          title: "Iterate and Improve",
          description:
            "Continuously refine the models and processes based on feedback and new data to enhance their predictive power and business value.",
        },
      ],
    },
    highlightedPoints: [],
  },

  descriptiveanalytics: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard19})`,
    title: "Descriptive Analytics",
    subtitle:
      "Descriptive analytics is a type of data analysis that summarizes past data to understand trends, patterns, and correlations. It provides insights into what has happened in the past.",
    extraContent: {
      content1:
        "Descriptive analytics is a type of data analysis that focuses on describing and summarizing historical data to understand what happened. It provides insights into past trends, patterns, and correlation.",
      heading: "Key Components of Descriptive Analytics",
      details: [
        "Data Collection: Gathering relevant data from various sources such as databases, surveys, and social media platforms.",
        "Data Cleaning and Preprocessing: Ensuring the data is accurate, complete, and formatted correctly for analysis.",
        "Data Visualization: Using charts, graphs, and other visual tools to represent data in an easily understandable format.",
        "Summary Statistics: Calculating measures of central tendency (mean, median, mode), dispersion (range, variance, standard deviation), and frequency.",
        "Data Interpretation: Analyzing the visualized data and summary statistics to draw meaningful insights and conclusions.",
      ],
      image: AI7,
      sections: [
        {
          text: "Key Benefits of Descriptive Analytics",
          description:
            "Descriptive analytics improves decision-making and operational efficiency by providing insights into historical data and optimizing resources. It also offers valuable customer insights and aids in risk management.",
          details: [
            "Enhanced Operational Efficiency: It identifies patterns and anomalies in operational data, allowing businesses to streamline processes, reduce waste, and improve overall efficiency.",
            "Risk Management: By analyzing historical data, organizations can identify potential risks and take proactive measures to mitigate them. This is particularly useful in finance and insurance sectors.",
            "Resource Optimization: Descriptive analytics helps in optimizing resource allocation by identifying areas of high demand and underutilization, ensuring better resource management.",
            "Performance Monitoring: It allows businesses to track key performance indicators (KPIs) and measure progress against goals, facilitating continuous improvement.",
            "Foundation for Advanced Analytics: Descriptive analytics serves as a foundation for more advanced analytics, such as predictive and prescriptive analytics, by offering insights into past performance and trends.",
          ],
          image: AI8,
        },
      ],
      additional: [
        {
          text: "Applications of Descriptive Analytics in Various Industries",
          description:
            "Descriptive analytics is widely used across various industries to interpret historical data and identify trends.",
          details: [
            "Finance: Financial institutions use descriptive analytics to monitor transactions, detect fraud, and assess financial performance.",
            "Healthcare: Hospitals and clinics use descriptive analytics to track patient outcomes, monitor the spread of diseases, and manage resources efficiently.",
            "Manufacturing: In manufacturing, descriptive analytics is used to monitor production processes, ensure quality control, and manage supply chains.",
            "Telecommunications: Telecom companies use descriptive analytics to analyze call data records, monitor network performance, and improve customer service",
            "Marketing: Marketers use descriptive analytics to evaluate the effectiveness of campaigns, understand customer preferences, and segment markets.",
          ],
          image: AI9,
        },
      ],
      newSectionTitle: "Implementing Descriptive Analytics",
      newSectionDescription:
        "Descriptive analytics involves collecting, preparing, analyzing, and interpreting data to gain insights into past events and current trends. It provides valuable information for informed decision-making.",
      newSection: [
        {
          title: "Identify Data Sources",
          description:
            "Data collection involves gathering data from internal sources like databases and CRM systems, as well as external sources like public data and industry reports.",
        },
        {
          title: "Data Cleaning and Preparation",
          description:
            "Data preparation involves handling missing values, correcting inconsistencies, and ensuring data is in a consistent format and data type.",
        },

        {
          title: "Data Exploration",
          description:
            "Involves calculating summary statistics, creating visual representations of data, and analyzing the distribution of values within variables.",
        },
        {
          title: "Data Analysis",
          description:
            "Involves calculating summary statistics, analyzing relationships between categorical variables using cross-tabulation, and measuring the strength and direction of relationships between numerical variables using correlation analysis.",
        },
        {
          title: "Data Visualization",
          description:
            "Effective data visualization requires selecting appropriate charts, using clear labels, and considering color and formatting to enhance readability and understanding.",
        },
        {
          title: "Interpretation and Insights",
          description:
            "Descriptive analytics involves identifying patterns and trends in data, drawing conclusions based on the findings, and effectively communicating insights to stakeholders.",
        },
        {
          title: "Monitor and Optimize",
          description:
            "Continuously monitor data systems and pipelines for performance and reliability. Optimize processes to improve efficiency.",
        },
      ],
    },
    highlightedPoints: [],
  },
  diagnosticanalytics: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard20})`,
    title: "Diagnostic Analytics ",
    subtitle:
      "Diagnostic analytics investigates the underlying causes of events, providing deeper insights and enabling informed decision-making.",
    extraContent: {
      content1:
        "Diagnostic analytics is a powerful tool used to investigate the underlying causes of events, providing deeper insights beyond the surface-level understanding offered by descriptive analytics. It's essential for identifying root causes, understanding complex relationships, and making informed decisions.",
      heading: "Key Components of Diagnostic Analytics",
      details: [
        "Data Mining: This process involves exploring large datasets to uncover patterns and relationships.",
        "Root Cause Analysis: Identifying the fundamental cause of a problem or trend is crucial for understanding why something happened.",
        "Correlation Analysis: Examining the relationships between different data points to determine if and how they are related",
        "Hypothesis Testing: Formulating and testing hypotheses to validate assumptions about potential causes.",
        "Drill-Down and Drill-Through Analysis: These techniques allow for deeper exploration of data by breaking it down into more detailed levels.",

        "Statistical Analysis: Using statistical methods to analyze data and identify significant patterns and relationships.",
      ],
      image: AI10,
      sections: [
        {
          text: "Key Benefits of Diagnostic Analytics",
          description:
            "Diagnostic analytics, a powerful tool for investigating the underlying causes of events, offers numerous benefits across various industries. Here are some key advantages:",
          details: [
            "Deeper Insights: It provides more granular insights by drilling down into data, allowing businesses to understand the underlying causes of trends and anomalies.",
            "Hypothesis Testing: It enables the formation and testing of hypotheses using historical data, helping businesses validate their assumptions with hard evidence.",
            "Anomaly Detection: Diagnostic analytics can detect anomalies and potential problems early, allowing for proactive measures to address them.",
            "Risk Mitigation: Proactive measures mitigate risks, risk assessment identifies them, and crisis management offers guidance for handling unexpected events.",
            "Customer Satisfaction: Personalized experiences meet individual needs, improved service addresses concerns, and increased loyalty strengthens customer relationships.",
          ],
          image: AI11,
        },
      ],
      additional: [
        {
          text: "Applications of Diagnostic Analytics in Various Industries",
          description:
            "Diagnostic analytics is a powerful tool used to investigate why events occurred, providing deeper insights beyond the surface-level understanding offered by descriptive analytics.",
          details: [
            "Finance: Financial institutions use diagnostic analytics to detect fraud, understand market trends, and analyze the reasons behind financial performance fluctuations.",
            "Healthcare: Diagnostic analytics helps identify the causes of patient symptoms, track disease outbreaks, and improve treatment plans by analyzing patient data and medical histories.",
            "Manufacturing: In manufacturing, diagnostic analytics is used to determine the causes of production issues, improve quality control, and predict equipment failures.",
            "IT: IT departments apply diagnostic analytics to diagnose technical problems, improve system performance, and enhance cybersecurity measures.",
            "Marketing: Marketers use diagnostic analytics to analyze campaign performance, understand customer engagement, and identify factors driving website traffic.",
          ],
          image: AI12,
        },
      ],
      newSectionTitle: "Implementing Diagnostic Analytics",
      newSectionDescription:
        "Implementing diagnostic analytics involves several key steps to uncover the root causes of trends and anomalies in your data",
      newSection: [
        {
          title: "Define Objectives",
          description:
            "Clearly outline the problem or opportunity you aim to address. This helps in focusing the analysis and setting measurable goals.",
        },
        {
          title: "Data Collection and Preparation",
          description:
            "Gather relevant data from various sources such as CRM systems, sales data, website analytics tools, and social media platforms.",
        },

        {
          title: "Choose the Right Tools",
          description:
            "Select appropriate analytics tools that fit your needs. These can range from statistical software like R and Python to specialized diagnostic analytics platforms.",
        },
        {
          title: "Hypothesis Testing",
          description:
            "Formulate hypotheses about potential causes of observed trends or issues. Use statistical methods to test these hypotheses and validate your assumptions",
        },
        {
          title: " Data Analysis",
          description:
            "Apply techniques such as regression analysis, data mining, and correlation analysis to identify the root causes of trends and anomalies.",
        },
        {
          title: "Interpretation and Insights",
          description:
            "Analyze the results to draw meaningful insights and conclusions. This step involves understanding the relationships between variables and distinguishing between correlation and causation.",
        },
        {
          title: "Actionable Recommendations",
          description:
            "Based on the insights gained, develop actionable recommendations to address the identified issues or capitalize on opportunities.",
        },
        {
          title: "Monitor and Refine",
          description:
            "Continuously monitor the outcomes of implemented actions and refine the analysis process as needed to ensure ongoing improvement.",
        },
      ],
    },
    highlightedPoints: [],
  },
  prescriptiveanalytics: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard21})`,
    title: "Prescriptive Analytics ",
    subtitle:
      "Empowering Decision-Making: Leveraging Prescriptive Analytics for Optimal Actions and Outcomes.",
    extraContent: {
      content1:
        "Prescriptive analytics is an advanced form of data analytics that goes beyond predicting future outcomes to recommend specific actions to achieve desired results¹². It combines data, algorithms, and machine learning to provide actionable insights and optimal decision-making strategies.",
      heading: "Key Components of Prescriptive Analytics",
      details: [
        "Data Collection: Gathering relevant data from various sources.",
        "Predictive Modeling: Using predictive analytics to forecast future outcomes.",
        "Optimization Algorithms: Applying algorithms to determine the best course of action based on predictions.",
        "Simulation: Testing different scenarios to evaluate potential outcomes and refine recommendations.",
        "Decision Support Systems: Integrating recommendations into business processes for real-time decision-making.",
      ],
      image: AI13,
      sections: [
        {
          text: "Key Benefits of Prescriptive Analytics",
          description:
            "Prescriptive analytics, a sophisticated form of data analysis, goes beyond prediction by recommending optimal actions based on data-driven insights. Here are its key benefits:",
          details: [
            "Optimized Decision Making: Provides optimal recommendations, enhances efficiency, and reduces decision-making errors, leading to improved outcomes.",
            "Increased Profitability: Helps optimize revenue by identifying opportunities to increase revenue and reduce costs, allocating resources effectively, and developing effective pricing strategies.",
            "Improved Customer Experience: Enhances customer experience by providing personalized recommendations, offering proactive support, and delivering superior customer experiences, leading to increased satisfaction and loyalty.",
            "Risk Mitigation: Helps mitigate risks by proactively identifying and addressing potential threats, developing effective crisis management plans, and ensuring compliance with regulations.",
            "Competitive Advantage: Drives innovation, gains a competitive edge, and mitigates risks by identifying new opportunities, making data-driven decisions, and proactively addressing potential threats. ",
          ],
          image: AI14,
        },
      ],
      additional: [
        {
          text: "Applications of Prescriptive Analytics in Various Industries",
          description:
            "Prescriptive analytics is widely used in industries such as healthcare, finance, manufacturing, and logistics to optimize operations, reduce risks, and improve overall efficiency.",
          details: [
            "Healthcare: Prescriptive analytics in healthcare recommends personalized treatment plans and optimizes resource allocation to improve patient care and reduce costs.",
            "Finance: Prescriptive analytics in finance identifies fraudulent activities and provides strategies to mitigate financial risks and optimize investment portfolios.",
            "Supply Chain and Logistics: Prescriptive analytics in supply chain management optimizes inventory levels and recommends efficient routes to reduce costs and improve efficiency.",
            "Marketing: Prescriptive analytics in marketing recommends targeted marketing strategies and optimizes campaigns to maximize ROI and customer engagement.",
            "Energy: Prescriptive analytics in energy management predicts energy demand and recommends strategies to balance supply and demand, optimizing resource utilization.",
          ],
          image: AI15,
        },
      ],
      newSectionTitle: "Implementing Prescriptive Analytics",
      newSectionDescription:
        "Prescriptive analytics, the most advanced form of data analysis, goes beyond prediction by recommending optimal actions based on data-driven insights. Here's a step-by-step guide to implementing prescriptive analytics:",
      newSection: [
        {
          title: "Identify Business Objectives",
          description:
            "Identify business objectives involves clearly defining the goals and challenges you want to address with prescriptive analytics and understanding the potential benefits and expected outcomes.",
        },
        {
          title: "Gather and Prepare Data",
          description:
            "Data collection and preparation involves gathering relevant data from various sources, cleaning and correcting errors, and transforming the data into a suitable format for analysis.",
        },

        {
          title: "Feature Engineering",
          description:
            "Feature engineering involves selecting relevant features and creating new features to improve the predictive power of a model.",
        },
        {
          title: "Model Building and Selection",
          description:
            "Model building and selection involve choosing appropriate algorithms, training the model on historical data, evaluating its performance, and selecting the best-performing model for deployment.",
        },
        {
          title: "Prescriptive Analytics Techniques",
          description:
            "Prescriptive analytics techniques include optimization algorithms, simulation modeling, and decision support systems to find optimal solutions, test scenarios, and recommend specific actions.",
        },
        {
          title: "Model Deployment and Monitoring",
          description:
            "Model deployment and monitoring involve integrating the prescriptive analytics model into existing systems and continuously monitoring its performance to ensure accuracy.",
        },

        {
          title: "Interpretation and Action",
          description:
            "Interpretation and action involve analyzing the model's recommendations to understand their implications and taking appropriate actions to achieve desired outcomes.",
        },
      ],
    },
    highlightedPoints: [],
  },
  // -------------------------------------------------------------------------------------------------------------------------------------------
  dataInsights: {
    component: AppliedAIEngineering,

    backgroundImage: `url(${DashDashboard22})`,
    title: "Data Insights",
    subtitle:
      "Data insights refer to the deep understanding gained from analyzing data, which helps organizations make informed decisions.",
    extraContent: {
      content1:
        "Data insights refer to the actionable information extracted from data analysis that provides a deeper understanding of a particular issue or business process.",
      heading: "Key aspects of Data Insights",
      details: [
        "Data Quality: Ensuring data accuracy, completeness, and consistency is essential for deriving reliable insights.",
        " Statistical Analysis: Applying statistical methods to quantify relationships, test hypotheses, and measure the significance of findings.",
        " Machine Learning: Utilizing machine learning algorithms to build predictive models, identify patterns, and make predictions based on historical data.",
        "Visualization: Creating visual representations of data to communicate findings effectively and identify trends or anomalies.",
        "Interpretation: Translating technical insights into actionable business recommendations or strategies.",
      ],
      image: AI16,
      sections: [
        {
          text: "Key benefits of Data Insights",
          description:
            "Data insights offer a multitude of advantages for businesses and organizations. Here are some of the key benefits.",
          details: [
            "Customer Segmentation: Grouping customers based on their characteristics to tailor marketing campaigns.",
            "Fraud Detection: Identifying fraudulent activities in financial transactions.",
            "Predictive Maintenance: Predicting equipment failures to prevent downtime.",
            "Recommendation Systems: Suggesting products or services to users based on their preferences.",
            "Risk Assessment: Evaluating risk factors in various domains (e.g., credit risk, insurance).",
          ],
          image: AI17,
        },
      ],
      additional: [
        {
          text: "Applications of Data Insights in Various Industries ",
          description:
            "Data insights have become indispensable across a wide range of industries, enabling businesses to make informed decisions and gain a competitive edge.",
          details: [
            "Retail and E-commerce: Data insights can be used to personalize recommendations, segment customers, optimize inventory, and forecast demand.",
            "Healthcare: Improving patient care by analysing treatment outcomes and patient data.",
            "Finance: Analysing financial data to optimize budgeting and investment strategies.",
            "Manufacturing: Enhancing production efficiency by identifying bottlenecks and optimizing processes.",
            "Marketing and Advertising: Understanding customer behavior and preferences to tailor marketing campaigns.",
          ],
          image: AI18,
        },
      ],
      newSectionTitle: "Implementing Data Insights",
      newSectionDescription:
        "Data insights are the valuable information and knowledge extracted from data. Implementing these insights effectively can drive significant improvements in decision-making and business outcomes",
      newSection: [
        {
          title: " Problem Definition",
          description:
            "Clearly define the problem you want to solve using data.",
        },
        {
          title: "Data Acquisition",
          description: "Gather relevant data from various sources.",
        },
        {
          title: "Data Cleaning and Preparation",
          description:
            "Clean and prepare the data to make it suitable for analysis.",
        },
        {
          title: "Exploratory Data Analysis (EDA)",
          description:
            "Explore the data to understand its characteristics and identify patterns.",
        },

        {
          title: "Feature Engineering",
          description:
            "Create new features or transform existing ones to improve model performance.",
        },

        {
          title: "Model Selection and Training",
          description:
            "Choose appropriate machine learning algorithms and train them on the data.",
        },

        {
          title: "Model Evaluation",
          description:
            "Evaluate the model's performance using appropriate metrics.",
        },

        {
          title: "Deployment",
          description: "Deploy the model into a production environment.",
        },

        {
          title: "Monitoring and Maintenance",
          description:
            "Continuously monitor the model's performance and update it as needed.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Data Engineering",
        description:
          "Data engineering is the process of creating and maintaining data systems for analysis, reporting, and decision-making. It involves collecting, transforming, and storing data efficiently.",
        link: "/dataengineering",
      },
      {
        title: "Data Operations",
        description:
          "Data Operations is the management of data systems, processes, and tools. It involves tasks like data ingestion, transformation, storage, and governance to ensure data quality and accessibility.",
        link: "/operations",
      },
      {
        title: "Data Visualization",
        description:
          "Data visualization is the graphical representation of data to make it easier to understand. It uses charts and graphs to communicate complex information effectively.",
        link: "/visualization",
      },
      {
        title: "Reporting & Dashboards",
        description:
          "Reporting & Dashboards are visual representations of data that provide insights and facilitate decision-making. Reports are structured documents, while dashboards are interactive interfaces.",
        link: "/reportingAndDashboarding",
      },
    ],
  },
  // ------------------------------------------------------------------------------------------------------------------------------
  dataengineering: {
    component: DataOperations,
    backgroundImage: `url(${DashDashboard23})`,
    title: "Data Engineering",
    subtitle:
      "Involves collecting, transforming, and storing data in a way that is accessible, reliable, and efficient.",
    extraContent: {
      content1:
        "Data engineering is essential for transforming raw data into valuable insights, improving data quality, accessibility, and scalability. It supports real-time processing, cost efficiency, enhanced decision-making, and collaboration while ensuring compliance and security.",
      heading: "Key Components of Data Engineering",
      details: [
        "Data Ingestion and Transformation: Extracting, cleaning, and transforming data from various sources.",
        "Data Storage: Storing data in appropriate repositories, such as data warehouses, data lakes, or data marts.",
        "Data Pipelines: Automating the flow of data through the data infrastructure.",
        "Data Quality and Governance: Ensuring data integrity, security, and compliance with regulations.",
        "Data Visualization and Analysis: Presenting data insights through visualizations and conducting analysis to extract valuable information.",
      ],
      image: AI21,
      sections: [
        {
          text: "Key Benefits of Data Engineering",
          description:
            "Data engineering offers numerous benefits that enhance the efficiency, reliability, and value of data-driven processes. Some key benefits are:",
          details: [
            "Improved Data Quality: Data cleaning and transformation ensure data accuracy and consistency, while standardization applies consistent formats and standards for easier analysis and integration.",
            "Enhanced Data Accessibility: Centralized data repositories make data easily accessible, while APIs and data pipelines ensure seamless data flow between systems.",
            "Real-Time Data Processing: Stream processing enables real-time data analysis, while event-driven architectures support real-time applications by processing data as it arrives.",
            "Scalability: Efficiently handling large datasets supports data-driven initiatives, while elastic infrastructure optimizes resource usage by scaling up or down based on demand.",
            "Cost Efficiency: Optimized resource usage reduces costs by optimizing data storage and processing, while automation reduces manual intervention and operational costs.",
          ],
          image: AI22,
        },
      ],
      additional: [
        {
          text: "Applications of Data Engineering in Various Industries",
          description:
            "Data engineering is integral to various industries, enhancing decision-making, optimizing operations, and improving customer experiences. By leveraging data engineering, organizations can unlock the full potential of their data and drive innovation.",
          details: [
            "Finance: Enables real-time fraud detection, risk management through predictive analytics, and personalized customer experiences. ",
            "Healthcare: Facilitates patient record management, disease surveillance, and evidence-based clinical decision-making.",
            "Manufacturing and Supply Chain: Enables predictive maintenance, supply chain optimization, and real-time quality control.",
            "Energy and Utilities: Facilitates smart grid management, predictive maintenance, and energy optimization through data analysis.",
            "Education: Enables personalized learning, administrative efficiency, and predictive analytics in education.",
          ],
          image: AI32,
        },
      ],
      newSectionTitle: "Implementing Data Engineering",
      newSectionDescription:
        "Data engineering is a critical component of applied data science, laying the foundation for effective data analysis and insights. Here's a step-by-step guide to implementing data engineering:",
      newSection: [
        {
          title: "Define Objectives",
          description:
            "Clearly define the objectives and requirements of the data engineering project. Understand the data needs of the applied data science initiatives.",
        },
        {
          title: "Design Architecture",
          description:
            "Design a scalable and robust data architecture that supports data collection, storage, processing, and integration.",
        },

        {
          title: "Select Tools and Technologies",
          description:
            "Choose the appropriate tools and technologies based on your data requirements and architecture design.",
        },
        {
          title: "Develop Data Pipelines",
          description:
            "Build data pipelines to automate data ingestion, processing, and integration. Ensure pipelines are scalable and maintainable.",
        },
        {
          title: "Implement Data Quality and Governance",
          description:
            "Establish data quality checks and governance policies to ensure data integrity and compliance.",
        },
        {
          title: "Ensure Data Security",
          description:
            "Implement security measures to protect data from unauthorized access and breaches.",
        },
        {
          title: "Monitor and Optimize",
          description:
            "Continuously monitor data systems and pipelines for performance and reliability. Optimize processes to improve efficiency.",
        },
      ],
    },
    highlightedPoints: [],
  },
  // -----------------------------------------------------------------------------------------------------------------------------

  operations: {
    component: DataOperations,
    backgroundImage: `url(${DashDashboard24})`,
    title: "Data Operations",
    subtitle:
      "Modern practice that combines data management, data science, and DevOps principles to streamline the entire data lifecycle.",
    extraContent: {
      content1:
        "DataOps is a set of practices and processes aims at improving the speed, quality, and reliability of data insights. It focuses on automating data management tasks, fostering collaboration between data teams, and ensuring data is readily available and accurate.",
      heading: "Key Components of Data Operations",
      details: [
        "Data Acquisition and Ingestion: Involves identifying and connecting to various data sources, then extracting data from them efficiently.",
        "Data Cleaning and Preprocessing: Involves cleaning, transforming, imputing missing values, and engineering features to prepare data for analysis.",
        "Data Storage and Management: Involves utilizing data warehouses and data lakes for storage, and implementing data governance for quality and security.",
        "Data Modeling and Machine Learning: Involves selecting appropriate machine learning algorithms, training them on labeled data, and evaluating their performance using relevant metrics.",
        "Data Visualization: Involves using data visualization tools to create interactive visualizations and effectively communicating data insights through storytelling.",

        "Data Deployment and Monitoring:  Involves deploying trained models into production, monitoring their performance, and continuously updating them with new data to improve accuracy.",
      ],
      image: AI21,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Increased Efficiency: By identifying trends and patterns in data, businesses can optimize their operations and improve efficiency.",
            "Competitive Advantage: Gain a competitive edge by leveraging data insights to develop innovative products and services.",
            "Risk Mitigation: Identify potential risks and take proactive measures to mitigate them.",
            "Cost Reduction: Optimize resource allocation and reduce costs by identifying areas for improvement",
            "Compliance: Ensure compliance with regulations and industry standards by using data to track and monitor performance.",
          ],
          image: AI22,
        },
      ],
      additional: [
        {
          text: "Applications of Data Operations in Various Industries",
          description:
            "By leveraging data operations, these industries can enhance efficiency, improve customer satisfaction, and drive innovation, leading to better overall performance and competitive advantage.",
          details: [
            "Finance: Data operations Supports risk assessment, fraud detection, and market analysis. ",
            "Healthcare: Supports patient data management, clinical research, and predictive analytics in healthcare.",
            "Manufacturing and Supply Chain: Supports quality control, predictive maintenance, and supply chain optimization.",
            "Government: Supports informed policymaking, improved citizen services, and crime analysis.",
            "Transportation: Supports traffic management, transportation planning, and autonomous vehicle development.",
          ],
          image: AI32,
        },
      ],
      newSectionTitle: "Implementing Data Operations",
      newSectionDescription:
        "Implementing DataOps involves a comprehensive approach to managing data from collection to integration, ensuring data quality, governance, and security. ",
      newSection: [
        {
          title: "Data Collection and Ingestion",
          description:
            "Automate data ingestion using tools like Apache Kafka or NiFi, and implement real-time data processing for timely insights.",
        },
        {
          title: "Data Storage and Management",
          description:
            "Utilize data warehouses for structured data and data lakes for unstructured/semi-structured data. Employ relational and NoSQL databases based on data requirements.",
        },

        {
          title: "Data Processing and Transformation",
          description:
            "Use batch processing frameworks for large datasets and stream processing tools for real-time data. Clean and transform data using ETL tools or custom scripts.",
        },
        {
          title: "Data Integration and Orchestration",
          description:
            "Use orchestration tools to build and manage data pipelines, and develop APIs for data access and integration.",
        },
        {
          title: "Data Quality and Governance",
          description:
            "Implement automated data quality checks and establish data governance policies to ensure data integrity and security.",
        },
        {
          title: "Data Security",
          description:
            "Use encryption and access control to protect data, and continuously monitor systems for security threats.",
        },
        {
          title: "Monitor and Optimize",
          description:
            "Continuously monitor data systems and pipelines for performance and reliability. Optimize processes to improve efficiency.",
        },
      ],
    },
    highlightedPoints: [],
  },
  visualization: {
    component: Datavisualization,
    backgroundImage: `url(${DashDashboard25})`,
    title: "Data Visualization",
    subtitle:
      "Empowering Data-Driven Decision Making through Visual Storytelling.",
    extraContent: {
      content1:
        "Data visualization is the graphical representation of data. It transforms raw data into meaningful visuals that can be easily understood and interpreted. By using visual elements like charts, graphs, and maps, data visualization helps to communicate complex information effectively.",
      heading: "Key Components of Data Visualization",
      details: [
        "Data Preparation: Data cleaning removes errors and inconsistencies, transformation converts data into a suitable format, and aggregation combines data points into meaningful groups.",
        "Chart Types: Summary: Bar charts compare categories, line charts show trends, pie charts represent parts, scatter plots show relationships, histograms display distributions, and maps visualize geography.",
        "Visual Elements: Axes provide context by labeling and scaling, legends explain symbols, colors, and patterns, and annotations highlight specific data points or trends.",
        "Visualization Tools: Popular visualization tools include Tableau, Power BI, Python libraries (Matplotlib, Seaborn, Plotly), and R libraries (ggplot2, plotly).",
        "Best Practices: Keep visualizations simple, clear, consistent, relevant, and interactive for effective communication.",
      ],
      image: AI24,
      sections: [
        {
          text: "Key Benefits of Data Visualization",
          description:
            "Data visualization offers numerous benefits that enhance the understanding and utilization of data.",
          details: [
            "Enhanced Understanding: Data visualization simplifies complex data and helps identify patterns and trends that might be missed in raw data.",
            "Improved Decision-Making: Data visualization provides clear insights for informed decision-making and enables real-time analysis for timely decisions.",
            "Better Communication: Data visualization helps tell compelling stories with data and engages the audience, making it easier to communicate insights.",
            "Increased Efficiency: Data visualization allows for quick analysis and understanding of data, and tools can automate report creation, saving time and effort.",
            "Improved Data Quality: Data visualization helps identify errors in raw data and ensures data accuracy for reliable insights.",
          ],
          image: AI25,
        },
      ],
      additional: [
        {
          text: "Applications of Data Visualization in Various Industries",
          description:
            "Data visualization is widely used across various industries to enhance decision-making, improve efficiency, and communicate insights effectively.",
          details: [
            "Business Intelligence: Data visualization helps make informed decisions and monitor performance through dashboards.",
            "Healthcare: Helps healthcare providers understand patient data and track disease outbreaks.",
            "Manufacturing: Ensures product quality through real-time monitoring and optimizes supply chain operations by analyzing logistics and inventory data.",
            "Public Sector: Data visualization informs policy-making and optimizes resource management by providing clear insights into public data.",
            "E-commerce: Data visualization helps understand customer behavior and optimize sales performance through dashboards.  ",
          ],
          image: AI27,
        },
      ],
      newSectionTitle: "Implementing Data Visualization",
      newSectionDescription:
        "Implementing data visualization involves collecting and preparing data, selecting the right tools, designing and building visualizations, and ensuring their accuracy and usability.",
      newSection: [
        {
          title: "Data Collection and Preparation",
          description:
            "Data sources identify and gather data from various sources, while data cleaning and transformation ensure data quality and prepare it for visualization.",
        },
        {
          title: "Tool Selection",
          description:
            "Choose visualization tools based on your needs. Popular options include Tableau, Power BI, Google Charts, D3.js, and Grafana.",
        },

        {
          title: "Designing Visualizations",
          description:
            "Choose appropriate chart types and incorporate interactive elements to enhance user engagement. Focus on aesthetics for a visually appealing and uncluttered design.",
        },
        {
          title: "Implementing Visualizations",
          description:
            "Data integration connects visualization tools to data sources, dashboards combine multiple visualizations, and customization tailors visualizations to user needs.",
        },
        {
          title: "Testing and Validation",
          description:
            "Accuracy ensures visualizations represent data correctly, usability tests how user-friendly they are, and performance checks how well they function with large datasets.",
        },
        {
          title: "Deployment and Maintenance",
          description:
            "Deployment involves making visualizations accessible, monitoring tracks performance and usage, and updates ensure visualizations remain relevant.",
        },
      ],
    },
    highlightedPoints: [],
  },

  reportingAndDashboarding: {
    component: ReportingAndDashboarding,
    backgroundImage: `url(${DashDashboard26})`,
    title: "Reporting & Dashboards",
    subtitle:
      "Enhancing Business Intelligence with Real-Time and Historical Data",
    extraContent: {
      content1:
        "Reporting & Dashboards are essential tools for businesses to track performance, identify trends, and make data-driven decisions. They provide a visual representation of complex data, making it easier to understand and share.",
      heading: "Key Components of Reporting & Dashboards",
      details: [
        "Data Sources: The foundation of any report or dashboard is the data collected from various sources, such as databases, spreadsheets, APIs, and CRM systems.",
        "Metrics: These are the specific measurements used to track performance, such as sales, customer satisfaction, website traffic, and financial metrics.",
        "Visualizations: Charts, graphs, and other visual elements are used to present data in an easy-to-understand format. Common visualizations include line charts, bar charts, pie charts, and tables.",
        "Filters and Slicers: These allow users to drill down into specific data points and analyze different subsets of the data.",
        "Distribution: Reports and dashboards can be shared with relevant stakeholders through various channels, including email, collaboration platforms, and dedicated reporting tools.",
      ],
      image: AI28,
      sections: [
        {
          text: "Key Benefits of Reporting & Dashboards",
          description:
            "Reporting & dashboards offer numerous advantages for businesses of all sizes.",
          details: [
            "Informed Decision-Making: Both reporting and dashboards provide critical data that helps stakeholders make evidence-based decisions, enhancing strategic planning and operational efficiency.",
            "Real-Time and Historical Insights: Dashboards and reports are complementary tools. Dashboards provide real-time data visualization for monitoring KPIs, while reports offer detailed historical data for trend analysis.",
            "Enhanced Visualization and Interactivity: Dashboards present data in a visually appealing and interactive format, making complex data easier to understand and allowing users to drill down for deeper insights.",
            "Accountability and Performance Monitoring: Regular reporting tracks progress towards goals and ensures accountability, while dashboards provide real-time monitoring of KPIs, enabling continuous improvement.",
            "Efficiency and Compliance: Dashboards and reports are essential tools for organizations. Dashboards consolidate data sources into a single view, while reports provide necessary documentation to meet regulatory requirements",
          ],
          image: AI29,
        },
      ],
      additional: [
        {
          text: "Applications of Reporting & Dashboards in Various Industries",
          description:
            "Reporting & dashboards have become indispensable tools across various industries, providing valuable insights and driving data-driven decision-making.",
          details: [
            "Human Resources: HR functions focus on tracking employee performance, analyzing recruitment, measuring employee satisfaction, and identifying potential successors for key roles.",
            "Healthcare: Healthcare functions focus on tracking patient outcomes, monitoring disease prevalence, optimizing resource allocation, and analyzing clinical trial data.",
            "Manufacturing: Manufacturing functions focus on tracking production metrics, ensuring quality control, predicting equipment failures, and optimizing supply chain operations.",
            "Retail: Business functions focus on tracking sales trends, managing inventory, segmenting customers, and optimizing pricing to improve overall business performance.",
            "Marketing and Sales: Marketing functions focus on measuring campaign effectiveness, tracking customer acquisition and retention, and predicting future sales trends.",
          ],
          image: AI30,
        },
      ],
      newSectionTitle: "Implementing Reporting & Dashboards",
      newSectionDescription:
        "Implementing reporting and dashboards effectively involves several key steps and best practices. ",
      newSection: [
        {
          title: "Define Objectives and KPIs",
          description:
            "Identify the key performance indicators (KPIs) and objectives that the reports and dashboards need to track. This ensures alignment with business goals.",
        },
        {
          title: "Choose the Right Tools",
          description:
            "Select tools that fit your needs. Popular options include Tableau, Power BI, and Looker for dashboards, and Excel or Google Sheets for reporting.",
        },

        {
          title: "Data Integration",
          description:
            "Ensure the tools can integrate with your data sources. This might involve setting up data pipelines to pull data from databases, APIs, or other sources.",
        },
        {
          title: "Design and Development",
          description:
            "To design effective reports and dashboards, prioritize the end-user experience by using clear visualizations and an intuitive layout. Start by sketching out the design on paper.",
        },
        {
          title: "Testing and Validation",
          description:
            "Test the reports and dashboards with a small group of users to gather feedback and make necessary adjustments. Validate the accuracy of the data and the effectiveness of the visualizations.",
        },
        {
          title: "Deployment and Training",
          description:
            "Deploy the reports and dashboards to the intended users. Provide training sessions to ensure they understand how to use the tools effectively.",
        },
        {
          title: "Maintenance and Updates",
          description:
            "Regularly update the reports and dashboards to reflect any changes in data sources or business objectives. Monitor performance and make improvements as needed.",
        },
      ],
    },
    highlightedPoints: [],
  },

  // AI QUALITY----------------------------------------------------------------------------------------------------------------------

  modelValidation: {
    component: AIOptimization,
    backgroundImage: `url(${DashDashboard27})`,
    title: "Model Validation",
    subtitle:
      "Model validation assesses an AI model's performance on new data to ensure accuracy and reliability, safeguarding against risks and improving decision-making.",
    extraContent: {
      content1:
        "Model validation ensures AI models are accurate, robust, and fit for purpose by evaluating their performance on unseen data. This process mitigates risks, enhances decision-making, and builds trust in AI-driven systems.",
      heading: "Key aspects of Model Validation",
      details: [
        "Robustness testing: Assessing the model's ability to handle diverse and unexpected data inputs.",
        "Bias detection: Identifying and mitigating biases in the model's outputs.",
        "Performance evaluation: Measuring the model's accuracy, precision, recall, F1-score, and other relevant metrics.",
        "Cross-Validation: Testing the model on multiple data subsets to ensure consistent performance.",
        "Accuracy: Ensuring the model's predictions are correct and reliable.",
      ],
      image: AI31,
      sections: [
        {
          text: "Applications of Model Validation in Different Industries",
          description:
            "Model validation is essential across various industries to ensure the reliability and effectiveness of AI systems. Here are some key applications.",
          details: [
            "Finance: Model validation in finance ensures accuracy in creditworthiness assessment, fraud detection, and risk evaluation.",
            "Healthcare: Model validation in healthcare ensures accuracy in medical image analysis, drug discovery, and patient risk prediction.",
            "Retail: Model validation in retail ensures accurate product recommendations, customer churn prediction, and demand forecasting.",
            "Autonomous Vehicles: Model validation ensures accurate object detection, safe path planning, and reliable vehicle control in autonomous driving systems.",
            "Manufacturing: Model validation in manufacturing ensures accurate equipment failure prediction, product quality, and supply chain optimization.",
          ],
          image: AI32,
        },
      ],
      additional: [
        {
          text: "Model Validation in Business Intelligence",
          description:
            "Model validation in business intelligence (BI) is essential for ensuring the accuracy, reliability, and effectiveness of predictive models used in decision-making processes. It involves several key steps.",
          details: [
            "Data Splitting: Divide the dataset into training, validation, and testing sets to evaluate the model's performance on unseen data.",
            "Model Training: Develop the model using the training dataset.",
            "Performance Evaluation: Compute statistical values to assess the model's performance on the validation dataset.",
            "Cross-Validation: Use techniques like k-fold cross-validation to ensure the model performs consistently across different data subsets.",
            "Testing and Tuning: Model validation involves stress testing for robustness, fine-tuning parameters, and final evaluation on unseen data to ensure optimal performance and generalization.",
            "Monitoring and Feedback: Continuously monitor the model's performance and implement a feedback loop for ongoing improvements.",
          ],
          image: AI33,
        },
      ],
      newSectionTitle:
        "Implementing Modal Validation: The Foundation of AI quality",
      newSectionDescription:
        "Model validation ensures AI reliability by rigorously testing models on diverse data, identifying flaws, optimizing performance, and guaranteeing real-world effectiveness. Here are key steps to fortify your AI models.",
      newSection: [
        {
          title: "Data Preparation",
          description:
            "Creating representative training, validation, and testing datasets.",
        },
        {
          title: "Performance metrics",
          description:
            "Selecting appropriate metrics to measure model accuracy, precision, recall, and other relevant indicators.",
        },
        {
          title: "Cross-Validation",
          description:
            "Assessing model performance on different subsets of data to prevent overfitting.",
        },
        {
          title: "Error analysis",
          description:
            "Identifying patterns in model errors to improve performance.",
        },
        {
          title: "Hyperparameter tuning",
          description: "Optimizing model parameters for optimal performance.",
        },
        {
          title: "Regularization",
          description:
            "Preventing overfitting and improving model generalization.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Bench Marking(IP)",
        description:
          "enchmarking assesses AI models by comparing them to standards, revealing strengths, weaknesses, and ensuring quality.",
        link: "/benchmarking",
      },
      {
        title: "Robustness & Performance",
        description:
          "Model validation ensures AI models are robust and perform well. Robustness maintains accuracy despite data changes, while performance is measured through metrics like accuracy, precision, recall, and F1 score.",
        link: "/robustness",
      },
      {
        title: "Explainablity & Interpretability",
        description:
          "Model validation for explainability and interoperability ensures that AI models are transparent and can work seamlessly across different systems.",
        link: "/explainability",
      },
    ],
  },

  benchmarking: {
    component: BenchmarkingIP,
    backgroundImage: `url(${DashDashboard28})`,
    title: "Bench Marking (IP)",
    subtitle:
      "Helps identify the model's strengths, weaknesses, and overall effectiveness and evaluates a model's performance by comparing it to a standard.",
    extraContent: {
      content1:
        "Benchmarking involves comparing the performance of a model against a standard or reference point, which can be another model, a set of metrics, or empirical data. This process helps in identifying strengths and weaknesses, ensuring the model meets the required standards and performs well under various conditions.",
      heading: "Core functionalities of Benchmarking(IP)",
      details: [
        "Defining relevant metrics: Starts by defining what success looks like (metrics) and then setting a target to aim for (benchmarks).",
        "Model Comparison: Involves comparing different models to find the best one based on the set targets.",
        "Performance Evaluation: Helps in understanding how well the model performs by identifying its strengths, weaknesses, and how far it is from the ideal performance.",
        "Iterative Improvement: Ongoing process of refining the model based on its performance against the set standards, and then testing it again to see the improvement.",
        "Model Selection: Helps in choosing the best model for the needs by providing evidence to support the decision.",
      ],
      image: Anl10,
      sections: [
        {
          text: "Key Benefits of Benchmarking in Model Validation",
          description:
            "Benchmarking is instrumental in enhancing the quality and reliability of AI models. Its primary benefits include.",
          details: [
            "Improved Model Performance: By comparing models against established benchmarks, organizations can identify areas for improvement and optimize model performance.",
            "Enhanced Decision Making: Benchmarking provides data-driven insights to support informed decisions about model selection, deployment, and resource allocation.",
            "Risk Mitigation: By identifying model weaknesses, organizations can proactively address potential issues and mitigate risks associated with model deployment.",
            "Increased Trust and Confidence: Benchmarking helps build trust in model outputs by demonstrating their reliability and accuracy.",
            "Competitive Advantages: Organizations that excel in model benchmarking can gain a competitive edge by developing superior AI capabilities.",
          ],
          image: Anl11,
        },
      ],
      additional: [
        {
          text: "Applications of BenchMarking(IP) across Several Industries",
          description:
            " The intersection of IP benchmarking and model validation is a relatively new frontier, but its potential is vast. Two disciplines can be combined across various industries: ",
          details: [
            "Technology Industry: Software development uses IP benchmarking to find comparable models and analyze validation methods. Hardware manufacturing leverages design patents for model validation parameters and constraints.",
            "Pharmaceuticals: Drug discovery uses IP benchmarking to find and assess existing models for validation. Clinical trials leverage patent analysis for model selection and validation strategy.",
            "Financial Services: Risk modeling uses IP benchmarking to find and compare risk assessment Fraud detection leverages patent analysis to identify and benchmark fraud detection models.",
            "Retail: IP benchmarking finds suitable models for retail operations, setting performance standards and informing validation processes.",
            "Agriculture: IP benchmarking identifies agricultural models for yield prediction, disease detection, and precision farming. This sets benchmarks and guides validation efforts.",
          ],
          image: Anl12,
        },
      ],
      newSectionTitle: "Implementing BenchMarking(IP)",
      newSectionDescription:
        " Integrating IP benchmarking into model validation requires a strategic approach that combines elements from both disciplines. Here's a step-by-step process:",
      newSection: [
        {
          title: "Identify Relevant IP",
          description:
            "IP analysis involves examining patents for technical details, trademarks for market perception, and copyrights for originality of model outputs.",
        },
        {
          title: "Define Benchmarking Metrics",
          description:
            "Performance metrics measure model effectiveness based on its purpose, while IP metrics assess the strength and relevance of identified intellectual property.",
        },
        {
          title: "Conduct Benchmarking Analysis",
          description:
            "Benchmarking analysis involves comparing model performance, identifying IP gaps, and understanding competitor strengths through competitive intelligence.",
        },
        {
          title: "Incorporate Benchmarking into Validation Process",
          description:
            "Leverage benchmark models for performance comparison. Create test cases based on IP claims. Continuously refine benchmarks and validation with emerging IP.",
        },
        {
          title: "IP Protection and Strategy",
          description:
            " Identify potential new IP from model improvements, protect novel components, and explore licensing opportunities for valuable model assets.",
        },
      ],
    },
    highlightedPoints: [],
  },

  robustness: {
    component: Robustness,
    backgroundImage: `url(${DashDashboard29})`,
    title: "Robust & Performance",
    subtitle:
      "Enhancing model robustness with data augmentation and adversarial training; measuring effectiveness with accuracy.",
    extraContent: {
      content1:
        "Robust models handle diverse data, noise, and unexpected conditions through techniques like data augmentation, adversarial training, and regularization, while performance metrics like accuracy, precision, recall, F1-score, ROC curve, and AUC assess a model's effectiveness.",
      heading: "Core functionalities of Robust & Performance",
      details: [
        "Data Handling: Ability to process diverse, noisy, and incomplete data without significant performance degradation.",
        "Resilience: Resistance to adversarial attacks and other malicious inputs.",
        "Performance Evaluation: Helps in understanding how well the model performs by identifying its strengths, weaknesses, and how far it is from the ideal performance.",
        "Generalization: Applying knowledge learned from training data to unseen data.",
        "Interpretability: Understanding the model's decision-making process.",
      ],
      image: AI7,
      sections: [
        {
          text: "Key Benefits of Robust & Performance",
          description:
            "Benchmarking is instrumental in enhancing the quality and reliability of AI models. Its primary benefits include.",
          details: [
            "Increased reliability: Models can handle unexpected data variations and outliers.",
            "Reduced maintainence: The model is less likely to require frequent updates due to changing conditions.",
            "Accurate predictions: Models generate precise and reliable outputs.",
            "Efficient Operations: Models process data quickly and efficiently.",
            "Improved Decision Making: Faster and more accurate results enhance user satisfaction.",
          ],
          image: AI8,
        },
      ],
      additional: [
        {
          text: "Applications of Robust & Performance across Several Industries",
          description:
            "Robustness and performance are essential for AI success. Applications span healthcare, finance, automotive, retail, and manufacturing, requiring models to handle diverse conditions and deliver accurate predictions.",
          details: [
            "Healthcare: Robust models enhance diagnostic accuracy in medical image analysis. High performance accelerates drug discovery by processing diverse chemical structures.",
            "Finance: Robust models identify new fraud patterns to minimize financial losses, while accurate risk assessment models handle economic fluctuations for better investment decisions.",
            "Automotive: Robust models enhance safety in autonomous vehicles by handling diverse conditions, while accurate performance optimizes maintenance schedules in predictive maintenance.",
            "Retail: Robust recommendation systems adapt to changing preferences for increased sales, while accurate demand forecasting optimizes inventory management.",
            "Manufacturing: Robust models enhance quality control by handling diverse conditions. Accurate predictive maintenance optimizes schedules based on varying environments.",
          ],
          image: AI9,
        },
      ],
      newSectionTitle: "Implementing Robust & Performance",
      newSectionDescription:
        "Achieving robust and high-performing models requires a systematic approach. Here are key steps:",
      newSection: [
        {
          title: "Data Preparation",
          description:
            "Data quality is essential for model performance. Data augmentation and feature engineering enhance dataset diversity and model understanding.",
        },
        {
          title: "Model Development",
          description:
            "Model development involves algorithm selection and hyperparameter tuning. Regularization prevents overfitting, while ensemble methods enhance robustness.",
        },
        {
          title: "Model Validation",
          description:
            "Model validation assesses performance across different data subsets using appropriate metrics. Robustness and adversarial testing ensure model reliability under various conditions.",
        },
        {
          title: "Deployment and Monitoring",
          description:
            "Continuous monitoring tracks model performance, retraining updates models with new data, and error analysis identifies and addresses model shortcomings.",
        },
        {
          title: "Additional Considerations",
          description:
            "Balancing robustness and performance is crucial. Model interpretability enhances trust and aids debugging. Ensuring fairness and avoiding biases is essential for ethical model development.",
        },
      ],
    },
    highlightedPoints: [],
  },
  explainability: {
    component: Explainability,
    backgroundImage: `url(${DashDashboard30})`,
    title: "Explainability & Interoperability ",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Explainability in model validation focuses on understanding model decisions, building trust, and identifying biases. Interoperability ensures seamless collaboration between models and systems through effective data exchange and compatibility.",
      heading: "Core characteristics of Explainability and Interoperability",
      details: [
        "Transparency: Clear understanding of how and why a model makes specific decisions.",
        "Bias Detection: Identifying and mitigating biases within the model to ensure fairness.",
        "Accountability: Enabling stakeholders to hold models accountable for their decisions.",
        "Scalability: Allowing systems to grow and adapt without losing the ability to collaborate effectively.",
        "Compatibility: Ensuring models and systems can work together seamlessly. ",
        "Standardization: Adhering to common standards and protocols to ensure smooth integration.",
      ],
      image: explainability,
      sections: [
        {
          text: "Key Advantages of Explainability and Interoperability",
          description:
            "Explainability builds trust, detects bias, and improves model development. Interoperability enhances efficiency, scalability, and collaboration in AI systems.",
          details: [
            "Regulatory Compliance: Explainability ensures compliance with regulations like GDPR and HIPAA. ",
            "Business Insights: Explainability provides valuable business insights, enabling data-driven decisions.",
            "Model Improvement: Explainability facilitates model improvement and refinement.",
            "Seamless Integration: Interoperability enables seamless integration of AI systems and data sources.",
            "Innovation: Interoperability drives innovation by enabling the combination of different AI technologies.",
            "Cost Savings: Interoperability reduces costs by avoiding proprietary lock-in and enabling reuse of AI components.",
          ],
          image: explainability1,
        },
      ],
      additional: [
        {
          text: "Explainability and Interoperability: Applications in a Range of Industries",
          description:
            "Explainability and interoperability are essential for the successful adoption of AI in various sectors. These principles enable trust, collaboration, and effective decision-making.",
          details: [
            "Healthcare: Enhances trust in AI-driven diagnoses by providing clear reasons behind medical recommendations, aiding in patient and doctor acceptance.",
            "Finance: Helps in understanding credit scoring models, ensuring transparency in loan approvals and detecting biases in financial decisions.",
            "Legal: Assists in explaining AI-based legal predictions and decisions, promoting fairness and accountability in judicial processes.",
            "Telecommunications: Facilitates effective data exchange between various communication networks, improving service quality and coverage.",
            "Smart Cities: Enables different urban systems (e.g., traffic management, public safety) to work together, creating more efficient and responsive urban environments.",
          ],
          image: explainability2,
        },
      ],
      newSectionTitle: "Implementing Explainability & Interoperability",
      newSectionDescription:
        "Explainability and interoperability are crucial for building trustworthy and effective AI systems. Effective implementation involves a strategic approach that considers various factors.",
      newSection: [
        {
          title: "Model Documentation",
          description:
            "Thoroughly document model architecture, training data, and decision-making processes to provide transparency.",
        },
        {
          title: "Visualization Tools",
          description:
            "Use tools like SHAP (SHapley Additive exPlanations) and LIME (Local Interpretable Model-agnostic Explanations) to visualize and explain model predictions.",
        },
        {
          title: "User-friendly Interfaces",
          description:
            "Develop interfaces that present model decisions in an understandable way for non-technical stakeholders.",
        },
        {
          title: "Bias Audits",
          description:
            "Regularly conduct audits to identify and mitigate biases in the model, ensuring fairness and ethical use.",
        },
        {
          title: "Standard Protocols",
          description:
            "Adopt common standards and protocols (e.g., REST APIs, JSON) to ensure seamless data exchange between systems.",
        },

        {
          title: "Modular Design",
          description:
            "Design systems with modularity in mind, allowing different components to interact and integrate easily.",
        },

        {
          title: "Middleware Solutions",
          description:
            "Use middleware to facilitate communication and data exchange between disparate systems.",
        },

        {
          title: "Scalability Planning",
          description:
            "Ensure that systems are designed to scale while maintaining interoperability, allowing for future growth and integration.",
        },
      ],
    },
    highlightedPoints: [],
  },

  // ------------------------------------------------------------------------------------------------

  modelReproducibility: {
    component: AIOptimization,
    backgroundImage: `url(${DashDashboard31})`,
    title: "Model Reproducibility  ",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Low Code Application Development enables rapid application creation with minimal hand-coding, utilizing visual development tools and pre-built components to streamline the process.",
      heading: "Key Benefits of Low Code Application Development",
      details: [
        "Faster Development: Quickly build and deploy applications, reducing time-to-market.",
        "Reduced Costs: Lower development and maintenance costs by minimizing the need for extensive coding.",
        "Enhanced Flexibility: Easily adapt to changing business needs with customizable solutions.",
        "Empowered Business Users: Enable non-technical users to create and modify applications.",
        "Improved Collaboration: Foster better alignment between business and IT teams.",
      ],
      image: AI3,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Internal Process Automation: Automate workflows and approval processes to reduce manual effort.",
            "Customer Engagement: Develop custom applications for gathering and analyzing customer feedback.",
            "Custom CRM Systems: Create tailored CRM solutions to manage customer interactions and sales pipelines.",
            "Data Management Solutions: Build dashboards and data visualization tools to generate insights.",
            "Integration with Modern Technologies: Integrate AI, ML, and automation tools to create intelligent applications.",
          ],
          image: AI5,
        },
      ],
      additional: [
        {
          text: "Harnessing Low Code Development for Business Success",
          description:
            "Low Code Application Development is crucial for businesses seeking to accelerate innovation, reduce costs, and improve agility.",
          details: [
            "Rapid Prototyping: Quickly develop prototypes to validate ideas and gather feedback.",
            "Lower Technical Barriers: Enable non-technical users to participate in application development.",
            "Simplified Maintenance: Easily update and maintain applications due to the visual development process.",
            "Scalable Solutions: Handle growing user numbers and data volumes with scalable low-code platforms.",
            "Enhanced User Experience: Create intuitive, user-friendly interfaces with responsive design.",
          ],
          image: AI8,
        },
      ],
      newSectionTitle: "Implementing Low Code Application Development",
      newSectionDescription:
        "Our approach to implementing Low Code Application Development ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your low-code applications run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your low-code applications.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Low Code Application Development.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of low-code solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Model Catalog",
        description:
          "Quickly build and deploy applications, reducing time-to-market.",
        link: "/modelcatalog",
      },
      {
        title: "Model meta data management",
        description:
          "Lower development and maintenance costs by minimizing the need for extensive coding.",
        link: "/modelmatadatamanagement",
      },
      {
        title: "Defence prediction,tezt metrics &reporting",
        description:
          "Easily adapt to changing business needs with customizable solutions.",
        link: "/defencePredictiontestmetricsreporting",
      },
    ],
  },

  modelcatalog: {
    component: AIDeiven,
    backgroundImage: `url(${DashDashboard32})`,
    title: "Modal Catalog",
    subtitle:
      "Model Catalog is a central repository storing detailed model metadata. It supports model reproducibility and management.",
    extraContent: {
      content1:
        "Model catalog is centralized repository that stores detailed metadata about different model versions, including their training parameters, evaluation metrics, and deployment environments. It essentially serves as a catalog of model information, accessible through a user interface or API ",
      heading: "Key Components of Model Catalog",
      details: [
        "Model metadata: Captures comprehensive details about each model version, including name, training data, hyperparameters, evaluation results, deployment environment, and creation information.",
        "Model Artifacts: Access to the actual model files, code, and data used for training and evaluation.",
        "Experiment Tracking: Integration with experiment tracking tools to record detailed information about model development and testing processes.",
        "Model Registry: A mechanism for registering and managing model versions, including version control and deployment status.",
        "User Interface: A user-friendly interface for browsing, searching, and accessing model information.",
      ],
      image: Anl11,
      sections: [
        {
          text: "Key Benefits of a Modal Catalog ",
          description:
            "Building and maintaining a comprehensive Model Catalog presents several challenges",
          details: [
            "Improved Model Governance: Centralized management of model artifacts and metadata. ",
            "Enhanced Collaboration: Facilitated sharing of models and knowledge among teams.",
            "Accelerated Model Deployment: Streamlined process for deploying models into production",
            "Increased Model Trustworthiness: Comprehensive documentation and version control build trust in models.",
            "Facilitated Model Auditing: Easy access to model information for compliance and regulatory purposes. ",
          ],
          image: Anl12,
        },
      ],
      additional: [
        {
          text: "Applications of Modal Catalog in Various Industries",
          description:
            "A modal catalog can be a powerful tool for managing and leveraging AI models across different industries. Here are some potential applications:",
          details: [
            "Finance: Centralizes risk models, fraud detection models, and trading models, enabling efficient management, comparison, and selection.",
            "Healthcare: Centralizes models for drug discovery, medical imaging, and patient risk, aiding research and treatment.",
            "Manufacturing: Centralizes predictive maintenance, quality control, and supply chain optimization models for improved efficiency and productivity.",
            "Marketing: Centralizes models for customer understanding, retention, and acquisition optimization.",
            "Retail: Centralizes recommendation, demand forecasting, and customer segmentation models for improved marketing and sales strategies.",
          ],
          image: Anl13,
        },
      ],
      newSectionTitle: "Implementing Model Catalog",
      newSectionDescription:
        "Implementing a model catalog involves defining metadata standards, selecting a platform, integrating with ML pipelines, and ensuring secure access. This central repository streamlines model management and improves reproducibility.",
      newSection: [
        {
          title: "Define Metadata Standards",
          description:
            "Establish clear standards for the metadata to be stored, including training parameters, evaluation metrics, deployment environments, and version history.",
        },
        {
          title: "Choose a Platform",
          description:
            "Select a platform or tool that supports model cataloging, such as MLflow, ModelDB, or custom-built solutions. Ensure it integrates well with your existing infrastructure.",
        },

        {
          title: "Data Ingestion",
          description:
            "Develop processes for automatically capturing and storing metadata from different stages of the model lifecycle, including training, validation, and deployment.",
        },
        {
          title: "User Interface and API",
          description:
            "Create a user-friendly interface and API to allow easy access and management of the model catalog. This should support searching, filtering, and retrieving model information.",
        },
        {
          title: "Version Control",
          description:
            "Implement robust version control mechanisms to track changes and updates to models, ensuring that previous versions can be easily accessed and compared.",
        },
        {
          title: "Security and Access Control",
          description:
            "Ensure that the model catalog is secure and that access is controlled based on user roles and permissions. This protects sensitive information and maintains data integrity.",
        },
        {
          title: "Integration with CI/CD Pipelines",
          description:
            "Integrate the model catalog with continuous integration and continuous deployment (CI/CD) pipelines to automate the updating and deployment of models.",
        },
        {
          title: "Monitoring and Maintenance",
          description:
            "Regularly monitor the model catalog for performance and accuracy. Update and maintain the catalog to ensure it remains current and relevant.",
        },
      ],
    },
    highlightedPoints: [],
  },

  modelmatadatamanagement: {
    component: AIDeiven,
    backgroundImage: `url(${DashDashboard33})`,
    title: "Model Metadata Management",
    subtitle:
      "Model metadata management is the systematic process of collecting, organizing, and maintaining information about AI models throughout their lifecycle.",
    extraContent: {
      content1:
        "Model metadata management involves systematically organizing and maintaining detailed information about AI models. This ensures transparency, reproducibility, and efficient model lifecycle management.",
      heading: "Key Components of Effective Model Metadata Management",
      details: [
        "Essential Metadata Elements: Model metadata captures essential details like model name, version, training data, hyperparameters, evaluation metrics, deployment environment, and lineage information.",
        "Metadata Standards and Governance: Model metadata management requires consistent definitions, clear governance, and regular quality checks.",
        "Metadata Capture and Storage: Automated tools capture metadata during development. This information is stored in a centralized, searchable repository for efficient management.",
        "Metadata Accessibility: A user interface enables browsing and searching metadata, while API integration provides programmatic access to model information.",
        "Metadata Lifecycle Management: Metadata management includes mechanisms for updating information as models evolve and establishing clear retention policies for storage and deletion.",
      ],
      image: Anl11,
      sections: [
        {
          text: "Key Benefits of a Model Metadata Management",
          description:
            "A robust model metadata management system offers several advantages",
          details: [
            "Improved Reproducibility: Comprehensive model metadata ensures reproducibility by enabling consistent model replication for validation and verification.",
            "Efficient Version Control: Model metadata tracks changes and updates, enabling comparison of different versions and understanding model evolution.",
            "Simplified Debugging: Helps in identifying and resolving issues by providing detailed insights into model parameters and training conditions.",
            "Better Collaboration: Centralized model metadata improves collaboration by providing easy access and sharing of model information.",
            "Optimized Deployment: Detailed model metadata ensures optimal deployment by identifying suitable environments and requirements.",
          ],
          image: Anl12,
        },
      ],
      additional: [
        {
          text: "Applications of Model Metadata Management Across Industries",
          description:
            " Model metadata management is crucial for various industries, enabling efficient model development, deployment, and governance.",
          details: [
            "Finance: Supports risk assessment by storing model information and aids in fraud detection by centralizing metadata for quick updates.",
            "Healthcare: Centralizes clinical trial data for reproducibility and supports accurate patient diagnoses through detailed model records.",
            "Manufacturing: Supports quality control by managing model information and aids in predictive maintenance by centralizing model data for efficient monitoring.",
            "Telecommunications: Model metadata centralizes information for efficient network management. It supports optimization and improves service quality.",
            "Retail: Model metadata centralizes customer segmentation and recommendation models for personalized marketing. It also centralizes demand forecasting models for accurate inventory management.",
          ],
          image: Anl13,
        },
      ],
      newSectionTitle: "Implementing Model Metadata Management",
      newSectionDescription:
        "Model metadata management involves defining a standardized schema, selecting a repository, and integrating with the model lifecycle. Key considerations include data quality, access controls, and version control.",
      newSection: [
        {
          title: "Define Metadata Standards",
          description:
            "Establish clear standards for the types of metadata to be collected, such as training parameters, evaluation metrics, model versions, and deployment environments.",
        },
        {
          title: "Centralized Repository",
          description:
            "Set up a centralized repository to store and manage metadata. This can be a dedicated model catalog or a database integrated with your AI infrastructure.",
        },

        {
          title: "Automated Data Capture",
          description:
            "Implement automated processes to capture metadata during different stages of the model lifecycle, including training, validation, and deployment.",
        },
        {
          title: "User Interface and API",
          description:
            "Develop a user-friendly interface and API to facilitate easy access and management of the metadata. This should support searching, filtering, and retrieving model information.",
        },
        {
          title: "Version Control",
          description:
            "Maintain robust version control to track changes and updates to models, ensuring that previous versions can be easily accessed and compared.",
        },
        {
          title: "Security and Access Control",
          description:
            "Ensure that the metadata repository is secure and that access is controlled based on user roles and permissions. This protects sensitive information and maintains data integrity.",
        },
        {
          title: "Integration with Tools",
          description:
            " Integrate metadata management with other tools and platforms used in the AI workflow, such as CI/CD pipelines, monitoring systems, and data management tools.",
        },
        {
          title: "Regular Audits",
          description:
            "Conduct regular audits to ensure the accuracy and completeness of the metadata. This helps in maintaining the integrity and reliability of the metadata repository.",
        },
      ],
    },
    highlightedPoints: [],
  },

  defencePredictiontestmetricsreporting: {
    component: AIDeiven,
    backgroundImage: `url(${DashDashboard34})`,
    title: "Defect predictions, Test metrics & Reporting",
    subtitle:
      "Leveraging Defect Prediction, Test Metrics, and Reporting for AI Model Excellence.",
    extraContent: {
      content1:
        "Defect prediction uses machine learning to identify potential defects in software. Test metrics measure testing quality and effectiveness. Effective reporting ensures transparency and accountability in AI development.",
      heading:
        "Significant Components of Defect predictions, Test metrics & Reporting",
      details: [
        "Defect Prediction: Analyze historical data and use machine learning to predict defect likelihood based on code features.",
        "Test Metrics: Measure code coverage, defect density, test case efficiency, and test suite effectiveness for comprehensive quality assessment.",
        "Reporting: Use dashboards for clear metric visualization, analyze trends for early issue detection, and make data-driven recommendations for improvement.",
        "Integration and Collaboration: Foster collaboration between development and testing teams to ensure quality. Integrate defect prediction, test metrics, and reporting for a view of model quality.",

        "Continuous Improvement: Continuously monitor and evaluate defect prediction and testing strategies, then refine processes and tools based on feedback and emerging technologies.",
      ],
      image: Anl16,
      sections: [
        {
          text: "Key Benefits of Implementing Defect Predictions, Test Metrics, and Reporting",
          description:
            "Implementing defect predictions, test metrics, and reporting offers several key benefits that enhance the quality and reliability of AI models:",
          details: [
            "Early Detection of Issues: Defect prediction models help identify potential issues early, reducing debugging time and improving efficiency.",
            "Improved Quality Assurance: Test metrics ensure comprehensive testing and provide an objective way to evaluate testing effectiveness.",
            "Enhanced Reproducibility: Detailed reporting ensures consistent results and transparency, making it easier to reproduce and verify outcomes.",
            "Resource Optimization: Defect prediction helps allocate resources efficiently and reduce costs by identifying and addressing issues early.",
            "Informed Decision-Making: Metrics and reports provide data-driven insights and facilitate stakeholder communication.",
          ],
          image: Anl17,
        },
      ],
      additional: [
        {
          text: "Transformative Applications of Defect Predictions, Test Metrics, and Reporting Across Industries",
          description:
            "By leveraging defect predictions, test metrics, and reporting, industries can significantly enhance the quality, reliability, and performance of their products and services, leading to better outcomes and higher customer satisfaction.",
          details: [
            "Software Development: Defect prediction helps identify potential bugs early, test metrics ensure comprehensive testing, and reporting provides transparency and facilitates improvement.",
            "Healthcare: Defect prediction helps identify potential errors in medical software and devices, test metrics measure their effectiveness, and reporting provides data for regulatory compliance.",
            "Automotive Industry: Defect prediction helps identify potential defects in automotive systems, test metrics assess their robustness, and reporting ensures compliance and product reliability.",
            "Finance: Defect prediction helps identify vulnerabilities in financial software, test metrics evaluate their performance, and reporting ensures compliance and risk management.",
            "Telecommunications: Defect prediction helps identify network issues, test metrics measure the effectiveness of network testing, and reporting ensures service improvement and compliance.",
          ],
          image: Anl1,
        },
      ],
      newSectionTitle:
        "Implementing Defect predictions, Test metrics & Reporting",
      newSectionDescription:
        "Implementing defect predictions, test metrics, and reporting involves several steps to ensure a robust and effective process.",
      newSection: [
        {
          title: "Data Collection",
          description:
            "Gather and preprocess data for defect prediction and test metrics.",
        },
        {
          title: "Model Training and Evaluation",
          description: "Train and evaluate defect prediction models.",
        },

        {
          title: "Metric Collection",
          description: "Continuously collect and visualize test metrics.",
        },
        {
          title: "Automated Reporting",
          description:
            "Generate and distribute reports automatically, including defect predictions, test metrics, and reproducibility documentation.",
        },
        {
          title: "Review and Feedback",
          description:
            "Regularly review metrics and reports with stakeholders to ensure quality and reproducibility.",
        },
      ],
    },
    highlightedPoints: [],
  },
  // AI ASSURANCE--------------------------------------------------------------------------------------------------------------------------------------------------

  modalsecurity: {
    component: AIOptimization,
    backgroundImage: `url(${DashDashboard35})`,
    title: "Modal Security",
    subtitle:
      "Modal security is a critical aspect of AI Assurance, focusing on protecting AI models from various threats and vulnerabilities.",
    extraContent: {
      content1:
        "Modal Security is a critical component of AI assurance. It focuses on protecting AI models from vulnerabilities, attacks, and unauthorized access. This involves a comprehensive approach to ensure the integrity, confidentiality, and availability of AI models throughout their lifecycle.",
      heading: "Key Benefits of Low Code Application Development",
      details: [
        "Robustness: Ensuring models can withstand adversarial attacks and function correctly under different conditions.",
        "Data Integrity: Protecting the data used for training and inference from tampering and unauthorized access.",
        "Access Control: Implementing strict access controls to prevent unauthorized use or modification of AI models.",
        "Monitoring: Continuously monitoring models for unusual behavior or performance degradation.",
        "Regular Updates: Keeping models and their underlying systems up-to-date with the latest security patches and improvements.",
      ],
      image: AI1,
      sections: [
        {
          text: "Application of Model Security across numerous sectors",
          description:
            "Model security is crucial for various industries due to the increasing reliance on AI. Here are some key applications.",
          details: [
            "Finance: Model security in finance safeguards against fraudulent activities, market manipulation, and biased credit scoring by protecting AI models and sensitive data.",
            "Healthcare: Model security in healthcare protects patient privacy, ensures accurate diagnoses, safeguards drug research, and secures wearable devices from cyber threats.",
            "Automotive: Model security in automotive ensures vehicle safety by preventing cyberattacks and adversarial manipulations that could compromise autonomous driving systems.",
            "Cyber Security: Model security in cybersecurity protects AI-powered security systems from attacks and safeguards sensitive data during incidents to prevent data loss.",
            "Manufacturing: Model security in manufacturing protects intellectual property in maintenance models and ensures supply chain integrity by preventing data breaches.",
          ],
          image: AI32,
        },
      ],
      additional: [
        {
          text: "Model Security: A Cornerstone of AI Assurance for Business Intelligence",
          description:
            " Modal security is vital for protecting AI models used in business intelligence. It safeguards sensitive data, prevents attacks, and ensures the reliability of AI-driven insights.",
          details: [
            "Protect Intellectual Property: Safeguard valuable AI models from theft and unauthorized access.",
            "Maintain Data Privacy: Prevent unauthorized disclosure of sensitive customer information.",
            "Enhance Decision Making: Ensure the reliability and accuracy of AI-generated insights.",
            "Build Trust: Demonstrate a commitment to data security and responsible AI practices.",
            "Mitigate Financial Loss: Prevent revenue loss and reputational damage due to data breaches.",
          ],
          image: AI23,
        },
      ],
      newSectionTitle:
        "Implementing Modal Security: A Cornerstone of AI Assurance",
      newSectionDescription:
        "Implementing robust modal security is essential for protecting AI systems and the valuable insights they generate. Here are key steps to fortify your AI models:",
      newSection: [
        {
          title: "Data Protection",
          description:
            " Effective data management safeguards sensitive information, ensures data accuracy, and anonymizes data to protect privacy and prevent bias in AI systems.",
        },
        {
          title: "Model Development and Training",
          description:
            "Model development requires a secure environment, rigorous testing to find weaknesses, and regular updates to keep models and software protected.",
        },
        {
          title: "Model Deployment and Monitoring",
          description:
            "Model deployment and monitoring requires secure infrastructure, constant surveillance for threats, and a detailed plan to handle security breaches.",
        },
        {
          title: "Additional Considerations",
          description:
            "Protect model integrity through watermarking, detect and prevent adversarial attacks, regularly assess security, and train employees for robust model security.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "AI Driven SOC & SIEM (IP)",
        description:
          "Quickly build and deploy applications, reducing time-to-market.",
        link: "/aideivin",
      },
      {
        title: "AI Security",
        description:
          "AI security involves protecting AI systems from threats, ensuring secure development and operation, safeguarding models and data, and complying with security standards.",
        link: "/security",
      },
      {
        title: "Security for AI",
        description:
          "AI enhances security by detecting threats in real-time, analyzing data for anomalies, and automating security protocols to protect systems and data from cyberattacks.",
        link: "/securityAI",
      },
    ],
  },

  aideivin: {
    component: AIDeiven,
    backgroundImage: `url(${DashDashboard36})`,
    title: "AI Driven SOC & SIEM(IP)",
    subtitle:
      "AI-driven SOC and SIEM use artificial intelligence to improve threat detection, automate responses, and enhance overall cybersecurity.",
    extraContent: {
      content1:
        "AI-driven SOC and SIEM represent a significant leap forward in cybersecurity. By incorporating artificial intelligence and machine learning, these platforms dramatically enhance threat detection, incident response, and overall security posture.",
      heading: "Core functionalities of AI-driven SOC and SIEM",
      details: [
        "Behavioral Analytics: Analyzing user and entity behavior to detect anomalies.",
        "Machine Learning: Continuously learning from data to improve threat detection accuracy.",
        "Automation: Automating routine tasks like incident triage and response.",
        "Correlation Engine: Correlating disparate security events to identify complex threats.",
        "Threat Intelligence Integration: Incorporating external threat intelligence to enrich analysis.",
      ],
      image: AI34,
      sections: [
        {
          text: "Key benefits of AI-driven SOC and SIEM",
          description:
            "AI-driven SOC and SIEM use AI to enhance threat detection, automate responses, and improve security analyst efficiency.",
          details: [
            "Enhanced Threat Detection: AI can analyze vast datasets to identify subtle anomalies and patterns indicative of emerging threats, allowing for proactive response.",
            "Automated Incident Response: AI-powered automation can streamline incident response processes, reducing mean time to detect (MTD) and mean time to respond (MTTR).",
            "Improved Security Analyst Efficiency: By automating routine tasks, AI frees up security analysts to focus on high-value activities like threat hunting and investigation.",
            "Predictive Analytics: AI can predict potential threats based on historical data, enabling organizations to take preventive measures.",
            "Real-time Threat Intelligence: AI-driven platforms can process real-time data to provide up-to-data threat intelligence.",
          ],
          image: Anl1,
        },
      ],
      additional: [
        {
          text: "Applications of AI-Driven SOC and SIEM across Numerous Industries",
          description:
            "AI-Driven SOC and SIEM solutions offer significant benefits across various industries. Here are some key applications:",
          details: [
            "Financial Services: AI-driven SOC and SIEM in financial services helps prevent fraud, ensure regulatory compliance, and detect insider threats.",
            "Healthcare: AI-driven SOC and SIEM in healthcare protects patient data, ensures regulatory compliance, and detects medical device anomalies.",
            "Retail: AI-driven SOC and SIEM in retail protects against fraud, secures supply chains, and safeguards customer data.",
            "Manufacturing: AI-driven SOC and SIEM in manufacturing protects critical infrastructure, safeguards intellectual property, and ensures uninterrupted operations by preventing cyberattacks.",
            "Government and Public Sector: AI-driven SOC and SIEM in government protects critical infrastructure, ensures data privacy compliance, and detects insider threats.",
          ],
          image: Anl2,
        },
      ],
      newSectionTitle: "Implementing AI-Driven SOC and SIEM",
      newSectionDescription:
        "Implementing an AI-driven SOC and SIEM requires a strategic approach that involves several key steps:",
      newSection: [
        {
          title: "Data Ingestion and Normalization",
          description:
            "Data is collected from various sources, standardized for analysis, and enriched with external threat intelligence.",
        },
        {
          title: "AI Model Development and Training",
          description:
            "AI models are developed by selecting appropriate algorithms, preparing data, training on historical data, and continuously assessing performance.",
        },
        {
          title: "Integrating with Existing Security Infrastructure",
          description:
            "Integrate AI-driven platform with existing SOC and SIEM tools to automate responses and improve incident handling.",
        },
        {
          title: "Security Analyst Training",
          description:
            "Analysts require training in AI concepts, tool proficiency, and new incident response procedures to effectively utilize AI-driven SOC and SIEM.",
        },
        {
          title: "Continuous Monitoring and Improvement",
          description:
            "Continuously monitor and refine AI models based on performance metrics, updated threat intelligence, and analyst feedback.",
        },
      ],
    },
    highlightedPoints: [],
  },
  security: {
    component: AISecurity,
    backgroundImage: `url(${DashDashboard37})`,
    title: "AI Security",
    subtitle:
      "AI Security is a crucial aspect of modern technology, focusing on both AI to enhance cybersecurity and protecting AI systems from cyber threats.",
    extraContent: {
      content1:
        "AI security encompasses the measures and technologies designed to protect AI systems from unauthorized access, manipulation, and malicious attacks. It involves safeguarding the AI development process, the AI models themselves, and the data they process.",
      heading: "Core functionalities of AI Security ",
      details: [
        "Threat Detection and Prevention: AI security systems use machine learning to detect threats by analyzing network data and user behavior for abnormal patterns.",
        "Incident Response and Remediation: AI automates threat response by isolating affected systems, removing malware, and restoring operations, minimizing attack impact.",
        "Vulnerability Management: AI continuously scans for vulnerabilities, prioritizes them, and helps fix critical issues first, improving overall security.",
        "Behavioral Analysis: AI security tools detect unusual user and system behavior, flagging potential security breaches.",
        "Continuous Monitoring and Auditing: AI security systems offer real-time monitoring and response to threats, while regular audits maintain ongoing protection.",
      ],
      image: AI32,
      sections: [
        {
          text: "Key Challenges of AI Security",
          description:
            "Securing AI systems presents several unique challenges. Here are some of the key issues.",
          details: [
            "Data Privacy: AI systems often rely on vast amounts of data, raising concerns about privacy breaches and misuse.",
            "Model Poisoning: Malicious actors can manipulate training data to compromise the accuracy and reliability of AI models.",
            " Adversarial Attacks: AI systems can be deceived by carefully crafted inputs designed to mislead them.",
            " Explainability: Understanding how AI systems reach their decisions is crucial for identifying vulnerabilities and biase.",
            "Ethical Implications: AI systems can make autonomous decisions, raising ethical questions about accountability and potential harm.",
          ],
          image: Anl4,
        },
      ],
      additional: [
        {
          text: "Applications of AI-Security in Various Industries",
          description:
            "Applications demonstrate the versatility and importance of AI security across different sectors. By leveraging AI, industries can enhance their security measures, protect sensitive information, and ensure the smooth operation of their systems",
          details: [
            "Financial Services: AI in finance detects fraud by analyzing transaction patterns and assesses risks to aid financial decisions.",
            "Healthcare: AI safeguards patient data and medical devices by detecting privacy breaches and cyber threats.",
            "Telecommunication: AI protects telecommunications networks from cyber threats and prevents fraud like SIM swapping.",
            "Energy: AI safeguards critical infrastructure like smart grids and oil and gas facilities from cyberattacks.",
            "Transportation: AI protects autonomous vehicles from cyber threats and optimizes traffic flow for safer transportation.",
          ],
          image: Anl5,
        },
      ],
      newSectionTitle: "Implementing AI Security",
      newSectionDescription:
        " Implementing AI security involves a comprehensive approach that addresses various aspects of the AI lifecycle. Here are key steps:",
      newSection: [
        {
          title: "Model Security",
          description:
            "Model security requires protection from attacks and manipulation. Build models resistant to adversarial attacks, prevent data poisoning, and ensure transparency through explainability.",
        },
        {
          title: "AI System Security",
          description:
            "Implement a zero-trust approach and continuous monitoring. Develop AI-driven incident response plans.",
        },
        {
          title: "Ethical Consideration",
          description:
            "AI systems must be fair, accountable, and transparent. Eliminate biases, assign responsibility for decisions, and make AI understandable.",
        },
        {
          title: "Additional Considerations",
          description:
            "Build a skilled team, collaborate with others, and keep AI systems updated. Invest in talent development and knowledge sharing for continuous improvement.",
        },
      ],
    },
    highlightedPoints: [],
  },
  securityAI: {
    component: AISecurity,
    backgroundImage: `url(${DashDashboard3})`,
    title: "Security for AI",
    subtitle:
      "Securing AI involves protecting models, data, and infrastructure from threats like adversarial attacks and data breaches. Robust security measures are essential for safe AI deployment..",
    extraContent: {
      content1:
        "Security for AI is crucial to protect the integrity, confidentiality, and availability of AI systems and their underlying data. It involves safeguarding AI models from attacks like model poisoning, adversarial attacks, and model theft.",
      heading: "Key Areas to focus on Security for AI",
      details: [
        "Threat Detection and Prevention: AI detects threats by identifying unusual patterns, analyzing emerging threats, recognizing phishing attacks, and blocking malware.",
        "Incident Response: AI accelerates incident response by prioritizing incidents, analyzing data for investigations, and proactively hunting threats.",
        "Security Operations Centre (SOC) Optimization: AI automates routine tasks, prioritizes threats, and integrates security tools for efficient response.",
        "Identity and Access Management: AI enhances security by analyzing user behavior to detect anomalies and improves identity verification through biometrics and fraud prevention.",
        "Cloud Security: AI enhances cloud security by detecting threats and vulnerabilities, and assessing overall cloud security posture.",
      ],
      image: AI27,
      sections: [
        {
          text: "Key Challenges of Security for AI",
          description:
            "Securing AI systems presents several unique challenges. Here are some of the key issues.",
          details: [
            "Technical Challenges: AI security implementation is hindered by insufficient awareness of risks, resistance to adopting new technologies, and limited resources.",
            "Operational Challenges: AI security faces operational challenges like a shortage of skilled professionals, complex integration with existing systems, and balancing accuracy with false positives.",
            "Ethical Challenges: AI raises ethical concerns. Models can be biased, accountability for decisions is unclear, and job displacement is a potential issue.",
            "Organizational Challenges: Organizational adoption of AI security is hampered by limited awareness, resistance to change, and insufficient resources.",
            "External Challenges: The evolving threat landscape, complex regulations, and geopolitical tensions pose significant challenges to maintaining effective AI security.",
          ],
          image: AI28,
        },
      ],
      additional: [
        {
          text: "Applications of Security for AI in Various Industries",
          description:
            "Security for AI has several key applications that enhance the protection of systems and data. Here are some notable ones",
          details: [
            "Threat Detection: AI excels at identifying patterns and anomalies in vast amounts of data, making it effective for detecting potential cyber threats.",
            "Incident Response: AI can automate responses to detected threats, reducing the time and potential errors associated with human intervention.",
            "Vulnerability Management: AI helps in identifying and prioritizing vulnerabilities in systems, allowing for timely remediation.",
            "Ai-Assisted Threat Hunting: AI tools assist security analysts in proactively searching for potential threats within an organization's network.",
            "Endpoint Security: AI enhances the protection of devices like desktops, laptops, and mobile devices from malware and phishing attacks.",
          ],
          image: AI29,
        },
      ],
      newSectionTitle: "Implementing Security for AI",
      newSectionDescription:
        "Implementing robust security for AI systems requires a comprehensive approach that addresses the entire AI lifecycle. Here are key strategies.",
      newSection: [
        {
          title: "Model Security",
          description:
            "Secure the infrastructure. Protect networks, cloud platforms, and physical assets to safeguard AI systems.",
        },
        {
          title: "AI-Driven Security",
          description:
            "AI enhances threat detection and response. It identifies unusual patterns, analyzes threat intelligence, and automates incident response tasks.",
        },
        {
          title: "Governance and Risk Managment",
          description:
            "Proactive risk management, compliance, and employee awareness are essential. Identify and address potential risks, adhere to regulations, and educate staff on AI security best practices.",
        },
      ],
    },
    highlightedPoints: [],
  },

  // --------------------------------------------------------------------------------------------------

  modelgovernance: {
    component: AIOptimization,
    backgroundImage: `url(${Clouddashboard6})`,
    title: "Model Governance",
    subtitle:
      "Model governance manages the AI model lifecycle, ensuring quality, accuracy, fairness, and regulatory compliance through established policies and controls.",
    extraContent: {
      content1:
        " Model governance is the framework for managing the entire lifecycle of AI models, from development to deployment and retirement. It involves establishing policies, procedures, and controls to ensure model quality, accuracy, fairness, and compliance with regulations.",
      heading: "Core Principles of Modal Governance",

      details: [
        "Human-Centered AI: AI systems should be designed and used to augment human capabilities, not replace them.",
        "Ethical AI: AI should adhere to strong ethical principles, avoiding bias, discrimination, and harm",
        "Transparent AI: The decision-making processes of AI systems should be understandable and explainable.",
        "Robust AI: AI systems should be secure, reliable, and resilient.",
        "Accountable AI: Clear lines of responsibility should be established for AI-related decisions and outcomes.",
      ],

      image: Anl10,
      sections: [
        {
          text: "Applications of Model Governance across numerous sectors",
          description:
            "Model governance is crucial for organizations across various industries to ensure the responsible and ethical development, deployment, and use of AI. Here are some key applications.",
          details: [
            "Finance: Model governance ensures fairness and accuracy in financial services. Credit scoring is unbiased and compliant while fraud detection systems are reliable and effective.",
            "Healthcare: Model governance ensures accuracy and ethics in healthcare AI. Predictive models are precise and unbiased, while clinical decision support is transparent and accountable.",
            "Public Sector: Model governance ensures ethical and responsible AI in the public sector. Policymaking is transparent and accountable while surveillance and security protect citizens' rights.",
            "Telecommunications: Model governance optimizes operations and enhances customer experience. Network performance is improved through AI-driven optimization while customer service benefits from ethical and effective AI applications.",
            "Manufacturing: Model governance optimizes operations and quality. Predictive maintenance models are accurate and reliable, while quality control systems are consistent and unbiased.",
          ],
          image: Anl11,
        },
      ],
      additional: [
        {
          text: "Model Governance: AI Assurance's Foundation for Business Intelligence",
          description:
            "Model governance is the cornerstone of ensuring trust, reliability, and ethical use of AI in business intelligence. It encompasses a comprehensive framework for managing the entire AI lifecycle, from data acquisition to model deployment and monitoring.",
          details: [
            "Data Governance: Ensure data quality, accuracy, and privacy.",
            "Model Development: Establish rigorous development and testing processes.",
            "Model Deployment: Implement robust deployment and monitoring systems.",
            "Risk Management: Identify, assess, and mitigate potential risks.",
            "AI Ethics: Adhere to ethical principles and avoid biases.",
          ],

          image: Anl12,
        },
      ],
      newSectionTitle: "Implementing Modal Governance",
      newSectionDescription:
        "Model governance is essential for managing the lifecycle of AI models, ensuring their accuracy, fairness, and compliance. Here's a breakdown of key steps:",

      newSection: [
        {
          title: "Define Model Governance Framework",
          description:
            "A clear model governance framework involves defining roles, responsibilities, and decision-making processes for key stakeholders across the entire model lifecycle.",
        },
        {
          title: "Data Management",
          description:
            "Effective data management for AI models involves ensuring data quality, privacy, and traceability.",
        },
        {
          title: "Model Development and Testing",
          description:
            "Developing robust AI models requires establishing coding standards, conducting thorough testing, and validating for accuracy and fairness.",
        },
        {
          title: "Model Deployment and Monitoring",
          description:
            "Successful model deployment and management involve establishing clear deployment procedures, continuous performance monitoring, and ensuring model explainability to identify biases.",
        },
        {
          title: "Model Risk Management",
          description:
            "Effective model risk management involves identifying potential risks, developing mitigation strategies, and establishing incident response procedures.",
        },
        {
          title: "Model Governance Tools",
          description:
            "Model governance tools facilitate centralizing model information, monitoring performance, and understanding model decisions.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Consulting(ATS)",
        description:
          "Modal Governance Consulting (ATS) specializes in providing comprehensive AI governance solutions to ensure responsible and ethical AI deployment.",
        link: "/consulting",
      },
      {
        title: "Responsible & Trustworthy AI",
        description:
          "Responsible and trustworthy AI is a core principle guiding the development and deployment of artificial intelligence systems. It ensures that AI benefits society while minimizing potential harms.",
        link: "/responsible",
      },
      {
        title: "AI Observability",
        description:
          "AI observability is understanding AI models through monitoring, analysis, and visualization to ensure their performance, reliability, and effectiveness.",
        link: "/observability",
      },
    ],
  },

  consulting: {
    component: Consulting,
    backgroundImage: `url(${DashDashboard5})`,
    title: "Consulting(ATS)",
    subtitle:
      "Consulting(ATS) specializes in providing comprehensive consulting services to optimize AI governance and model management.",
    extraContent: {
      content1:
        "A Consulting Application Tracking System (ATS) is a powerful tool that can significantly contribute to effective model governance. By leveraging ATS data and functionalities, organizations can enhance various aspects of their AI models.",
      heading: "The Role of ATS in Model Governance",
      details: [
        "Identify and mitigate bias: Analyze candidate data to uncover potential biases in AI models.",
        "Enhance data quality: Improve the quality of training data by leveraging ATS data cleaning and standardization capabilities.",
        "Optimize model performance: Use ATS metrics to evaluate model performance and identify areas for improvement.",
        "Ensure regulatory compliance: Align model development and deployment with relevant employment laws and regulations.",
        "Continuous Improvement: ATS data fuels continuous model improvement through feedback loops and timely retraining.",
      ],
      image: Anl13,
      sections: [
        {
          text: "Benefits of Consulting ATS for Model Governance",
          description:
            "By leveraging ATS’s expertise, organizations can ensure their AI initiatives are both responsible and successful.",
          details: [
            "Improved model performance: Leveraging ATS data can enhance model accuracy and effectiveness.",
            "Enhanced decision-making: Data-driven insights from ATS can inform better business decisions.",
            "Reduced risk: Mitigating bias and ensuring compliance can reduce legal and reputational risks.",
            "Customized Solutions: They provide tailored governance frameworks that align with your organization’s specific needs and regulatory requirements.",
            "Increased efficiency: Streamlining model governance processes can save time and resources.",
          ],
          image: Anl14,
        },
      ],
      additional: [
        {
          text: "Applications of Consulting(ATS) in Various Industries",
          description:
            " Consulting ATS (Applicant Tracking System) has become increasingly crucial across various industries due to the growing complexity of talent acquisition processes. Let's explore some key applications",
          details: [
            "Technology Industry: ATS in the Tech Industry; Streamlines hiring for startups, optimizes talent pipelines for large corporations.",
            "Healthcare: Manages healthcare staffing efficiently, streamlines hiring for specialized roles in pharmaceutical companies.",
            "Financial Services: Manages high-volume applications, ensures regulatory compliance, and optimizes hiring for various financial roles.",
            "Consulting and Professional Services: Streamlines hiring for consultants and legal professionals, ensuring efficient processes and industry compliance.",
            "Manufacturing: Manages diverse talent pools, streamlines hiring for both blue-collar and skilled workers.",
          ],
          image: Anl15,
        },
      ],
      newSectionTitle: "Implementing Consulting(ATS)",
      newSectionDescription:
        "Implementing an Applicant Tracking System (ATS)with a focus on modal governance ensures that AI models used in recruitment and efficiency. Here are the key steps:",
      newSection: [
        {
          title: "Needs Assessment",
          description:
            "Evaluate your organization's specific requirements and goals for AI governance in recruitment.",
        },
        {
          title: "Vendor Selection",
          description:
            "Choose an ATS vendor that offers robust governance features and aligns with your needs.",
        },
        {
          title: "Stakeholder Engagement",
          description:
            "Involve key stakeholders to ensure buy-in and smooth implementation.",
        },
        {
          title: "Customization",
          description:
            "Tailor the ATS to fit your organization's workflows and governance policies.",
        },
        {
          title: "Data Migration",
          description:
            "Plan and execute the migration of existing data to the new system, ensuring data integrity.",
        },
        {
          title: "Training and Continuous Monitoring",
          description:
            "Provide comprehensive training for users and ongoing support to maximize the system's effectiveness. Regularly review and optimize the ATS to ensure it continues to meet governance standards and improve recruitment outcomes.",
        },
      ],
    },
    highlightedPoints: [],
  },
  responsible: {
    component: ResponsibleAndTrustworthy,
    backgroundImage: `url(${DashDashboard6})`,
    title: "Responsible and Trustworthy Code Application",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Low Code Application Development enables rapid application creation with minimal hand-coding, utilizing visual development tools and pre-built components to streamline the process.",
      heading: "Key Benefits of Low Code Application Development",
      details: [
        "Faster Development: Quickly build and deploy applications, reducing time-to-market.",
        "Reduced Costs: Lower development and maintenance costs by minimizing the need for extensive coding.",
        "Enhanced Flexibility: Easily adapt to changing business needs with customizable solutions.",
        "Empowered Business Users: Enable non-technical users to create and modify applications.",
        "Improved Collaboration: Foster better alignment between business and IT teams.",
      ],
      image: Anl16,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Internal Process Automation: Automate workflows and approval processes to reduce manual effort.",
            "Customer Engagement: Develop custom applications for gathering and analyzing customer feedback.",
            "Custom CRM Systems: Create tailored CRM solutions to manage customer interactions and sales pipelines.",
            "Data Management Solutions: Build dashboards and data visualization tools to generate insights.",
            "Integration with Modern Technologies: Integrate AI, ML, and automation tools to create intelligent applications.",
          ],
          image: Anl17,
        },
      ],
      additional: [
        {
          text: "Harnessing Low Code Development for Business Success",
          description:
            "Low Code Application Development is crucial for businesses seeking to accelerate innovation, reduce costs, and improve agility.",
          details: [
            "Rapid Prototyping: Quickly develop prototypes to validate ideas and gather feedback.",
            "Lower Technical Barriers: Enable non-technical users to participate in application development.",
            "Simplified Maintenance: Easily update and maintain applications due to the visual development process.",
            "Scalable Solutions: Handle growing user numbers and data volumes with scalable low-code platforms.",
            "Enhanced User Experience: Create intuitive, user-friendly interfaces with responsive design.",
          ],
          image: AI10,
        },
      ],
      newSectionTitle: "Implementing Low Code Application Development",
      newSectionDescription:
        "Our approach to implementing Low Code Application Development ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your low-code applications run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your low-code applications.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Low Code Application Development.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of low-code solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [],
  },
  observability: {
    component: Observability,
    backgroundImage: `url(${DashDashboard7})`,
    title: "AI Observability and Trustworthy ",
    subtitle:
      "Practice of monitoring, analyzing, and visualizing the internal states, inputs, and outputs of artificial intelligence (AI) models to understand their behavior, performance, and cost.",
    extraContent: {
      content1:
        "AI observability is the practice of monitoring, analyzing, and visualizing the internal states, inputs, and outputs of AI models embedded within applications. It provides real-time insights into the behavior and performance of AI systems, ensuring they are reliable, transparent, and accountable.",
      heading: "Key components of AI observability include",
      details: [
        "Orchestration Layer: Detailed workflow analysis and resource allocation insights.",
        "Semantic Layer: Monitoring semantic caches and vector databases.",
        "Model Layer: Tracking resource consumption, operation costs, and service-level performance metrics.",
        "Core Observability: Metrics measure system performance, logs provide detailed event records, and traces track request flows to identify bottlenecks.",
        "AI-Specific Observability: Model performance is measured, data and model changes are tracked, explainability, fairness, and cost are managed for optimal AI system.",
      ],
      image: dev1,
      sections: [
        {
          text: "Benefits of AI Observability",
          description:
            "AI observability offers numerous advantages for organizations:",
          details: [
            "Improved System Reliability: By proactively identifying and addressing issues, AI observability reduces system downtime and failures.",
            "Enhanced Performance: Optimizing resource utilization and model performance leads to increased efficiency and speed.",
            "Accelerated Problem Resolution: Rapid identification of root causes and faster resolution of issues minimizes business impact.",
            "Cost Reduction: Efficient resource allocation and reduced downtime contribute to significant cost savings.",
            "Compliance Adherence: Monitoring for bias and fairness in AI models helps ensure compliance with regulations.",
          ],
          image: dev2,
        },
      ],
      additional: [
        {
          text: "Applications of AI Observability in Various Industries",
          description:
            "AI observability is transforming industries by providing insights into the performance and behavior of AI systems. Here are some key applications:",
          details: [
            "Healthcare: Optimizing medical image analysis, drug discovery, and patient monitoring.",
            "Finance: Enhancing fraud detection, risk assessment, and algorithmic trading.",
            "Autonomous Vehicles: Ensuring vehicle safety, optimizing route planning, and preventing accidents.",
            "Customer Service: Improving chatbot performance, sentiment analysis, and customer support efficiency.",
            "Manufacturing: Improving predictive maintenance, quality control, and supply chain optimization.",
          ],
          image: dev4,
        },
      ],
      newSectionTitle: "Implementing AI Observability",
      newSectionDescription:
        "Implementing AI observability involves several key steps:",
      newSection: [
        {
          title: "Define Key Metrics",
          description:
            "Identify critical metrics for model performance, data quality, and system health. ",
        },
        {
          title: "Data Collection",
          description:
            "Gather data from various sources, including model outputs, logs, and system metrics.",
        },
        {
          title: "Visualization",
          description:
            "Create dashboards and reports to visualize metrics and trends.",
        },
        {
          title: "Anomaly Detection",
          description:
            "Implement algorithms to identify unexpected patterns in data.",
        },
        {
          title: "Root Cause Analysis",
          description:
            "Investigate anomalies to understand their impact and origin.",
        },
        {
          title: "Continuous Improvement",
          description:
            "Use insights to optimize model performance, data quality, and system efficiency.",
        },
      ],
    },
    highlightedPoints: [],
  },

  // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  optimization: {
    component: AIOptimization,
    backgroundImage: `url(${DashDashboard8})`,
    title: "Optimization ",
    subtitle:
      "Accelerate your development process with our Low Code Application solutions.",
    extraContent: {
      content1:
        "Low Code Application Development enables rapid application creation with minimal hand-coding, utilizing visual development tools and pre-built components to streamline the process.",
      heading: "Key Benefits of Low Code Application Development",
      details: [
        "Faster Development: Quickly build and deploy applications, reducing time-to-market.",
        "Reduced Costs: Lower development and maintenance costs by minimizing the need for extensive coding.",
        "Enhanced Flexibility: Easily adapt to changing business needs with customizable solutions.",
        "Empowered Business Users: Enable non-technical users to create and modify applications.",
        "Improved Collaboration: Foster better alignment between business and IT teams.",
      ],
      image: Anl12,
      sections: [
        {
          text: "Applications of Low Code Development Across Industries",
          description:
            "Low Code Application Development can be applied in various sectors to enhance efficiency and drive innovation.",
          details: [
            "Internal Process Automation: Automate workflows and approval processes to reduce manual effort.",
            "Customer Engagement: Develop custom applications for gathering and analyzing customer feedback.",
            "Custom CRM Systems: Create tailored CRM solutions to manage customer interactions and sales pipelines.",
            "Data Management Solutions: Build dashboards and data visualization tools to generate insights.",
            "Integration with Modern Technologies: Integrate AI, ML, and automation tools to create intelligent applications.",
          ],
          image: Anl13,
        },
      ],
      additional: [
        {
          text: "Harnessing Low Code Development for Business Success",
          description:
            "Low Code Application Development is crucial for businesses seeking to accelerate innovation, reduce costs, and improve agility.",
          details: [
            "Rapid Prototyping: Quickly develop prototypes to validate ideas and gather feedback.",
            "Lower Technical Barriers: Enable non-technical users to participate in application development.",
            "Simplified Maintenance: Easily update and maintain applications due to the visual development process.",
            "Scalable Solutions: Handle growing user numbers and data volumes with scalable low-code platforms.",
            "Enhanced User Experience: Create intuitive, user-friendly interfaces with responsive design.",
          ],
          image: Anl14,
        },
      ],
      newSectionTitle: "Implementing Low Code Application Development",
      newSectionDescription:
        "Our approach to implementing Low Code Application Development ensures seamless integration and optimal performance.",
      newSection: [
        {
          title: "Technical Implementation",
          description:
            "We utilize advanced low-code platforms to develop and deploy applications efficiently.",
        },
        {
          title: "IT Helpdesk Support",
          description:
            "We offer comprehensive support to ensure your low-code applications run smoothly and efficiently.",
        },
        {
          title: "Managed IT Services",
          description:
            "Our managed services include continuous monitoring and optimization of your low-code applications.",
        },
        {
          title: "IT Consulting",
          description:
            "Our experts provide strategic guidance to maximize the benefits of Low Code Application Development.",
        },
        {
          title: "Network Support",
          description:
            "We ensure seamless integration of low-code solutions with your existing IT infrastructure.",
        },
      ],
    },
    highlightedPoints: [
      {
        title: "Faster Development",
        description:
          "Quickly build and deploy applications, reducing time-to-market.",
      },
      {
        title: "Reduced Costs",
        description:
          "Lower development and maintenance costs by minimizing the need for extensive coding.",
      },
      {
        title: "Enhanced Flexibility",
        description:
          "Easily adapt to changing business needs with customizable solutions.",
      },
      {
        title: "Empowered Business Users",
        description:
          "Enable non-technical users to create and modify applications.",
      },
      {
        title: "Improved Collaboration",
        description: "Foster better alignment between business and IT teams.",
      },
    ],
  },
};
const TemplateComponent = () => {
  const { option } = useParams();
  const content = contentMap[option];

  if (!content) {
    return <div>Content not found</div>;
  }

  const {
    component: SelectedComponent,
    title,
    subtitle,
    extraContent,
    backgroundImage,
    highlightedPoints,
  } = content;

  return (
    <div className="template">
      <Header
        title={title}
        subtitle={subtitle}
        backgroundImage={backgroundImage}
        extraContent={extraContent}
        highlightedPoints={highlightedPoints}
      />
      <main></main>
    </div>
  );
};

export default TemplateComponent;
