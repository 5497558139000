import { MenuItem } from "@mui/material";
import { color } from "d3";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


  body {
    font-family:  sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

export const Typography = styled.p`
  font-family: "Poppins", sans-serif;
  background: none;
  font-weight: 700;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #343a40;
  font-size: 25px;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 00;
  color: #6c757d;
`;

export const Button = styled.button`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  border: none;
  padding: 12px 46px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.primary {
    background-color: #007bff;
    color: #fff;
  }

  &.secondary {
    background-color: #6c757d;
    color: #fff;
  }

  &.success {
    background-color: #28a745;
    color: #fff;
  }

  &.danger {
    background-color: #dc3545;
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
    span {
      padding-left: 10px;
      transform: translateX(6px);
      transition: transform 0.1s ease-in-out;
    }
  }
`;
