import React, { useEffect, useRef } from "react";
import { Grid, Typography, Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import Mission from "./../../../Assets/Images/Mission.jpg";
import Vission from "./../../../Assets/Images/Vission.jpg";
import Iop from "./../../../Assets/Images/OIP.jpeg";
import Values from "./../../../Assets/Images/Values.jpg";
import "./About.css"; // Import the CSS file

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  scrollMarginTop: "100px", // Adjust this value based on your fixed header height
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8, 4),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
  },
}));

const TopSection = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${Iop})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: theme.spacing(4, 2),
  scrollMarginTop: "100px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  "& > *": {
    position: "relative",
    zIndex: 2,
  },
}));

const About = () => {
  const visionImageRef = useRef(null);
  const missionImageRef = useRef(null);
  const valuesImageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (visionImageRef.current) {
      observer.observe(visionImageRef.current);
    }
    if (missionImageRef.current) {
      observer.observe(missionImageRef.current);
    }
    if (valuesImageRef.current) {
      observer.observe(valuesImageRef.current);
    }

    return () => {
      if (visionImageRef.current) {
        observer.unobserve(visionImageRef.current);
      }
      if (missionImageRef.current) {
        observer.unobserve(missionImageRef.current);
      }
      if (valuesImageRef.current) {
        observer.unobserve(valuesImageRef.current);
      }
    };
  }, []);

  return (
    <Box className="section-container" id="company">
      <TopSection className="section-padding">
        <Container>
          <Grid container spacing={15} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="https://quiety-react.themetags.com/assets/img/banner_image.png"
                alt="About TechOptima"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" component="h6" paragraph>
                About TechOptima
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{ fontStyle: "italic" }}
              >
                TechOptima is proud to be India's first AI bot company,
                pioneering the development and deployment of advanced artificial
                intelligence solutions tailored to meet the diverse needs of
                businesses across various industries. Our commitment to
                innovation, excellence, and customer-centricity sets us apart as
                a leader in the AI space, driving the transformation of
                industries through intelligent automation and data-driven
                insights."
                {/* TechOptima is India’s first AI developer cloud provider with the best compute power along with our expert guidance services which help organizations and business to drive the full potential of Advisory, transformative and strategy(ATS) through AI driven expertise .​
                <br/>
                Our commitment to innovation, excellence, and customer-centricity sets us apart as a leader in the AI space, driving the transformation of industries through intelligent automation and data-driven insights.​ */}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </TopSection>

      <StyledSection id="vision" className="section-padding section-alternate">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h4" paragraph>
                Vision
              </Typography>
              <Typography variant="h6" component="h6" paragraph>
                “To produce best compute fuel for AI”
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Vision:</strong> Be the preferred partner uniting human
                ingenuity and AI, leveraging technology and expertise to deliver
                best-in-class solutions that enhance client success.
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Technology:</strong> Utilizing advanced cloud technology
                to offer scalable solutions for modern AI needs.
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Commitment:</strong> Ensuring clients can focus on AI
                innovation while we manage the computational tasks.
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Goal:</strong> Creating a smarter, more connected world
                through each computation.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={Vission}
                alt="Vision"
                className="section-image"
                ref={visionImageRef}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledSection>

      <StyledSection id="mission" className="section-padding">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <img
                src={Mission}
                alt="Mission"
                className="section-image-alt"
                ref={missionImageRef}
              />
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Typography variant="h4" component="h4" paragraph>
                Mission
              </Typography>
              <Typography variant="h6" component="h6" paragraph>
                “To produce a Personalized Super Computer”
              </Typography>
              <Typography variant="body1" component="p">
                <strong> Mission:</strong>
                Empowering clients business with AI-driven insights,
                computational technology excellence, accelerated solutions, and
                proven delivery.
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Dedication:</strong>
                Offering custom computing solutions tailored to each user’s
                unique needs for optimal performance and efficiency.
              </Typography>
              <Typography variant="body1" component="p">
                <strong>Technology:</strong>
                Combining advanced AI and cloud technologies to deliver a
                seamless, scalable, and secure platform that adapts to clients’
                evolving demands.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </StyledSection>

      <StyledSection id="values" className="section-padding section-alternate">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h4" paragraph>
                Values
              </Typography>
              <Typography variant="h6" component="h6" paragraph>
                “ 3P’s: Protect, Provide, and Prosper. ”
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                At TechOptima, our core values are encapsulated in the 3P’s:
                <strong>Protect, Provide, and Prosper </strong>. We Protect our
                culture, integrity, and inclusivity, ensuring a respectful and
                collaborative environment for all. We Provide unparalleled
                security, foster innovation, and promote teamwork, driving
                forward-thinking solutions and robust support for our clients.
                We Prosper through continuous learning, delivering exceptional
                services, and upholding the highest standards of ethical
                conduct. These values are the foundation of our commitment to
                excellence, guiding us in empowering businesses with
                cutting-edge AI and cloud computing solutions.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={Values}
                alt="Values"
                className="section-image-alt"
                ref={valuesImageRef}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledSection>

      {/* <StyledSection className="section-padding section-alternate">
        <Container>
          <Typography
            id="awards"
            variant="h4"
            align="center"
            // className="section-padding"
          >
            Awards & Recognitions
          </Typography>
          <Typography variant="body1">
            Details about awards and recognitions.
          </Typography>
        </Container>
      </StyledSection> */}
    </Box>
  );
};

export default About;
