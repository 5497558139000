import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { textAlign } from "@mui/system";

const Forum = () => {
  const styles = {
    root: {
      paddingTop: "150px",
      paddingBottom: "50px",
    },
    header: {
      marginBottom: "32px",
    },
    content: {
      marginBottom: "32px",
      textAlign: "left",
      fontSize: "1.1rem",
    },
    image: {
      width: "100%",
      height: "auto",
      marginBottom: "32px",
    },
    section: {
      marginTop: "32px",
    },
    paper: {
      padding: "24px",
      marginBottom: "16px",
      
    },
  };

  return (
    <Container style={styles.root}>
      <Typography variant="h3" component="h1" style={styles.header}>
        AI Quality Forum
      </Typography>
      {/* <img
        src="https://thumbs.dreamstime.com/z/research-word-scientists-looking-new-solutions-39629376.jpg?ct=jpeg"
        alt="Forum"
        style={styles.image}
      /> */}
      <Typography variant="body1" style={styles.content}>
        Welcome to TechOptima’s AI Quality Forum. This platform is dedicated to
        discussing and sharing insights about the quality of AI and its impact
        on various sectors. Here, you can engage with experts, share your
        experiences, and stay updated with the latest advancements in AI quality
        standards.
      </Typography>

      <Box style={styles.section}>
        <Typography variant="h4" component="h2" style={styles.header}>
          Forum Discussions
        </Typography>
        <Paper style={styles.paper}>
          <Typography variant="h5">Model Performance:</Typography>
          <Typography variant="body2">
            Discuss and share insights on evaluating the performance of machine
            learning models, focusing on aspects like accuracy, stability, and
            robustness.
          </Typography>
        </Paper>
        <Paper style={styles.paper}>
          <Typography variant="h5">Societal Impact:</Typography>
          <Typography variant="body2">
            Engage in conversations about the societal impact of AI, including
            fairness, transparency, privacy, and security. Discuss the ethical
            responsibilities of AI systems and how to ensure equity and
            fairness.
          </Typography>
        </Paper>
        <Paper style={styles.paper}>
          <Typography variant="h5">Operational Compatibility:</Typography>
          <Typography variant="body2">
            Share experiences and best practices for ensuring effective
            collaboration between humans and AI systems, as well as seamless
            integration of AI within existing operational environments.
          </Typography>
        </Paper>
        <Paper style={styles.paper}>
          <Typography variant="h5">Data Quality:</Typography>
          <Typography variant="body2">
            Discuss the importance of data quality in AI model development.
            Share techniques for ensuring data representativeness, handling
            missing data, and maintaining the quality of production data.
          </Typography>
        </Paper>
      </Box>

      <Box style={styles.section}>
        <Typography variant="h4" component="h2" style={styles.header}>
          Community Contributions
        </Typography>
        <Typography variant="body1" style={styles.content}>
          Our community members are the heart of this forum. Share your
          projects, ask questions, and collaborate with others to push the
          boundaries of AI quality.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper style={styles.paper}>
              <Typography variant="h6">Project Showcase:</Typography>
              <Typography variant="body2">
                Showcase your AI projects, share your methodologies, and receive
                feedback from fellow community members.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={styles.paper}>
              <Typography variant="h6">Expert Q&A:</Typography>
              <Typography variant="body2">
                Engage with AI experts through Q&A sessions. Ask questions, seek
                advice, and gain insights from industry leaders.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={styles.paper}>
              <Typography variant="h6">Workshops & Webinars:</Typography>
              <Typography variant="body2">
                Participate in workshops and webinars hosted by TechOptima to
                learn about the latest trends and advancements in AI quality.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={styles.paper}>
              <Typography variant="h6">Collaborative Projects:</Typography>
              <Typography variant="body2">
                Join collaborative projects to work with others on cutting-edge
                AI solutions. Share your skills and learn from your peers.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box style={styles.section}>
        <Typography variant="h5" component="h3" style={styles.header}>
          Forum Rules
        </Typography>
        <Paper style={styles.paper}>
          <Typography variant="body1">
            To ensure a respectful and engaging community, please adhere to the
            following rules:
            <ul>
              <li>Be respectful to others.</li>
              <li>No spamming or advertising.</li>
              <li>Keep discussions on topic.</li>
              <li>Provide constructive feedback.</li>
              <li>Respect privacy and confidentiality.</li>
            </ul>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Forum;
