// import React, { useState, useEffect } from "react";

// const CookieBanner = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       const cookieAccepted = localStorage.getItem("cookieAccepted");
//       const cookieDeclined = localStorage.getItem("cookieDeclined");

//       if (!cookieAccepted && !cookieDeclined) {
//         setIsVisible(true);
//         document.body.style.overflow = "hidden";
//       }
//     }, 5000);

//     return () => {
//       clearTimeout(timer);
//       document.body.style.overflow = "auto";
//     };
//   }, []);

//   const handleAccept = () => {
//     localStorage.setItem("cookieAccepted", "true");
//     localStorage.removeItem("cookieDeclined");
//     setIsVisible(false);
//     document.body.style.overflow = "auto";
//   };

//   //   const handleDecline = () => {
//   //     localStorage.setItem("cookieDeclined", "true");
//   //     setIsVisible(false);
//   //     document.body.style.overflow = "auto";
//   //   };

//   const handleDecline = () => {
//     // localStorage.setItem("cookieDeclined", "true");
//     setIsVisible(false);
//     document.body.style.overflow = "auto";
//     // Do not change overflow here; it will be set on next reload
//   };

//   const handleClose = () => {
//     setIsVisible(false);
//     document.body.style.overflow = "auto";
//   };

//   return (
//     isVisible && (
//       <div
//         style={{
//           position: "fixed",
//           top: "50%",
//           right: "0%",
//           transform: "translate(-10%, 100%)",
//           backgroundColor: "var(--backgroundcolor)",
//           padding: "20px",
//           borderRadius: "8px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 4)",
//           zIndex: "1000",
//           textAlign: "center",
//           width: "100%",
//           maxWidth: "200px",
//         }}
//       >
//         <button
//           onClick={handleClose}
//           style={{
//             position: "absolute",
//             top: "10px",
//             right: "10px",
//             background: "none",
//             border: "1px solid black",
//             borderRadius: "100%",
//             fontSize: "20px",
//             cursor: "pointer",
//             color: "var(--text-color)",
//           }}
//         >
//           &times;
//         </button>

//         <h5>
//           This website uses cookies to ensure you get the best experience on our
//           website.
//         </h5>
//         <div style={{ marginTop: "10px" }}>
//           <button
//             onClick={handleAccept}
//             style={{
//               backgroundColor: "var(--success-color)",
//               color: "var(--white)",
//               border: "none",
//               padding: "10px 20px",
//               cursor: "pointer",
//               margin: "0 5px",
//               borderRadius: "4px",
//             }}
//           >
//             Accept
//           </button>
//           <button
//             onClick={handleDecline}
//             style={{
//               backgroundColor: "var(--blue)",
//               color: "var(--white)",
//               border: "none",
//               padding: "10px 20px",
//               cursor: "pointer",
//               margin: "0 5px",
//               borderRadius: "4px",
//             }}
//           >
//             Decline
//           </button>
//         </div>
//       </div>
//     )
//   );
// };

// export default CookieBanner;

import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const cookieAccepted = localStorage.getItem("cookieAccepted");
      const cookieDeclined = localStorage.getItem("cookieDeclined");

      if (!cookieAccepted && !cookieDeclined) {
        setIsVisible(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", "true");
    localStorage.removeItem("cookieDeclined");
    setIsVisible(false);
  };

  const handleDecline = () => {
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  // Render the banner using a portal
  return (
    isVisible &&
    ReactDOM.createPortal(
      <div
        style={{
          position: "fixed",
          top: "50%",
          right: "0%",
          transform: "translate(-10%, 50%)",
          backgroundColor: "var(--backgroundcolor)",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 4)",
          zIndex: "1000",
          textAlign: "center",
          width: "100%",
          maxWidth: "200px",
        }}
      >
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "1px solid black",
            borderRadius: "100%",
            fontSize: "20px",
            cursor: "pointer",
            color: "var(--text-color)",
          }}
        >
          &times;
        </button>

        <h5>
          This website uses cookies to ensure you get the best experience on our
          website.
        </h5>
        <div style={{ marginTop: "10px" }}>
          <button
            onClick={handleAccept}
            style={{
              backgroundColor: "var(--success-color)",
              color: "var(--white)",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              margin: "0 5px",
              borderRadius: "4px",
            }}
          >
            Accept
          </button>
          <button
            onClick={handleDecline}
            style={{
              backgroundColor: "var(--blue)",
              color: "var(--white)",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              margin: "0 5px",
              borderRadius: "4px",
            }}
          >
            Decline
          </button>
        </div>
      </div>,
      document.body // Use portal to render the popup at the body level
    )
  );
};

export default CookieBanner;
