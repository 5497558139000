import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../../Assets/Images/OIP.jpeg";

const IntroducingClusters = () => {
  return (
    <Box
      sx={{
        padding: "150px 20px  40px 20px",
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
            xl: "3.5rem",
          },
         
        }}
      >
        Introducing 1-Click Clusters
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src="https://img.freepik.com/free-photo/business-people-meeting-looking-presentation_9975-23062.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890"
            alt="1-Click Clusters"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.3rem",
                xl: "1.4rem",
              },
             
            }}
          >
            <h3>
              On-demand GPU clusters featuring NVIDIA H100 Tensor Core GPUs with
              Quantum-2 Infiniband
            </h3>
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1.1rem",
                lg: "1.2rem",
                xl: "1rem",
              },
            }}
          >
            "No long-term contract required. Self-serve directly from the Optima
            Cloud dashboard. Enjoy the power of NVIDIA H100 Tensor Core GPUs
            combined with the speed of Quantum-2 Infiniband, available at your
            fingertips with just a click.
            <br />
            <br />
            Optima Cloud's 1-Click Clusters are designed for flexibility and
            performance, ensuring you can scale your computational needs as your
            projects demand.
            <br />
            <br />
            Experience seamless integration and unparalleled power without the
            commitment of long-term contracts. Start utilizing cutting-edge
            technology today, straight from the Optima Cloud dashboard."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntroducingClusters;
