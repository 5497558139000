import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../../Assets/Images/OIP.jpeg";

const Research = () => {
  return (
    <Box
      sx={{
        padding: "150px  25px 25px 25px",
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Our Research Initiatives
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <Box
            component="img"
            src="https://img.freepik.com/free-photo/business-people-meeting-looking-presentation_9975-23062.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890"
            alt="Research Image"
            sx={{
              width: "100%",
              borderRadius: "8px",
              height: {
                xs: "auto",
                md: "100%",
              },
              maxHeight: {
                xs: "300px",
                md: "500px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Research and Development at TechOptima</h3>
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1rem",
                lg: "1.25rem",
              },
            }}
          >
            " At TechOptima, continuous research and development are fundamental
            to our success. Our R&D team collaborates with leading academic
            institutions and industry partners to explore new AI and machine
            learning frontiers. This ensures our AI 360 solutions are always at
            the cutting edge of technology and capable of addressing the most
            complex business challenges.
            <br />
            <br />
            Our team of experts collaborates with industry leaders and academic
            institutions to push the boundaries of what is possible. We are
            committed to creating technologies that not only meet the current
            needs but also anticipate future challenges.
            <br />
            <br />
            We are continuously working on projects that drive efficiency,
            improve performance, and deliver exceptional value to our clients.
            Stay tuned for our latest updates and breakthroughs in the field of
            technology."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Research;
