

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  styled,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";

import { Container } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import Config from "../../../Config/Config"

const Logo = styled("img")(({ theme }) => ({
  maxWidth: 100,
  margin: theme.spacing(1),
}));

const CreateAccount = () => {
  const [formData, setFormData] = useState({
    accountType: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    agree: false,
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!formData.agree) {
      alert("You must agree to the terms and policies.");
      return;
    }

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName: "signupforgpu",
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      accountType: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      agree: false,
    });
    navigate(location.state?.from || "/");
  };

  return (
    <Container sx={{ padding: { xs: 2, sm: 4, md: 6 } }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box
          onClick={() => navigate("/")}
          sx={{
            textDecoration: "none",
            backgroundColor: "var(--button-color)",
            padding: { xs: "6px 8px", sm: "8px 12px", md: "10px 16px" },
            cursor: "pointer",
            borderRadius: "15px 0 15px 0",
            boxShadow:
              "0px 0px 8px var(--white), 0 0 0px 1px var(--button-color), 0 0 0px 1px var(--button-color)",
            display: "inline-block",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: "700",
              color: "white",
              textDecoration: "none",
              fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
            }}
          >
            TechOptima
          </Typography>
        </Box>
        <Box>
          <Link
            onClick={() => navigate("/signin")}
            variant="body2"
            sx={{
              cursor: "pointer",
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.125rem" },
            }}
          >
            Have an account? Sign in &rarr;
          </Link>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
            >
              The best price for cloud GPUs on the market. Save over 68% vs AWS,
              GCP, and Azure
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" } }}
            >
              No yearly commits or unpredictable spot instances.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
            >
              Trusted by more than 500 ML engineers and researchers
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
            >
              Pay by the second
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" } }}
            >
              Only pay for what you use. Starting at $0.50 per hour.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
            >
              Train & fine-tune models on a GPU cloud built for AI workloads
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" } }}
            >
              PyTorch®, TensorFlow, and Jupyter pre-installed on every GPU
              instance.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
            >
              Ease of use
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" } }}
            >
              Launch ML-optimized instances in just a few clicks.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              padding: { xs: 2, sm: 3, md: 4 },
              borderLeft: { md: "1px solid #ccc" },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } }}
            >
              Create your free account
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
              <TextField
                label="Account type"
                name="accountType"
                fullWidth
                margin="normal"
                value={formData.accountType}
                onChange={handleInputChange}
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <TextField
                label="First name"
                name="firstName"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange}
                required
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <TextField
                label="Last name"
                name="lastName"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <TextField
                label="Phone number (optional)"
                name="phoneNumber"
                fullWidth
                margin="normal"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <TextField
                label="Email"
                name="email"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange}
                required
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                fullWidth
                margin="normal"
                value={formData.password}
                onChange={handleInputChange}
                required
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", marginY: 2 }}>
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleInputChange}
                />
                <label htmlFor="agree">
                  &nbsp;I agree to TechOptima's&nbsp;
                  <Link href="#">Privacy Policy</Link>,&nbsp;
                  <Link href="#">Terms of Service</Link>, and&nbsp;
                  <Link href="#">Acceptable Use Policy</Link>.
                </label>
              </Box>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "var(--button-color)",
                  fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
                  padding: { xs: "8px", sm: "10px", md: "12px" },
                  "&:hover": {
                    backgroundColor: "var(--buttonHover)",
                  },
                }}
              >
                Sign up
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: 300, sm: 400 },
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: { xs: 3, sm: 4 },
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" } }}
            >
              Account Created Successfully
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 2,
                fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
              }}
            >
              Your account has been created. You can now sign in and start using
              our services.
            </Typography>
            <Button
              onClick={handleClose}
              sx={{
                mt: 2,
                fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
              }}
            >
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default CreateAccount;
