import React, { useState, useEffect } from "react";
import "./CTFramework.css";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Tooltip,
} from "recharts";

const CTFramework = () => {
  const initialScores = {
    clarity: Array(11).fill(""),
    consistency: Array(6).fill(""),
    completeness: Array(6).fill(""),
    testability: Array(4).fill(""),
  };

  const initialNotes = {
    clarity: Array(11).fill(""),
    consistency: Array(6).fill(""),
    completeness: Array(6).fill(""),
    testability: Array(4).fill(""),
  };

  const [scores, setScores] = useState(initialScores);
  const [notes, setNotes] = useState(initialNotes);
  const [open, setOpen] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [showDashboard, setShowDashboard] = useState(false);

  const maxScores = {
    clarity: 11 * 5,
    consistency: 6 * 5,
    completeness: 6 * 5,
    testability: 4 * 5,
  };

  const calculateTotal = (category) =>
    scores[category].reduce((acc, score) => acc + Number(score), 0);

  const calculatePercentage = (category) =>
    (calculateTotal(category) / maxScores[category]) * 25;

  useEffect(() => {
    const total =
      calculatePercentage("clarity") +
      calculatePercentage("consistency") +
      calculatePercentage("completeness") +
      calculatePercentage("testability");
    setTotalScore(total);
  }, [scores]);

  const handleScoreChange = (category, index) => (event) => {
    const newScores = { ...scores };
    newScores[category][index] = event.target.value;
    setScores(newScores);
  };

  const handleNoteChange = (category, index) => (event) => {
    const newNotes = { ...notes };
    newNotes[category][index] = event.target.value;
    setNotes(newNotes);
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowDashboard(true);
  };

  const handleGoBack = () => {
    setShowDashboard(false);
  };

  const questions = {
    clarity: [
      "Is each requirement uniquely and persistently identified?",
      "Is each requirement written as concisely and simply as possible?",
      "Does each requirement express only one function or idea?",
      "Is each requirement clear to the intended audience, possessing a single interpretation?",
      "Are requirements expressed using the simplest grammar and as few words as possible?",
      "Are the rationale, examples, and other supporting data separated from the requirement?",
      "Are the requirements written in non-technical language that uses the vocabulary of the client domain?",
      "Do the requirements describe only EXTERNAL behaviour, as seen from the user's point of view?",
      "Do the requirements avoid stating how the problem is to be solved or what techniques are to be used?",
      "Does all data and process exist within the problem domain, not the solution domain?",
      "Is every noun a term from the data dictionary?",
    ],
    consistency: [
      "Is each requirement represented only once in a specification and referenced where needed?",
      "Is each requirement internally consistent with other product requirements, if applicable?",
      "Are the characteristics of real-world objects consistent?",
      "Are requirements free from redundancy?",
      "Are the logical and temporal characteristics of required actions consistent?",
      "Is all terminology used consistently?",
    ],
    completeness: [
      "Does the requirement have multiple sub-requirements? If yes, are they referenced accordingly?",
      "Is each characteristic of the final product described?",
      "Do all of the inputs to the system include their source, accuracy, range of values, and frequency?",
      "Do all of the outputs from the system include their destination, accuracy, range of values, frequency, and format?",
      "Does each function specify the data used in the function and data resulting from the function?",
      "Are all aspects of the processing specified for each function?",
    ],
    testability: [
      "Can the requirement be validated by a person or automated tool?",
      "Does each requirement use concrete terms and measurable quantities?",
      "Do the requirements contain any implied implementation constraints?",
      "Does the requirement refer to the jobs / third party control (upstream/downstream) that are not accessible?",
    ],
  };

  const radarData = [
    { subject: "Clarity", A: calculatePercentage("clarity") },
    { subject: "Consistency", A: calculatePercentage("consistency") },
    { subject: "Completeness", A: calculatePercentage("completeness") },
    { subject: "Testability", A: calculatePercentage("testability") },
  ];

  const dotColors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300"];

  return (
    <div style={{ marginTop: "200px" }}>
      {!showDashboard && (
        <div id="ctFrameworkSection" className="content-section">
          <Typography variant="h3" className="heading-gradient">
            Axiom
          </Typography>
          <Typography variant="h5">Evaluation Criteria</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Leading Questions
                  </TableCell>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    className="table-header"
                    style={{ color: "#fff", fontWeight: "800" }}
                  >
                    Notes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(questions).map((category) => (
                  <React.Fragment key={category}>
                    <TableRow>
                      <TableCell
                        component="th"
                        colSpan={3}
                        className="table-category-header"
                        style={{ color: "#fff", fontWeight: "600" }}
                      >
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </TableCell>
                    </TableRow>
                    {questions[category].map((question, index) => (
                      <TableRow key={index}>
                        <TableCell className="table-cell">{question}</TableCell>
                        <TableCell className="table-cell">
                          <Select
                            value={scores[category][index]}
                            onChange={handleScoreChange(category, index)}
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {[1, 2, 3, 4, 5].map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell className="table-cell">
                          <TextField
                            placeholder="Add note"
                            fullWidth
                            value={notes[category][index]}
                            onChange={handleNoteChange(category, index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                <TableRow>
                  <TableCell component="th" className="table-cell">
                    Total Score
                  </TableCell>
                  <TableCell className="table-cell">
                    {totalScore.toFixed(2)}%
                  </TableCell>
                  <TableCell className="table-cell"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              className="sub-btn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {showDashboard && (
        <div id="ctFrameworkDashboard" className="content-section">
          <Typography variant="h1" className="heading-gradient text-center">
            CT Framework Dashboard
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadarChart
              cx="50%"
              cy="50%"
              outerRadius="80%"
              width={600}
              height={500}
              data={radarData}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 25]} tickCount={6} />
              <Radar
                name="Total"
                dataKey="A"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
                dot={({ cx, cy, index }) => (
                  <circle
                    cx={cx}
                    cy={cy}
                    r={4}
                    fill={dotColors[index % dotColors.length]}
                  />
                )}
              />
              <Tooltip />
              <Legend />
            </RadarChart>
          </div>
          <div id="summaryTableCT" className="summary-table">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "800" }}
                    >
                      Category
                    </TableCell>
                    <TableCell
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "800" }}
                    >
                      Total Percentage
                    </TableCell>
                    <TableCell
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "800" }}
                    >
                      Notes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(questions).map((category) => (
                    <TableRow key={category}>
                      <TableCell className="table-cell">
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {calculatePercentage(category).toFixed(2)}%
                      </TableCell>
                      <TableCell className="table-cell">
                        <div>
                          {notes[category]
                            .map((note, index) => ({ note, index }))
                            .filter((item) => item.note)
                            .map((item, filteredIndex) => (
                              <div key={item.index}>
                                {`${filteredIndex + 1}. ${item.note}`}
                              </div>
                            ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      // className="table-cell"
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "600" }}
                    >
                      <strong>Total</strong>
                    </TableCell>
                    <TableCell
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "600" }}
                    >
                      <strong>{totalScore}%</strong>
                    </TableCell>
                    <TableCell
                      className="summary-table-header"
                      style={{ color: "#fff", fontWeight: "600" }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button variant="contained" color="primary" onClick={handleGoBack}>
              Go Back
            </Button>
          </div>
        </div>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your total score is {totalScore.toFixed(2)}%.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CTFramework;
