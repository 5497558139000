import { styled, Button, Box } from "@mui/material";

// Primary Button styling
export const ButtonPrimary = styled(Button)(({ theme }) => ({
  backgroundColor: "var(--button-color)",
  color: "var(--white)",
  borderRadius: "35px",
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "400",
  lineHeight: "1.2",
  padding: theme.spacing(1, 2),
  textDecoration: "none",
  transition: "background-color 0.3s ease", // Smooth transition for hover effect
  "&:hover": {
    backgroundColor: "var(--buttonHover)",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(2, 4),
  },
}));

// Secondary Button styling
export const ButtonSecondary = styled(Button)(({ theme }) => ({
  borderColor: "var(--secondary-button)",
  color: "var(--secondary-button)",
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "400",
  borderRadius: "35px",
  lineHeight: "1.2",
  padding: theme.spacing(1, 2),
  textDecoration: "none",
  "&:hover": {
    borderColor: "var(--hover-Secondary-btn)",
    color: "var(--hover-Secondary-btn)",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(2, 4),
  },
}));

export const DividerWithText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  background: "var(--gradient2)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  paddingTop: "25px",
  margin: theme.spacing(4, 0),
  "&::after": {
    content: '""',
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.divider,
    marginRight: theme.spacing(2),
  },
}));
