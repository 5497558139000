import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";

const CardDetail = () => {
  const { id } = useParams();

  // Replace with your CMS fetching logic
  const cardDetails = {
    1: {
      title: "How to Analyze and Adapt to Company Politics",
      content: "Full content of the card goes here...",
    },
    2: {
      title: "Design an IT Infrastructure Strategy Ready for the Unknown",
      content: "Full content of the card goes here...",
    },
    3: {
      title: "The Premier Conference for IT Executives",
      content: "Full content of the card goes here...",
    },
  };

  const card = cardDetails[id];

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        {card.title}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">{card.content}</Typography>
      </Box>
    </Container>
  );
};

export default CardDetail;
