import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Iop from "./../../../../Assets/Images/OIP.jpeg";

const OptimaStackInfo = () => {
  return (
    <Box
      sx={{
        padding: "150px 20px  40px 20px",
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
            xl: "3.5rem",
          },
         
        }}
      >
        Optima Stack is used by more than 50k ML teams
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src="https://img.freepik.com/free-photo/business-people-meeting-looking-presentation_9975-23062.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890"
            alt="Optima Stack"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.3rem",
                xl: "1.4rem",
              },
             
            }}
          >
            <h3>One-line installation and managed upgrade path</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.9rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1.1rem",
              },
            }}
          >
            "Optima Stack offers a seamless experience for machine learning
            teams with a one-line installation and managed upgrade path for
            PyTorch, TensorFlow, CUDA, cuDNN, and NVIDIA Drivers.
            <br />
            <br />
            This ensures that your team can focus on what matters most -
            developing and deploying machine learning models without worrying
            about compatibility issues or infrastructure maintenance.
            <br />
            <br />
            Join the thousands of teams already benefiting from the efficiency
            and reliability of Optima Stack, and take your ML projects to the
            next level with ease."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OptimaStackInfo;
