import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Button,
} from "@mui/material";
import Contact from "../../../Footer/FooterOptions/Contact/Contact"; // Import the Contact component
import AppliedAI20 from "../../../../Assets/Images/AppliedAI/AppliedAI20.jpg";
import dev8 from "../../../../Assets/Images/AppliedAI/dev8.jpg";
import dev7 from "../../../../Assets/Images/AppliedAI/dev7.jpg";
import AppliesAI4 from "../../../../Assets/Images/AppliedAI/AppliesAI4.jpg";
import Config from "../../../../Config/Config";

const NVIDIA = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "India",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [open, setOpen] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log(formData);

    const apiEndpoint = `${Config.apiBaseUrl}`;
    const data = {
      toEmail: formData.email,
      pageName: "NVIDIA contact", // Specify the page name
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "India",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  return (
    <Box
      sx={{
        paddingTop: {
          xs: "150px ",
          sm: "150px ",
          md: "150px ",
          lg: "130px ",
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${AppliesAI4})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          color: "white",
          textAlign: { xs: "center", md: "left" }, // Center text on small screens
          px: { xs: 2, sm: 4, md: 10 }, // Responsive padding
          py: { xs: 4, sm: 6, md: 15 }, // Responsive padding
        }}
      >
        <>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ color: "white" }}
          >
            Optima Systems
          </Typography>
        </>
      </Box>

      <Box sx={{ mt: 4, px: { xs: 2, sm: 4, md: 10 } }}>
        <Grid
          container
          spacing={4}
          alignItems="center "
          //   sx={{ fontStyle: "italic" }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.25rem",
              },
              padding: {
                xs: "2rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "2rem",
              },
            }}
          >
            In a world that is so competent, being on your toes is extremely
            important. When it comes to AI Model training, speed, ability to
            multitask, and a good memory bandwidth are the components that are
            essential to an AI engineer. A Graphic Processing Unit is such a
            system which comes handy for an AI engineer in building their models
            smoothly.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.25rem",
              },
              padding: {
                xs: "2rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "2rem",
              },
            }}
          >
            TechOptima’s NVIDIA DGX System is one such platform which provides
            ai developers with the scope of building models without having to
            worry about the space, cost or even the rate of training. TechOptima
            aims to help budding developers overcome the following challenges:
          </Typography>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography
              variant="h5"
              component="h4"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1.25rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
              }}
            >
              Computational Demands
            </Typography>
            <Typography variant="body1" gutterBottom>
              AI models, especially deep learning ones, require significant
              computational power. GPUs are well-suited for this due to their
              parallel processing capabilities. However, managing computational
              demands efficiently can be challenging.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <img
              src={AppliedAI20}
              alt="Computational Demands"
              style={{
                width: "100%",
                height: "350px",
                borderRadius: "45px 0px 45px 0px",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <img
              src={AppliesAI4}
              alt="Cost and Scalability"
              style={{
                width: "100%",
                height: "350px",
                borderRadius: "45px 0px 45px 0px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h2" gutterBottom>
              Cost and Scalability
            </Typography>
            <Typography variant="body1" gutterBottom>
              High-end GPUs can be expensive, and scaling up GPU resources can
              strain budgets.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Compatibility and Drivers
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ensuring that AI frameworks (such as TensorFlow or PyTorch) work
              seamlessly with specific GPU models and drivers can be tricky.
              Compatibility issues may arise during updates or when using
              different libraries.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Memory Constraints
            </Typography>
            <Typography variant="body1" gutterBottom>
              Deep learning models often require large amounts of memory.
              Engineers must optimize memory usage, batch sizes, and model
              architectures to fit within GPU memory limits.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Cooling and Power
            </Typography>
            <Typography variant="body1" gutterBottom>
              GPUs generate heat, and proper cooling solutions are essential.
              Additionally, power consumption can impact system stability and
              energy costs.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Parallelization Challenges
            </Typography>
            <Typography variant="body1" gutterBottom>
              While GPUs excel at parallel processing, not all AI algorithms can
              be efficiently parallelized. Engineers must choose algorithms that
              benefit from GPU acceleration.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Data Movement Bottlenecks
            </Typography>
            <Typography variant="body1" gutterBottom>
              Transferring data between CPU and GPU can introduce bottlenecks.
              Engineers need to minimize data movement and optimize data loading
              pipelines.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <img
              src={dev7}
              alt="Cooling and Power"
              style={{
                width: "100%",
                height: "350px",
                borderRadius: "45px 0px 45px 0px",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Debugging and Profiling
            </Typography>
            <Typography variant="body1" gutterBottom>
              Identifying performance bottlenecks, memory leaks, or incorrect
              GPU usage can be time-consuming. Engineers rely on profiling tools
              and debugging techniques.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
              Resource Sharing
            </Typography>
            <Typography variant="body1" gutterBottom>
              In multi-GPU setups, engineers must manage resource allocation,
              load balancing, and communication between GPUs effectively.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
              AI-Specific Challenges
            </Typography>
            <Typography variant="body1" gutterBottom>
              AI workloads involve matrix operations (common in deep learning),
              which GPUs excel at.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <img
              src={dev8}
              alt="Cost and Scalability"
              style={{
                width: "100%",
                height: "350px",
                borderRadius: "45px 0px 45px 0px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Form Submitted Successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Thank you for contacting us. Our team will reach out to you
              shortly.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default NVIDIA;
