

import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Grid,
} from "@mui/material";

const Documentation = () => {
  const styles = {
    root: {
      paddingTop: {
        xs: "150px",
        sm: "150px",
        md: "150px",
        lg: "150px",
      },
      textAlign: "center",
    },
    header: {
      marginBottom: "32px",
      fontSize: {
        xs: "2rem",
        sm: "2.5rem",
        md: "3rem",
        lg: "3.5rem",
      },
    },
    content: {
      marginBottom: "32px",
      fontSize: {
        xs: "1rem",
        sm: "1.125rem",
        md: "1.25rem",
        lg: "1.375rem",
      },
    },
    section: {
      marginTop: "32px",
      textAlign: "left",
    },
    paper: {
      padding: "16px",
      marginBottom: "16px",
    },
    button: {
      marginTop: "16px",
      fontSize: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
      },
    },
    image: {
      width: "100%",
      height: "auto",
    },
  };

  const documentationSections = [
    {
      title: "Getting Started",
      description:
        "Learn how to get started with TechOptima's products and services. This section covers the basics you need to know to begin using our solutions effectively.",
    },
    {
      title: "API Reference",
      description:
        "Detailed API documentation for integrating TechOptima's services into your applications. Find information on endpoints, request/response formats, and more.",
    },
    {
      title: "User Guides",
      description:
        "Comprehensive guides to help you make the most of TechOptima's offerings. Includes step-by-step instructions, best practices, and tips.",
    },
    {
      title: "FAQs",
      description:
        "Frequently asked questions about TechOptima's products, services, and policies. Get quick answers to common queries.",
    },
  ];

  const recentUpdates = [
    {
      title: "API v2.1 Released",
      author: "TechOptima Team",
      date: "2024-07-29",
    },
    {
      title: "New User Guide for Cloud Services",
      author: "TechOptima Documentation",
      date: "2024-07-28",
    },
    {
      title: "FAQ Section Updated",
      author: "TechOptima Support",
      date: "2024-07-27",
    },
  ];

  return (
    <Container
      sx={{
        paddingTop: {
          xs: "150px",
          sm: "150px",
          md: "150px",
        },
        textAlign: "center",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        sx={{
          marginBottom: "32px",
          fontSize: {
            xs: "1.75rem",
            sm: "2rem",
            md: "2.5rem",
          },
        }}
      >
        Documentation - TechOptima Pvt Ltd
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "32px",
          fontSize: {
            xs: "0.875rem",
            sm: "1rem",
            md: "1.125rem",
          },
        }}
      >
        Welcome to the TechOptima documentation center. Here you will find all
        the information you need to effectively use our products and services.
        Whether you are a developer, system administrator, or end-user, our
        comprehensive documentation has got you covered.
      </Typography>

      <Box
        sx={{
          marginTop: "32px",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginBottom: "32px",
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
            },
          }}
        >
          Documentation Sections
        </Typography>
        <Grid container spacing={3}>
          {documentationSections.map((section, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                sx={{
                  padding: "16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1.25rem",
                      md: "1.5rem",
                    },
                  }}
                >
                  {section.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: {
                      xs: "0.75rem",
                      sm: "0.875rem",
                      md: "1rem",
                    },
                  }}
                >
                  {section.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{
          marginTop: "32px",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginBottom: "32px",
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
            },
          }}
        >
          Recent Documentation Updates
        </Typography>
        <Paper
          sx={{
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <List>
            {recentUpdates.map((update, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={update.title}
                    secondary={`by ${update.author} on ${update.date}`}
                    sx={{
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.875rem",
                        md: "1rem",
                      },
                    }}
                  />
                </ListItem>
                {index < recentUpdates.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Box>

      <Box
        sx={{
          marginTop: "32px",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginBottom: "32px",
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
            },
          }}
        >
          Tutorials and How-Tos
        </Typography>
        <Paper
          sx={{
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
              },
            }}
          >
            Explore our tutorials and how-to guides to deepen your understanding
            of TechOptima's products. These resources are designed to help you
            solve specific problems and optimize your workflow.
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Tutorial 1: Setting Up Your First Project
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Tutorial 2: Integrating with Our API
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Tutorial 3: Advanced Configuration Options
              </Typography>
            </li>
          </ul>
        </Paper>
      </Box>

      <Box
        sx={{
          marginTop: "32px",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginBottom: "32px",
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
            },
          }}
        >
          Support and Resources
        </Typography>
        <Paper
          sx={{
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
              },
            }}
          >
            Need help? Visit our support section to find additional resources,
            including contact information, support tickets, and community
            forums. Our support team is here to assist you with any issues you
            may encounter.
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Contact Support: Reach out to our support team for personalized
                assistance.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Support Tickets: Submit a ticket to get help with specific
                issues.
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Community Forums: Engage with other users and find answers to
                common questions.
              </Typography>
            </li>
          </ul>
        </Paper>
      </Box>
    </Container>
  );
};

export default Documentation;
