

import React from "react";
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import TestIO from "./../../../Assets/Videos/TEstIO.mp4";
import Iop from "./../../../Assets/Images/OIP.jpeg";

const TestIo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getResponsiveTypography = (variant) => {
    switch (variant) {
      case "h1":
        return isSmallScreen
          ? { fontSize: "2rem" }
          : isMediumScreen
          ? { fontSize: "2.5rem" }
          : { fontSize: "3rem" };
      case "body1":
        return isSmallScreen
          ? { fontSize: "0.875rem" }
          : isMediumScreen
          ? { fontSize: "1rem" }
          : { fontSize: "1.125rem" };
      default:
        return {};
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: "150px 20px", sm: "150px 40px", md: "190px 80px" },
        backgroundImage: `url(${Iop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={getResponsiveTypography("h1")}
      >
        What we are saying about TestIO?
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <video width="100%" controls>
            <source src={TestIO} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={getResponsiveTypography("body1")}
          >
            <h3>
              Introducing TestIO:{" "}
              <span style={{ fontWeight: "bold" }}>
                AI-based Automated Code Tester
              </span>
            </h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ fontStyle: "italic", ...getResponsiveTypography("body1") }}
          >
            "TestIO is an{" "}
            <span style={{ fontWeight: "bold" }}>innovative product</span> by
            Techoptima that
            <span style={{ fontWeight: "bold" }}> revolutionizes</span> the way
            software testing is conducted. By{" "}
            <span style={{ fontWeight: "bold" }}>automating</span> the process
            of code testing, TestIO generates{" "}
            <span style={{ fontWeight: "bold" }}>comprehensive test cases</span>{" "}
            and handles{" "}
            <span style={{ fontWeight: "bold" }}> bug reporting</span> with{" "}
            <span style={{ fontWeight: "bold" }}> precision</span> with the use
            of <span style={{ fontWeight: "bold" }}> AI libraries</span>. This
            powerful AI tool ensures
            <span style={{ fontWeight: "bold" }}>
              {" "}
              error-free software testing
            </span>{" "}
            , eliminating the human errors typically associated with manual
            testing.
            <br />
            <br />
            With TestIO, you can achieve{" "}
            <span style={{ fontWeight: "bold" }}>
              efficient bug detection
            </span>{" "}
            and maintain high{" "}
            <span style={{ fontWeight: "bold" }}>
              software quality assurance
            </span>{" "}
            standards. Leveraging{" "}
            <span style={{ fontWeight: "bold" }}> AI-driven technology</span>,{" "}
            TestIO simplifies code testing, making it faster, efficient and
            eventually more reliable. Experience the
            <span style={{ fontWeight: "bold" }}>
              {" "}
              future of automated software testing
            </span>{" "}
            with Techoptima’s TestIO."
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestIo;
