import React, { useEffect } from "react";
import * as d3 from "d3";
import { Button } from "@mui/material";

const Chart = () => {
  useEffect(() => {
    generateRadarChart();
    generateSummaryTable();
  }, []);

  const generateRadarChart = () => {
    const svg = d3.select("#radarChart");
    svg.selectAll("*").remove();

    const width = +svg.attr("width");
    const height = +svg.attr("height");
    const radius = (Math.min(width, height) / 2) * 0.9;
    const levels = 5;

    const selectedData = JSON.parse(
      localStorage.getItem("vendorSelectionData")
    );
    const allAxis = Object.keys(selectedData);
    const total = allAxis.length;
    const angleSlice = (Math.PI * 2) / total;

    const rScale = d3.scaleLinear().range([0, radius]).domain([0, 25]);

    const radarLine = d3
      .lineRadial()
      .curve(d3.curveLinearClosed)
      .radius((d) => rScale(d.value))
      .angle((d, i) => i * angleSlice);

    const axisGrid = svg
      .append("g")
      .attr("class", "axisWrapper")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    axisGrid
      .selectAll(".levels")
      .data(d3.range(1, levels + 1).reverse())
      .enter()
      .append("circle")
      .attr("class", "gridCircle")
      .attr("r", (d) => (radius / levels) * d)
      .style("fill", "#CDCDCD")
      .style("stroke", "#CDCDCD")
      .style("fill-opacity", 0.1);

    axisGrid
      .selectAll(".axisLabel")
      .data(d3.range(1, levels + 1).reverse())
      .enter()
      .append("text")
      .attr("class", "axisLabel")
      .attr("x", 4)
      .attr("y", (d) => (-d * radius) / levels)
      .attr("dy", "0.4em")
      .style("font-size", "10px")
      .attr("fill", "#737373")
      .text((d) => `${Math.round(5 * d)}%`);

    const axis = axisGrid
      .selectAll(".axis")
      .data(allAxis)
      .enter()
      .append("g")
      .attr("class", "axis");

    axis
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d, i) => rScale(25) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr("y2", (d, i) => rScale(25) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr("class", "line")
      .style("stroke", "white")
      .style("stroke-width", "2px");

    const data = allAxis.map((d, i) => ({
      axis: d,
      value: parseFloat(selectedData[d].score),
    }));

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const radarWrapper = svg
      .append("g")
      .attr("class", "radarWrapper")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    radarWrapper
      .datum(data)
      .append("path")
      .attr("d", radarLine)
      .style("fill", "#1f77b4")
      .style("fill-opacity", 0.1)
      .style("stroke", "#1f77b4")
      .style("stroke-width", "2px");

    radarWrapper
      .selectAll(".radarCircle")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "radarCircle")
      .attr("r", 4)
      .attr(
        "cx",
        (d, i) => rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2)
      )
      .attr(
        "cy",
        (d, i) => rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2)
      )
      .style("fill", (d, i) => color(i))
      .style("fill-opacity", 0.8);
  };

  const generateSummaryTable = () => {
    const summaryTable = document.getElementById("summaryTable");
    const selectedData = JSON.parse(
      localStorage.getItem("vendorSelectionData")
    );

    const totalScore = Object.values(selectedData)
      .reduce((acc, val) => acc + parseFloat(val.score), 0)
      .toFixed(1);

    summaryTable.innerHTML = `
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Criteria</th>
                        <th>Score</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    ${Object.entries(selectedData)
                      .map(([key, value]) => {
                        const notesList = value.notes
                          .filter((note) => note.trim() !== "")
                          .map((note) => `<li>${note}</li>`)
                          .join("");
                        return `
                            <tr>
                                <td>${key}</td>
                                <td>${parseFloat(value.score).toFixed(1)}%</td>
                                <td>${
                                  notesList ? `<ul>${notesList}</ul>` : ""
                                }</td>
                            </tr>
                        `;
                      })
                      .join("")}
                    <tr>
                        <td><strong>Total</strong></td>
                        <td><strong>${totalScore}%</strong></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        `;
  };

  return (
    <div>
      <h1 className="heading-gradient text-center">
        Vendor Performance Dashboard
      </h1>
      <div className="row">
        <div className="col-12 col-md-12 d-flex justify-content-center">
          <svg id="radarChart" width="600" height="550"></svg>
        </div>
        <div className="col-12 col-md-12" id="summaryTable"></div>
      </div>
      <div className="text-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.history.back()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default Chart;
