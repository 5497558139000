import React from "react";
import { Grid, Typography, Box, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        padding: {
          xs: "150px 20px",
          sm: "150px 40px",
          md: "150px 60px",
          lg: "150px 80px",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        Optima Reserved Cloud powered by NVIDIA
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Optima Reserved Cloud</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Optima Reserved Cloud is now available with the NVIDIA H200 Tensor
            Core GPU. H200 is packed with 141GB of HBM3e running at 4.8TB/s.
            That's nearly double the GPU memory at 1.4x faster bandwidth than
            H100."
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            <h3>Pricing Information</h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Contact our sales team for detailed pricing information on the
            Optima Reserved Cloud with NVIDIA H200 Tensor Core GPU."
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="h6"
            onClick={() => navigate("/Contact")}
            sx={{
              backgroundColor: "var(--button-color)",
              color: "var(--white)",
              borderRadius: "0",
              fontSize: {
                xs: "0.75rem",
                sm: "0.875rem",
                md: "1rem",
                lg: "1rem",
              },
              padding: "12px 15px",
              borderRadius: "5px",
              fontWeight: "400",
              lineHeight: "1.2",

              textDecoration: "none",
              transition: "background-color 0.3s ease", // Smooth transition for hover effect
              "&:hover": {
                backgroundColor: "var(--buttonHover)",
              },
            }}
          >
            Contact us
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pricing;
