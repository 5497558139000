// src/Components/NotFound/NotFound.js
import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NotFound = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        textAlign: "center",
        padding: 2,
        marginTop: "150px",
      }}
    >
      <Typography
        variant={isMobile ? "h3" : "h1"}
        sx={{ mb: 2, fontSize: isMobile ? "4rem" : "6rem" }}
      >
        404
      </Typography>
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{ fontSize: isMobile ? "1.5rem" : "2rem" }}
      >
        Page Not Found
      </Typography>
    </Box>
  );
};

export default NotFound;
