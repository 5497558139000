import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  styled,
  List,
  ListItem,
  Divider,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import "./Header.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeStandbyTwoToneIcon from "@mui/icons-material/ModeStandbyTwoTone";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Contact from "../../../Footer/FooterOptions/Contact/Contact";
import CircleImage from "../../../../Assets/Images/circle.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import Config from "../../../../Config/Config";
import { ButtonPrimary } from "../../../../Styles/Styles";



const Header = ({
  title,
  subtitle,
  extraContent,
  backgroundImage,
  highlightedPoints,
}) => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "India",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [pageName, setPageName] = useState("");
  const [visibleItems, setVisibleItems] = useState(0);

  const scrollContainerRef = useRef(null);
  const sectionRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const pageNameMapping = {
      "/ai-engineering": "aiengineering",
      "/data-science": "datascience",
      "/ai-quality": "aiquality",
      "/ai-assurance": "aiassurance",
      "/cloud-transformation": "cloudtransformation",
      "/cloud-managed-services": "cloudmanagedservices",
      "/cloud-migrations": "cloudmigrations",
      "/cloud-operations": "cloudoperations",
      "/cloud-native-apps": "cloudnativeapps",
      "/cloud-devops": "clouddevops",
      "/enterprise-development": "enterprisedevelopment",
      "/enterprise-platform": "enterpriseplatform",
      "/agile-devops": "agiledevops",
      "/support-maintenance": "supportmaintenance",
      "/intelligent-automation": "intelligentautomation",
      "/low-code": "lowcode",
      "/become-a-client": "BecomeAClient",
    };
    setPageName(pageNameMapping[path] || "");
  }, [location]);

  const testimonials = [
    {
      logo: "https://via.placeholder.com/50",
      text: "Tecnologia has been an outstanding partner. Their team is professional, knowledgeable and customer-service driven. Tecnologia proactive collaborative approach has been critical in helping us build an IT infrastructure that enables our success today and supports our long-term positioning strategy.",
      name: "John Labkins",
      position: "Partner & CEO, Telecommunication Company",
    },
    {
      logo: "https://via.placeholder.com/50",
      text: "I’ve been a customer for more than a decade. Tecnologia is an example of the way Managed Services should be done. They do their very best to make sure you succeed. If there’s an issue, they step in immediately. We will continue to be a customer for years to come.",
      name: "Daniel Legrante",
      position: "CIO, Restaurant Product Supplier",
    },
    // Add more testimonials as needed
  ];

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentTestimonial(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log({ ...formData, pageName });

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  const CustomArrow = ({ direction, onClick }) => (
    <Button
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        [direction === "prev" ? "left" : "right"]: -60,
        minWidth: "auto",
      }}
    >
      {direction === "prev" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
    </Button>
  );

  useEffect(() => {
    const handleScroll = (e) => {
      const target = scrollContainerRef.current;
      if (target) {
        if (visibleItems < extraContent.newSection.length && e.deltaY > 0) {
          e.preventDefault();
          setVisibleItems((prev) =>
            Math.min(prev + 1, extraContent.newSection.length)
          );
        } else if (visibleItems > 0 && e.deltaY < 0) {
          e.preventDefault();
          setVisibleItems((prev) => Math.max(prev - 1, 0));
        } else {
          // Allow normal scrolling when all items are visible
          return;
        }
      }
    };

    const section = sectionRef.current;
    if (section) {
      section.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (section) {
        section.removeEventListener("wheel", handleScroll);
      }
    };
  }, [extraContent.newSection.length, visibleItems]);

  return (
    <Box className="header-container">
      <Box
        className="header-top"
        sx={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "absulate",
          color: "var(--white)",
          textAlign: { xs: "center", md: "left" },
          px: { xs: 2, sm: 4, md: 10 },
          py: { xs: 4, sm: 6, md: 8 },
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              color: "var(--white)",
              padding: "20px 0px",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, // Responsive font size
            }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "var(--white)",
              width: { xs: "100%", md: "80%" }, // Responsive width
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
            }}
          >
            {subtitle}
          </Typography>
          <ButtonPrimary
            variant="contained"
            className="header-button"
            onClick={() =>
              navigate("/scheduleCall", { state: { from: location.pathname } })
            }
            sx={{
              mt: { xs: 2, md: 4 }, // Responsive margin
              width: { xs: "100%", sm: "auto" }, // Full width on small screens
              fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" }, // Responsive font size
              padding: { xs: "10px 20px", sm: "12px 24px", md: "14px 28px" }, // Responsive padding
            }}
          >
            Schedule a Free Consultation
          </ButtonPrimary>
        </Container>
      </Box>

      <Container>
        {extraContent && (
          <Box sx={{ mt: 8 }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box className="image-container">
                  <img
                    src={extraContent.image}
                    alt="Content Image"
                    className="section-image"
                    style={{ width: "100%", height: "500px !importent" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  {extraContent.heading}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {extraContent.content1}
                </Typography>
                <ul>
                  {extraContent.details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>

                <Grid container spacing={4}>
                  {highlightedPoints.map((point, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Box>
                        <Typography
                          variant="h5"
                          component="h6"
                          gutterBottom
                          style={{}}
                        >
                          <CheckCircleIcon
                            sx={{
                              color: "var(--button-color)",
                              mr: 1,
                              fontSize: "14px",
                            }}
                          />
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(point.link);
                            }}
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              textDecoration: "none",
                              color: "var(--button-color)",
                              "&:hover": {
                                color: "var(--button-color) !important",
                              },
                            }}
                          >
                            {point.title}
                          </a>
                        </Typography>
                        <Typography style={{ fontSize: "12px" }}>
                          {point.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Box spacing={4} sx={{ mt: 4 }}>
              {extraContent.sections.map((section, index) => (
                <Grid
                  container
                  spacing={4}
                  key={index}
                  sx={{ mt: 4, alignItems: "center" }}
                >
                  <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                    <Box className="text-container">
                      <Typography variant="h5" gutterBottom>
                        {section.text}
                      </Typography>
                      <Typography variant="body1">
                        {section.description}
                      </Typography>
                      <ul>
                        {section.details.map((detail, index) => (
                          <li key={index}>
                            <Typography
                              variant="p"
                              style={{ fontSize: "14px" }}
                            >
                              {detail}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                    <Box
                      className="image-container"
                      sx={{ textAlign: "center" }}
                    >
                      <img
                        src={section.image}
                        alt="Content Image"
                        className="section-image"
                        style={{
                          width: "100% !importent",
                          height: "500px !importent",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>

            <Box spacing={4} sx={{ mt: 4 }}>
              {extraContent.additional.map((content, index) => (
                <Grid
                  container
                  spacing={4}
                  key={index}
                  sx={{ mt: 4, alignItems: "center" }}
                >
                  {index % 2 === 0 ? (
                    <>
                      <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
                        <Box
                          className="image-container"
                          sx={{ textAlign: "center" }}
                        >
                          <img
                            src={content.image}
                            alt="Content Image"
                            className="section-image-alt"
                            sstyle={{
                              width: "100%",
                              height: "500px !importent",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        order={{ xs: 2, md: 2 }}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box className="text-container">
                          <Typography variant="h5" gutterBottom>
                            {content.text}
                          </Typography>
                          <Typography variant="body1">
                            {content.description}
                          </Typography>
                          <ul>
                            {content.details.map((detail, index) => (
                              <li key={index}>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: "14px" }}
                                >
                                  {detail}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        order={{ xs: 2, md: 1 }}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box className="text-container">
                          <Typography variant="h5" gutterBottom>
                            {content.text}
                          </Typography>
                          <Typography variant="p">
                            {content.description}
                          </Typography>
                          <ul>
                            {content.details.map((detail, index) => (
                              <li key={index} style={{ fontSize: "12px" }}>
                                <Typography variant="p">{detail}</Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                        <Box
                          className="image-container"
                          sx={{ textAlign: "center" }}
                        >
                          <img
                            src={content.image}
                            alt="Content Image"
                            className="responsive-image"
                            style={{
                              width: "100%",
                              height: "500px !importent",
                            }}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </Box>

            {/* New Section */}
            {/* <Box ref={sectionRef} className="">
              <Grid container spacing={4} sx={{ mt: 8 }} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box>
                    <Box>
                      <Typography
                        sx={{
                          position: "relative",
                          textAlign: "start",
                          backgroundImage: `url(${CircleImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "auto",
                          backgroundPosition: "top right",
                          padding: 12,
                          width: { xs: "150px", sm: "150px", md: "350px" },
                          height: "10px",
                        }}
                      ></Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" }, // Responsive font size
                      }}
                    >
                      {extraContent.newSectionTitle}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "1rem", sm: "1rem", md: "1rem" }, // Responsive font size
                      }}
                    >
                      {extraContent.newSectionDescription}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: "400px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    ref={scrollContainerRef}
                  >
                    <List
                      sx={{
                        padding: 0,
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        transition: "transform 0.3s ease-out",
                        transform: `translateY(${
                          (extraContent.newSection.length - visibleItems) * 100
                        }px)`,
                      }}
                    >
                      {extraContent.newSection.map((tool, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                            borderBottom: "1px solid #e0e0e0",
                            paddingBottom: 2,
                            mb: 2,
                            opacity: index < visibleItems ? 1 : 0,
                            maxHeight: "130px",
                            ":last-child": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <ModeStandbyTwoToneIcon
                              sx={{ mr: 1, color: "var(--fontcolor)" }}
                            />

                            <Typography
                              variant="h6"
                              sx={{
                                color: "var(--fontcolor)",
                                display: "inline",
                                fontSize: {
                                  xs: "1rem",
                                  sm: "1rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              {tool.title}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: {
                                xs: "0.875rem",
                                sm: "1rem",
                                md: "1rem",
                              },
                            }}
                          >
                            {tool.description}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
            <Box ref={sectionRef} className="">
              <Grid container spacing={4} sx={{ mt: 8 }} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Box>
                    <Box>
                      <Typography
                        sx={{
                          position: "relative",
                          textAlign: "start",
                          backgroundImage: `url(${CircleImage})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "auto",
                          backgroundPosition: "top right",
                          padding: 12,
                          width: { xs: "150px", sm: "150px", md: "350px" },
                          height: "10px",
                        }}
                      ></Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" },
                      }}
                    >
                      {extraContent.newSectionTitle}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "1rem", sm: "1rem", md: "1rem" },
                      }}
                    >
                      {extraContent.newSectionDescription}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: "400px",
                      overflowY: "scroll",
                      scrollbarWidth: "none", // For Firefox
                      "&::-webkit-scrollbar": {
                        display: "none", // For Chrome, Safari, and Opera
                      },
                    }}
                    ref={scrollContainerRef}
                  >
                    <List sx={{ padding: 0 }}>
                      {extraContent.newSection.map((tool, index) => (
                        <Box key={index}>
                          <ListItem
                            sx={{
                              flexDirection: "column",
                              alignItems: "flex-start",
                              paddingBottom: 2,
                              mb: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                              }}
                            >
                              <ModeStandbyTwoToneIcon
                                sx={{ mr: 1, color: "var(--fontcolor)" }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "var(--fontcolor)",
                                  display: "inline",
                                  fontSize: {
                                    xs: "1rem",
                                    sm: "1rem",
                                    md: "1rem",
                                  },
                                }}
                              >
                                {tool.title}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: {
                                  xs: "0.875rem",
                                  sm: "1rem",
                                  md: "1rem",
                                },
                              }}
                            >
                              {tool.description}
                            </Typography>
                          </ListItem>
                          {/* Divider for line separation */}
                          <Divider
                            sx={{
                              backgroundColor: "var(--white)",
                              width: "100%",
                              my: 1,
                            }}
                          />
                        </Box>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Container>
      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid var(--white)",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {/* Form Submitted Successfully */}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {pageName === "aiengineering"
                ? "Thank you for showing interest in our AI engineering services. Our team will reach out to you shortly."
                : pageName === "datascience"
                ? "Thank you for showing interest in our Data Science services. Our team will reach out to you shortly."
                : pageName === "aiquality"
                ? "Thank you for showing interest in our AI Quality services. Our team will reach out to you shortly."
                : pageName === "aiassurance"
                ? "Thank you for showing interest in our AI Assurance services. Our team will reach out to you shortly."
                : pageName === "cloudtransformation"
                ? "Thank you for showing interest in our Cloud Transformation services. Our team will reach out to you shortly."
                : pageName === "cloudmanagedservices"
                ? "Thank you for showing interest in our Cloud Managed Services. Our team will reach out to you shortly."
                : pageName === "cloudmigrations"
                ? "Thank you for showing interest in our Cloud Migrations services. Our team will reach out to you shortly."
                : pageName === "cloudoperations"
                ? "Thank you for showing interest in our Cloud Operations services. Our team will reach out to you shortly."
                : pageName === "cloudnativeapps"
                ? "Thank you for showing interest in our Cloud Native Application Development services. Our team will reach out to you shortly."
                : pageName === "clouddevops"
                ? "Thank you for showing interest in our Cloud DevOps services. Our team will reach out to you shortly."
                : pageName === "enterprisedevelopment"
                ? "Thank you for showing interest in our Enterprise Application Development services. Our team will reach out to you shortly."
                : pageName === "enterpriseplatform"
                ? "Thank you for showing interest in our Enterprise Platform Development services. Our team will reach out to you shortly."
                : pageName === "agiledevops"
                ? "Thank you for showing interest in our Agile DevOps services. Our team will reach out to you shortly."
                : pageName === "supportmaintenance"
                ? "Thank you for showing interest in our Support and Maintenance services. Our team will reach out to you shortly."
                : pageName === "intelligentautomation"
                ? "Thank you for showing interest in our Intelligent Automation RPA services. Our team will reach out to you shortly."
                : pageName === "lowcode"
                ? "Thank you for showing interest in our Low Code Application services. Our team will reach out to you shortly."
                : pageName === "BecomeAClient"
                ? "Thank you for expressing interest in becoming a client with us. We are thrilled at the prospect of working with you and are committed to providing you with exceptional service and solutions tailored to your needs. Our team will reach out to you shortly to discuss how we can best support your goals and requirements."
                : "Thank you for your submission. Our team will reach out to you shortly."}
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Header;
