import React from "react";
import { Grid, Typography, Container } from "@mui/material";

const OWS = () => {
  return (
    <Container
      sx={{
        padding: {
          xs: "150px 20px 20px 20px",
          sm: "150px 20px 20px 20px",
          md: "150px 20px 20px 20px",
          lg: "150px 20px 20px 20px",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "2rem",
            md: "2.5rem",
            lg: "3rem",
          },
        }}
      >
        OWS: A Brief Overview
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.rem",
                lg: "1rem",
              },
            }}
          >
            OWS stands for Optima Web Services. It's a cloud computing platform
            provided by TechOptima that offers a wide range of on-demand
            services to individuals, businesses, and governments. Think of it as
            renting computer resources (like processing power, storage,
            databases, etc.) over the internet, instead of buying and
            maintaining your own hardware.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: {
                xs: "1.25rem",
                sm: "1.5rem",
                md: "1.5rem",
                lg: "1.5rem",
              },
              marginBottom: "16px",
            }}
          >
            Key Benefits of OWS:
          </Typography>
          <ul>
            {[
              "Scalability: Easily adjust resources based on demand.",
              "Cost-efficiency: Pay only for what you use.",
              "Global reach: Access data centers worldwide.",
              "Reliability: High availability and durability.",
              "Security: Robust security measures in place.",
              "Wide range of services: From computing and storage to machine learning and IoT.",
            ].map((benefit, index) => (
              <Typography
                key={index}
                variant="body1"
                component="li"
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                  marginBottom: "8px",
                }}
              >
                {benefit}
              </Typography>
            ))}
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
              },
            }}
          >
            In essence, OWS empowers organizations to focus on innovation rather
            than managing infrastructure.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: {
                xs: "1.25rem",
                sm: "1.5rem",
                md: "1.5rem",
                lg: "1.5rem",
              },
              marginBottom: "16px",
            }}
          >
            Optima Web Services (OWS) Overview:
          </Typography>
          <ul>
            {[
              "Extensive Services: OWS provides over featured services including computing power, storage, databases, machine learning, and IOT.",
              "Global Reach: OWS operates data centers globally, allowing users to deploy applications and services closer to their customers.",
              "Scalability and Flexibility: OWS offers scalable solutions that can grow with your business needs. You can scale up or down based on demand, ensuring cost efficiency.",
              "Security: OWS is designed to be highly secure, meeting the requirements of sensitive organizations like global banks and the military. It offers a wide range of security tools and compliance certifications.",
              "Innovation: OWS is known for its rapid pace of innovation, introducing new technologies and services regularly. For example, AWS Lambda allows developers to run code without managing servers.",
              "Cost-Effective: OWS uses a pay-as-you-go pricing model, which helps businesses manage costs effectively by only paying for the resources they use.",
            ].map((point, index) => (
              <Typography
                key={index}
                variant="body1"
                component="li"
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                  marginBottom: "8px",
                }}
              >
                {point}
              </Typography>
            ))}
          </ul>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
              },
            }}
          >
            OWS is a powerful platform for businesses of all sizes, from
            startups to large enterprises, enabling them to innovate faster and
            operate more efficiently.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OWS;
