

import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../Config/Config";

const SignupPage = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const getFontSize = () => {
    if (isXs) return "1rem";
    if (isSm) return "1.2rem";
    if (isMd) return "1.4rem";
    if (isLg) return "1.6rem";
    return "1.8rem";
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const apiEndpoint = `${Config.apiBaseUrl}`

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save email in sessionStorage
      sessionStorage.setItem("userEmail", formData.email);

      // Send email to Flask server
      await axios.post(apiEndpoint, {
        toEmail: formData.email,
        pageName: "signup",
      });

      // Show success popup
      setOpen(true);
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error("There was an error!", error);
      alert("There was an error signing up or sending the email.");
    }
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/"); // Redirect to home page
  };

  return (
    <Container maxWidth="sm" sx={{ paddingTop: { xs: "100px", sm: "100px" } }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: getFontSize() }}
        >
          Client Signup
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "var(--button-color)",
              "&:hover": {
                backgroundColor: "var(--buttonHover) ",
              },
              color: "var(--white)",
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Signup Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for signing up! Our team will reach out to you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SignupPage;
