import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  Link,
  CardActions,
  Modal,
  Backdrop,
  Fade,
  Container,
  styled,
  keyframes,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useNavigate, useLocation } from "react-router-dom";
import Soloution from "./../../../Assets/Images/Soloution.jpg";
import Backgroundvidio from "./../../../Assets/Videos/Backgroundvidio.mp4";
import Config from "../../../Config/Config.js";

import Lambda from "./../../../Assets/Images/lambda.jpg";
import cluster from "./../../../Assets/Images/cluster.jpg";
import reservedcloud from "./../../../Assets/Images/reservedcloud.jpg";
import AI360image from "../../../Assets/Images/AI360.png";
import Contact from "../../Footer/FooterOptions/Contact/Contact";
import { ButtonPrimary, ButtonSecondary } from "../../../Styles/Styles.js";
import optimalab from "./../../../Assets/Images/blog1.jpeg";
import AppliedAIEngineering from "../../Services/Applied-AI- Engineering/AppliedAIEngineering.js";

const DividerWithText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  background: "var(--gradient2)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  paddingTop: "25px",
  margin: theme.spacing(4, 0),
  "&::after": {
    content: '""',
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.divider,
    marginRight: theme.spacing(2),
  },
}));

const contentMap = {
  "ai-engineering": {
    component: AppliedAIEngineering,

    // backgroundImage: `url(${background1})`,
    title: "Applied AI Engineering",
    subtitle:
      "Applied AI Engineering is the practice of leveraging advanced artificial intelligence (AI) technologies to solve real-world problems",
    extraContent: {
      content1:
        "As an expert in  AI 360, we offer guidance through each of these steps, ensuring that AI solutions are not only technically sound but also strategically aligned with your business goals. Our approach is collaborative and tailored to your specific needs, drawing on deep expertise to deliver impactful and sustainable AI-driven transformations.",
      heading: "Transform your enterprise data into intelligent ",
      // image: AIbussiness,
    },
    highlightedPoints: [
      {
        title: "APPLIED AI ENGINEERING",
        description:
          "Applied AI Engineering is the practice of leveraging advanced artificial intelligence (AI) technologies to solve real-world problems.",
        link: "/ai-engineering",
      },
      {
        title: "APPLIED DATA SCIENCE",
        description:
          "By leveraging Applied Data Science, organizations can transform raw data into strategic insights, driving decision-making, efficiency, and innovation.",
        link: "/data-science",
      },
      {
        title: "AI QUALITY",
        description:
          "By focusing on AI Quality, organizations can navigate the complexities of AI implementation with confidence, ensuring their systems deliver valuable and trustworthy results.",
        link: "/ai-quality",
      },
      {
        title: "AI ASSURANCE",
        description:
          "AI Assurance is crucial for building trust in AI systems by ensuring reliability, ethical behavior, and regulatory compliance.",
        link: "/ai-assurance",
      },
    ],
  },
};

const AI360 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery("(max-width:370px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    country: "",
    phone: "",
    contactReason: "Select",
    message: "",
  });
  const [pageName, setPageName] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log("Form submitted");
    console.log({ ...formData, pageName });

    const apiEndpoint = `${Config.apiBaseUrl}`; // Replace with your actual API endpoint
    const data = {
      toEmail: formData.email,
      pageName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);

      // Show success popup
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.location.reload();
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  const [open, setOpen] = useState(false);

  const handleLaunchGPUInstance = () => {
    navigate("/create-account");
  };

  return (
    <Box>
      <Box
        className="header-top"
        sx={{
          // backgroundImage: `url(${Lowcode})`,
          backgroundImage: `url(${optimalab})`, // Assuming AppliedAIEngineering is the correct import for the image
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "absulate", // Corrected from "absulate" to "center"
          color: "var(--white)",
          textAlign: { xs: "center", md: "left" },
          px: { xs: 2, sm: 4, md: 10 },
          py: { xs: 4, sm: 6, md: 8 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              color: "var(--white)",
              padding: "20px 0px",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
            gutterBottom
          >
            AI 360
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "var(--white)",
              width: { xs: "100%", md: "80%" },
              fontSize: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
            }}
          >
            AI 360 is a comprehensive AI platform offering end-to-end AI
            solutions.
          </Typography>

          <ButtonPrimary
            variant="contained"
            className="header-button"
            onClick={() =>
              navigate("/scheduleCall", { state: { from: location.pathname } })
            }
            sx={{
              mt: { xs: 2, md: 4 }, // Responsive margin
              width: { xs: "100%", sm: "auto" }, // Full width on small screens
              fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" }, // Responsive font size
              padding: { xs: "10px 20px", sm: "12px 24px", md: "14px 28px" }, // Responsive padding
            }}
          >
            Schedule a Free Consultation
          </ButtonPrimary>
        </Container>
      </Box>

      <Box
        sx={{
          padding: theme.spacing(3),
          border: "none",
          borderRadius: theme.shape.borderRadius,
          margin: theme.spacing(2),
          textAlign: "",
          boxShadow:
            "1px 1px 2px var(--boxshadow), 0 0 25px var(--boxshadow), 0 0 5px var(--boxshadow)",
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems="center"
          padding={" 25px 10px 10px 25px"}
        >
          <Grid item xs={12} md={6}>
            <Box className="image-container">
              <img
                src={AI360image}
                alt="Content Image"
                className="section-image"
                style={{ width: "80%", height: "100px !importent" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {contentMap["ai-engineering"].extraContent.heading}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {contentMap["ai-engineering"].extraContent.content1}
            </Typography>
            {/* <ul>
              {contentMap["ai-engineering"].extraContent.details.map(
                (detail, index) => (
                  <li key={index}>{detail}</li>
                )
              )}
            </ul> */}

            <Grid container spacing={4}>
              {contentMap["ai-engineering"].highlightedPoints.map(
                (point, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box>
                      <Typography
                        variant="h5"
                        component="h6"
                        gutterBottom
                        style={{}}
                      >
                        <CheckCircleIcon
                          sx={{
                            color: "var(--button-color)",
                            mr: 1,
                            fontSize: "14px",
                          }}
                        />
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(point.link);
                          }}
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textDecoration: "none",
                            color: "var(--button-color)",
                            "&:hover": {
                              color: "var(--button-color) !important",
                            },
                          }}
                        >
                          {point.title}
                        </a>
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>
                        {point.description}
                      </Typography>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center", pt: 9 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              background: "var(--text-gradient)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
              fontSize: isSmallScreen
                ? "1.75rem"
                : isMobile
                ? "2rem"
                : isTablet
                ? "3rem"
                : "6rem",
            }}
          >
            Optima AI Developer Cloud
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              pt: 2,
              fontSize: isSmallScreen
                ? "0.875rem"
                : isMobile
                ? "1rem"
                : isTablet
                ? "1.25rem"
                : "1.5rem",
            }}
          >
            On-demand NVIDIA GPU instances & clusters for AI training &
            inference
          </Typography>
          <Box
            sx={{
              mt: 4,
              mb: 6,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonPrimary
              variant="contained"
              sx={{
                mr: { xs: 0, sm: 2 },
                mb: { xs: 2, sm: 0 },
                width: { xs: "100%", sm: "auto" },
              }}
              onClick={handleLaunchGPUInstance}
            >
              Launch GPU instance
            </ButtonPrimary>
            <ButtonSecondary
              variant="outlined"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              onClick={() => navigate("/Contact", { state: { from: "home" } })}
            >
              Contact sales
            </ButtonSecondary>
          </Box>
        </Box>
      </Box>
      {/* ------------------------------------------------------------------------------------ */}

      <DividerWithText></DividerWithText>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          padding: isMobile ? "0 16px" : isTablet ? "0 50px" : "0 150px",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingRight: isMobile ? 0 : isTablet ? 4 : 6, // Padding between image and content for larger screens
            paddingTop: isMobile ? 4 : 0, // Padding above the image for mobile screens
          }}
        >
          <img
            src={Lambda} // Replace with the actual image source
            alt="Lambda Stack"
            style={{ width: "100%" }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: isMobile ? 0 : isTablet ? 4 : 6, // Padding between content and image for larger screens
            paddingBottom: isMobile ? 4 : 0, // Padding below the content for mobile screens
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isSmallScreen
                ? "1.25rem"
                : isMobile
                ? "1.5rem"
                : isTablet
                ? "2rem"
                : "2.5rem",
            }}
          >
            Optima Stack is used by more than 500 ML teams
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isSmallScreen
                ? "0.75rem"
                : isMobile
                ? "0.875rem"
                : isTablet
                ? "1rem"
                : "1.125rem",
            }}
          >
            One-line installation and managed upgrade path for PyTorch,
            TensorFlow, CUDA, cuDNN, and NVIDIA Drivers.
          </Typography>
          <ButtonSecondary
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => navigate("/optimaStackInfo")}
          >
            Learn more
          </ButtonSecondary>
        </Box>
      </Box>
      <DividerWithText></DividerWithText>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          padding: isMobile ? "0 16px" : isTablet ? "0 50px" : "0 150px",
          mt: 8,
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingRight: isMobile ? 0 : isTablet ? 4 : 6, // Padding between content and image for larger screens
            paddingBottom: isMobile ? 4 : 0, // Padding below the content for mobile screens
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isSmallScreen
                ? "1.25rem"
                : isMobile
                ? "1.5rem"
                : isTablet
                ? "2rem"
                : "2.5rem",
            }}
          >
            Introducing 1-Click Clusters
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isSmallScreen
                ? "0.75rem"
                : isMobile
                ? "0.875rem"
                : isTablet
                ? "1rem"
                : "1.125rem",
            }}
          >
            On-demand GPU clusters featuring NVIDIA H100 Tensor Core GPUs with
            Quantum-2 Infiniband. Self-serve directly from the Optima Cloud
            dashboard.
          </Typography>
          <ButtonSecondary
            variant="outlined"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => navigate("/introducingClusters")}
          >
            Learn more
          </ButtonSecondary>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: isMobile ? 0 : isTablet ? 4 : 6, // Padding between image and content for larger screens
            paddingTop: isMobile ? 4 : 0, // Padding above the image for mobile screens
          }}
        >
          <img
            src={cluster}
            alt="NVIDIA H100"
            style={{
              width: "100%",
              maxWidth: 600,
              margin: "auto",
            }}
          />
        </Box>
      </Box>
      <DividerWithText></DividerWithText>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          alignItems: "center",
          padding: isMobile ? "0 16px" : isTablet ? "0 50px" : "0 150px",
          mt: 8,
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingRight: isMobile ? 0 : isTablet ? 4 : 6, // Padding between image and content for larger screens
            paddingTop: isMobile ? 4 : 0, // Padding above the image for mobile screens
          }}
        >
          <img
            src={reservedcloud}
            alt="Voltron Data"
            style={{
              width: "100%",
              maxWidth: 600,
              margin: "auto",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: isMobile ? 0 : isTablet ? 4 : 6, // Padding between content and image for larger screens
            paddingBottom: isMobile ? 4 : 0, // Padding below the content for mobile screens
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isSmallScreen
                ? "1.25rem"
                : isMobile
                ? "1.5rem"
                : isTablet
                ? "2rem"
                : "2.5rem",
            }}
          >
            Optima Reserved Cloud powered by NVIDIA
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isSmallScreen
                ? "0.75rem"
                : isMobile
                ? "0.875rem"
                : isTablet
                ? "1rem"
                : "1.125rem",
            }}
          >
            Optima Reserved Cloud is now available with the NVIDIA H200 Tensor
            Core GPU. H200 is packed with 141GB of HBM3e running at 4.8TB/s.
            That's nearly double the GPU memory at 1.4x faster bandwidth than
            H100.
          </Typography>
          <ButtonPrimary
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => navigate("/Contact", { state: { from: "home" } })}
          >
            Get pricing
          </ButtonPrimary>
        </Box>
      </Box>
      <DividerWithText></DividerWithText>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          padding: isMobile ? "0 16px" : isTablet ? "0 50px" : "0 25px",
          mt: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            paddingRight: isMobile ? 0 : isTablet ? 2 : 0, // Padding between content and image for larger screens
            paddingBottom: isMobile ? 4 : 2, // Padding below the content for mobile screens
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isSmallScreen
                ? "1.25rem"
                : isMobile
                ? "1.5rem"
                : isTablet
                ? "2rem"
                : "2.5rem",
            }}
          >
            Want to accelerate AI system development at your company?
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: isSmallScreen
                ? "0.75rem"
                : isMobile
                ? "0.875rem"
                : isTablet
                ? "1rem"
                : "1.125rem",
            }}
          >
            See how we can help.
          </Typography>
          <ButtonPrimary
            variant="contained"
            onClick={() => navigate("/scheduleCall")}
          >
            Schedule a Call
          </ButtonPrimary>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingLeft: isMobile ? 0 : isTablet ? 2 : 0, // Padding between image and content for larger screens
            paddingTop: isMobile ? 2 : 0, // Padding above the image for mobile screens
            paddingBottom: "5px",
          }}
        >
          <img
            src={Soloution}
            alt="Soloution"
            style={{
              width: "100%",
              maxWidth: 800,
              margin: "auto",
            }}
          />
        </Box>
      </Box>
      <Contact
        formData={formData}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        setFormData={setFormData}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid var(--white)",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {/* Form Submitted Successfully */}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Thank you for your submission. Our team will reach out to you
              shortly.
            </Typography>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              OK
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AI360;
