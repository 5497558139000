import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Password } from "@mui/icons-material";

const SigninClient = () => {
  return (
    <Container
      maxWidth="xs"
      sx={{
        marginTop: "150px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Sign In
      </Typography>
      <Box component="form" sx={{ mt: 1, width: "100%" }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email-phone"
          label="Email Address or Phone Number"
          name="email-phone"
          autoComplete="email-phone"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "var(--button-color)",
            "&:hover": {
              backgroundColor: "var(--buttonHover)",
            },
          }}
        >
          Sign In
        </Button>
      </Box>
    </Container>
  );
};

export default SigninClient;
